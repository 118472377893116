interface JobCategory {
    code: string;
    name: string;
}
const jobCategories: JobCategory[] = [
    {
      code: "BG",
      name: "Building & Grounds Cleaning and Maintenance", 
    },
    {
        code: "CC",
        name: "Child Care", 
    },
    {
        code: "CM",
        name: "Communication & Media", 
    },
    {
        code: "CS",
        name: "Community and Social Service", 
    },
    {
        code: "CT",
        name: "Construction Trade", 
    },
    {
        code: "ET",
        name: "Education and Training", 
    },
    {
        code: "EG",
        name: "Environmental / GreenJobs", 
    },
    {
        code: "FM",
        name: "Fashion Designing / Modelling", 
    },
    {
        code: "FG",
        name: "Federal Government", 
    },
    {
        code: "FS",
        name: "Food Service", 
    },
    {
        code: "HC",
        name: "Health Care", 
    },
]

export default jobCategories;