import React from "react";
import { Navigate } from "react-router-dom";
import { UserRole } from "src/app/@types/enums/user.roles";
import YouthJobApplication from "./YouthJobApplication";

import i18next from "i18next";
import english from "./i18n/english";
import spanish from "./i18n/spanish";
import korean from "./i18n/korean";
import PdfCompoent from "./pdfCompoent";
import ProofofAge from "./proofofAge";
import ProofofResidence from "./proofofResidence";
import ProofofSSN from "./proofofSSN";
import ProofofWork from "./proofofWork";
import YouthJobApplicationBankingPage from "./youth-job-application-banking/youth-job-application-banking.Page";
import YouthJobApplicationCertificatePage from "./youth-job-application-certificate-content/youth-job-application-certificate.Page";
import YouthJobApplicationOrientationPage from "./youth-job-application-orientation-content/youth-job-application-orientation.Page";
import TransportDetails from "./transportDetails";
import ParentConsentFormAddPage from "./parentConsentFormAdd";
import JobApplicationList from "./youth-job-application-index";
import CareerExpoInfoPage from "../admin-program/admin-program-add-update/components/careerExpoInfo";
import CareerInterest from "../auth/sign-up/components/youth-sign-up/CareerInterest";
import ApplicationEnroll from "./application-enroll";

i18next.addResourceBundle("en", "JobApplication", english);
i18next.addResourceBundle("es", "JobApplication", spanish);
i18next.addResourceBundle("kr", "JobApplication", korean);

export const YouthJobApplicationRoutes = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "job-application-index",
      element: <JobApplicationList />,
    },
    {
      path: "job-application",
      element: <YouthJobApplication />,
      children: [
        {
          path: "",
          element: <Navigate to="application-enroll" />,
        },
        {
          path: "application-enroll",
          element: <ApplicationEnroll />,
        },
        {
          path: "career-interest",
          element: <CareerInterest />,
        },
        {
          path: "application-resume",
          element: <PdfCompoent />,
        },
        {
          path: "proof-of-age",
          element: <ProofofAge />,
        },
        {
          path: "proof-of-residency",
          element: <ProofofResidence />,
        },
        {
          path: "proof-of-ssn",
          element: <ProofofSSN />,
        },
        {
          path: "proof-of-work",
          element: <ProofofWork />,
        },
        {
          path: "certification",
          element: <YouthJobApplicationCertificatePage />,
        },
        {
          path: "orientation",
          element: <YouthJobApplicationOrientationPage />,
        },
        {
          path: "application-banking",
          element: <YouthJobApplicationBankingPage />,
        },
        {
          path: "transportation",
          element: <TransportDetails />,
        },
        {
          path: "parent-consent-form",
          element: (
            <ParentConsentFormAddPage isParent={false} isAdmin={false} />
          ),
        },
      ],
    },
  ],
};
