import React from 'react'
import Card from '@mui/material/Card/Card'

function UnderConstruction() {
  return (
    <div>
      <Card variant="outlined" className="m-10 mb-12">
     <img
       src="assets/images/demo-content/UnderConstruction.png"
       alt="beach"
       style={{
         maxWidth: '400px',
         width: '100%',
         margin:'auto'
       }}
       className="rounded-6"
     />
     </Card>
    </div>
    
  )
}

export default UnderConstruction
