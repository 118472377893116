import React from "react";
import { Navigate } from "react-router-dom";
import { UserRole } from "src/app/@types/enums/user.roles";
import YouthJobApplication from "./YouthJobApplication";

import i18next from "i18next";
import english from "./i18n/english";
import spanish from "./i18n/spanish";
import korean from "./i18n/korean";
import PdfCompoent from "./pdfCompoent";
import ProofofAge from "./proofofAge";
import ProofofResidence from "./proofofResidence";
import ProofofSSN from "./proofofSSN";
import ProofofWork from "./proofofWork";
import YouthJobApplicationBankingPage from "./youth-job-application-banking/youth-job-application-banking.Page";
import YouthJobApplicationCertificatePage from "./youth-job-application-certificate-content/youth-job-application-certificate.Page";
import YouthJobApplicationOrientationPage from "./youth-job-application-orientation-content/youth-job-application-orientation.Page";
import TransportDetails from "./transportDetails";
import ParentConsentFormAddPage from "./parentConsentFormAdd";

i18next.addResourceBundle("en", "JobApplication", english);
i18next.addResourceBundle("es", "JobApplication", spanish);
i18next.addResourceBundle("kr", "JobApplication", korean);

export const ParentConsentFormParentViewRoutes = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null,
  routes: [
    {
      path: "parent-consent-form/:userId",
      element: <ParentConsentFormAddPage isParent={true} isAdmin={false} />,
    },
  ],
};
