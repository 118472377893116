import React from "react";
import { Navigate } from "react-router-dom";
import IncidentForm from "./incident-form";
import TerminationFormAddUpdatePage from "./incident-form-add-update/IncidentFormAddUpdatePage";
import IncidentFormIndexPage from "./incident-form-index/IncidentFormIndexPage";
import { UserRole } from "src/app/@types/enums/user.roles";

export const IncidentFormRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Host],
  routes: [
    {
      path: "incident-form",
      element: <IncidentForm />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <IncidentFormIndexPage />,
        },
        {
          path: "add",
          element: <TerminationFormAddUpdatePage />,
        },
        {
          path: "update/:id",
          element: <TerminationFormAddUpdatePage />,
        },
      ],
    },
  ],
};
