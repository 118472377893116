import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";

import Typography from "@mui/material/Typography";

import React from "react";

import ReactPlayer from "react-player";
function Video() {
  return (
    <div className="flex flex-col p-10 sm:p-10 container">
      <div className="flex flex-col w-full max-w-6xl">
        <div className="sm:mt-5">
          <ReactPlayer
            width={"100%"}
            url="https://www.youtube.com/watch?v=Y2v4v1A3fCU"
            controls={true}
          />
        </div>
      </div>
    </div>
  );
}

export default Video;
