import authRoles from "../../../auth/authRoles";
import TwoFactorVerificationPage from "./TwoFactorVerificationPage";
import TwoFactorVerificationSelectPage from "./TwoFactorVerificationSelectPage";

import i18next from "i18next";
import english from "./i18n/english";
import spanish from "./i18n/spanish";
import korean from "./i18n/korean";

i18next.addResourceBundle("en", "2FaPage", english);
i18next.addResourceBundle("es", "2FaPage", spanish);
i18next.addResourceBundle("kr", "2FaPage", korean);

const TwoFAConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null,
  routes: [
    {
      path: "two-factor-verification-select",
      element: <TwoFactorVerificationSelectPage />,
    },
    {
      path: "two-factor-verification/:authType",
      element: <TwoFactorVerificationPage />,
    },
  ],
};

export default TwoFAConfig;
