const locale = {
  SALARY_DETAILS: "급여 내역",
  PROGRAME: "프로그램",
  COMPANY: "회사",
  YEAR: "년도",
  WEEK: "주",
  VIEW_BTN: "보다",
  DOWNLOAD_BTN: "다운로드",
  HOURS_WORKED: "근무 시간",
  HOURLY_RATE: "시간당 요금",
  TOTAL: "총",
};
export default locale;
