import * as React from "react";
import AdminViewAnswersFilter from "./components/AdminViewAnswersFilter";
import AdminViewAnswersTable from "./components/AdminViewAnswersTable";
import IndexHeader from "app/shared-components/IndexHeader";
import CustomButton from "app/shared-components/CustomColorButton";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import history from "@history";

const handleClick = () => {
  history.push("admin-view-answer/add");
};

export default function AdminViewAnswersIndexPage() {
  return (
    <>
      <IndexHeader
        name={"View Answers"}
        update={false}
        add={false}
        buttons={[]}
      />
      <AdminViewAnswersFilter />
      <AdminViewAnswersTable />
    </>
  );
}
