const spanish = {
  //banking
  INDEX_NAME_BANKING: "Bancario",
  View_Video: "Ver vídeo",
  Test_Title: "Evaluación",
  Question_One: "Sample Question1",
  Question_Two: "Sample Question2",
  Question_Three: "Sample Question3",
  Question_Four: "Sample Question4",
  Question_Five: "Sample Question5",
  Question_Six: "Sample Question6",
  Question_Seven: "Sample Question7",
  Question_Eight: "Sample Question8",

  Question_Nine: "Sample Question9",
  Question_Ten: "Sample Question10",

  //radio options
  Rad_One: "RadioOption1",
  Rad_Two: "RadioOption2",
  Rad_Three: "RadioOption3",
  Rad_Four: "RadioOption4",

  Submit_BTN: "Submit",
  ReAttampt_BTN: "Re-Attempt",

  //certifiaction
  Index_Name: "Aplicacion de trabajo",
  View_Video: "Ver vídeo",
  Test_Title: "prueba",
  Question_One: "Sample Question1",
  Question_Two: "Sample Question2",
  Question_Three: "Sample Question3",
  Question_Four: "Sample Question4",
  Question_Five: "Sample Question5",
  Question_Six: "Sample Question6",
  Question_Seven: "Sample Question7",
  Question_Eight: "Sample Question8",
  Question_Nine: "Sample Question9",
  Question_Ten: "Sample Question10",
  //radio options
  Rad_One: "RadioOption1",
  Rad_Two: "RadioOption2",
  Rad_Three: "RadioOption3",
  Rad_Four: "RadioOption4",
  RadioBtn_Error: "Please select an option",
  Submit_BTN: "entregar",
  ReAttampt_BTN: "Reintentar",
  //error messages
  Error_Message: "Please select an option",

  //Orientation

  Index_Name: "Job Application",
  View_Video: "View Video",
  Test_Title: "Test",
  Question_One: "Sample Question1",
  Question_Two: "Sample Question2",
  Question_Three: "Sample Question3",
  Question_Four: "Sample Question4",
  Question_Five: "Sample Question5",
  Question_Six: "Sample Question6",
  Question_Seven: "Sample Question7",
  Question_Eight: "Sample Question8",
  Question_Nine: "Sample Question9",
  Question_Ten: "Sample Question10",
  //radio options
  Rad_One: "RadioOption1",
  Rad_Two: "RadioOption2",
  Rad_Three: "RadioOption3",
  Rad_Four: "RadioOption4",
  RadioBtn_Error: "Please select an option",
  Submit_BTN: "Submit",
  ReAttampt_BTN: "Re-Attempt",
  //error messages
  Error_Message: "Please select an option",

  //Proof of Residency
  INDEX_NAME: "Aplicacion de trabajo",
  BROWSE_BTN: "Entregar",

  UPLOAD_HINT: "Cargue su archivo jpg, jpeg, png o pdf solamente",
  Acceptable_Documents: "Documentos Aceptables",
  Type: "Tipo",
  SELECTED_DOCUMENTS_ERROR: "Debe seleccionar al menos un documento",
  Drivers_licens:
    "Licencia de conducir emitida por el estado, permiso de aprendizaje o tarjeta de identificación de no conductor vigente",
  Official_School_Record:
    "Registro escolar oficial, como una transcripción o boleta de calificaciones (debe mostrar el nombre y la dirección del participante)",
  Immunization_Record:
    "Registro de vacunas (debe mostrar el nombre y la dirección del participante y debe estar firmado y fechado por un funcionario escolar)",
  Income_Tax_Form:
    "Formulario 1040 de impuestos sobre la renta de 2014 (debe estar firmado y notariado)",
  Executed_Lease:
    "Contrato de arrendamiento o alquiler ejecutado con fecha dentro de un año (debe estar firmado y fechado 2014 W-2)",
  notics:
    "(*No aceptaremos ningún documento de la Oficina de Programas Juveniles. Todos los documentos que verifiquen la residencia deben mostrar el nombre y la dirección de los participantes)",
  No_FILE_SELECTED: "No file selected ",
  No_FILE_SELECTED: "Ningún archivo seleccionado",
  Browse_Btn: "Examinar",
  Upload_Btn: "Subir",
  DELETE_BTN: "Eliminar",
  Learners_Permit: "Permiso de aprendizaje",
  PROOF_OF_RESIDENCY_DESCRIPTION:
    "Para garantizar un registro preciso y la identificación del participante, requerimos una copia del documento de identificación de su hijo, como una licencia de conducir vigente emitida por el estado, un permiso de aprendizaje, una tarjeta de identificación de no conductor, un registro escolar oficial, un registro de vacunas, un formulario de impuestos sobre la renta, Contrato de Arrendamiento o Alquiler Ejecutado. Este proceso de verificación es crucial para la administración del programa y para garantizar la seguridad de todos los participantes.",
  Non_Drivers_Identification_Card: "Tarjeta de identificación de no conductor",

  Official_School_Record: "Expediente Escolar Oficial",

  Immunization_record: "Cartilla de vacunación",

  Income_Tax_Form: "Formulario de Impuesto sobre la Renta",

  Executed_Lease_Rental_Agreement:
    "Contrato de Arrendamiento o Alquiler Ejecutado",
  APPROVAL_STATUS: "Aprobación pendiente",

  // Proof of age
  Index_Name: "Aplicacion de trabajo",
  Submit_Btn: "Entregar",

  UploadHint: "Cargue su archivo jpg, jpeg, png o pdf solamente",
  Acceptable_Documents: "Documentos aceptables",
  Type: "Tipo",
  SELECTED_DOCUMENTS_ERROR: "Debe seleccionar al menos un documento",
  Us_Passport: "Pasaporte de EE. UU.",
  Unexpired_state_issued_DriverLicense:
    "Licencia de conducir emitida por el estado vigente",
  Learners_PermitorNon_Driver_Identification_Card:
    "Permiso de aprendizaje o tarjeta de identificación de no conductor",
  Official_School_Record: "Registro escolar oficial",
  School_ID: "Identificación de la escuela",
  Permanent_Resident_Card: "Tarjeta de residente permanente",
  No_FILE_SELECTED: "Ningún archivo seleccionado",
  Browse_Btn: "Examinar",
  Upload_Btn: "Subir",
  APPROVAL_STATUS: "Aprobación pendiente",
  FILE_NAME: "Nombre del archivo",
  PROOF_OF_AGE_DESCRIPTION:
    "Para garantizar un registro preciso y la identificación del participante, requerimos una copia del documento de identificación de su hijo, como un pasaporte, una licencia de conducir emitida por el estado que no haya vencido, un permiso de aprendizaje, una identificación de no conductor, un registro escolar oficial, una identificación escolar o una tarjeta de residente permanente. . Este proceso de verificación es crucial para la administración del programa y para garantizar la seguridad de todos los participantes.",
  //Proof of ssn
  INDEX_NAME: "aplicacion de trabajo",
  PROOF_OF_SSN: "Prueba de SSN",
  BROWSE_BTN: "Entregar",

  UPLOAD_HINT: "Cargue su archivo jpg, jpeg, png o pdf solamente",
  ACCEPTABLE_DOCUMENTS: "Documentos aceptables",
  TYPE: "Tipo",
  SELECTED_DOCUMENTS_ERROR: "Debe seleccionar al menos un documento",

  NO_FILE_SELECTED: "Ningún archivo seleccionado",
  LETTER_SSN:
    "Carta de la Administración del Seguro Social (debe mostrar el nombre y SSN del participante)",
  SHOWING_PARTICIPANT_SSSN:
    "2014 W-2 (que muestre el nombre y SSN del participante)",
  SSN_CARD_DOC: "Tarjeta de seguro Social",
  LETTER_DOC: "Carta de la Administración del Seguro Social",
  W2_DOC: "2014 W-2",
  PROOF_OF_SSN_DESCRIPTION:
    "Para garantizar un registro preciso y la identificación del participante, requerimos una copia del documento de identificación de su hijo, como una carta de la Administración del Seguro Social (debe mostrar el nombre y SSN del participante) o el formulario W-2 2014 (que muestra el nombre y SSN del participante) . Este proceso de verificación es crucial para la administración del programa y para garantizar la seguridad de todos los participantes.",

  //Proof of work
  INDEX_NAME: "Aplicacion de trabajo",
  PROOF_OF_WORK: "Prueba de trabajo",
  BROWSE_BTN: "Entregar",
  PROOF_OF_WORK_DESCRIPTION:
    "Para garantizar un registro preciso y la identificación del participante, requerimos una copia del documento de identificación de su hijo, como una identificación con foto y un certificado de nacimiento de EE. UU. o tarjeta de seguro social, pasaporte de EE. UU., tarjeta de residente permanente o formulario INS, pasaporte extranjero vigente con 551 Sello. Este proceso de verificación es crucial para la administración del programa y para garantizar la seguridad de todos los participantes.",

  UPLOAD_HINT: "Cargue su archivo jpg, jpeg, png o pdf solamente",
  ACCEPTABLE_DOCUMENTS: "Documentos aceptables",
  TYPE: "Tipo",
  SELECTED_DOCUMENTS_ERROR: "Debe seleccionar al menos un documento",

  NO_FILE_SELECTED: "Ningún archivo seleccionado",
  ACCEPTABLE_DOCUMENTS_01:
    "Una identificación con foto y un certificado de nacimiento de los EE. UU. (La identificación con foto debe ser una licencia de conducir emitida por el estado, una tarjeta de identificación o una identificación escolar)",
  ACCEPTABLE_DOCUMENTS_02:
    "Una identificación con fotografía y una tarjeta del Seguro Social (la identificación con fotografía debe ser una licencia de conducir, una tarjeta de identificación o una identificación escolar emitida por el estado)",
  ACCEPTABLE_DOCUMENTS_03:
    "Pasaporte de EE. UU., tarjeta de residente permanente o formulario INS I-766 con autorización de empleo vigente",
  ACCEPTABLE_DOCUMENTS_04:
    "Pasaporte extranjero vigente con sello I-551 o formulario INS adjunto I-94",

  DOC_TYPE_01:
    "Una identificación con foto y un certificado de nacimiento de los EE. UU.",
  DOC_TYPE_02: "Una identificación con foto y una tarjeta de seguro social",
  DOC_TYPE_03:
    "Pasaporte de EE. UU., tarjeta de residente permanente o formulario INS",
  DOC_TYPE_04: "Pasaporte extranjero vigente o formulario INS adjunto",
  APPROVAL_PENDING: "Aprobación pendiente",

  //transportation
  Index_Name: "aplicacion de trabajo",
  Submit_Btn: "Entregar",
  Card_Number: "Número de tarjeta",
  Card_Fair: "feria de cartas",
  Card_Issue_Date: "Fecha de emisión de la tarjeta",
  //error messages
  MUST_ENTER_CARD_NUMBER: "Debes ingresar un Número de Tarjeta",
  MUST_ENTER_CARD_FAIR: "Debes ingresar a una Feria de Tarjetas.",
  MUST_ENTER_CARD_ISSUEDATE: "Debe ingresar una fecha de emisión de la tarjeta",

  INDEX_NAME_ORIENTATION: "Aplicacion de trabajo",

  //certificate
  INDEX_NAME_CERTIFICATE: "Aplicacion de trabajo",
  //resume
  UPLOAD_HINT_ONE: "Solo se admiten archivos pdf. ",
  UPLOAD_BTN: "Subir",
  DOWNLOAD_BTN: "Descargar",

  //sideBar navigation
  RESUME: "REANUDAR",
  PROOF_OF_AGE: "Prueba de edad",

  //sideBar navigation
  RESUME_NAVI: "REANUDA",
  PROOF_OF_AGE_NAVI: "Prueba de edad",
  PROOF_OF_SSN_NAVI: "Prueba de SSN",
  PROOF_OF_WORK_NAVI: "Prueba de trabajo",
  PROOF_OF_TRANSPORTATION_NAVI: "Prueba de transporte",
  PROOF_OF_RESIDENCE_NAVI: "Prueba de residencia",
  CERTIFICATE_NAVI: "Certificado",
  ORIENTATION_NAVI: "Orientación",
  BANKING_NAVI: "Bancario",
};

export default spanish;
