import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import ProfileHeader from "./components/ProfileHeader";
import { styled } from "@mui/material/styles";
import FusePageSimple from "@fuse/core/FusePageSimple";
import FuseNavigation from "@fuse/core/FuseNavigation";
import { useThemeMediaQuery } from "@fuse/hooks";
import Card from "@mui/material/Card";
import { motion } from "framer-motion";
import { UserRole } from "src/app/@types/enums/user.roles";
import { useSelect } from "@mui/base";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import UnderConstruction from "./components/UnderConstruction";
import { UserRoleNames } from "src/app/@types/enums/user.rolenames";

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    "& > .container": {
      maxWidth: "100%",
    },
  },
}));

const navigationData = [
  {
    id: "1",
    title: "Actions",
    type: "group",
    children: [
      {
        id: "1",
        title: "Basic Info",
        type: "item",
        icon: "heroicons-outline:plus-circle",
        url: "contact-info",
      },
      {
        id: "2",
        title: "Program Info",
        type: "item",
        icon: "heroicons-outline:user-group",
        url: "program-info",
      },
      {
        id: "3",
        title: "Disclaimer",
        type: "item",
        icon: "heroicons-outline:briefcase",
        url: "disclaimer",
      },
    ],
  },
];

export default function UserProfile() {
  const isMobile = useThemeMediaQuery((theme: any) =>
    theme.breakpoints.down("lg")
  );

  const user = useSelector(selectUser);
  console.log(user);

  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <>
      <Root
        header={<ProfileHeader />}
        content={
          <Card variant="outlined" className="m-24 w-full">
            <div className="m-24">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1, transition: { delay: 0.2 } }}
              >
                {user.data.role == UserRoleNames.Host ? (
                  <Outlet></Outlet>
                ) : (
                  <UnderConstruction />
                )}
              </motion.div>
            </div>
          </Card>
        }
        leftSidebarContent={
          <div className="px-12 py-24 min-h-6xl">
            <div className="mx-12 text-3xl font-bold tracking-tighter">
              My Profile
            </div>
            <FuseNavigation navigation={user.data.role == UserRoleNames.Host ? navigationData : []} />
          </div>
        }
        leftSidebarOpen={leftSidebarOpen}
        scroll="content"
      />
    </>
  );
}
