import * as React from "react";

import AdminTerminationFormFilter from "./components/AdminTerminationFormFilter";
import AdminTerminationFormTable from "./components/AdminTerminationFormTable";

import IndexHeader from "app/shared-components/IndexHeader";
import CustomButton from "app/shared-components/CustomColorButton";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import history from "@history";

const handleClick = () => {
  history.push("admin-termination-form/add");
};

export default function AdminTerminationFormIndexPage() {
  return (
    <>
      <IndexHeader
        name={"Admin Termination Form "}
        update={false}
        add={false}
        buttons={[]}
      />
      <AdminTerminationFormFilter />
      <AdminTerminationFormTable />
    </>
  );
}
