import axios, { AxiosInstance } from "axios";
import { AuthDataService } from "../../services/data/auth.data.service";

export class ApiInterceptor {
  public axios: AxiosInstance = axios.create();
  private static instance: ApiInterceptor;

  private constructor() {
    this.initRequest();
  }

  public static getInstance(): ApiInterceptor {
    if (!ApiInterceptor.instance) {
      ApiInterceptor.instance = new ApiInterceptor();
    }
    return ApiInterceptor.instance;
  }

  private initRequest() {
    this.axios.interceptors.request.use(
      (config) => {
        if (AuthDataService.token) {
          config.headers["Authorization"] = "Bearer " + AuthDataService.token;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }

  private initResponse() {
    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status == 500) {
          Promise.reject(error);
        }
        return;
      }
    );
  }
}
