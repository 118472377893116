import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/Add";
import GenaralInfo from "./components/genaral-info";
import CareerExpo from "./components/career-expo";
import FinancialSupport from "./components/financial-support";

export default function SimpleAccordion() {
  return (
    <div className="m-24">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="mt-8 text-2xl sm:text-1xl font-bold tracking-tight leading-tight">
            General Info
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <GenaralInfo />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <div className="mt-8 text-2xl sm:text-1xl font-bold tracking-tight leading-tight">
            Career Expo
          </div>
        </AccordionSummary>
        <AccordionDetails>
        <CareerExpo />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <div className="mt-8 text-2xl sm:text-1xl font-bold tracking-tight leading-tight">
            Financial Support
          </div>
        </AccordionSummary>
        <AccordionDetails>
        <FinancialSupport />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
