import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import _ from "@lodash";
import { Avatar, Checkbox, ListItem, Typography, styled } from "@mui/material";
import { format } from "date-fns";
import React, { useContext } from "react";

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  background: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.divider}`,

  "&.selected": {
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      display: "block",
      height: "100%",
      width: 3,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default function HostMailListItem(props: MailData) {
  return (
    <div className="w-full mt-16 mb-16 ">
      <div className="flex flex-col flex-auto min-w-0">
        <div className="flex w-full space-x-6 items-center mb-8 ">
          <Avatar
            sx={{
              backgroundColor: (theme: any) => theme.palette.primary[500],
            }}
            src={props.avatar}
            className="mt-1"
          ></Avatar>

          <div className="flex flex-col w-full min-w-0">
            <div className="flex items-center w-full">
              <div className="font-bold">{props.username}</div>
              <Typography
                className="ml-auto mt-0 text-md text-right whitespace-nowrap"
                color="text.secondary"
              >
                {format(new Date(props.date), "LLL dd")}
              </Typography>
            </div>
            <div className="flex items-center w-full mt-4">
              <Typography
                color="text.secondary"
                className="mt-8 leading-normal line-clamp-2"
              >
                {_.truncate(props.content.replace(/<(?:.|\n)*?>/gm, ""), {
                  length: 180,
                })}
              </Typography>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
}
