const locale = {
  SALARY_DETAILS: "Salary Details",
  PROGRAME: "Program",
  COMPANY: "Company",
  YEAR: "Year",
  WEEK: "Week",
  VIEW_BTN: "View",
  DOWNLOAD_BTN: "Download",
  HOURS_WORKED: "Hours Worked",
  HOURLY_RATE: "Hourly Rate",
  TOTAL: "Total",
};
export default locale;
