import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  FormControl,
  Select,
  MenuItem,
  Card,
  Typography,
  FormHelperText,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import IndexHeader from "app/shared-components/IndexHeader";
import { useParams } from "react-router-dom";
import CustomButton from "app/shared-components/CustomColorButton";
import history from "@history";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PrintIcon from "@mui/icons-material/Print";

const defaultValues = {
  participantName: "",
  hostAgency: "",
  date: "",
  worksiteName: "",
  worksiteSupervisor: "",
  participantPosition: "",
  falsification: "",
  insubordination: "",
  disruptive: "",
  excessive: "",
  possession: "",
  harassment: "",
  theft: "",
  other: "",
  otherReason: "",
  reason: "",
  ssn: "",
  approvedBy: "",
  terminationReq: "",
  terminationResult: "",
  Date: "",
};

const schema = yup.object().shape({
  participantName: yup.string().required("First Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Enter valid Email address"),
  employeeType: yup.string().required("Employee Type is required"),
  primaryPhone: yup.string().required("Primary Phone is required"),
  secondaryPhone: yup.string().required("Secondary Phone is required"),
  dob: yup.string().nullable().required("Date of Birth is required"),
  faxNumber: yup.string().required("Fax Number is required"),
  ssn: yup.string().required("SSN is required"),
  isUnder18: yup.boolean().default(false),
  isCreateSystemAccount: yup.boolean().default(false),
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), ""], "Password not matched"),

  approvedBy: yup.string().required("Approved By is required"),
});

export default function AdminTerminationFormAddUpdate() {
  const routeParams = useParams();
  const { id } = routeParams;

  const { control, handleSubmit, watch, formState, register } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  const [values, setValue] = useState({
    employeeType: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue({
      ...values,
      [name]: value,
    });
  };

  const handleClick = () => {
    history.push("admin-termination-form/add");
  };

  const myButtons = [
    <Button
      className="mx-8"
      variant="contained"
      disabled={false}
      startIcon={<CancelIcon />}
    >
      {"Cancel"}
    </Button>,

    <CustomButton
      StartIcon={<PrintIcon />}
      className="mx-8"
      disabled={false}
      onClick={handleClick}
      name="Print"
      color="primary"
      variant="contained"
    ></CustomButton>,
    <CustomButton
      StartIcon={<SaveIcon />}
      className="mx-8"
      disabled={false}
      onClick={handleClick}
      name="Download"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];

  return (
    <>
      <IndexHeader
        name={"Admin Termination Form"}
        update={id ? true : false}
        add={id ? false : true}
        previousPath={"admin-termination-form/list"}
        buttons={myButtons}
      />

      <Card variant="outlined" className="m-24 mb-12">
        <div className="m-24 mt-8">
          <div className="flex grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start">
          <Controller
              control={control}
              name="participantName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Participant Name"
                  placeholder="Participant Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.participantName}
                  helperText={errors?.participantName?.message}
                  value={"David Williams"}
                />
              )}
            />
            <Controller
              control={control}
              name="ssn"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="SSN (Last four digits) XXX-XX-0000"
                  placeholder="SSN (Last four digits)"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.ssn}
                  helperText={errors?.ssn?.message}
                  value={"XXX-XX-1586"}
                />
              )}
            />
            <Controller
              control={control}
              name="hostAgency"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Host Agency"
                  placeholder="Host Agency"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.hostAgency}
                  helperText={errors?.hostAgency?.message}
                  value={"Codice"}
                />
              )}
            />
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <DatePicker
                  className="mt-16 w-full"
                  label="Date"
                  slotProps={{ textField: { size: "small" } }}
                  value={Date.now()}
                />
              )}
            />
            <Controller
              control={control}
              name="worksiteName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Worksite Name"
                  placeholder="Worksite Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.worksiteName}
                  helperText={errors?.worksiteName?.message}
                  value={"Head Office"}
                />
              )}
            />
             <Controller
              control={control}
              name="worksiteSupervisor"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Worksite Supervisor"
                  placeholder="Worksite Supervisor"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.worksiteSupervisor}
                  helperText={errors?.worksiteSupervisor?.message}
                  value={"Thomas Brown"}
                />
              )}
            />

            <Controller
              control={control}
              name="participantPosition"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Participant's Position"
                  placeholder="Participant's Position"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.participantPosition}
                  helperText={errors?.participantPosition?.message}
                  required
                  value={"Accountant Assistance"}
                />
              )}
            />

            <Controller
              control={control}
              name="participantPosition"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Status"
                  placeholder="Status"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.participantPosition}
                  helperText={errors?.participantPosition?.message}
                  required
                  value={"Approved"}
                />
              )}
            />
          </div>
          <Typography className="ml-10 mt-16 text-2xl font-semibold tracking-tight leading-tight">
            Please check the box indicating the reason for the termination:
          </Typography>
          <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
            <Controller
              control={control}
              name="falsification"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Falsification of documents ( e.g. signing in and out for someone)"
                  labelPlacement="end"
                />
              )}
            />
          </div>
          <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
            <Controller
              control={control}
              name="insubordination"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Insubordination (e.g. disobeying a supervisor)"
                  labelPlacement="end"
                />
              )}
            />
          </div>
          <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
            <Controller
              control={control}
              name="disruptive"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Disruptive behavior"
                  labelPlacement="end"
                />
              )}
            />
          </div>
          <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
            <Controller
              control={control}
              name="excessive"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Excessive absences"
                  labelPlacement="end"
                  checked
                />
              )}
            />
          </div>
          <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
            <Controller
              control={control}
              name="possession"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Possession, sale or use of illegal drugs"
                  labelPlacement="end"
                />
              )}
            />
          </div>
          <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
            <Controller
              control={control}
              name="harassment"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Harassment ( e.g. sexual, verbal, or physical)"
                  labelPlacement="end"
                />
              )}
            />
          </div>
          <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
            <Controller
              control={control}
              name="theft"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Theft"
                  labelPlacement="end"
                />
              )}
            />
          </div>
          <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
            <Controller
              control={control}
              name="other"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Other"
                  labelPlacement="end"
                />
              )}
            />
            <Controller
              control={control}
              name="otherReason"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="w-full"
                  label="other"
                  placeholder="other"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              )}
            />
          </div>
          <div className="ml-10 mr-10">
            <Controller
              control={control}
              name="reason"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Please provide a detailed explanation supporting the termination
                  request."
                  placeholder="Please provide a detailed explanation supporting the termination
                  request."
                  variant="outlined"
                  fullWidth
                  multiline
                  size="small"
                  value={"David is taking too many leaves without informing prior"}
                />
              )}
            />
          </div>
        </div>
      </Card>
    </>
  );
}
