import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import BackIcon from "@mui/icons-material/Backspace";
import { FormControl, InputLabel, Select, IconButton, Card, MenuItem } from "@mui/material";

const defaultValues = { name: "", email: "", subject: "", message: "" };
const schema = yup.object().shape({
  name: yup.string().required("You must enter a name"),
  subject: yup.string().required("You must enter a subject"),
  message: yup.string().required("You must enter a message"),
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
});

function AdminIncidentFormFilter() {
  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  if (_.isEmpty(form)) {
    return null;
  }

  return (
    <>

<Card variant="outlined" className="m-24 mb-0">
        <div className="flex content-between justify-start m-24  ">
          <div className="w-1/5 mt-16 mr-16">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Program</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                >
                  <MenuItem value={"1"}>
                    Summer Youth Employment Program
                  </MenuItem>
                  <MenuItem value={"2"}>In School Youth Program</MenuItem>
                  <MenuItem value={"3"}>Out-of-School Youth Program</MenuItem>
                  <MenuItem value={"4"}>Youth Leadership Program</MenuItem>
                  <MenuItem value={"5"}>Summer Intensive Training</MenuItem>
                  <MenuItem value={"6"}>
                    Pathways for Young Adults Program
                  </MenuItem>
                  <MenuItem value={"7"}>Youth Earn and Learn Program</MenuItem>
                  <MenuItem value={"8"}>School Year Program</MenuItem>
                  <MenuItem value={"8"}>Global Education Program</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="w-1/5 mt-16 mr-16">
            {" "}
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Year"
                >
                  <MenuItem value={"1"}>2020</MenuItem>
                  <MenuItem value={"2"}>2021</MenuItem>
                  <MenuItem value={"3"}>2022</MenuItem>
                  <MenuItem value={"4"}>2023</MenuItem>
                  <MenuItem value={"5"}>2024</MenuItem>
                  <MenuItem value={"5"}>2025</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="w-1/5  mr-16">
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Company name"
                  placeholder="company name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.subject}
                  helperText={errors?.subject?.message}
                />
              )}
            />
          </div>

          <div className="w-1/5  mr-16 ">
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Pariticipant name"
                  placeholder="participant name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.subject}
                  helperText={errors?.subject?.message}
                />
              )}
            />
          </div>

          <div className="w-1/5 mt-16 mr-16">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                >
                  <MenuItem value={"1"}>Approved</MenuItem>
                  <MenuItem value={"2"}>Submitted</MenuItem>
                  <MenuItem value={"3"}>Draft</MenuItem>
                  <MenuItem value={"4"}>Denied</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="mt-20">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <SearchIcon />
            </IconButton>
          </div>
          <div className="mt-20  mr-1">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <BackIcon />
            </IconButton>
          </div>
        </div>
      </Card>

    </>
  );
}

export default AdminIncidentFormFilter;
