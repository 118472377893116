import * as React from "react";
import IndexHeader from "app/shared-components/IndexHeader";
import CustomButton from "app/shared-components/CustomColorButton";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import history from "@history";
import IncidentFormFilter from "./components/IncidentFormFilter";
import IncidentFormTable from "./components/IncidentFormTable";

  const handleClick = () => {
    history.push('incident-form/add');
  };


export default function IncidentFormIndexPage() {
  const myButtons = [
    <CustomButton
      StartIcon={<AddIcon />}
      className="mx-9"
      disabled={false}
      onClick={handleClick}
      name="New"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];
  return (
    <>
      <IndexHeader
        name={"Incident Form Index"}
        update={false}
        add={false}
        buttons={myButtons}
      />
      <IncidentFormFilter />
      <IncidentFormTable />
    </>
  );
}
