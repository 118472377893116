import * as React from "react";
import IndexHeader from "app/shared-components/PageHeader";
import { useState } from "react";
import TimeSheetFilter from "./components/TimeSheetFilters";
import TimeSheetTable from "./components/TimeSheetTable";


export default function TimeSheetPage() {
  return (
    <>
      <IndexHeader
        name={"Timesheet"}
        update={false}
        add={false}
        redirectingPath={""}
        noButtons={true}
      />
      <TimeSheetFilter />
      <TimeSheetTable />
    </>
  );
}
