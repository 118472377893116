import IndexHeader from "app/shared-components/PageHeader";
import * as React from "react";
import AdminApprovalTerminationFormView from "./components/AdminApprovalTerminationView";

export default function AdminApprovalTerminationViewPage() {
    return (
    <>
      <AdminApprovalTerminationFormView />
    </>
  );
}