import React from "react";
import { lazy } from "react";
import { UserRole } from "src/app/@types/enums/user.roles";

const HostInfo = lazy(() => import("./HostInfo"));
const LoginInfo = lazy(() => import("./LoginInfo"));
const ContactInfo = lazy(() => import("./ContactInfo"));
const ProgramInfo = lazy(() => import("./ProgramInfo"));
const Disclaimer = lazy(() => import("./Disclaimer"));

const HostSignUpRoutes = [
  {
    path: "host-info",
    element: <HostInfo />,
    auth:[UserRole.Host],
  },
  {
    path: "login-info",
    element: <LoginInfo />,
    auth:[UserRole.Host],
  },
  {
    path: "contact-info",
    element: <ContactInfo />,
    auth:[UserRole.Host],
  },
  {
    path: "program-info",
    element: <ProgramInfo />,
    auth:[UserRole.Host],
  },
  {
    path: "disclaimer",
    element: <Disclaimer />,
    auth:[UserRole.Host],
  },
];

export default HostSignUpRoutes;
