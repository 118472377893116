import React from "react";
import { Navigate } from "react-router-dom";
import TransferForm from "./transfer-form";
import TransferFormIndexPage from "./transfer-form-index/TransferFormIndexPage";
import TransferFormAddUpdatePage from "./transfer-form-add/TransferFormAddUpdatePage";
import { UserRole } from "src/app/@types/enums/user.roles";

export const TransferFormRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Host],
  routes: [
    {
      path: "transfer-form",
      element: <TransferForm />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <TransferFormIndexPage />,
        },
        {
          path: "add",
          element: <TransferFormAddUpdatePage />,
        },
        {
          path: "update/:id",
          element: <TransferFormAddUpdatePage />,
        },
      ],
    },
  ],
};
