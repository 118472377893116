import React, { Component, ReactNode } from "react";
import { Link } from "react-router-dom";
import history from "@history";
import { Button, Paper, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import InnerFooter from "app/shared-components/InnerFooter";

class SignUp extends Component {
  onSignUpTypeClick(pageUrl: string): void {
    history.push(`/sign-up/host/${pageUrl}`);
  }
  onYouthSignUpTypeClick(pageUrl: string): void {
    history.push(`/sign-up/youth/${pageUrl}`);
  }

  render(): ReactNode {
    return (
      <>
        <div className="flex flex-col sm:flex-row items-center sm:justify-center flex-1 min-w-0">
          <Paper className="h-full sm:h-auto w-full sm:w-auto py-8 px-16 sm:p-48 sm:rounded-2xl sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
            <div className="w-full sm:w-auto mx-auto sm:mx-0">
              <img
                className="h-52"
                src="assets/images/logo/logo-ypms-full.png"
                alt="logo"
              />

              <Typography className="mt-16 text-2xl font-semibold tracking-tight leading-tight">
                Sign up
              </Typography>

              <Typography className="m-16 ml-0 font-semibold">
                Please select your role
              </Typography>

              <div className="flex content-between justify-center gap-16 m-16 ml-0">
                <Card variant="outlined" className="w-1/2 max-w-256">
                  <CardContent>
                    <Typography
                      className="text-2xl font-semibold mb-8"
                      color="secondary"
                    >
                      Create Pathways
                    </Typography>
                    <Typography variant="body2">
                      Help Young minds to discover their potential and realize their dreams.
                      <br />
                    </Typography>
                  </CardContent>
                  <CardActions className="flex content-between justify-center">
                    <Button
                      size="large"
                      variant="contained"
                      color="inherit"
                      className="min-w-128 font-semibold"
                      onClick={() => this.onSignUpTypeClick("host-info")}
                    >
                      Host
                    </Button>
                  </CardActions>
                </Card>

                <Card variant="outlined" className="w-1/2 max-w-256">
                  <CardContent>
                    <Typography
                      className="text-2xl font-semibold  mb-8"
                      color="secondary"
                    >
                      Unlock Opportunities
                    </Typography>

                    <Typography variant="body2">
                      Be an architect of tomorrow's world by igniting the prsonal growth.
                      <br />
                      
                    </Typography>
                  </CardContent>
                  <CardActions className="flex content-between justify-center">
                    <Button
                      size="large"
                      variant="contained"
                      color="inherit"
                      className="min-w-128 font-semibold"
                      onClick={() => this.onYouthSignUpTypeClick("login-info")}
                    >
                      Youth
                    </Button>
                  </CardActions>
                </Card>
              </div>

              <div className="flex items-baseline mt-16 mb-16 font-medium">
                <span>Already have an account?</span>
                <Link className="ml-4" to="/sign-in">
                  Sign in
                </Link>
              </div>

              <InnerFooter />
            </div>
          </Paper>
        </div>
      </>
    );
  }
}

export default SignUp;
