import jwtDecode from "jwt-decode";
import EventEmitter from "../../../@fuse/utils/FuseUtils";

interface IResetToken {
  userId: string;
  userName: string;
  email: string;
}
interface ITokenPayload extends IResetToken {
  role: any;
  roleName: any;
  roleCode: any;
  permissions: string[];
  iat: number;
  exp: number;
  phone: any;
}

export class AuthDataService extends EventEmitter {
  private name!: string;
  private email!: string;
  private id!: string;
  private role!: any;
  private roleCode!: any;
  private roleName!: any;
  private permissions!: string[];
  private phone!: any;

  get userName(): string {
    return String(localStorage.getItem("ms_un"));
  }

  get userEmail(): string {
    return String(localStorage.getItem("ms_em"));
  }

  get userPhone(): string {
    return String(localStorage.getItem("ms_p"));
  }

  get userRoleId(): number {
    return Number(localStorage.getItem("ms_ro"));
  }

  get userRole(): string {
    return String(localStorage.getItem("ms_ron"));
  }

  get getId(): string {
    return this.id;
  }

  get getRole(): number {
    return Number(this.role);
  }

  get getPermissions(): string[] {
    return this.permissions;
  }

  static get token(): any {
    return localStorage.getItem("ms_tk");
  }

  get userId(): any {
    return localStorage.getItem("ms_uid");
  }

  async decodeToken(token: string): Promise<ITokenPayload> {
    const tokenPayload: ITokenPayload = await jwtDecode(token);
    this.name = tokenPayload.userName;
    this.email = tokenPayload.email;
    this.id = tokenPayload.userId;
    this.roleCode = tokenPayload.roleCode;
    this.roleName = tokenPayload.roleName;
    this.permissions = tokenPayload.permissions;
    this.phone = tokenPayload.phone;
    return tokenPayload;
  }

  async setSession(token: string) {
    await this.decodeToken(token);
    localStorage.setItem("ms_un", this.name);
    localStorage.setItem("ms_uid", this.id);
    localStorage.setItem("ms_em", this.email);
    localStorage.setItem("ms_ro", this.roleCode);
    localStorage.setItem("ms_ron", this.roleName);
    localStorage.setItem("ms_per", this.permissions?.toString());
    localStorage.setItem("ms_p", this.phone);
    localStorage.setItem("ms_tk", token);
  }

  async setInitialValues(token: string) {
    await this.decodeToken(token);
    localStorage.setItem("ms_un", this.name);
    localStorage.setItem("ms_em", this.email);
    localStorage.setItem("ms_p", this.phone);
    localStorage.setItem("token", token);
  }

  async saveToken(token: string) {
    localStorage.setItem("token", token);
  }

  async getToken() {
    return localStorage.getItem("token");
  }

  async clearToken() {
    localStorage.removeItem("token");
  }

  async logout() {
    //this.emit("onLogout", "Logged out");
    localStorage.clear();
  }

  async isLoggedIn(): Promise<boolean> {
    const userId: any = localStorage.getItem("ms_uid");
    const isLogged: boolean = userId ? true : false;
    return isLogged;
  }

  getCurrentUrl(): string {
    return window.location.href;
  }
}

export const authDataService = new AuthDataService();
