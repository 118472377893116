import React from "react";
import { Navigate } from "react-router-dom";
import { UserRole } from "src/app/@types/enums/user.roles";
import YouthSalaryDetailsFormIndexPage from "./youth-salary-detailsformIndexPage";
import i18next from "i18next";
import english from "./i18n/english";
import spanish from "./i18n/spanish";
import korean from "./i18n/korean";

i18next.addResourceBundle("en", "YouthSalaryDetailsFormIndexPage", english);
i18next.addResourceBundle("es", "YouthSalaryDetailsFormIndexPage", spanish);
i18next.addResourceBundle("kr", "YouthSalaryDetailsFormIndexPage", korean);

export const YouthSalaryDetailsFormRoutes = {
  settings: {
    layout: {},
  },
  auth: null,
  routes: [
    {
      path: "youth-salary-details",
      element: <YouthSalaryDetailsFormIndexPage />,
      auth: [UserRole.Youth],
      children: [
        {
          path: "",
          element: <YouthSalaryDetailsFormIndexPage />,
        },
      ],
    },
  ],
};
