import React, { useEffect, useState } from "react";
import { Select, MenuItem, Divider, SelectChangeEvent } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import languages from "../@types/static.data/languages";

export default function InnerFooter() {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(() => {
    return localStorage.getItem("selectedLanguage") || "en";
  });

  const { t } = useTranslation("innerFooter");

  useEffect(() => {
    i18next.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const selectedLanguageCode = event.target.value;
    setSelectedLanguage(selectedLanguageCode);
    localStorage.setItem("selectedLanguage", selectedLanguageCode);
  };

  return (
    <>
      <Divider />
      <div className="flex items-baseline justify-between mt-4 text-sm">
        <div className="flex">
          <Select
            displayEmpty
            size="small"
            className="w-full h-8 text-sm"
            value={selectedLanguage}
            onChange={handleLanguageChange}
          >
            <MenuItem className="h-10 text-sm" value="" disabled>
              {t("LANGUAGE")}
            </MenuItem>
            {languages.map((language) => (
              <MenuItem
                className="mt-1 h-8 text-sm"
                key={language?.code}
                value={language?.code}
              >
                {language?.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="flex flex-row gap-16">
          <div className="cursor-pointer">{t("HELP")}</div>
          <div className="cursor-pointer">{t("PRIVACY")}</div>
          <div className="cursor-pointer">{t("TERMS")}</div>
        </div>
      </div>
    </>
  );
}
