const locale = {
  SIGN_IN: "로그인",
  DONT_HAVE_ACCOUNT: "계정이 없습니까?",
  SIGNUP: "가입하기",
  EMAIL: "이메일",
  PASSWORD: "비밀번호",
  REMEMBER_ME: "날 기억해",
  FORGOT_PASSWORD: "비밀번호를 잊으 셨나요?",

  //Error messages
  VALID_EMAIL_ERROR: "유효한 이메일을 입력해야 합니다.",
  MUST_ENTER_EMAIL_ERROR: "이메일을 입력해야 합니다.",
  PASSWORD_ERROR: "비밀번호를 입력하세요.",
  PASSWORD_SHORT: "암호가 너무 짧습니다. 4자 이상이어야 합니다.",
};

export default locale;
