import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
const defaultValues = {
  all: "",
  returning: "",
  eligible: "",
  completed_orientation: "",
  certified: "",
  ssa_verified: "",
  ssa_unverified: "",
  address_verified: "",
  address_unverified: "",
  banking_completed: "",
  uncompleted_orientation: "",
  uncompleted_certification: "",
  uncompleted_banking: "",
  employed: "",
  unemployed: "",
};
const schema = yup.object().shape({});
export default function SelectYouth() {
  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();
  const { t } = useTranslation("AdminMessages");

  return (
    <div className="m-32">
      <Typography className="ml-10 mt-16 font-bold text-black text-lg w-full text-left items-start">
        {t("SELECT_YOUTH")}
      </Typography>
      <div className="flex grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2  content-start">
        <div className="flex items-start  ml-10 mr-10 ">
          <Controller
            control={control}
            name="all"
            render={({ field }) => (
              <FormControlLabel
                value="all"
                control={<Checkbox />}
                label={t("ALL")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start   ml-10 mr-10 ">
          <Controller
            control={control}
            name="returning"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("RETURNING")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start   ml-10 mr-10 ">
          <Controller
            control={control}
            name="eligible"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("ELIGIBLE")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start   ml-10 mr-10 ">
          <Controller
            control={control}
            name="completed_orientation"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("COMPLETED_ORIENTATION")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start   ml-10 mr-10">
          <Controller
            control={control}
            name="certified"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("CERTIFIED")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start   ml-10 mr-10">
          <Controller
            control={control}
            name="ssa_verified"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("SAA_VERIFIED")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start   ml-10 mr-10">
          <Controller
            control={control}
            name="ssa_unverified"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("SAA_UNVERIFIED")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start   ml-10 mr-10">
          <Controller
            control={control}
            name="address_verified"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("ADDRESS_VERIFIED")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start   ml-10 mr-10">
          <Controller
            control={control}
            name="address_unverified"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("ADDRESS_UNVERIFIED")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start  ml-10 mr-10">
          <Controller
            control={control}
            name="banking_completed"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("BANK_COMPLETED")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start   ml-10 mr-10">
          <Controller
            control={control}
            name="uncompleted_orientation"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("UNCOMPLETED_ORIENTATION")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start   ml-10 mr-10">
          <Controller
            control={control}
            name="uncompleted_certification"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("UNCOMPLETED_CERTIFICATION")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start   ml-10 mr-10">
          <Controller
            control={control}
            name="uncompleted_banking"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("UNCOMPLETED_BANKING")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start   ml-10 mr-10">
          <Controller
            control={control}
            name="employed"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("EMPLOYED")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start   ml-10 mr-10">
          <Controller
            control={control}
            name="unemployed"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("UNEMPLOYED")}
                labelPlacement="end"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
