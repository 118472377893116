const locale = {
  CREATE_USER: "Créer",
  GENERATE_QR: "Générer QR",
  SIGNIN: "S'identifier",
  SIGNUP: "S’enregistrer",
  LOGIN_INFORMATION: "Informations de connexion",
  EMAIL_ADDRESS: "Adresse courriel",
  CONFIRM_EMAIL_ADDRESS: "Confirmer l’adresse e-mail",
  PASSWORD: "Mot de passe",
  CONFIRM_PASSWORD: "Confirmer le mot de passe",

  //Error messages
  EMAIL_ADDRESS_NOT_MATCHED_ERROR: "Adresse e-mail non correspondante",
  EMAIL_NOT_VALID: "Entrez une adresse e-mail valide",
  PASSWORD_NOT_MATCHED_ERROR: "Mot de passe non associé",
  EMAIL_PRIMARY_ERROR:
    "Cette adresse e-mail est le principal moyen de communiquer avec vous. Par conséquent, si vous n’avez pas d’adresse e-mail personnelle, veuillez en créer une au préalable",
  CONFIRM_EMAIL_ERROR: "Confirmer l’adresse e-mail requise",
  CONFIRM_PASSWORD_ERROR: "Confirmer que le mot de passe est requis",

  //Button
  Next: "Prochain",
  Back: "Précédent",

  //#PersonalInfo-----------------
  PERSONAL_DETAILS: "Données personnelles",

  //Error Message
  LEGAL_FIRST_NAME_ERROR: "Veuillez ajouter votre prénom légal",
  LEGAL_LAST_NAME_ERROR: "Veuillez ajouter votre nom de famille légal",
  MIDDLE_NAME_ERROR: "Veuillez ajouter votre deuxième prénom",
  MAIDEN_NAME_ERROR: "Veuillez ajouter votre nom de jeune fille",

  SSN_ERROR: "Le SSN est requis",
  CONFIRM_SSN_ERROR: "Confirmer que le SSN est requis",
  SSN_NOT_MATCHED_ERROR: "Le SSN ne correspond pas",

  LEGAL_FIRST_NAME: "Prénom légal",
  LEGAL_LAST_NAME: "Nom de famille légal",
  MIDDLE_NAME: "Deuxième prénom",
  MAIDEN_NAME: "Nom de jeune fille",
  DATE_OF_BIRTH: "Date de naissance",
  LANGUAGE: "Langue",
  GENDER: "Genre",
  RACE: "Course",
  SUFFIX: "Suffixe",
  T_SHIRT_SIZE: "Taille de T-shirt",
  SOCIAL_SECURITY_NUMBER: "Numéro de sécurité sociale",
  CONFIRM_SSN: "Confirmer le SSN",

  //-----------END of PersonalInfoPage------------

  //#EducationalInfo
  //Error Message
  REQUIRED_ERROR: "Ce champ doit être rempli",

  EDUCATIONAL_DETAILS: "Détails de l’éducation",
  CURRENT_EDUCATIONAL_STATUS: "Niveau de scolarité actuel",
  EDUCATIONAL_STUDENT_TYPE: "Type d’étudiant en éducation",
  CURRENT_GRADE: "Année actuelle ou dernière année fréquentée",
  CURRENT_SCHOOL: "École actuelle ou dernière école fréquentée",

  //----------END of EducationalInfoPage---------------

  //#ContactInfo
  CONTACT_INFORMATION: "Coordonnées",
  PERMENANT_ADDRESS_DETAILS: "Détails de l’adresse permanente",
  ADDRESS_LINE: "Ligne d’adresse",
  APT: "Numéro d’appartement ou suite",
  STATE: "État",
  CITY: "Ville",
  ZIP: "ZIP ou code postal",
  CELL_PHONE: "Téléphone portable",
  HOME_PHONE: "Téléphone résidentiel",
  PARENT: "Parent ou tuteur",
  FIRST_NAME: "Prénom",
  LAST_NAME: "Nom",
  WORK_PHONE: "Téléphone professionnel",
  RELATIONSHIP: "Relation",
  EMERGENCY: "Urgence",
  PHONE_NUMBER: "Numéro de téléphone",

  //--------END OF CONTACT INFO PAGE--------

  //#NotificationInfo
  NOTIFICATION_INFORMATION: "Informations sur les notifications",
  NOTIFICATION_DETAILS: "Détails de la notification",
  TWITTER_USER_NAME: "Nom d’utilisateur Twitter@",
  CONTACT_TEXT_MESSAGE: "Pouvons-nous vous contacter par SMS?",
  COMPUTER_STATUS: "Avez-vous un ordinateur?",
  INTERNET_STATUS: "Avez-vous accès à Internet chez vous?",
  NETWORK_TYPE: "Type de réseau",

  //-----------END of Notification Info Page---------

  //#Career Interest
  CAREER_INTEREST: "Intérêts de carrière",
  JOB_ASSIGNMENTS: "Affectations de poste que vous préférez",
  WORK_EXPERIENCE: "Expérience professionnelle",
  WORK_TRAINING: "Préparation à la formation professionnelle",
  JOB_MATCHES: "Emploi correspondant",
  CLOSE_HOME: "Près de chez moi",
  MATCHES_CAREER: "Correspond à mon objectif de carrière",
  CAREER_TYPE_1: "Premier intérêt de carrière",
  CAREER_TYPE_2: "Deuxième intérêt de carrière",
  CAREER_TYPE_3: "Troisième intérêt de carrière",
  AFTER_SYEP: "Tout plan pour l’avenir, après SYEP",
  CAREER_GOALS: "Quels sont vos objectifs de carrière à long terme?",

  //----------END of Career Interest Page----------------

  //#Supplemenatl Info
  SUPPLEMENTAL_INFORMATION: "Renseignements supplémentaires",
  SPECIAL_ACCOMODATION:
    "Mesures d’adaptation spéciales / Renseignements médicaux",
  REQ_SPECIAL_ACCOMODATION: "Avez-vous besoin d’un hébergement spécial?",
  MEDICAL_CONDITION:
    "Condition médicale qui vous empêcherait de travailler à l’extérieur",
  DEMOGRAPHIC: "Informations démographiques",
  OFFENDER: "Délinquante ou tribunal impliqué",
  MILITARY: "A servi dans l’armée",
  HOMELESS: "Sans-abri",
  PARENT: "Parent",
  MYOWN: "Vis par moi-même",
  FOSTER: "Accueil familial",
  PUBLIC_ASSISTANCE: "Assistance publique",
  REQ_PUBLIC_ASSISTANCE:
    "Vérifiez ici si vous ou votre famille recevez actuellement de l’aide public",
  YES: "Oui",
  INCOME: "Revenu et taille de la famille",
  FAMILY_INCOME: "Revenu familial total des six derniers mois",
  FAMILY_SIZE: "Nombre total actuel de membres de votre ménage",

  //------END of Supplemental Page--------------

  //#Review Page
  REVIEW_SIGN: "Révision et signature",
  DIGITAL_SIGNATURE: "Signature numérique",
  CHECKING_INFO:
    "En cochant la case ci-dessous, je certifie toutes ces informations à condition que ce formulaire soit vrai et exact.",
  APPROVE_INFO:
    "Je comprends que ces renseignements font l’objet d’une vérification, je comprends également que le fait de fournir de faux renseignements peut entraîner mon renvoi d’un bureau du programme jeunesse de DOES et peut me soumettre à une procédure civile et criminelle, le cas échéant.",
  READ_CHECK:
    "J’ai lu la déclaration ci-dessus et j’aimerais soumettre ma demande au Programme d’emploi d’été pour les jeunes du maire",
  DATE: "Date",
  SIGNATURE: "Signature",
  DOES: "DOES est un employeur / fournisseur d’égalité d’accès à l’emploi ",
  LANGUAGE_SERVICE:
    "Les services d’interprétation linguistique sont disponibles gratuitement",
  AUXILIARY_AIDS:
    "Des services auxiliaires et des aides sont disponibles sur demande pour les personnes handicapées",
  NOTICE_HEADER: "Avis de non-discrimination",
  ACCORDANCE:
    "Conformément à la loi sur les droits de l’homme de 1977 de DC, telle que modifiée, section 2-1401-01 et seg., le district de Columbia ne fait aucune discrimination fondée sur la race, la couleur, la religion, la nation, l’origine, le sexe, l’âge, l’état matrimonial, l’apparence personnelle, l’orientation sexuelle, l’identité ou l’expression de genre, la situation familiale, les responsabilités familiales, l’immatriculation, l’affiliation politique,  les renseignements génétiques, le handicap, la source de revenu ou le lieu de résidence ou d’entreprise.  Le harcèlement sexuel sous une forme de discrimination par la SEC qui est également interdite par la loi. En outre, le harcèlement fondé sur l’une ou l’autre des catégories protégées ci-dessus est interdit par la Loi. La discrimination en violation de la Loi ne sera pas tolérée. Les contrevenants feront l’objet de mesures disciplinaires",
};

export default locale;
