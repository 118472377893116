import IndexHeader from "app/shared-components/PageHeader";
import * as React from "react";
import JobAdd from "./Components/JobAdd";

export default function JobAddPage() {
    return (
    <>
      <JobAdd />
    </>
  );
}
