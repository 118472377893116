import IndexHeader from "app/shared-components/PageHeader";
import * as React from "react";
import QuestionnaireFormAddUpdate from "./components/QuestionnaireFormAddPage";

export default function QuestionnaireFormAddPage() {
  return (
    <>
      <QuestionnaireFormAddUpdate />
    </>
  );
}
