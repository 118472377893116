import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  FormControl,
  Select,
  MenuItem,
  Card,
  Typography,
  FormHelperText,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IndexHeader from "app/shared-components/IndexHeader";
import { SelectMenu } from "@ypms/types";
import CustomButton from "app/shared-components/CustomColorButton";
import history from "@history";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

const defaultValues = {
  worksiteName: "",
  mainPhone: "",
  mainExtension: "",
  secondaryPhone: "",
  secondaryExtension: "",
  address1: "",
  address2: "",
  state: "",
  city: "",
  postalCode: "",
  facilityType: "",
  otherFacility: "",
  isMetroAccessible: false,
  isWorksiteADAComplaint: false,
  isPowerMachineryUsed: false,
  isOfficeEquipmentUsed: false,
  isRequireOutdoorWork: false,
};

const schema = yup.object().shape({
  worksiteName: yup.string().required("Worksite Name is required"),
  mainPhone: yup.string().required("MainPhone is required"),
  mainExtension: yup.string(),
  secondaryPhone: yup.string(),
  secondaryExtension: yup.string(),
  address1: yup.string().required("Address 1 is required"),
  address2: yup.string(),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  postalCode: yup.string().required("Postal Cde is required"),
  facilityType: yup.string().required("Facility Type is required"),
  otherFacility: yup.string().required("Other Facility is required"),
  isMetroAccessible: yup.boolean().default(false),
  isWorksiteADAComplaint: yup.boolean().default(false),
  isPowerMachineryUsed: yup.boolean().default(false),
  isOfficeEquipmentUsed: yup.boolean().default(false),
  isRequireOutdoorWork: yup.boolean().default(false),
});

export default function WorksiteAddUpdate() {
  const stateList: SelectMenu[] = [
    { id: 1, value: "CA", label: "California" },
    { id: 2, value: "TE", label: "Texas" },
    { id: 3, value: "FL", label: "Florida" },
    { id: 4, value: "AL", label: "Alaska" },
  ];

  const facilityTypeList: SelectMenu[] = [
    { id: 1, value: "1", label: "Cafeteria" },
    { id: 2, value: "2", label: "Church" },
    { id: 3, value: "3", label: "Hospital" },
    { id: 4, value: "4", label: "Mailroom" },
    { id: 5, value: "5", label: "Child Care Facility" },
    { id: 6, value: "6", label: "Office" },
    { id: 7, value: "7", label: "School" },
    { id: 8, value: "8", label: "Warehouse" },
    { id: 9, value: "9", label: "Virtual" },
    { id: 10, value: "10", label: "Other" },
  ];

  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  const [values, setValue] = useState({
    state: "",
    facilityType: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue({
      ...values,
      [name]: value,
    });
  };
  const handleClick = () => {
    history.push("/worksite/list");
  };
  const myButtons = [
    <Button
      className="mx-8"
      variant="contained"
      disabled={false}
      startIcon={<CancelIcon />}
      onClick={handleClick}
    >
      {"Cancel"}
    </Button>,

    <CustomButton
      StartIcon={<SaveIcon />}
      className="mx-8"
      disabled={false}
      onClick={handleClick}
      name="Save"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];

  return (
    <>
      <IndexHeader
        name={"Create Worksite"}
        update={false}
        add={true}
        previousPath={"/worksite/list"}
        buttons={myButtons}
      />

      <Card variant="outlined" className="m-24 mb-0">
        <div className="m-24">
          <div className="flex grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start">
            <Controller
              control={control}
              name="worksiteName"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Worksite Name"
                  placeholder="Worksite Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  id="worksiteName"
                  error={!!errors.worksiteName}
                  helperText={errors?.worksiteName?.message}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="mainPhone"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Main Phone"
                  placeholder="Main Phone"
                  variant="outlined"
                  fullWidth
                  size="small"
                  id="mainPhone"
                  error={!!errors.mainPhone}
                  helperText={errors?.mainPhone?.message}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="mainExtension"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Main Extension"
                  placeholder="Main Extension (If Applicable)"
                  variant="outlined"
                  fullWidth
                  size="small"
                  id="mainExtension"
                  error={!!errors.mainExtension}
                  helperText={errors?.mainExtension?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="secondaryPhone"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Secondary Phone"
                  placeholder="Secondary Phone"
                  variant="outlined"
                  fullWidth
                  size="small"
                  id="secondaryPhone"
                  error={!!errors.secondaryPhone}
                  helperText={errors?.secondaryPhone?.message}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="secondaryExtension"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Secondary Extension "
                  placeholder="Secondary Extension (If Applicable)"
                  variant="outlined"
                  fullWidth
                  size="small"
                  id="secondaryExtension"
                  error={!!errors.secondaryExtension}
                  helperText={errors?.secondaryExtension?.message}
                  required
                />
              )}
            />
          </div>
          <Typography
            className="mt-24 mb-12 text-lg text-muted font-semibold"
            color="gray"
          >
            Worksite Location
          </Typography>
          <div className="flex grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start">
            <Controller
              control={control}
              name="address1"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Address Line 1"
                  placeholder="Address Line 1"
                  variant="outlined"
                  fullWidth
                  size="small"
                  id="address1"
                  error={!!errors.address1}
                  helperText={errors?.address1?.message}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="address2"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Apt. No / Suite"
                  placeholder="Apt. No / Suite"
                  variant="outlined"
                  fullWidth
                  size="small"
                  id="address2"
                  error={!!errors.address2}
                  helperText={errors?.address2?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="state"
              render={({ field }) => (
                <FormControl error={!!errors.state}>
                  <Select
                    {...field}
                    value={values.state}
                    onChange={handleChange}
                    displayEmpty
                    size="small"
                    id="state"
                  >
                    <MenuItem value="" disabled>
                      State
                    </MenuItem>
                    {stateList.map((state: SelectMenu, index: number) => {
                      return (
                        <MenuItem key={index} value={state.value}>
                          {state.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>{errors?.state?.message}</FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="city"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="City"
                  placeholder="City"
                  variant="outlined"
                  fullWidth
                  size="small"
                  id="city"
                  error={!!errors.city}
                  helperText={errors?.city?.message}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="postalCode"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Postal Code"
                  placeholder="Postal Code"
                  variant="outlined"
                  fullWidth
                  size="small"
                  id="postalCode"
                  error={!!errors.postalCode}
                  helperText={errors?.postalCode?.message}
                  required
                />
              )}
            />
          </div>

          <Typography
            className="mt-24 mb-12 text-lg text-muted font-semibold"
            color="gray"
          >
            Demographics
          </Typography>
          <div className="flex grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start">
            <Controller
              control={control}
              name="facilityType"
              render={({ field }) => (
                <FormControl error={!!errors.facilityType}>
                  <Select
                    {...field}
                    value={values.facilityType}
                    onChange={handleChange}
                    displayEmpty
                    size="small"
                    id="facilityType"
                  >
                    <MenuItem value="" disabled>
                      Facility Type
                    </MenuItem>
                    {facilityTypeList.map(
                      (facilityType: SelectMenu, index: number) => {
                        return (
                          <MenuItem key={index} value={facilityType.value}>
                            {facilityType.label}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                  <FormHelperText>{errors?.state?.message}</FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="otherFacility"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Other Facility"
                  placeholder="Other Facility"
                  variant="outlined"
                  fullWidth
                  size="small"
                  id="otherFacility"
                  error={!!errors.otherFacility}
                  helperText={errors?.otherFacility?.message}
                  required
                />
              )}
            />
          </div>

          <div className="flex grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start mt-24">
            <div className="flex">
              <Controller
                control={control}
                name="isMetroAccessible"
                render={({ field: { onChange } }) => (
                  <Checkbox
                    onChange={(ev) => onChange(ev.target.checked)}
                    size="medium"
                    id="isMetroAccessible"
                    className="-mt-8 -ml-10"
                  />
                )}
              />
              <Typography className="mt-2">Metro Accessible</Typography>
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="isWorksiteADAComplaint"
                render={({ field: { onChange } }) => (
                  <Checkbox
                    onChange={(ev) => onChange(ev.target.checked)}
                    size="medium"
                    id="isWorksiteADAComplaint"
                    className="-mt-8 -ml-10"
                  />
                )}
              />
              <Typography className="mt-2">
                Is this Worksite ADA Complaint
              </Typography>
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="isPowerMachineryUsed"
                render={({ field: { onChange } }) => (
                  <Checkbox
                    onChange={(ev) => onChange(ev.target.checked)}
                    size="medium"
                    id="isPowerMachineryUsed"
                    className="-mt-8 -ml-10"
                  />
                )}
              />
              <Typography className="mt-2">Power Machinery Used</Typography>
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="isOfficeEquipmentUsed"
                render={({ field: { onChange } }) => (
                  <Checkbox
                    onChange={(ev) => onChange(ev.target.checked)}
                    size="medium"
                    id="isOfficeEquipmentUsed"
                    className="-mt-8 -ml-10"
                  />
                )}
              />
              <Typography className="mt-2">Office Equipment Used</Typography>
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="isMetroAccessible"
                render={({ field: { onChange } }) => (
                  <Checkbox
                    onChange={(ev) => onChange(ev.target.checked)}
                    size="medium"
                    id="isMetroAccessible"
                    className="-mt-8 -ml-10"
                  />
                )}
              />
              <Typography className="mt-2">
                Worksite require participants to work outdoors
              </Typography>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
