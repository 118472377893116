import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  FormControl,
  Select,
  MenuItem,
  Card,
  Typography,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  InputLabel,
  IconButton,
  ToggleButtonGroup,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IndexHeader from "app/shared-components/IndexHeader";
import { useParams } from "react-router-dom";
import CustomButton from "app/shared-components/CustomColorButton";
import history from "@history";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { ToggleOff } from "@mui/icons-material";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useTranslation } from "react-i18next";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const defaultValues = {
  upload: "",
  browse: "",

  video: "",
  fileName: "",
  download: "",
};

const schema = yup.object().shape({
  upload: yup.string().required("Upload is required"),
  browse: yup.string().required("Browse is required"),
});

export default function PdfCompoent() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { t } = useTranslation("JobApplication");

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] as File;
    setSelectedFile(file);
  };

  const handleDownload = () => {
    {
      if (selectedFile) {
        const url = URL.createObjectURL(selectedFile);
        const link = document.createElement("a");
        link.href = url;
        link.download = selectedFile.name;
        link.click();
      }
    }
  };

  const { control, handleSubmit, watch, formState, register, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const form = watch();
  const myButtons = [
    <Button
      className="mx-8"
      variant="contained"
      disabled={false}
      startIcon={<CancelIcon />}
    >
      {"Cancel"}
    </Button>,
    <CustomButton
      StartIcon={<SaveIcon />}
      className="mx-8"
      disabled={false}
      onClick={() => {}}
      name="Save"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];

  return (
    <div>
      <Typography className="text-20 ml-32 mt-8 font-semibold tracking-tight leading-tight">
        {t("RESUME_NAVI")}
      </Typography>
      <Card variant="outlined" className="m-24">
        <Card variant="outlined" className="m-24 mb-24">
          <Typography className="ml-32 mt-8 text-l font-semibold tracking-tight leading-tight">
            {t("UPLOAD_HINT_ONE")}
          </Typography>
          <Button
            variant="contained"
            className="w-1/8 ml-32 mt-32 mr-16 mb-32  mb-0  rounded-8 bg-blue hover:bg-blue text-white"
          >
            <Controller
              control={control}
              name="browse"
              render={({ field: { onChange, value } }) => (
                <Box className=" mt-16 relative flex items-center justify-center w-128 h-128 rounded-1 overflow-hidden">
                  <div className="absolute inset-0 flex items-center justify-center z-20">
                    <label
                      htmlFor="button-upload"
                      className="hover:text-white
                       flex-auto w-full text-center  cursor-pointer mt-16 mb-16"
                    >
                      <Typography className="justify-center text-center w-full mb-16 items-center hover:bg-blue text-white">
                        {t("Browse_Btn")}
                      </Typography>
                      <input
                        onChange={handleFileChange}
                        accept="pdf/*"
                        className="hidden w-full mt-16"
                        id="button-upload"
                        type="file"
                      />
                    </label>
                  </div>
                </Box>
              )}
            />
          </Button>

          {selectedFile && (
            <Button className="w-1/8 ml-16 mr-auto mb-32 mt-32 mb-0 rounded-8 bg-blue text-white  hover:bg-blue text-white">
              <Controller
                control={control}
                name="download"
                render={({ field: { onChange, value } }) => (
                  <Box className="mt-16 relative flex items-center justify-center w-128 h-128 rounded-1 overflow-hidden">
                    <div className="absolute inset-0 flex items-center justify-center z-20">
                      <label
                        className="flex-auto w-full text-center  cursor-pointer mt-16 mb-16"
                        onClick={handleDownload}
                      >
                        <Typography className="justify-center text-center w-full mb-16 items-center hover:bg-blue text-white">
                          {t("DOWNLOAD_BTN")}
                        </Typography>
                      </label>
                    </div>
                  </Box>
                )}
              />
            </Button>
          )}

          {selectedFile && (
            <Typography className="ml-32 mt-8 mb-32">
              {selectedFile && (
                <Controller
                  control={control}
                  name="fileName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="w-1/2"
                      variant="outlined"
                      fullWidth
                      size="small"
                      disabled={true}
                      value={selectedFile?.name}
                      error={!!errors.fileName}
                      helperText={errors?.fileName?.message}
                    />
                  )}
                />
              )}
            </Typography>
          )}

          {selectedFile && (
            <div
              style={{ display: "flex" }}
              className="mb-32 ml-32 mr-32 mt-32"
            >
              <div
                style={{
                  width: "100%",
                  border: "3px solid gray",
                  height: "height: 297mm",
                }}
              >
                {selectedFile ? (
                  <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.min.js">
                    <Viewer fileUrl={URL.createObjectURL(selectedFile)} />
                  </Worker>
                ) : (
                  <div>{t("No_FILE_SELECTED")}</div>
                )}
              </div>
            </div>
          )}
        </Card>
      </Card>
    </div>
  );
}
