const locale = {
  TWO_STEP_VERIFICATION: "2단계 인증",
  PLEASE_SELECT_AUTHENTICATION: "인증 방법을 선택하세요.",
  EMAIL: "이메일",
  GET_VERIFICATION_CODE: "인증 코드 받기 ",
  SMS: "SMS",
  OR_TRY: "또는 시도",
  SIGN_IN: "로그인",
  AGAIN: "다시!",

  //verification page
  TEXT_MESSAGE_SENT:
    "4자리 인증 코드가 포함된 문자 메시지가 다음 주소로 전송되었습니다",
  EMAIL_MESSAGE_SENT:
    "4자리 인증 코드가 포함된 이메일이 다음 주소로 전송되었습니다 ",
  ENTER_CODE: "코드를 입력",
  NEXT: "다음",

  ENTER_CODE_LENGTH_ERROR: "코드 길이는 6자리여야 합니다.",
  CODE_REQUIRED_ERROR: "확인 코드가 필요합니다.",
};

export default locale;
