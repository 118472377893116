import React,{useState,ChangeEvent} from 'react'
import _ from '@lodash'
import {Card,TextField,FormControl,Select,MenuItem,FormHelperText,Typography,Checkbox,Divider,InputAdornment,InputLabel,OutlinedInput,FormGroup,Switch,FormControlLabel} from '@mui/material'
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import states from "src/app/@types/static.data/states";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const schema = yup.object().shape({
    firstName: yup.string().required("FIRST_NAME_ERROR"),
    middleName: yup.string(),
    lastName: yup.string().required("LAST_NAME_ERROR"),
    homeAddress: yup.string(),
    socialSecurityNumber:yup.string().required("SSN_ERROR").matches(/^\d{3}-\d{2}-\d{4}$/, 'ZIP code must be in the format XXX-XX-XXXX'),
    state:yup.string(),
    city:yup.string(),
    zip:yup.number().min(10000).max(99999),
    secA_total:yup.number(),
    secB_total:yup.number(),
    total_allowance:yup.number(),
    exempt:yup.string().matches(/^(|EXEMPT)$/, "Invalid value for exempt field"),
    year:yup.number(),
  });

  const defaultValues = { firstName: "", middleName: "", lastName: "", homeAddress: "" ,socialSecurityNumber:"",MI:"",city:"",state:"",zip:"",secA_total:"",secB_total:"",total_allowance:"",exempt:"",signedDate:"",signature:"",year:"",};

function w4AllowanceCertificate() {
    const {t} =useTranslation("w4AllowanceCertificate");
    const { control, handleSubmit, watch, formState } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
      });
      const { isValid, dirtyFields, errors } = formState;
      const form = watch();
    
      if (_.isEmpty(form)) {
        return null;
      }

      const [selectedCheckbox, setSelectedCheckbox] = useState('');

      const handleCheckboxChange = (value:any) => {
        setSelectedCheckbox(value);
      };

    const [switchValue, setSwitchValue] = useState(false);
    const [stateValue, setStateValue] = useState('');
    const [switchFulltimeValue,setSwitchFulltimeValue]= useState(false)

    const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSwitchValue(event.target.checked);
      };
    
    const handleStateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setStateValue(event.target.value);
      };

    const handleFulltimeSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSwitchFulltimeValue(event.target.checked);
      };

  return (
    <>
    <Card variant="outlined" className="m-24 mb-0">
    <div className="flex content-between justify-start m-8 ">
    <div className="w-1/4 mt-16 ">
    <img
            className="h-52"
            src="assets/images/logo/logo-ypms-full.png"
            alt="logo"
          />
    </div> 
    <div className=" flex w-1/2 mt-16 ">
    <Controller
              control={control}
              name="year"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="w-1/3 "
                  label={t("YEAR")}
                  placeholder="Year"
                  variant="filled"
                  fullWidth
                  size="small"
                  error={!!errors.year}
                  helperText={t(errors?.year?.message ?? "")}
                />
              )}
            />
    <Typography className='font-800 h-32 mt-8 ml-4'>{t("TITLE")}</Typography>
    </div>  
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
            <div className="w-1/4 mt-16 ">
            <Controller
              control={control}
              name="socialSecurityNumber"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label={t("SSN")}
                  placeholder="Enter your SSN"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.socialSecurityNumber}
                  helperText={t(errors?.socialSecurityNumber?.message ?? "")}
                />
              )}
            />
            </div>
        </div>
        <div className="flex content-between justify-start m-8 ">
            <div className="w-1/2 mr-16">
            <Controller
              control={control}
              name="firstName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label={t("FIRST_NAME")}
                  placeholder="Enter your First Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.firstName}
                  helperText={t(errors?.firstName?.message ?? "")}
                />
              )}
            />
            </div>
            <div className='flex w-full'>
            <div className="flex">
            <Controller
              control={control}
              name="MI"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-1/5 mr-6"
                  label={t("M_I")}
                  placeholder=""
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.MI}
                  helperText={t(errors?.MI?.message ?? "")}
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label={t("LAST_NAME")}
                  placeholder="Please add your Last Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.lastName}
                  helperText={t(errors?.lastName?.message ?? "")}
                />
              )}
            />
            </div>
            </div>
        </div>
        <div className="flex content-between justify-start m-8 ">
            <div className="w-full mt-16 ">
            <Controller
              control={control}
              name="homeAddress"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="w-full"
                  label={t("HOME_ADDRESS")}
                  placeholder="Home Address"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.homeAddress}
                  helperText={t(errors?.homeAddress?.message ?? "")}
                />
              )}
            />
            </div>
        </div>

        <div className="flex content-between justify-start m-8 mt-24 ">
        <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      error={!!errors.city}
                      className="w-1/3 mr-8"
                    >
                      <Select
                        {...field}
                        displayEmpty
                        size="small"
                        variant="outlined"
                        required
                        value={field.value || ""}
                      >
                        <MenuItem value="">
                          <em>{t("CITY")}</em>
                        </MenuItem>
                        <MenuItem value={"1"}>Washington</MenuItem>
                        <MenuItem value={"2"}>NewYork</MenuItem>
                        <MenuItem value={"3"}>Amado</MenuItem>
                      </Select>
                      <FormHelperText>
                        {t(errors?.city?.message ?? "")}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      error={!!errors.state}
                      className="w-1/3 mr-8"
                    >
                      <Select
                        {...field}
                        displayEmpty
                        size="small"
                        variant="outlined"
                        required
                        value={field.value || ""}
                      >
                        <MenuItem value="">
                          <em>{t("STATE")}</em>
                        </MenuItem>
                        {states.map((state) => (
                          <MenuItem key={state?.code} value={state?.code}>
                            {state?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {t(errors?.state?.message ?? "")}
                      </FormHelperText>
                    </FormControl>
                  )}
                />

                <Controller
                  name="zip"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("ZIP")}
                      size="small"
                      error={!!errors.zip}
                      helperText={errors?.zip?.message}
                      variant="outlined"
                      className="w-1/3 "
                      required
                    />
                  )}
                />
        </div>

        <Divider></Divider>
        <Typography className='w-1/4 font-600 m-8'>{t("TAX_FILLING")}</Typography>
        <div className="flex content-between justify-start m-8 mt-24">
          <div className="flex w-full">
            <div className="flex items-center mr-8 w-1/3">
              <Checkbox
                checked={selectedCheckbox === 'single'}
                onChange={() => handleCheckboxChange('single')}
              />
              <Typography
                style={{
                  fontWeight: selectedCheckbox === 'single' ? 'bold' : 'normal',
                }}
              >
                {t("SINGLE")}
              </Typography>
            </div>
            <div className="flex items-center mr-8 w-1/3">
              <Checkbox
                checked={selectedCheckbox === 'married_jointly'}
                onChange={() => handleCheckboxChange('married_jointly')}
              />
              <Typography
                style={{
                  fontWeight: selectedCheckbox === 'married_jointly' ? 'bold' : 'normal',
                }}
              >
                {t("MARIED_JOINTLY")}
              </Typography>
            </div>
            <div className="flex items-center w-1/3">
              <Checkbox
                checked={selectedCheckbox === 'headOfHousehold'}
                onChange={() => handleCheckboxChange('headOfHousehold')}
              />
              <Typography
                style={{
                  fontWeight: selectedCheckbox === 'headOfHousehold' ? 'bold' : 'normal',
                }}
              >
                {t("HEAD")}
              </Typography>
            </div>
          </div>
          
        </div>

        <div className="flex content-between justify-start m-8">
          
          <div className="flex w-full">
            <div className="flex items-center  w-1/3">
              <Checkbox
                checked={selectedCheckbox === 'married_seperately'}
                onChange={() => handleCheckboxChange('married_seperately')}
              />
              <Typography
                style={{
                  fontWeight: selectedCheckbox === 'married_seperately' ? 'bold' : 'normal',
                }}
              >
                {t("MARRIED_SEPERATELY")}
              </Typography>
            </div>
            <div className="flex items-center mr-8">
              <Checkbox
                checked={selectedCheckbox === 'married'}
                onChange={() => handleCheckboxChange('married')}
              />
              <Typography
                style={{
                  fontWeight: selectedCheckbox === 'married' ? 'bold' : 'normal',
                }}
              >
              {t("MARRIED_PARENTS")}
              </Typography>
            </div>
          </div>
          
        </div>
        <Divider/>
      
        <Typography className='m-8 mt-24'> <b>{t("TOTAL_ALLOWANCES")}</b></Typography>
        <div className=" flex content-between justify-start m-8 ">
        <div className="flex items-center mr-8 w-1/3">
            <Typography>{t("TOTAL_A")} </Typography>
            <Controller
              control={control}
              name="secA_total"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="ml-16 w-1/5 text-center"
                  variant="filled"
                  fullWidth
                  size="small"
                  error={!!errors.secA_total}
                  helperText={t(errors?.secA_total?.message ?? "")}
                />
              )}
            />
        </div>
        <div className="flex items-center mr-8 w-1/3">
            <Typography>{t("TOTAL_B")} </Typography>
            <Controller
              control={control}
              name="secB_total"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="ml-16 w-1/5 text-center"
                  variant="filled"
                  fullWidth
                  size="small"
                  error={!!errors.secB_total}
                  helperText={t(errors?.secB_total?.message ?? "")}
                />
              )}
            />
        </div>
        <div className="flex items-center mr-8 w-1/3">
            <Typography>{t("TOTAL_W")} </Typography>
            <Controller
              control={control}
              name="total_allowance"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="ml-16 w-1/5 text-center"
                  variant="filled"
                  fullWidth
                  size="small"
                  error={!!errors.total_allowance}
                  helperText={t(errors?.total_allowance?.message ?? "")}
                />
              )}
            />
        </div>
        </div>
        <Divider/>
        <div className=" flex content-between justify-start m-8 ">
            <Typography>{t("ADDITIONAL_AMOUNT")} </Typography>
            <FormControl  sx={{ m: 1 ,width: '25ch'}}>
            <InputLabel htmlFor="outlined-adornment-amount">{t("AMOUNT")}</InputLabel>
            <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Amount"
             />
            </FormControl>
        </div>

        <Divider/>
        <div className=" flex content-between justify-start m-8 ">
        <div className="flex items-center mr-8 ">
            <Typography>{t("EXEMPT")} </Typography>
            <Controller
              control={control}
              name="exempt"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="ml-16 w-1/4 text-center"
                  variant="filled"
                  fullWidth
                  size="small"
                  error={!!errors.exempt}
                  helperText={t(errors?.exempt?.message ?? "")}
                />
              )}
            />
        </div>
        </div>
        <Divider/>
        <div className=" flex content-between justify-start m-8 ">
        <Typography>{t("DISTRICT")} </Typography>
        <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value="no"
            control={
              <Switch
                checked={switchValue}
                onChange={handleSwitchChange}
                color="primary"
              />
            }
            label={switchValue ? 'Yes' : 'No'}
            labelPlacement="start"
          />
        </FormGroup>
      </FormControl>
      {switchValue && (
        <TextField
          label={t("STATE")}
          value={stateValue}
          onChange={handleStateChange}
          className='ml-24'
        />
      )}
    </div>
    <div className=" content-between justify-start m-8 ">
        <Typography>{t("DC_INCOME")}</Typography>
        <div className='flex mt-8'>
        <Typography><b>{t("FULL_TIME_STUDENT")}</b></Typography>
        <FormControl component="fieldset">
        <FormGroup aria-label="full-time" row>
          <FormControlLabel
            value="no"
            control={
              <Switch
                checked={switchFulltimeValue}
                onChange={handleFulltimeSwitchChange}
                color="primary"
              />
            }
            label={switchFulltimeValue ? 'Yes' : 'No'}
            labelPlacement="start"
          />
        </FormGroup>
      </FormControl>
     </div>
    </div>

    <Divider className='border-b-4 border-black font-bold h-8'/>

    <div className=" content-between justify-start m-12 ">
    <Typography><b>{t("SIGNATURE")}</b> {t("UNDER")} </Typography>
    <div className='flex'>
    <Controller
        control={control}
        name="signature"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/3 text-center"
                variant="standard"
                label={t("SIGNATURE")}
                fullWidth
                size="small"
                error={!!errors.signature}
                helperText={t(errors?.signature?.message ?? "")}
                />
              )}
            />
    <Controller
        control={control}
        name="signedDate"
        render={({ field }) => (
            <DatePicker
                className="mt-16 w-1/4 bg-white text-black-500 rounded-4 ml-256"
                label={t("DATE")}
                value={field.value ? new Date(field.value) : null}
                onChange={(date) => field.onChange(date?.toISOString())}
                slotProps={{ textField: { size: "small" } }}
            />
        )}
    />
    </div>
    <Typography><span className='text-red font-700'>{t("EMPLOYER")}</span> {t("KEEP")}</Typography>
     <Typography className='text-center font-600'>{t("DETACH")}</Typography>
    </div>    
    </Card>
    </>
      
   
  )
}

export default w4AllowanceCertificate;
