import React from "react";
import { Navigate } from "react-router-dom";
import { UserRole } from "src/app/@types/enums/user.roles";
import YouthOpportunitiesViewPage from "./youth-opportunities-view/YouthOpportunitiesViewPage";
import i18next from "i18next";
import english from "./i18n/english";

i18next.addResourceBundle("en", "YouthOpportunitiesPage", english);


export const YouthOpportunitiesRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Youth],
  routes: [
    {
      path: "youth-opportunities",
      element: <YouthOpportunitiesViewPage />,
      auth: [UserRole.Youth],
      children: [
        {
          path: "",
          element: <YouthOpportunitiesViewPage />,
        },
      ],
    },
  ],
};
