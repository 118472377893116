import * as React from "react";
import IndexHeader from "app/shared-components/IndexHeader";
import CustomButton from "app/shared-components/CustomColorButton";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import history from "@history";
import AdminFormFilter from "./components/AdminFormFilter";
import AdminFormTable from "./components/AdminFormTable";

  const handleClick = () => {
    history.push('admin-form/add');
  };


export default function AdminFormIndexPage() {
  const myButtons = [
    <CustomButton
      StartIcon={<AddIcon />}
      className="mx-9"
      disabled={false}
      onClick={handleClick}
      name="New"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];
  return (
    <>
      <IndexHeader
        name={"User Index"}
        update={false}
        add={false}
        buttons={myButtons}
      />
      <AdminFormFilter />
      <AdminFormTable />
    </>
  );
}
