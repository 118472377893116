const locale = {
  SIGN_IN: "Sign in",
  DONT_HAVE_ACCOUNT: "Don't have an account?",
  SIGNUP: "Sign up",
  EMAIL: "Email",
  PASSWORD: "Password",
  REMEMBER_ME: "Remember me",
  FORGOT_PASSWORD: "Forgot password?",

  //Error messages
  VALID_EMAIL_ERROR: "You must enter a valid email",
  MUST_ENTER_EMAIL_ERROR: "Please enter your email",
  PASSWORD_ERROR: "Please enter your password.",
  PASSWORD_SHORT: "Password is too short - must be at least 4 chars.",
};

export default locale;
