const locale= {
    COMPANY:"Company:",
    CATEGORY:"Category:",
    HOURLY_RATE:"Hourly Rate:",
    ADDRESS:"Address:",
    MILEAGE:"Mileage:",
    MORE:"More",
    APPLY:"Apply",
    START_DATE:"Start Date:",
    END_DATE:"End Date:",
    DESCRIPTION:"Description:",
    CLOSE:"Close",
    STATUS:"Status",
}
export default locale;