import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import BackIcon from "@mui/icons-material/Backspace";
import { FormControl, InputLabel, Select, IconButton, Card, MenuItem } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from "react-i18next";


function SubmitSalaryDetailsFilter() {
    const { t } = useTranslation("submitSalaryDetails"); 
  return (
    <>

<Card variant="outlined" className="m-24 mb-0">
        <div className="flex content-between justify-start m-24  ">
          <div className="w-1/5 mt-16 mr-16">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">{t("PROGRAM")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Program"
                >
                  <MenuItem value={"1"}>
                    Summer Youth Employment Program
                  </MenuItem>
                  <MenuItem value={"2"}>In School Youth Program</MenuItem>
                  <MenuItem value={"3"}>Out-of-School Youth Program</MenuItem>
                  <MenuItem value={"4"}>Youth Leadership Program</MenuItem>
                  <MenuItem value={"5"}>Summer Intensive Training</MenuItem>
                  <MenuItem value={"6"}>
                    Pathways for Young Adults Program
                  </MenuItem>
                  <MenuItem value={"7"}>Youth Earn and Learn Program</MenuItem>
                  <MenuItem value={"8"}>School Year Program</MenuItem>
                  <MenuItem value={"8"}>Global Education Program</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="w-1/5 mt-16 mr-16">
            {" "}
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">{t("YEAR")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Year"
                >
                  <MenuItem value={"1"}>2020</MenuItem>
                  <MenuItem value={"2"}>2021</MenuItem>
                  <MenuItem value={"3"}>2022</MenuItem>
                  <MenuItem value={"4"}>2023</MenuItem>
                  <MenuItem value={"5"}>2024</MenuItem>
                  <MenuItem value={"5"}>2025</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="w-1/5 mt-16 mr-16">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">{t("PAY_PERIOD")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                >
                  <MenuItem value={"1"}>Period-1</MenuItem>
                  <MenuItem value={"2"}>Period-2</MenuItem>
                  <MenuItem value={"3"}>Period-3</MenuItem>
                  <MenuItem value={"4"}>Period-4</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className=" mt-16 mr-16">
          <FormGroup>
            <FormControlLabel control={<Checkbox defaultChecked />} label={t("INCLUDE_NOT")} />
        </FormGroup>
          </div>

          <div className="mt-20">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <SearchIcon />
            </IconButton>
          </div>
          <div className="mt-20  mr-1">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <BackIcon />
            </IconButton>
          </div>
        </div>
      </Card>

    </>
  );
}

export default SubmitSalaryDetailsFilter;
