import { Navigate } from "react-router-dom";
import React from "react";
import AdminAddProgramPage from "./admin-program-add-update/adminProgramAddUpdatePage";
import AdminProgramPageIndex from "./admin-program-index/admin-program";
import AdminProgramAddUpdatePage from "./admin-program-add-update/adminProgramAddUpdatePage";
import AdminProgram from "./admin-program";
import { UserRole } from "src/app/@types/enums/user.roles";

export const AdminProgramRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Admin],
  routes: [
    {
      path: "admin-program-page",
      element: <AdminProgram />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <AdminProgramPageIndex />,
        },
        {
          path: "add",
          element: <AdminProgramAddUpdatePage />,
        },
        {
          path: "update/:id",
          element: <AdminProgramAddUpdatePage />,
        },
      ],
    },
  ],
};
