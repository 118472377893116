import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  FormControl,
  Select,
  MenuItem,
  Card,
  Typography,
  FormHelperText,
  Button,
  FormControlLabel,
  InputLabel,
  Box
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import IndexHeader from "app/shared-components/IndexHeader";
import { useParams } from "react-router-dom";
import CustomButton from "app/shared-components/CustomColorButton";
import history from "@history";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PrintIcon from "@mui/icons-material/Print";
const defaultValues = {
  hostAgency: "",
  dateOfIncident: "",
  worksite: "",
  worksiteSupervisor: "",
  nameOfPersonsInvolved: "",
  howIncidentOccurred: "",
  witnessOneName: "",
  witnessTwoName: "",
  witnessOnePhone: "",
  witnessTwoPhone: "",
  otherRemark: "",
  nameOfPersonCompletingForm: "",
  formFilledDate: "",
 
};

const schema = yup.object().shape({
  participantName: yup.string().required("First Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Enter valid Email address"),
  employeeType: yup.string().required("Employee Type is required"),
  worksiteType: yup.string().required("Worksite Type is required"),
  primaryPhone: yup.string().required("Primary Phone is required"),
  secondaryPhone: yup.string().required("Secondary Phone is required"),
  dob: yup.string().nullable().required("Date of Birth is required"),
  faxNumber: yup.string().required("Fax Number is required"),
  ssn: yup.string().required("SSN is required"),
  isUnder18: yup.boolean().default(false),
  isCreateSystemAccount: yup.boolean().default(false),
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), ""], "Password not matched"),
});

export default function IncidentFormAddUpdate() {
  const routeParams = useParams();
  const { id } = routeParams;

  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  const [values, setValue] = useState({
    employeeType: "",
  });
  

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue({
      ...values,
      [name]: value,
    });
  };

  
  const handleClick = () => {
    history.push("incident-form/list");
  };

  const myButtons = [
    <Button
      className="mx-8"
      variant="contained"
      disabled={false}
      startIcon={<CancelIcon />}
      onClick={handleClick}
    >
      {"Cancel"}
    </Button>,
    <CustomButton
      StartIcon={<PrintIcon />}
      className="mx-8"
      disabled={false}
      onClick={handleClick}
      name="Print"
      color="primary"
      variant="contained"
    ></CustomButton>,
    <CustomButton
      StartIcon={<SaveIcon />}
      className="mx-8"
      disabled={false}
      onClick={handleClick}
      name="Save"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];

  return (
    <>
      <IndexHeader
        name={id ? "Update Incident Form" : "Incident Form"}
        update={id ? true : false}
        add={id ? false : true}
        previousPath={"incident-form/list"}
        buttons={myButtons}
      />

      <Card variant="outlined" className="m-24 mb-12">
        <div className="m-24 mt-8">
          <Typography className="text-1xl tracking-tight leading-tight">
            This report is to be completed be the worksite Supervisor within 24
            hours of the incident. This form is a confidential, internal
            document and is not be shared with persons who are not employees of
            the Department of Employment Services.
          </Typography>
          <div className="flex grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start">
          <Controller
              control={control}
              name="hostAgency"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Host Agency"
                  placeholder="Host Agency"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.hostAgency}
                  helperText={errors?.hostAgency?.message}
                  multiline
                  value={"Codice"}
                />
              )}
            />
           
            <Controller
              control={control}
              name="dateOfIncident"
              render={({ field }) => (
                <DatePicker
                  className="mt-16 w-full"
                  label="Date of Incident "
                  slotProps={{ textField: { size: "small" } }}
                />
              )}
            />
            <div className=" mt-16 mr-16">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Worksite
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Worksite"
                >
                  <MenuItem value={"1"}>Head Office</MenuItem>
                  <MenuItem value={"2"}>Financial Division</MenuItem>
                  <MenuItem value={"3"}>Marketing Division</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className=" mt-16 mr-16">
            <Controller
              control={control}
              name="worksiteSupervisor"
              render={({ field }) => (
                <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Worksite Supervisor
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Worksite Supervisor"
                >
                  <MenuItem value={"1"}>Andrew Smith</MenuItem>
                  <MenuItem value={"2"}>Emma Watson</MenuItem>
                  <MenuItem value={"3"}>Peter Parker</MenuItem>
                </Select>
              </FormControl>
              )}
            />
            </div>
            <div className=" mt-16 mr-16">
            <Controller
              control={control}
              name="nameOfPersonsInvolved"
              render={({ field }) => (
                <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Persons Involved
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Persons Involved"
                >
                  <MenuItem value={"1"}>Simon Lee</MenuItem>
                  <MenuItem value={"2"}>Penny Cooper</MenuItem>
                  <MenuItem value={"3"}>Mia George</MenuItem>
                </Select>
              </FormControl>
              )}
            />
            </div>
          </div>

          <div className="flex grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 mt-16 content-start">
            <Controller
              control={control}
              name="howIncidentOccurred"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label=" Describe how the incident occurred (Include facts only, exclude opinions and/ or assumption):"
                  placeholder="Describe how the incident occurred (Include facts only, exclude opinions and/ or assumption):"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.howIncidentOccurred}
                  helperText={errors?.howIncidentOccurred?.message}
                  multiline
                />
              )}
            />
          </div>
          <div className="flex grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 mt-16 gap-16 content-start">
            <Controller
              control={control}
              name="witnessOneName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Witness(Title:Supervisor Youth,etc)"
                  placeholder="Witness(Title:Supervisor Youth,etc)"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.witnessOneName}
                  helperText={errors?.witnessOneName?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="witnessOnePhone"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Phone"
                  placeholder="Phone"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.witnessOneName}
                  helperText={errors?.witnessOneName?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="witnessTwoName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Witness(Title:Supervisor Youth,etc)"
                  placeholder="Witness(Title:Supervisor Youth,etc)"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.witnessOneName}
                  helperText={errors?.witnessOneName?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="witnessTwoPhone"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Phone"
                  placeholder="Phone"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.witnessOneName}
                  helperText={errors?.witnessOneName?.message}
                />
              )}
            />
          </div>
          <div className="flex grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 mt-16 content-start">
            <Controller
              control={control}
              name="otherRemark"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Other remarks"
                  placeholder="Other remarks"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.otherRemark}
                  helperText={errors?.otherRemark?.message}
                  multiline
                />
              )}
            />
          </div>

          <div className="flex grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 mt-16 gap-16 content-start">
            <Controller
              control={control}
              name="nameOfPersonCompletingForm"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Name of person completing this form"
                  placeholder="Name of person completing this form"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.nameOfPersonCompletingForm}
                  helperText={errors?.nameOfPersonCompletingForm?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="formFilledDate"
              render={({ field }) => (
                <DatePicker
                  className="mt-16 w-full"
                  label="Date"
                  slotProps={{ textField: { size: "small" } }}
                />
              )}
            />
          </div>
        </div>
      </Card>
    </>
  );
}
