import React from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";

export default function ProfileHeader() {
  const user = useSelector(selectUser);
  return (
    <>
      <div className="flex flex-col bg-white">
        <div className="flex flex-col flex-0 lg:flex-row items-center justify-start max-w-5xl w-full px-32 lg:h-96 mt-24">
          {/* <div className="rounded-full lg:mb-24">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1, transition: { delay: 0.1 } }}
            >
              <Avatar
                sx={{ borderColor: "background.paper" }}
                className="h-96 w-full border-2 border-gray-400"
                src="assets/images/pages/profile/company-1.jpg"
                alt="User avatar"
              />
            </motion.div>
          </div> */}

          <div className="flex flex-col items-center lg:items-start mt-16 lg:-mt-24 lg:mt-0 lg:ml-24">
            <Typography color="text.secondary">Full Name</Typography>
            <Typography className="text-lg font-bold leading-none mt-2">
              {user.data.displayName}
            </Typography>
          </div>

          <div className="hidden lg:flex h-32 mx-32 border-l-2" />

          <div className="flex flex-col items-center lg:items-start mt-16 lg:-mt-24 lg:mt-0 lg:ml-12">
            <Typography color="text.secondary">Company Type</Typography>
            <Typography className="text-lg font-bold leading-none mt-2">
              SME Company
            </Typography>
          </div>

          <div className="hidden lg:flex h-32 mx-32 border-l-2" />

          <div className="flex flex-col items-center lg:items-start mt-16 lg:-mt-24 lg:mt-0 lg:ml-12">
            <Typography color="text.secondary">ID Type</Typography>
            <Typography className="text-lg font-bold leading-none mt-2">
              FGTH
            </Typography>
          </div>

          <div className="hidden lg:flex h-32 mx-32 border-l-2" />

          <div className="flex flex-col items-center lg:items-start mt-16 lg:-mt-24 lg:mt-0 lg:ml-12">
            <Typography color="text.secondary">ID Number</Typography>
            <Typography className="text-lg font-bold leading-none mt-2">
              46519874654
            </Typography>
          </div>

          <div className="mt-24">
          </div>
        </div>
      </div>
    </>
  );
}
