import * as React from "react";
import IndexHeader from "app/shared-components/IndexHeader";
import history from "@history";
import AdminApprovalFormFilter from "./components/AdminApprovalFormFilter";
import AdminApprovalFormList from "./components/ApminApprovalFormTable";

  const handleClick = () => {
    history.push('incident-form/add');
  };


export default function AdminApprovalsFormIndexPage() {
  
  return (
    <>
      <IndexHeader
        name={"Approvals"}
        update={false}
        add={false}
      />
      <AdminApprovalFormFilter />
      <AdminApprovalFormList />
    </>
  );
}
