import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// import ProfileHeader from "./components/ProfileHeader";
import { styled } from "@mui/material/styles";
import FusePageSimple from "@fuse/core/FusePageSimple";
import FuseNavigation from "@fuse/core/FuseNavigation";
import { useThemeMediaQuery } from "@fuse/hooks";
import Card from "@mui/material/Card";
import { motion } from "framer-motion";
import InnerFooter from "app/shared-components/InnerFooter";

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    "& > .container": {
      maxWidth: "100%",
    },
  },
}));

const navigationData = [
  {
    id: "1",
    title: "Actions",
    type: "group",
    children: [
      {
        id: "1",
        title: "Enroll",
        type: "item",
        icon: "heroicons-outline:inbox",
        url: "application-enroll",
        badge: {
          title: " ✓",
          classes: "px-5 bg-green text-on-secondary rounded-full",
        },
      },
      {
        id: "2",
        title: "Career Preference",
        type: "item",
        icon: "heroicons-solid:star",
        url: "career-interest",
      },
      {
        id: "3",
        title: "Resume",
        type: "item",
        icon: "heroicons-outline:document",
        url: "application-resume",
        badge: {
          title: " ✓",
          classes: "px-5 bg-green text-on-secondary rounded-full",
        },
      },
      {
        id: "4",
        title: "Parent Consent",
        type: "item",
        icon: "heroicons-outline:check",
        url: "parent-consent-form",
      },
      {
        id: "5",
        title: "Proof of Age",
        type: "item",
        icon: "heroicons-outline:calendar",
        url: "proof-of-age",
      },
      {
        id: "6",
        title: "Proof of Residency",
        type: "item",
        icon: "heroicons-outline:home",
        url: "proof-of-residency",
      },
      {
        id: "7",
        title: "Proof of SSN",
        type: "item",
        icon: "heroicons-outline:map",
        url: "proof-of-ssn",
        badge: {
          title: " ✓",
          classes: "px-5 bg-green text-on-secondary rounded-full",
        },
      },
      {
        id: "8",
        title: "Proof of Work",
        type: "item",
        icon: "heroicons-outline:briefcase",
        url: "proof-of-work",
      },
      {
        id: "9",
        title: "Certification",
        type: "item",
        icon: "heroicons-outline:document",
        url: "certification",
        badge: {
          title: " ✓",
          classes: "px-5 bg-green text-on-secondary rounded-full",
        },
      },
      {
        id: "10",
        title: "Orientation",
        type: "item",
        icon: "heroicons-outline:briefcase",
        url: "orientation",
      },
      {
        id: "11",
        title: "Banking",
        type: "item",
        icon: "heroicons-outline:user",
        url: "application-banking",
      },
      {
        id: "12",
        title: "Transportation",
        type: "item",
        icon: "heroicons-outline:truck",
        url: "transportation",
        badge: {
          title: " ✓",
          classes: "px-5 bg-green text-on-secondary rounded-full",
        },
      },
    ],
  },
];

export default function YouthJobApplication() {
  const isMobile = useThemeMediaQuery((theme: any) =>
    theme.breakpoints.down("lg")
  );

  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <>
      <Root
        // header={<ProfileHeader />}
        content={
          <Card variant="outlined" className="m-24 w-full">
            <div className="m-24">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1, transition: { delay: 0.2 } }}
              >
                <Outlet></Outlet>
              </motion.div>
            </div>
          </Card>
        }
        leftSidebarContent={
          <div className="px-12 py-24 min-h-6xl">
            <div className="mx-12 text-3xl font-bold tracking-tighter">
              Job Application
            </div>
            <FuseNavigation navigation={navigationData} />
          </div>
        }
        leftSidebarOpen={leftSidebarOpen}
        scroll="content"
      />
    </>
  );
}
