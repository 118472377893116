import axios from "axios";
import { CoreService } from "./core.service";
import { authDataService } from "../data/auth.data.service";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const ProgramService  = {
    getData : async () => {
        try {
          const response = await axios.get(`${BASE_URL}/program`); 
          console.log(response.data);
          return response.data;
        } catch (error) {
            console.log(error)
          throw new Error('Error fetching data from the API');
        }
      },
   
      enrollProgram: async (programIds: string[]) => {
        try {
          const url = `${BASE_URL}/youth-selected-program`;
          // const headers = {
          //   'Content-Type': 'application/json', 
          //   'Authorization': `Bearer ${localStorage.getItem('ms_uid')}`, 
          // };

          const userId = authDataService.userId;
          const requestBody = {
            programId: programIds,
            userId :userId
          };
      
          const response = await axios.post(url, requestBody);
      
          console.log(response.data); 
        } catch (error) {
          console.error(error);
        }
      },

      getenrollProgram: async () => {
        try {
          const token = localStorage.getItem('ms_uid'); 
          console.log(token)
          const headers = {
            'Authorization': `Bearer ${token}`,
          };
      
          const response = await axios.get(`${BASE_URL}/youth-selected-program`, { headers });
          console.log(response.data);
          return response.data;
        } catch (error) {
          console.error(error);
          throw new Error('Error fetching data from the API');
        }
      }

      
      
      
}

export default ProgramService