import React, { Component, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import BackIcon from "@mui/icons-material/Backspace";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Card,
  Typography,
  FormHelperText,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { DateTimePicker } from "@mui/x-date-pickers";
import IndexHeader from "app/shared-components/PageHeader";
import { useParams } from "react-router-dom";

const defaultValues = {
  program: "",
  year: "",
  description: "",
  employeeType: "",
  allowReturningYouth: "",
  allowReturningHost: "",
  isInactive: "",
};

const schema = yup.object().shape({});

export default function GeneralInfo() {
  const routeParams = useParams();
  const { id } = routeParams;

  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  const [values, setValue] = useState({
    employeeType: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue({
      ...values,
      [name]: value,
    });
  };

  return (
    <>
      <Card variant="outlined" className="m-24 mb-0">
        <div className="m-24 mt-8">
          <div className="flex grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start">
            <Controller
              control={control}
              name="program"
              render={({ field }) => (
                <FormControl error={!!errors.program}>
                  <Select
                    {...field}
                    displayEmpty
                    size="small"
                    className="mt-16 w-full"
                    value={field.value || ""}
                  >
                    <MenuItem value="" disabled>
                      Program
                    </MenuItem>
                    <MenuItem value={"1"}>
                      Summer Youth Employment Program
                    </MenuItem>
                    <MenuItem value={"2"}>In-School-Youth Program</MenuItem>
                    <MenuItem value={"3"}>Out-of-School-Youth Program</MenuItem>
                    <MenuItem value={"4"}>Youth Leadership Program</MenuItem>
                    <MenuItem value={"5"}>Summer Intensive Training</MenuItem>
                    <MenuItem value={"6"}>Pathways for young Adults</MenuItem>
                    <MenuItem value={"7"}>Youth Earn & Learn Program</MenuItem>
                    <MenuItem value={"8"}>School Year Program</MenuItem>
                    <MenuItem value={"9"}>Global Education Program</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.program?.message}</FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="year"
              render={({ field }) => (
                <FormControl error={!!errors.program}>
                  <Select
                    {...field}
                    displayEmpty
                    size="small"
                    className="mt-16 w-full"
                    value={field.value || ""}
                  >
                    <MenuItem value="" disabled>
                      Year
                    </MenuItem>
                    <MenuItem value={"1"}>2020</MenuItem>
                    <MenuItem value={"2"}>2021</MenuItem>
                    <MenuItem value={"3"}>2022</MenuItem>
                    <MenuItem value={"4"}>2023</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.year?.message}</FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Description"
                  placeholder="Description"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.description}
                  helperText={errors?.description?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="allowReturningYouth"
              render={({ field }) => (
                <FormControl error={!!errors.program}>
                  <Select
                    {...field}
                    displayEmpty
                    size="small"
                    className="mt-16 w-full"
                    value={field.value || ""}
                  >
                    <MenuItem value="" disabled>
                      Allow Returning Youth?
                    </MenuItem>
                    <MenuItem value={"1"}>Yes</MenuItem>
                    <MenuItem value={"2"}>No</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.allowReturningYouth?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="allowReturningHost"
              render={({ field }) => (
                <FormControl error={!!errors.program}>
                  <Select
                    {...field}
                    displayEmpty
                    size="small"
                    className="mt-16 w-full"
                    value={field.value || ""}
                  >
                    <MenuItem value="" disabled>
                      Allow Returning Host?
                    </MenuItem>
                    <MenuItem value={"1"}>Yes</MenuItem>
                    <MenuItem value={"2"}>No</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.allowReturningHost?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </div>
          <div className="flex mt-24">
            <Controller
              control={control}
              name="isInactive"
              render={({ field: { onChange } }) => (
                <Checkbox
                  onChange={(ev) => onChange(ev.target.checked)}
                  size="medium"
                  className="-mt-8 -ml-10"
                />
              )}
            />
            <Typography className="text-lg">Inactive</Typography>
          </div>
          <div className="flex items-center justify-end mt-32">
            <Button
              className="mx-8"
              variant="contained"
              color="secondary"
              disabled={false}
            >
              Save
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
}
