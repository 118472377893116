import React from "react";
import { Navigate } from "react-router-dom";
import AdminHostList from "./admin-host-list";
import AdminHostListIndexPage from "./admin-host-list-index/AdminHostListIndexPage";
import { UserRole } from "src/app/@types/enums/user.roles";
import i18next from "i18next";
import english from "./i18n/english";

i18next.addResourceBundle("en", "hostList", english);

export const AdminHostListRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Admin],
  routes: [
    {
      path: "host-details/admin-host-list",
      element: <AdminHostList />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <AdminHostListIndexPage />,
        },
        
      ],
    },
  ],
};