const locale = {
  TWO_STEP_VERIFICATION: "Verificación en dos pasos",
  PLEASE_SELECT_AUTHENTICATION: "Seleccione su método de autenticación",
  EMAIL: "Correo electrónico",
  GET_VERIFICATION_CODE: "Obtenga el código de verificación en ",
  SMS: "SMS",
  OR_TRY: "O tratar",
  SIGN_IN: "Iniciar sesión",
  AGAIN: "¡De nuevo!",

  //verification page
  TEXT_MESSAGE_SENT:
    "Se envió un mensaje de texto con un código de verificación de 4 dígitos a ",
  EMAIL_MESSAGE_SENT:
    "Se envió un correo electrónico con un código de verificación de 4 dígitos a ",
  ENTER_CODE: "Introduzca el código",
  NEXT: "Próxima",

  ENTER_CODE_LENGTH_ERROR: "La longitud del código debe ser de 6 dígitos.",
  CODE_REQUIRED_ERROR: "Se necesita un código de verificación.",
};

export default locale;
