import React from "react";
import { Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import EnrollProgramUpdate from "./components/EnrollProgramUpdate";

function EnrollFormPage() {
    return (
        <>
        <EnrollProgramUpdate/>
        </>
    );

}
export default EnrollFormPage;