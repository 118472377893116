import Button from "@mui/material/Button";
import { Link, Navigate } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React from "react";
import Box from "@mui/material/Box";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  FormGroup,
} from "@mui/material";

const defaultValues = {
  question1: "",
  question2: "",
  question3: "",
  question4: "",
  question5: "",
  question6: "",
  question7: "",
  question8: "",
  question9: "",
  question10: "",
};
const schema = yup.object().shape({
  question1: yup.string().required("This field is required"),
  question2: yup.string().required("This field is required"),
  question3: yup.string().required("This field is required"),
  question4: yup.string().required("This field is required"),
  question5: yup.string().required("This field is required"),
  question6: yup.string().required("This field is required"),
  question7: yup.string().required("This field is required"),
  question8: yup.string().required("This field is required"),
  question9: yup.string().required("This field is required"),
  question10: yup.string().required("This field is required"),
});

import SampleQuestion from "./sampleQuestion";
import { register } from "src/serviceWorker";
import { useTranslation } from "react-i18next";
function Test() {
  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();
  const { t } = useTranslation("JobApplication");

  if (_.isEmpty(form)) {
    return null;
  }

  return (
    <div className="flex flex-col p-10 sm:p-10 container">
      <div className="flex flex-col w-full max-w-6xl">
        <div className="sm:mt-5"></div>
        <Paper className="mt-5 sm:mt-5 p-24 pb-28 sm:p-5 sm:pb-10 rounded-2xl">
          <form className="px-0 sm:px-24">
            <div className="mb-24"></div>
            <SampleQuestion
              name="question1"
              question={t("Question_One")}
              radOne={t("Rad_One")}
              radTwo={t("Rad_Two")}
              radThree={t("Rad_Three")}
              radFour={t("Rad_Four")}
            />
            <SampleQuestion
              name="question2"
              question={t("Question_Two")}
              radOne={t("Rad_One")}
              radTwo={t("Rad_Two")}
              radThree={t("Rad_Three")}
              radFour={t("Rad_Four")}
            />
            <SampleQuestion
              name="question3"
              question={t("Question_Three")}
              radOne={t("Rad_One")}
              radTwo={t("Rad_Two")}
              radThree={t("Rad_Three")}
              radFour={t("Rad_Four")}
            />
            <SampleQuestion
              name="question4"
              question={t("Question_Four")}
              radOne={t("Rad_One")}
              radTwo={t("Rad_Two")}
              radThree={t("Rad_Three")}
              radFour={t("Rad_Four")}
            />
            <SampleQuestion
              name="question5"
              question={t("Question_Five")}
              radOne={t("Rad_One")}
              radTwo={t("Rad_Two")}
              radThree={t("Rad_Three")}
              radFour={t("Rad_Four")}
            />
            <SampleQuestion
              name="question6"
              question={t("Question_Six")}
              radOne={t("Rad_One")}
              radTwo={t("Rad_Two")}
              radThree={t("Rad_Three")}
              radFour={t("Rad_Four")}
            />
            <SampleQuestion
              name="question7"
              question={t("Question_Seven")}
              radOne={t("Rad_One")}
              radTwo={t("Rad_Two")}
              radThree={t("Rad_Three")}
              radFour={t("Rad_Four")}
            />
            <SampleQuestion
              name="question8"
              question={t("Question_Eight")}
              radOne={t("Rad_One")}
              radTwo={t("Rad_Two")}
              radThree={t("Rad_Three")}
              radFour={t("Rad_Four")}
            />
            <SampleQuestion
              name="question8"
              question={t("Question_Eight")}
              radOne={t("Rad_One")}
              radTwo={t("Rad_Two")}
              radThree={t("Rad_Three")}
              radFour={t("Rad_Four")}
            />
            <SampleQuestion
              name="question9"
              question={t("Question_Nine")}
              radOne={t("Rad_One")}
              radTwo={t("Rad_Two")}
              radThree={t("Rad_Three")}
              radFour={t("Rad_Four")}
            />
            <SampleQuestion
              name="question10"
              question={t("Question_Ten")}
              radOne={t("Rad_One")}
              radTwo={t("Rad_Two")}
              radThree={t("Rad_Three")}
              radFour={t("Rad_Four")}
            />
          </form>
          <div className="flex items-center justify-end mt-32">
            <Button
              className="mx-8"
              variant="contained"
              color="secondary"
              disabled={false}
            >
              {t("ReAttampt_BTN")}
            </Button>
            <Button
              className="mx-8"
              variant="contained"
              type="submit"
              color="secondary"
              disabled={false}
            >
              {t("Submit_BTN")}
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default Test;
