import React, { Component } from "react";
import { Outlet } from "react-router-dom";

export default class AdminForm extends Component {
  render() {
    return (
      <>
        <Outlet />
      </>
    );
  }
}
