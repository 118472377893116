import React from "react";
import { Navigate } from "react-router-dom";
import w4TaxForm from "./w4-tax-form";
import W4TaxFormIndexPage from "./w4-tax-form-index/W4TaxFormIndexPage"
import { UserRole } from "src/app/@types/enums/user.roles";
import i18next from "i18next";
import english from "./i18n/english";

i18next.addResourceBundle("en", "w4AllowanceCertificate", english);

export const w4TaxFormRoutes = {
    settings: {
      layout: {},
    },
    auth: [UserRole.Youth],
    routes: [
      {
        path: "w4-tax-form",
        element: <W4TaxFormIndexPage/>,
      },
    ],
  };
