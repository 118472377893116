import { t } from "i18next";
import React, { ChangeEvent, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  FormControl,
  Select,
  MenuItem,
  Card,
  Typography,
  FormHelperText,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Box,
} from "@mui/material";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useTranslation } from "react-i18next";
import Document from "react-pdf/dist/esm/Document";
import Page from "react-pdf/dist/esm/Page";
import ReactImage from "react-image";
import IndexHeader from "app/shared-components/IndexHeader";
import DeleteIcon from "@mui/icons-material/Delete";
import InnerFooter from "app/shared-components/InnerFooter";

const defaultValues = {
  proofsofAge: "",
  browse: "",
  download: "",
  upload: "",
  fileName: "",
  delete: "",
};

const schema = yup.object().shape({
  ProofofAge: yup.string().required(" SELECTED_DOCUMENTS_ERROR"),
  applicationType: yup.string().required(" SELECTED_DOCUMENTS_ERROR"),
});

export default function ProofofResidence() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { control, handleSubmit, watch, formState, register } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();
  const { t } = useTranslation("JobApplication");

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] as File;
    setSelectedFile(file);
  };

  const handleDownload = () => {
    {
      if (selectedFile) {
        const url = URL.createObjectURL(selectedFile);
        const link = document.createElement("a");
        link.href = url;
        link.download = selectedFile.name;
        link.click();
      }
    }
  };

  const [applicationType, setApplicationType] = useState("");

  const handleChange = (event: any) => {
    setApplicationType(event.target.value);
  };

  const handleDelete = () => {
    setSelectedFile(null);
    selectedFile != selectedFile;
  };

  const file = { selectedFile } || File || undefined;
  const isPDF = selectedFile?.name.endsWith(".pdf");

  return (
    <div>
      <Typography className="text-20 ml-32 mt-8 font-semibold tracking-tight leading-tight">
        {t("PROOF_OF_RESIDENCE_NAVI")}
      </Typography>
      <Card variant="outlined" className="m-24 p-10">
        <b>{t("PROOF_OF_RESIDENCE_NAVI")}</b> -{" "}
        {t("PROOF_OF_RESIDENCY_DESCRIPTION")}
      </Card>
      <Card variant="outlined" className="mt-24 ml-24 mr-24">
        <div>
          <div className=" flex  grid ml-32 mr-32 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-32 content-startm-32">
            <div className="w-full">
              <Card className="w-full mt-32 mb-32">
                {selectedFile && (
                  <Typography className="bg-orange mt-16 text-white text-lg w-full text-center items-center">
                    {t("APPROVAL_STATUS")}
                  </Typography>
                )}
                <div className="flex grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-16 content-startm-32">
                  <Controller
                    control={control}
                    name="proofsofAge"
                    render={({ field }) => (
                      <FormControl error={!!errors.proofsofAge}>
                        <Select
                          {...field}
                          value={applicationType}
                          onChange={handleChange}
                          displayEmpty
                          size="small"
                          className="mt-32 ml-32 mr-32 mb-0 w-7/8"
                        >
                          <MenuItem value="" disabled>
                            {t("Type")}
                          </MenuItem>
                          <MenuItem value={t("Us_Passport")}>
                            {t("Learners_Permit")}
                          </MenuItem>
                          <MenuItem value={t("on_Drivers_Identification_Card")}>
                            {t("Non_Drivers_Identification_Card")}
                          </MenuItem>
                          <MenuItem value={t("Official_School_Record")}>
                            {t("Official_School_Record")}
                          </MenuItem>
                          <MenuItem value={t("School_ID")}>
                            {t("Immunization_record")}
                          </MenuItem>
                          <MenuItem value={t("Permanent_Resident_Card")}>
                            {t("Income_Tax_Form")}
                          </MenuItem>
                          <MenuItem value={t("Permanent_Resident_Card")}>
                            {t("Executed_Lease_Rental_Agreement")}
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {errors?.proofsofAge?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                  <div className="flex grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-0 content-startm-32 mr-32">
                    <div className="mr-32">
                      <label htmlFor="" className="font-bold ml-32">
                        {t("FILE_NAME")}
                      </label>
                      {selectedFile && (
                        <Controller
                          control={control}
                          name="fileName"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mt-16 ml-32 mr-32"
                              variant="outlined"
                              fullWidth
                              size="small"
                              disabled={true}
                              value={selectedFile?.name}
                              error={!!errors.fileName}
                              helperText={errors?.fileName?.message}
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>

                  <Typography className="ml-32 mt-8  text-l font-semibold tracking-tight leading-tight">
                    {t("UPLOAD_HINT")}
                  </Typography>

                  <div className="flex grid mb-32 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 gap-16 content-startm-32">
                    {!selectedFile && (
                      <Button
                        variant="contained"
                        className="w-full ml-32 mr-auto mt-32 rounded-8 bg-blue hover:bg-blue text-white"
                      >
                        <Controller
                          control={control}
                          name="browse"
                          render={({ field: { onChange, value } }) => (
                            <Box className=" mt-16 relative flex items-center justify-center w-128 h-128 rounded-1 overflow-hidden">
                              <div className="absolute inset-0 flex items-center justify-center z-20">
                                <label
                                  htmlFor="button-upload"
                                  className="hover:text-white
                       flex-auto w-full text-center  cursor-pointer m-32"
                                >
                                  <Typography className="justify-center text-center w-1/8 mb-16 items-center hover:bg-blue text-white">
                                    {t("Browse_Btn")}
                                  </Typography>
                                  <input
                                    onChange={handleFileChange}
                                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                    className="hidden w-full m-32"
                                    id="button-upload"
                                    type="file"
                                  />
                                </label>
                              </div>
                            </Box>
                          )}
                        />
                      </Button>
                    )}

                    {selectedFile && (
                      <Button
                        variant="contained"
                        className="w-full ml-32 mr-auto mt-32 rounded-8 bg-blue hover:bg-blue text-white"
                      >
                        <Controller
                          control={control}
                          name="download"
                          render={({ field: { onChange, value } }) => (
                            <Box className=" mt-16 relative flex items-center justify-center w-128 h-128 rounded-1 overflow-hidden">
                              <div className="absolute inset-0 flex items-center justify-center z-20">
                                <label
                                  htmlFor="button-download"
                                  className="hover:text-white
                       flex-auto w-full text-center  cursor-pointer m-32"
                                  onClick={handleDownload}
                                >
                                  <Typography className="justify-center text-center w-1/8 mb-16  items-center hover:bg-blue text-white">
                                    {t("DOWNLOAD_BTN")}
                                  </Typography>
                                </label>
                              </div>
                            </Box>
                          )}
                        />
                      </Button>
                    )}

                    {selectedFile && (
                      <Button
                        variant="contained"
                        className="w-full ml-32 mr-auto mt-32 rounded-8 bg-blue hover:bg-blue text-white"
                      >
                        <Controller
                          control={control}
                          name="browse"
                          render={({ field: { onChange, value } }) => (
                            <Box className=" mt-16 relative flex items-center justify-center w-128 h-128 rounded-1 overflow-hidden">
                              <div className="absolute inset-0 flex items-center justify-center z-20">
                                <label
                                  htmlFor="button-delete"
                                  className="hover:text-white
                       flex-auto w-full text-center  cursor-pointer m-32"
                                  onClick={handleDelete}
                                >
                                  <Typography className="justify-center text-center w-1/8 mb-16 items-center hover:bg-blue text-white">
                                    {t("DELETE_BTN")}
                                  </Typography>
                                  <input
                                    onChange={handleFileChange}
                                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                    className="hidden w-full m-32"
                                    id="button-upload"
                                    type="file"
                                  />
                                </label>
                              </div>
                            </Box>
                          )}
                        />
                      </Button>
                    )}
                  </div>
                </div>
              </Card>
            </div>
            <div className="w-full mt-32 mb-32">
              <Card className="w-full bg-gray-300 mr-32">
                <Typography className="ml-16 mt-16 text-2xl font-semibold tracking-tight leading-tight text-blue">
                  {t("Acceptable_Documents")}
                </Typography>
                <ul className="m-32 list-disc w-7/8 items-center">
                  <li> {t("Drivers_licens")}</li>
                  <li>{t("Official_School_Record")}</li>
                  <li>{t("Immunization_Record")}</li>
                  <li>{t("Income_Tax_Form")}</li>
                  <li>{t("Executed_Lease")}</li>
                  {t("notics")}
                </ul>
              </Card>
            </div>
          </div>
          <hr />
          <div style={{ display: "flex" }} className="mb-32 ml-32 mr-32 mt-32">
            <div
              style={{
                width: "100%",
                border: "3px solid gray",
                height: "1500px",
              }}
            >
              {selectedFile ? (
                isPDF ? (
                  <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.min.js">
                    <Viewer fileUrl={URL.createObjectURL(selectedFile)} />
                  </Worker>
                ) : (
                  <>
                    <img src={URL.createObjectURL(selectedFile)} />
                  </>
                )
              ) : (
                <div>{t("No_FILE_SELECTED")}</div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
