import React, { Component, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import BackIcon from "@mui/icons-material/Backspace";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Card,
  Typography,
  FormHelperText,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { DateTimePicker } from "@mui/x-date-pickers";
import IndexHeader from "app/shared-components/PageHeader";
import { useParams } from "react-router-dom";
import AgeRequirementTable from "./components/ageRequirementTable";

const defaultValues = {
  fromAge: "",
  toAge: "",
  hourlyRate: "",
  employeeType: "",
  allowReturningYouth: "",
  allowReturningHost: "",
  isInactive: "",
  remark:"",
  maxHours:"",
};

const schema = yup.object().shape({});

export default function AgeRequirement() {
  const routeParams = useParams();
  const { id } = routeParams;

  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  const [values, setValue] = useState({
    employeeType: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue({
      ...values,
      [name]: value,
    });
  };

  return (
    <>
      <Card variant="outlined" className="m-24 mb-0">
        <div className="m-24 mt-8">
          <div className="flex grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start">
            <Controller
              control={control}
              name="fromAge"
              render={({ field }) => (
                <FormControl error={!!errors.fromAge}>
                  <Select
                    {...field}
                    displayEmpty
                    size="small"
                    className="mt-16 w-full"
                    value={field.value || ""}
                  >
                    <MenuItem value="" disabled>
                      From Age
                    </MenuItem>
                    <MenuItem value={"1"}>10</MenuItem>
                    <MenuItem value={"2"}>11</MenuItem>
                    <MenuItem value={"3"}>12</MenuItem>
                    <MenuItem value={"4"}>13</MenuItem>
                    <MenuItem value={"5"}>14</MenuItem>
                    <MenuItem value={"6"}>15</MenuItem>
                    <MenuItem value={"7"}>16</MenuItem>
                    <MenuItem value={"8"}>17</MenuItem>
                    <MenuItem value={"9"}>18</MenuItem>
                    <MenuItem value={"10"}>19</MenuItem>
                    <MenuItem value={"11"}>20</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.fromAge?.message}</FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="toAge"
              render={({ field }) => (
                <FormControl error={!!errors.toAge}>
                  <Select
                    {...field}
                    displayEmpty
                    size="small"
                    className="mt-16 w-full"
                    value={field.value || ""}
                  >
                    <MenuItem value="" disabled>
                      To Age
                    </MenuItem>
                    <MenuItem value={"1"}>10</MenuItem>
                    <MenuItem value={"2"}>11</MenuItem>
                    <MenuItem value={"3"}>12</MenuItem>
                    <MenuItem value={"4"}>13</MenuItem>
                    <MenuItem value={"5"}>14</MenuItem>
                    <MenuItem value={"6"}>15</MenuItem>
                    <MenuItem value={"7"}>16</MenuItem>
                    <MenuItem value={"8"}>17</MenuItem>
                    <MenuItem value={"9"}>18</MenuItem>
                    <MenuItem value={"10"}>19</MenuItem>
                    <MenuItem value={"11"}>20</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.toAge?.message}</FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="hourlyRate"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Hourly Rate($)"
                  placeholder="Hourly Rate($)"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.hourlyRate}
                  helperText={errors?.hourlyRate?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="maxHours"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Maximum Hours"
                  placeholder="Maximum Hours"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.maxHours}
                  helperText={errors?.maxHours?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="remark"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Remark"
                  placeholder="Remark"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.remark}
                  helperText={errors?.remark?.message}
                />
              )}
            />
          </div>
          <div className="flex mt-24">
            <Button
              className="mx-8"
              variant="contained"
              color="secondary"
              disabled={false}
            >
              Add
            </Button>
          </div>
          <AgeRequirementTable />
          <div className="flex items-center justify-end mt-32">
            <Button
              className="mx-8"
              variant="contained"
              color="secondary"
              disabled={false}
            >
              Save
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
}
