import { memo } from 'react';
import {Card} from "@mui/material";
import { useTranslation } from 'react-i18next';
import ReactPlayer from "react-player";


function DemoContent() {
  const { t } = useTranslation('demoContentPage');
  
  return (
    <div>
      <Card variant="outlined" className="m-10 mb-12">
        <div className='h-480'>
        <ReactPlayer
        width={"100%"}
        height={"100%"}
        url="https://www.youtube.com/watch?v=Y2v4v1A3fCU"
        controls={true}
        />
        </div>
      </Card>
      <Card variant="outlined" className="m-10 mb-12">
      <div className='flex'>
     
     <img
       src="assets/images/demo-content/demo-pic.png"
       alt="beach"
       style={{
         maxWidth: '400px',
         width: '100%',
       }}
       className="rounded-6"
     />
      <div >
       <h2 className="pb-12 font-800 mt-16">{t("INTRODUCTION")}</h2>
       <p className='text-justify mr-16'> 
            {t("INTRODUCTION_CONTENT")}
      </p>
     </div>
     </div>
      </Card>
      
      
      <blockquote>
      <Card variant="outlined" className="m-10 mb-12">
      <div>
      <h2 className="pb-12 font-800 mt-10 ml-16">{t("PROGRAM_OBJECTIVES")}</h2>
        <p className='text-justify m-10'> 
            {t("OBJECTIVE_CONTENT")}
        </p>
        
        </div>
      </Card>
      
        
      </blockquote>
     <Card variant="outlined" className="m-10 mb-12">
     <h2 className="pb-12 font-800 mt-10 ml-16">{t('IMPACT')}</h2>
     <p className='text-justify m-10'>
            {t("IMPACT_CONTENT")}
      </p>
     </Card>
      


      <Card  variant="outlined" className="m-10 mb-12">
      <h2 className="pb-12 font-800 mt-10 ml-16">{t("CONCLUSION")}</h2> 
      <p className='text-justify m-10'>
              {t("CONCLUSION_CONTENT")}
      </p>
      </Card>
      
      
    </div>
  );
}

export default memo(DemoContent);
