import i18next from "i18next";
import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";
import tr from "./navigation-i18n/tr";
import { UserRole } from "../@types/enums/user.roles";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);

const navigationConfig = [
  {
    id: "programs",
    title: "Programs",
    type: "collapse",
    icon: "heroicons-outline:clipboard-check",
    children: [
      {
        id: "enroll",
        title: "Register",
        type: "item",
        url: "/program/enroll",
      },
      {
        id: "syep",
        title: "SYEP",
        type: "item",
        url: "/program/syep",
      },
      {
        id: "isyp",
        title: "ISYP",
        type: "item",
        url: "/program/isyp",
      },
    ],
    auth: [UserRole.Host],
  },
  {
    id: "employees",
    title: "Employee Creation",
    type: "item",
    icon: "material-outline:work",
    url: "employees",
    auth: [UserRole.Host],
  },
  {
    id: "worksite-component",
    title: "Worksite Creation",
    type: "item",
    icon: "heroicons-solid:office-building",
    url: "worksite/list",
    auth: [UserRole.Host],
  },
  {
    id: "assign-supervisor-component",
    title: "Assign Supervisors",
    type: "item",
    icon: "heroicons-solid:user-add",
    url: "assign-supervisor-index",
    auth: [UserRole.Host],
  },
  {
    id: "job-component",
    title: "Job Creation",
    type: "item",
    icon: "heroicons-solid:briefcase",
    url: "job-index",
    auth: [UserRole.Host],
  },
  {
    id: "timesheet-component",
    title: "Timesheet",
    type: "item",
    icon: "heroicons-solid:clock",
    url: "timesheet",
    auth: [UserRole.Host],
  },

  {
    id: "host-forms",
    title: "Forms",
    type: "collapse",
    icon: "heroicons-outline:clipboard-check",
    auth: [UserRole.Host],
    children: [
      {
        id: "incidentForm-component",
        title: "Incident Form",
        type: "item",
        url: "incident-form",
      },
      {
        id: "transferForm-component",
        title: "Transfer Form",
        type: "item",
        url: "transfer-form",
      },
      {
        id: "terminationForm-component",
        title: "Termination Form",
        type: "item",
        url: "termination-form",
      },
    ],
  },
  {
    id: "admin-approval-form-component",
    title: "Approvals",
    type: "item",
    icon: "heroicons-solid:check",
    url: "admin-approval-form",
    auth: [UserRole.Admin],
  },
  {
    id: "submit-salary-details-component",
    title: "Submit Salary Details",
    type: "item",
    icon: "heroicons-solid:newspaper",
    url: "submit-salary-details",
    auth: [UserRole.Admin],
  },
  {
    id: "admin-forms",
    title: "Forms",
    type: "collapse",
    icon: "heroicons-outline:clipboard-check",
    auth: [UserRole.Admin],
    children: [
      {
        id: "admin-incidentForm-component",
        title: "Incident Form",
        type: "item",
        url: "admin-incident-form",
      },
      {
        id: "admin-transferForm-component",
        title: "Transfer Form",
        type: "item",
        url: "admin-transfer-form",
      },
      {
        id: "admin-terminationForm-component",
        title: "Termination Form",
        type: "item",
        url: "admin-termination-form",
      },
    ],
  },
  {
    id: "admin-messages-component",
    title: "Admin Messages",
    type: "item",
    icon: "heroicons-solid:chat",
    url: "admin-messages",
    auth: [UserRole.Admin],
  },
  {
    id: "youth-details",
    title: "Youth Details",
    type: "item",
    icon: "heroicons-outline:clipboard-check",
    auth: [UserRole.Admin],
    url: "/youth-details/admin-youth-list",
  },
  {
    id: "host-details",
    title: "Host Details",
    type: "item",
    icon: "heroicons-outline:clipboard-check",
    auth: [UserRole.Admin],
    url: "/host-details/admin-host-list",
  },
  {
    id: "questionnaire",
    title: "Questionnaire",
    type: "collapse",
    icon: "heroicons-solid:question-mark-circle",
    auth: [UserRole.Admin],
    children: [
      {
        id: "questionnaireForm-component",
        title: "Questionnaire Creation",
        type: "item",
        url: "questionnaire-form",
      },
      {
        id: "admin-view-answers-component",
        title: "View Answers",
        type: "item",
        url: "admin-view-answers",
      },
    ],
  },

  {
    id: "admin-form-component",
    title: "User Creation",
    type: "item",
    icon: "heroicons-solid:user-circle",
    url: "admin-form",
    auth: [UserRole.Admin],
  },
  {
    id: "admin-program-page-component",
    title: "Program Creation",
    type: "item",
    icon: "heroicons-solid:user",
    url: "admin-program-page",
    auth: [UserRole.Admin],
  },

  {
    id: "youth-job-application",
    title: "Job Application",
    type: "item",
    icon: "heroicons-solid:briefcase",
    url: "job-application-index",
    auth: [UserRole.Youth],
  },
  {
    id: "youth-opportunities-component",
    title: "Opportunities",
    type: "item",
    icon: "heroicons-solid:light-bulb",
    url: "youth-opportunities",
    auth: [UserRole.Youth],
  },
  {
    id: "youth-programs",
    title: "Register",
    type: "item",
    icon: "heroicons-outline:clipboard-check",
    auth: [UserRole.Youth],
    url: "/youth-program/enroll",
  },

  {
    id: "youth-timesheet-component",
    title: "Timesheet",
    type: "collapse",
    icon: "heroicons-solid:clock",
    children: [
      // {
      //   id: "calender",
      //   title: "Timesheet Calender",
      //   type: "item",
      //   url: "/timesheet/calender",
      //   hidden
      // },
      {
        id: "time-record",
        title: "Record Time",
        type: "item",
        url: "/timesheet/time-record",
      },
    ],
    auth: [UserRole.Youth],
  },

  {
    id: "host-job-application-component",
    title: "Job Application",
    type: "item",
    icon: "heroicons-solid:briefcase",
    url: "host-job-application",
    auth: [UserRole.Host],
  },

  {
    id: "youth-salary-details-component",
    title: "Salary Details",
    type: "item",
    icon: "heroicons-solid:currency-dollar",
    url: "youth-salary-details",
    auth: [UserRole.Youth],
  },
  {
    id: "w4-tax-form-component",
    title: "W4 Tax Form",
    type: "item",
    icon: "heroicons-solid:document-text",
    url: "w4-tax-form",
    auth: [UserRole.Youth],
  },
  {
    id: "youth-opportunities-component",
    title: "Opportunities",
    type: "item",
    icon: "heroicons-solid:light-bulb",
    url: "youth-opportunities",
    auth: [UserRole.Youth],
  },
  {
    id: "youth-messages-component",
    title: "Messages",
    type: "item",
    icon: "heroicons-solid:chat",
    url: "youth-messages",
    auth: [UserRole.Youth],
  },
  {
    id: "host-messages-component",
    title: "Messages",
    type: "item",
    icon: "heroicons-solid:chat",
    url: "host-messages",
    auth: [UserRole.Host],
  },
];

export default navigationConfig;
