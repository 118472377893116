import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import history from "@history";
import { CheckBoxRef } from "@ypms/types";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import InnerFooter from "app/shared-components/InnerFooter";
import moment from "moment";

export default function Disclaimer() {
  return (
    <>
      <Typography className="mb-4 text-xl font-semibold" color="gray">
        Disclaimer
      </Typography>

      <Typography className="mb-16 font-semibold">
        You are about to register as a Host Employer for the Summer Youth
        Employment Program. Please note that registration to participate is
        voluntary. DOES will pay stipends/wages directly to the youth workers
        assigned to your worksite, but will not provide funding for any
        additional costs that a Host Employer may incur.
      </Typography>

      <Typography className="mb-16">
        Participants are not permitted to report to a work site which they are
        not assigned. All work site assignments are visible in the participant
        portal and designated by the Office of Youth Programs (OYP) only. A
        participant’s failure to report to the assigned work site will result in
        forfeiture of pay for days in attendance at the incorrect site.
      </Typography>

      <Typography className="mb-32">
        I have read and understand the statement above. I agree to participate
        as a Host Employer and understand that DOES will not provide additional
        funds outside of the stipends/wages provided directly to youth
        participants.
      </Typography>

      <div className="flex place-content-between">
        <div className="flex flex-col items-center justify-start">
          <Typography className="-mb-10">
            <em>John</em>
          </Typography>
          <Typography>____________________</Typography>
          <Typography className="font-semibold">Signature</Typography>
        </div>
        <div className="flex flex-col items-center justify-start">
          <Typography className="-mb-10">
            {moment(new Date()).format("DD/MM/yyyy")}
          </Typography>
          <Typography>____________________</Typography>
          <Typography className="font-semibold">Date</Typography>
        </div>
      </div>

      <div className="border-sm bg-gray-300 rounded-md p-36 mt-24 text-center">
        <Typography className="mb-16">
          DOES is an Equal Opportunity Employer/Provider.
        </Typography>

        <Typography className="mb-16">
          Language interpretation services are available without cost.
        </Typography>

        <Typography className="mb-16">
          Auxiliary aids and services are available upon request for individuals
          with disabilities.
        </Typography>

        <Typography className="mb-16 font-bold text-lg">
          Notice of Non-Discrimination
        </Typography>

        <Typography>
          In accordance with the D.C. Human Rights Act of 1977, as amended, D.C.
          Official Code Section 2-1401.01 et seq., (Act) the District of
          Columbia does not discriminate on the basis of race, color, religion,
          national origin, sex, age, marital status, personal appearance, sexual
          orientation, gender identity or expression, familial status, family
          responsibilities, matriculation, political affiliation, genetic
          information, disability, source of income, or place of residence or
          business. Sexual harassment is a form of sex discrimination which is
          also prohibited by the Act. In addition, harassment based upon any of
          the above protected categories is prohibited by the Act.
          Discrimination in violation of the Act will not be tolerated.
          Violators will be subject to disciplinary action.
        </Typography>
      </div>
    </>
  );
}
