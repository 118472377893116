import IndexHeader from "app/shared-components/PageHeader";
import * as React from "react";
import AdminTransferFormView from "./components/AdminTransferFormView";

export default function AdminTransferFormViewPage() {
    return (
    <>
      <AdminTransferFormView />
    </>
  );
}
