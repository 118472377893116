import React from "react";
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Employees from "./Employees";
import authRoles from "../../auth/authRoles";
import { UserRole } from "src/app/@types/enums/user.roles";

const EmployeeList = lazy(() => import("./employees-list/EmployeeList"));
const EmployeeAddUpdate = lazy(
  () => import("./employees-add-update/EmployeeAddUpdate")
);

export const EmployeeRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Host],
  routes: [
    {
      path: "employees",
      element: <Employees />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <EmployeeList />,
        },
        {
          path: "add",
          element: <EmployeeAddUpdate />,
        },
        {
          path: "update/:id",
          element: <EmployeeAddUpdate />,
        },
      ],
    },
  ],
};
