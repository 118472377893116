import React from "react";
import HostJobApplicationPage from "./host-job-application-index/HostJobApplicationIndexPage";
import HostJobApplication from "./host-job-application";
import { UserRole } from "src/app/@types/enums/user.roles";
import i18next from "i18next";
import english from './i18n/english';

i18next.addResourceBundle("en", "hostJobApplication", english);

export const HostJobApplicationRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Host],
  routes: [
    {
      path: "host-job-application",
      element: <HostJobApplicationPage />,
    },
  ],
};