import * as React from "react";
import IndexHeader from "app/shared-components/IndexHeader";
import CustomButton from "app/shared-components/CustomColorButton";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import history from "@history";
import AdminHostListFilter from "./components/AdminHostListFilter";
import AdminHostListTable from "./components/AdminHostListTable";

  const handleClick = () => {
    history.push('incident-form/add');
  };


export default function AdminHostListIndexPage() {
  
  return (
    <>
      <IndexHeader
        name={"Host List"}
        update={false}
        add={false}
      />
      <AdminHostListFilter />
      <AdminHostListTable />
    </>
  );
}