import * as React from "react";
import IndexHeader from "app/shared-components/IndexHeader";
import CustomButton from "app/shared-components/CustomColorButton";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import history from "@history";
import JobApplicationList from "./components/jobApplicationsTable";
import JobApplicationFilter from "./components/jobApplicationsFilter";

const handleClick = () => {
  history.push("admin-program-page/add");
};

export default function JobApplicationIndexPage() {
  return (
    <>
      <IndexHeader name={"Job Applications"} update={false} add={false} />

      <JobApplicationFilter />
      <JobApplicationList />
    </>
  );
}
