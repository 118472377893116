const locale = {
  CREATE_USER: "创造",
  GENERATE_QR: "生成二维码",
  SIGNIN: "登入",
  SIGNUP: "登记",
  LOGIN_INFORMATION: "登录信息",
  EMAIL_ADDRESS: "电子邮件地址",
  CONFIRM_EMAIL_ADDRESS: "确认电子邮件地址",
  PASSWORD: "密码",
  CONFIRM_PASSWORD: "确认密码",

  //Error messages
  EMAIL_ADDRESS_NOT_MATCHED_ERROR: "电子邮件地址不匹配",
  EMAIL_NOT_VALID: "输入有效的电子邮件地址",
  PASSWORD_NOT_MATCHED_ERROR: "密码不匹配",
  EMAIL_PRIMARY_ERROR:
    "此电子邮件地址是与您沟通的主要方式。因此，如果您没有个人电子邮件地址，请先创建一个",
  CONFIRM_EMAIL_ERROR: "确认电子邮件地址必填",
  CONFIRM_PASSWORD_ERROR: "确认密码为必填项",

  //Button
  Next: "下一个",
  Back: "返回",

  //#PersonalInfo-----------------
  PERSONAL_DETAILS: "个人详细信息",

  //Error Message
  LEGAL_FIRST_NAME_ERROR: "请添加您的法定名字",
  LEGAL_LAST_NAME_ERROR: "请添加您的法定姓氏",
  MIDDLE_NAME_ERROR: "请添加您的中间名",
  MAIDEN_NAME_ERROR: "请添加您的婚前姓名",

  SSN_ERROR: "SSN 是必需的",
  CONFIRM_SSN_ERROR: "确认 SSN 是必需的",
  SSN_NOT_MATCHED_ERROR: "SSN 不匹配",

  LEGAL_FIRST_NAME: "法定名字",
  LEGAL_LAST_NAME: "法定姓氏",
  MIDDLE_NAME: "中间名",
  MAIDEN_NAME: "婚前姓名",
  DATE_OF_BIRTH: "出生日期",
  LANGUAGE: "语言",
  GENDER: "性",
  RACE: "比赛",
  SUFFIX: "后缀",
  T_SHIRT_SIZE: "T恤尺寸",
  SOCIAL_SECURITY_NUMBER: "社会安全号码",
  CONFIRM_SSN: "确认 SSN",

  //-----------END of PersonalInfoPage------------

  //#EducationalInfo
  //Error Message
  REQUIRED_ERROR: "应填写此字段",

  EDUCATIONAL_DETAILS: "教育详情",
  CURRENT_EDUCATIONAL_STATUS: "当前教育状况",
  EDUCATIONAL_STUDENT_TYPE: "教育学生类型",
  CURRENT_GRADE: "当前年级或就读的最后一年级",
  CURRENT_SCHOOL: "当前学校或最后就读的学校",

  //----------END of EducationalInfoPage---------------

  //#ContactInfo
  CONTACT_INFORMATION: "联系信息",
  PERMENANT_ADDRESS_DETAILS: "永久地址详细信息",
  ADDRESS_LINE: "地址行",
  APT: "公寓号或套房",
  STATE: "州",
  CITY: "城市",
  ZIP: "邮政编码",
  CELL_PHONE: "手机",
  HOME_PHONE: "住宅电话",
  PARENT: "父母或监护人",
  FIRST_NAME: "名字",
  LAST_NAME: "姓",
  WORK_PHONE: "工作电话",
  RELATIONSHIP: "关系",
  EMERGENCY: "紧急",
  PHONE_NUMBER: "电话号码",

  //--------END OF CONTACT INFO PAGE--------

  //#NotificationInfo
  NOTIFICATION_INFORMATION: "通知信息",
  NOTIFICATION_DETAILS: "通知详细信息",
  TWITTER_USER_NAME: "推特用户名@",
  CONTACT_TEXT_MESSAGE: "我们可以通过短信与您联系吗？",
  COMPUTER_STATUS: "你有电脑吗？",
  INTERNET_STATUS: "您家里有互联网接入吗？",
  NETWORK_TYPE: "网络类型",

  //-----------END of Notification Info Page---------

  //#Career Interest
  CAREER_INTEREST: "职业兴趣",
  JOB_ASSIGNMENTS: "您喜欢的工作分配。",
  WORK_EXPERIENCE: "工作经历",
  WORK_TRAINING: "工作培训准备",
  JOB_MATCHES: "匹配的作业",
  CLOSE_HOME: "离我家很近",
  MATCHES_CAREER: "符合我的职业目标",
  CAREER_TYPE_1: "第一个顶级职业兴趣",
  CAREER_TYPE_2: "职业兴趣第二高",
  CAREER_TYPE_3: "第三大职业兴趣",
  AFTER_SYEP: "SYEP之后的未来任何计划",
  CAREER_GOALS: "您的长期职业目标是什么？",

  //----------END of Career Interest Page----------------

  //#Supplemenatl Info
  SUPPLEMENTAL_INFORMATION: "补充信息",
  SPECIAL_ACCOMODATION: "特殊住宿/医疗信息",
  REQ_SPECIAL_ACCOMODATION: "您需要任何特殊住宿吗？",
  MEDICAL_CONDITION: "妨碍您在外工作的医疗状况",
  DEMOGRAPHIC: "人口统计信息",
  OFFENDER: "涉及的罪犯或法院",
  MILITARY: "曾在军队服役",
  HOMELESS: "无家可归",
  PARENT: "父母",
  MYOWN: "独自生活",
  FOSTER: "寄养",
  PUBLIC_ASSISTANCE: "公共援助",
  REQ_PUBLIC_ASSISTANCE:
    "如果您或您的家人目前正在接受任何公共援助，请在此处查看",
  YES: "是的",
  INCOME: "收入和家庭规模",
  FAMILY_INCOME: "过去六个月的家庭总收入",
  FAMILY_SIZE: "您家庭中当前成员总数",

  //------END of Supplemental Page--------------

  //#Review Page
  REVIEW_SIGN: "查看并签名",
  DIGITAL_SIGNATURE: "数字签名",
  CHECKING_INFO: "通过选中下面的框，我证明此表格提供的所有信息都是真实准确的。",
  APPROVE_INFO:
    "我了解此信息需要经过验证，我进一步了解提供任何虚假信息可能会导致我被从 DOES 青年计划办公室除名，并可能使我受到适用的民事和刑事游行。",
  READ_CHECK: "我已经阅读了上述声明，我想向市长暑期青年就业计划提交申请",
  DATE: "日期",
  SIGNATURE: "签名",
  DOES: "DOES是机会均等的雇主/提供者 ",
  LANGUAGE_SERVICE: "免费提供语言口译服务",
  AUXILIARY_AIDS: "酒店可应要求为残障人士提供辅助服务和辅助服务。",
  NOTICE_HEADER: "不歧视通知",
  ACCORDANCE:
    "根据经修订的 1977 年华盛顿特区人权法、华盛顿特区官方法典第 2-1401-01 节等（法案），哥伦比亚特区不因种族、肤色、宗教、民族、出身、性别、年龄、婚姻状况、个人外表、性取向、性别认同或表达、家庭状况、家庭责任、入学、政治派别、 遗传信息、残疾、收入来源或居住地或营业地点。该法也禁止的以安全歧视形式进行的性骚扰。此外，该法禁止基于上述任何受保护类别的骚扰。 违反该法的歧视将不被容忍。违反者将受到纪律处分",
};

export default locale;
