import React from "react";
import TerminationFormAddPage from "./termination-form-add/TerminationFormAddPage";
import TerminationFormIndexPage from "./termination-form-index/TerminationFormIndexPage";
import TerminationForm from "./termination-form";
import { Navigate } from "react-router-dom";
import { UserRole } from "src/app/@types/enums/user.roles";

export const TerminationFormRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Host],
  routes: [
    {
      path: "termination-form",
      element: <TerminationForm />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <TerminationFormIndexPage />,
        },
        {
          path: "add",
          element: <TerminationFormAddPage />,
        },
        {
          path: "update/:id",
          element: <TerminationFormAddPage />,
        },
      ],
    },
  ],
};
