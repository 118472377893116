interface WorkKind {
    code: string;
    name: string;
}

const workKinds: WorkKind[] = [
    {
      code: "1",
      name: "Accept payments and make change for customers", 
    },
    {
        code: "2",
        name: "Answer Phones", 
    },
    {
        code: "3",
        name: "Care for residents / patients", 
    },
    {
        code: "4",
        name: "Career Interest Category 1", 
    },
    {
        code: "5",
        name: "Career Interest Category 2", 
    },
    {
        code: "6",
        name: "Career Interest Category 3", 
    },
    {
        code: "7",
        name: "Computer Programming", 
    },
    {
        code: "8",
        name: "Conduct Research", 
    },
    {
        code: "10",
        name: "Design Clothes and Model", 
    },
    {
        code: "11",
        name: "Engage in community service projects", 
    },
    {
        code: "12",
        name: "Engage in creative writing", 
    },
    {
        code: "13",
        name: "Engage in sports and physical activity", 
    },
    {
        code: "14",
        name: "Provide information regarding products and services", 
    },
    {
        code: "15",
        name: "Provide visitors with information", 
    },
    {
        code: "16",
        name: "Set up and fix computers", 
    },
    {
        code: "17",
        name: "Sort and file documents", 
    },
    {
        code: "18",
        name: "Supervise camp activities", 
    },
    {
        code: "19",
        name: "Supervise Peers", 
    },
    {
        code: "20",
        name: "Travel to multiple worksite locations", 
    },
    {
        code: "21",
        name: "Using Computer Applications such as Microsoft Office", 
    },
    {
        code: "22",
        name: "Use fax machines", 
    },
    {
        code: "24",
        name: "Use hand tools", 
    },
    {
        code: "25",
        name: "Use photocopiers", 
    },
    {
        code: "26",
        name: "Use power tools", 
    },
    {
        code: "27",
        name: "Use the internet for research", 
    },
    {
        code: "28",
        name: "Work on art projects", 
    },
    {
        code: "29",
        name: "Work on graphic design projects", 
    },
    {
        code: "30",
        name: "Work outside", 
    },
    {
        code: "31",
        name: "Work with animals", 
    },
    {
        code: "32",
        name: "Work with hands", 
    },
    {
        code: "33",
        name: "Work with youth (ages 12 and under)", 
    },
    {
        code: "34",
        name: "Work with youth (ages 13 and under)", 
    },
    {
        code: "35",
        name: "Work with youth (ages 5 and under)", 
    },
    {
        code: "36",
        name: "Write articles,memos or other documents", 
    },

]

export default workKinds;