const locale = {
  //banking
  INDEX_NAME_BANKING: "은행업",
  View_Video: "비디오 보기",
  Test_Title: "평가",
  Question_One: "Sample Question1",
  Question_Two: "Sample Question2",
  Question_Three: "Sample Question3",
  Question_Four: "Sample Question4",
  Question_Five: "Sample Question5",
  Question_Six: "Sample Question6",
  Question_Seven: "Sample Question7",
  Question_Eight: "Sample Question8",

  Question_Nine: "Sample Question9",
  Question_Ten: "Sample Question10",

  //radio options
  Rad_One: "RadioOption1",
  Rad_Two: "RadioOption2",
  Rad_Three: "RadioOption3",
  Rad_Four: "RadioOption4",
  Submit_BTN: "Submit",
  ReAttampt_BTN: "Re-Attempt",

  //Certification
  INDEX_NAME: "인증",
  View_Video: "동영상 보기",
  Test_Title: "시험",
  Question_One: "Sample Question1",
  Question_Two: "Sample Question2",
  Question_Three: "Sample Question3",
  Question_Four: "Sample Question4",
  Question_Five: "Sample Question5",
  Question_Six: "Sample Question6",
  Question_Seven: "Sample Question7",
  Question_Eight: "Sample Question8",
  Question_Nine: "Sample Question9",
  Question_Ten: "Sample Question10",
  //radio options
  Rad_One: "RadioOption1",
  Rad_Two: "RadioOption2",
  Rad_Three: "RadioOption3",
  Rad_Four: "RadioOption4",
  RadioBtn_Error: "Please select an option",
  Submit_BTN: "제출하다",
  ReAttampt_BTN: "재시도",
  //error messages
  Error_Message: "Please select an option",

  //Korean
  Index_Name: "Job Application",
  View_Video: "동영상 보기",
  Test_Title: "시험",
  Question_One: "Sample Question1",
  Question_Two: "Sample Question2",
  Question_Three: "Sample Question3",
  Question_Four: "Sample Question4",
  Question_Five: "Sample Question5",
  Question_Six: "Sample Question6",
  Question_Seven: "Sample Question7",
  Question_Eight: "Sample Question8",
  Question_Nine: "Sample Question9",
  Question_Ten: "Sample Question10",
  //radio options
  Rad_One: "RadioOption1",
  Rad_Two: "RadioOption2",
  Rad_Three: "RadioOption3",
  Rad_Four: "RadioOption4",
  RadioBtn_Error: "Please select an option",
  Submit_BTN: "Submit",
  ReAttampt_BTN: "Re-Attempt",
  //error messages
  Error_Message: "Please select an option",

  //Proof of Residency
  INDEX_NAME: "입사 지원",
  BROWSE_BTN: "찾아보기",
  Acceptable_Documents: "허용 가능한 문서",
  Type: "유형",
  UPLOAD_HINT: "jpg,jpeg,png 또는 pdf 파일만 업로드하십시오.",
  Drivers_licens:
    "만료되지 않은 주에서 발급한 운전면허증, 연습 면허증 또는 비운전자 신분증",
  Official_School_Record:
    "성적 증명서 또는 성적표와 같은 공식 학교 기록(참가자의 이름과 주소가 표시되어야 함)",
  Immunization_Record:
    "예방 접종 기록(참가자의 이름과 주소가 표시되어야 하며 학교 관계자가 서명하고 날짜를 기입해야 함",
  Income_Tax_Form: "2014년 소득세 양식 1040(서명 및 공증 필요)",
  Executed_Lease:
    "1년 이내의 체결된 임대 또는 임대 계약(서명 및 날짜가 2014 W-2이어야 함)",
  notics:
    "(*청소년 프로그램 사무국에서 제출한 서류는 접수하지 않습니다. 모든 거주지 확인 서류에는 참가자 이름과 주소가 나와 있어야 합니다.)",
  No_FILE_SELECTED: "파일이 선택되지 않았습니다  ",
  Learners_Permit: "임시 운전 면허증",
  PROOF_OF_RESIDENCY_DESCRIPTION:
    "정확한 기록 보관 및 참가자 식별을 위해 만료되지 않은 주에서 발급한 운전 면허증, 연습 면허증, 비운전자 신분증, 공식 학교 기록, 예방 접종 기록, 소득세 양식, 실행된 임대 또는 임대 계약. 이 검증 프로세스는 프로그램 관리 및 모든 참가자의 안전과 보안을 보장하는 데 매우 중요합니다.",
  Non_Drivers_Identification_Card: "비운전자 신분증",

  Official_School_Record: "공식 학교 기록",

  Immunization_record: "예방접종 기록",

  Income_Tax_Form: "소득세 양식",

  Executed_Lease_Rental_Agreement: "실행된 임대 또는 임대 계약",
  APPROVAL_STATUS: "승인 대기 중",

  //Proof of age
  Index_Name: "입사 지원",
  Browse_Btn: "찾아보기",
  Acceptable_Documents: "허용 가능한 문서",
  Type: "유형",
  UploadHint: "jpg,jpeg,png 또는 pdf 파일만 업로드하십시오.",
  Us_Passport: "미국 여권",
  Unexpired_state_issued_DriverLicense: "예상치 못한 국가 발급 운전 면허증",
  PROOF_OF_AGE_DESCRIPTION:
    "정확한 기록 보관 및 참가자 식별을 위해 여권, 만료되지 않은 주에서 발급한 운전 면허증, 학습자 허가증, 비운전자 신분증, 공식 학교 기록, 학교 ID 또는 영주권 카드와 같은 자녀의 신분증 사본이 필요합니다. . 이 검증 프로세스는 프로그램 관리 및 모든 참가자의 안전과 보안을 보장하는 데 매우 중요합니다.",
  Learners_PermitorNon_Driver_Identification_Card:
    "학습자 허가증 또는 비운전자 신분증",
  Official_School_Record: "공식 학교 기록",
  School_ID: "학교 ID",
  Permanent_Resident_Card: "영주권 카드",
  No_FILE_SELECTED: "파일이 선택되지 않았습니다  ",

  APPROVAL_STATUS: "승인 대기 중",
  FILE_NAME: "파일 이름",

  //Proof of ssn
  PROOF_OF_SSN: "입사 지원",
  BROWSE_BTN: "찾아보기",
  ACCEPTABLE_DOCUMENTS: "허용 가능한 문서",
  TYPE: "유형",
  UPLOAD_HINT: "jpg,jpeg,png 또는 pdf 파일만 업로드하십시오.",

  NO_FILE_SELECTED: "파일이 선택되지 않았습니다 ",
  LETTER_SSN: "사회보장국의 편지(참가자의 이름과 SSN이 표시되어야 함)",
  SHOWING_PARTICIPANT_SSSN: "2014 W-2(참가자 이름 및 SSN 표시)",
  SSN_CARD_DOC: "사회 보장 카드",
  LETTER_DOC: "사회 보장국의 편지 ",
  W2_DOC: "2014 W-2",
  PROOF_OF_SSN_DESCRIPTION:
    "정확한 기록 보관 및 참가자 신원 확인을 위해 사회보장국의 편지(참가자의 이름과 SSN이 표시되어야 함) 또는 2014 W-2 양식(참가자의 이름과 SSN이 표시됨)과 같은 자녀의 신분증 사본이 필요합니다. . 이 검증 프로세스는 프로그램 관리 및 모든 참가자의 안전과 보안을 보장하는 데 매우 중요합니다.",

  //Proof of work
  INDEX_NAME: "입사 지원",
  PROOF_OF_WORK: "입사 지원",
  BROWSE_BTN: "찾아보기",
  ACCEPTABLE_DOCUMENTS: "허용 가능한 문서",
  TYPE: "유형",
  UPLOAD_HINT: "jpg,jpeg,png 또는 pdf 파일만 업로드하십시오.",
  PROOF_OF_WORK_DESCRIPTION:
    "정확한 기록 보관 및 참가자 신원 확인을 위해 사진이 부착된 신분증, 미국 출생 증명서 또는 사회 보장 카드, 미국 여권, 영주권 카드 또는 INS 양식, 만료되지 않은 외국 여권(I- 551 스탬프. 이 검증 프로세스는 프로그램 관리 및 모든 참가자의 안전과 보안을 보장하는 데 매우 중요합니다.",

  NO_FILE_SELECTED: "파일이 선택되지 않았습니다 ",
  ACCEPTABLE_DOCUMENTS_01:
    "사진이 있는 신분증 및 미국 출생 증명서(사진이 있는 신분증은 주에서 발급한 운전면허증, 신분증 또는 학교 신분증이어야 함)",
  ACCEPTABLE_DOCUMENTS_02:
    "사진 ID 및 사회 보장 카드(사진 ID는 주에서 발급한 운전면허증, ID 카드 또는 학교 ID여야 함)",
  ACCEPTABLE_DOCUMENTS_03:
    "만료되지 않은 고용 허가가 있는 미국 여권, 영주권 또는 INS 양식 I-766",
  ACCEPTABLE_DOCUMENTS_04:
    "I-551 스탬프가 있거나 INS 양식 I-94가 첨부된 만료되지 않은 외국 여권",

  DOC_TYPE_01: "사진이 부착된 신분증 및 미국 출생 증명서",
  DOC_TYPE_02: "사진이 부착된 신분증 및 사회 보장 카드",
  DOC_TYPE_03: "미국 여권, 영주권 카드 또는 INS 양식",
  DOC_TYPE_04: "만료되지 않은 외국 여권 또는 첨부된 INS 양식",
  APPROVAL_PENDING: "승인 대기 중",

  //transportation
  Index_Name: "입사 지원",
  Submit_Btn: "제출하다",
  Card_Number: "카드 번호",
  Card_Fair: "카드 페어",
  Card_Issue_Date: "카드 발급일",
  //error messages
  MUST_ENTER_CARD_NUMBER: "카드 번호를 입력해야 합니다",
  MUST_ENTER_CARD_FAIR: "카드 페어에 입장해야 합니다.",
  MUST_ENTER_CARD_ISSUEDATE: "카드 발급 날짜를 입력해야 합니다.",

  DELETE_BTN: "삭제",
  INDEX_NAME_ORIENTATION: "입사 지원",

  //certificate
  INDEX_NAME_CERTIFICATE: "입사 지원",
  //resume
  UPLOAD_HINT_ONE: "pdf 파일만 지원됩니다.",
  UPLOAD_BTN: "업로드",
  DOWNLOAD_BTN: "다운로드",
  //sideBar navigation
  RESUME: "재개하다",
  PROOF_OF_AGE: "연령 증명",

  //sideBar navigation
  RESUME_NAVI: "재개하다",
  PROOF_OF_AGE_NAVI: "연령 증명",
  PROOF_OF_SSN_NAVI: "연령 증명",
  PROOF_OF_WORK_NAVI: "작업 증명",
  PROOF_OF_TRANSPORTATION_NAVI: "교통 증명",
  PROOF_OF_RESIDENCE_NAVI: "거주 증명",
  CERTIFICATE_NAVI: "자격증",
  ORIENTATION_NAVI: "정위",
  BANKING_NAVI: "은행업",
};

export default locale;
