import { useParams } from "react-router-dom";
import _ from "@lodash";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Divider,
  FormControl,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import jwtService from "../../../auth/services/jwtService";
import AuthService from "../../../services/api/auth.service";
import history from "@history";
import { setUserData } from "app/store/userSlice";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { authDataService } from "../../../services/data/auth.data.service";
import { UserRole } from "../../../../app/@types/enums/user.roles";

const schema = yup.object().shape({
  otp: yup
    .string()
    .required("CODE_REQUIRED_ERROR")
    .length(6, "ENTER_CODE_LENGTH_ERROR"),
});

function TwoFactorVerificationPage(props) {
  const { t } = useTranslation("2FaPage");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { authType } = routeParams;

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues: { otp: "" },
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const onSubmit = async ({ otp }) => {
    setIsLoading(true);
    const authService = new AuthService();
    try {
      const { msg, tokenPayload } = await authService.verifyOtp(+otp);

      const userState = {
        role: [tokenPayload.roleCode],
        data: {
          displayName: tokenPayload.userName,
          email: tokenPayload.email,
          role: tokenPayload.roleName,
          photoURL:
            authDataService.userRoleId === UserRole.Admin
              ? "assets/images/avatars/male-14.jpg"
              : authDataService.userRoleId === UserRole.Youth
              ? "assets/images/avatars/male-07.jpg"
              : authDataService.userRoleId === UserRole.Host
              ? "assets/images/avatars/male-11.jpg"
              : "assets/images/avatars/brian-hughes.jpg",
        },
      };
      Promise.all([dispatch(setUserData(userState))]).then((values) => {
        history.push("/home");
      });
    } catch (error) {}
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col sm:flex-row items-center sm:justify-center flex-1 min-w-0">
      <Paper className="h-full sm:h-auto w-full sm:w-auto py-8 px-16 sm:p-48 sm:rounded-2xl sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            className="h-52"
            src="assets/images/logo/logo-ypms-full.png"
            alt="logo"
          />

          <Typography className="mt-16 text-2xl font-semibold tracking-tight leading-tight">
            {t("TWO_STEP_VERIFICATION")}
          </Typography>

          <Chip
            className="mt-16 font-semibold"
            icon={
              <FuseSvgIcon className="text-48" size={24} color="action">
                heroicons-outline:user-circle
              </FuseSvgIcon>
            }
            label={authDataService.userName}
          />

          <Typography className="mt-16 font-semibold">
            {authType == 1
              ? `${t("EMAIL_MESSAGE_SENT")} ${authDataService.email}`
              : `${t("TEXT_MESSAGE_SENT")} ${authDataService.userPhone}`}
          </Typography>

          <form
            className="flex flex-col justify-center w-full mt-16"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="otp"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Enter Code"
                  autoFocus
                  type="text"
                  error={!!errors.otp}
                  helperText={t(errors?.otp?.message ?? "")}
                  variant="outlined"
                  fullWidth
                />
              )}
            />

            <Button
              variant="contained"
              color="secondary"
              className="w-full mt-16 mb-16"
              aria-label="next"
              type="submit"
              size="large"
            >
              {t("NEXT")}
              {isLoading && <CircularProgress size={15} className="ml-10" />}
            </Button>
          </form>

          <Divider />

          <div className="flex items-baseline mt-16 font-medium">
            <span>{t("OR_TRY")}</span>
            <Link className="ml-4" to="/sign-in">
              {t("SIGN_IN")}
            </Link>
            <span className="ml-2"> {t("AGAIN")} </span>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default TwoFactorVerificationPage;
