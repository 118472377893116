import * as React from "react";
import IndexHeader from "app/shared-components/IndexHeader";
import CustomButton from "app/shared-components/CustomColorButton";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import history from "@history";
import QuestionnaireFormTable from "./components/QuestionnaireFormTable";
import QuestionnaireFormFilter from "./components/QuestionnaireFormFilter";

const handleClick = () => {
  history.push("questionnaire-form/add");
};

export default function QuestionnaireFormIndexPage() {
  const myButtons = [
    <CustomButton
      StartIcon={<AddIcon />}
      className="mx-9"
      disabled={false}
      onClick={handleClick}
      name="New"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];
  return (
    <>
      <IndexHeader
        name={"Questionnaire Index"}
        update={false}
        add={false}
        buttons={myButtons}
      />
      <QuestionnaireFormFilter />
      <QuestionnaireFormTable />
    </>
  );
}
