import React from "react";
import { Typography } from "@mui/material";
import { Outlet } from "react-router-dom";

function QuestionnairePage() {
    return (<div>
        Questionnaire Page
    </div>);

}
export default QuestionnairePage;