import { CoreService } from "./core.service";
import { ResponseData } from "src/app/@types/interfaces/get.response";
import {
  ParentSign,
  ParentSignAdd,
  YouthParentEmailPayload,
} from "src/app/@types/interfaces/parent.consent.form.sign";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export default class YouthJobApplicationService extends CoreService {
  async sendParentConsentFormEmail(
    parentEmailDetails: YouthParentEmailPayload
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.axios
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/youth-job-application/send-parent-consent`,
          {
            ...parentEmailDetails,
          }
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(message);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }

  async addParentSignInformation(
    parentSignInformation: ParentSignAdd
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.axios
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/youth-job-application/create-update`,
          {
            ...parentSignInformation,
          }
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(message);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }

  async getParentSignInformationByUserId(
    userId?: string
  ): Promise<ResponseData<ParentSign>> {
    return new Promise((resolve, reject) => {
      this.axios
        .get(
          `${process.env.REACT_APP_BASE_API_URL}/youth-job-application/${userId}`
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(response.data);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }
}
export const youthJobApplicationService = new YouthJobApplicationService();
