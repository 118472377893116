import React from "react";
import { Navigate } from "react-router-dom";
import QuestionnaireForm from "./questionnaire-form";
import QuestionnaireFormAddUpdate from "./questionnaire-form-add/QuestionnaireFormAddUpdate";
import QuestionnaireFormIndexPage from "./questionnaire-form-index/QuestionnaireFormIndexPage";
import { UserRole } from "src/app/@types/enums/user.roles";

export const QuestionnaireFormRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Admin],
  routes: [
    {
      path: "questionnaire-form",
      element: <QuestionnaireForm />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <QuestionnaireFormIndexPage />,
        },
        {
          path: "add",
          element: <QuestionnaireFormAddUpdate />,
        },
        {
          path: "update/:id",
          element: <QuestionnaireFormIndexPage />,
        },
      ],
    },
  ],
};
