import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Paper,
  TextField,
  Typography,
  Card,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import history from "@history";
import { useTranslation } from "react-i18next";
import { Label } from "@mui/icons-material";
import { json } from "stream/consumers";

const defaultValues = {
  cardNumber: "",
  cardFare: "",
  cardIssueDate: "",
};
const schema = yup.object().shape({
  cardNumber: yup
    .string()
    .required("MUST_ENTER_CARD_NUMBER")
    .min(4, "MUST_ENTER_CARD_NUMBER"),
  cardFare: yup.string().required("MUST_ENTER_CARD_FAIR"),
  cardIssueDate: yup.string().required("MUST_ENTER_CARD_ISSUEDATE"),
});
//#endregi
export default function ApplicationEnroll() {
  const { t } = useTranslation("JobApplication");

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  function onSubmit(data: any): void {
    console.log(data);
    reset(defaultValues);
  }

  return (
    <>
      <Typography className="text-20 ml-8 mt-8 font-semibold tracking-tight leading-tight">
        {"Enroll"}
      </Typography>
      <Card variant="outlined" className="mt-24">
        <div className="w-full">
          <form
            name="transportDetails"
            noValidate
            className="flex flex-col justify-start w-full items-start ml-24"
          >
            <div className="flex flex-col w-3/4">
              <Select
                size="small"
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                defaultValue={0}
                className="mb-24 mt-24 w-full pl-4"
              >
                <MenuItem value={0} disabled>
                  Select Program
                </MenuItem>
                <MenuItem value={10}>Summer Youth Employment Program</MenuItem>
                <MenuItem value={20}>In-School Youth Programs</MenuItem>
                <MenuItem value={30}>Youth Leadership Program</MenuItem>
                <MenuItem value={40}>
                  Pathways for Young Adults Program
                </MenuItem>
              </Select>
              <Select
                size="small"
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                defaultValue={0}
                className="mb-24 w-full pl-4"
              >
                <MenuItem value={0} disabled>
                  Select Year
                </MenuItem>
                <MenuItem value={10}>2023</MenuItem>
                <MenuItem value={20}>2024</MenuItem>
                <MenuItem value={30}>2025</MenuItem>
              </Select>
            </div>
            <div className="flex items-end place-content-start mb-24 w-3/4 pl-4">
              <Button
                variant="contained"
                color="secondary"
                className="min-w-96"
                aria-label="Register"
                type="submit"
                size="medium"
              >
                {"Save"}
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </>
  );
}
