import * as React from "react";
import IndexHeader from "app/shared-components/IndexHeader";
import CustomButton from "app/shared-components/CustomColorButton";
import CancelIcon from "@mui/icons-material/Cancel";
import YouthSalaryDetailsFormFilter from "./youth-salary-detailsform.filter";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function YouthSalaryDetailsFormIndexPage() {
  const myButtons = [
    <Button
      className="mx-8"
      variant="contained"
      disabled={false}
      startIcon={<CancelIcon />}
    >
      {"Cancel"}
    </Button>,
  ];
  const { t } = useTranslation("YouthSalaryDetailsFormIndexPage");

  return (
    <>
      <IndexHeader
        name={t("SALARY_DETAILS")}
        update={false}
        add={false}
        
      />
      <YouthSalaryDetailsFormFilter />
    </>
  );
}
