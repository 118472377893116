import React from "react";
import UserProfile from "./UserProfile";
import { Navigate } from "react-router-dom";
import ContactInfo from "./components/ContactInfo";
import Disclaimer from "./components/Disclaimer";
import ProgramInfo from "./components/ProgramInfo";
import UnderConstruction from "./components/UnderConstruction";
import { UserRole } from "src/app/@types/enums/user.roles";

export const UserProfileRoutes = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "my-profile",
      element: <UserProfile />,
      children: [
        {
          path: "",
          element: <Navigate to="contact-info" />,
        },
        {
          path: "contact-info",
          element: <ContactInfo />,
        },
        {
          path: "program-info",
          element: <ProgramInfo />,
        },
        {
          path: "disclaimer",
          element: <Disclaimer />,
        },
      ],
    },
  ],
};
