import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  Card,
  Typography,
  Button,
  Box,
  Divider,
  IconButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import { authDataService } from "src/app/services/data/auth.data.service";
import { useTranslation } from "react-i18next";
import { youthSignUpService } from "src/app/services/api/youth.signup.service";
import { YouthReviewAndSign } from "src/app/@types/interfaces/youth.review.and.sign";
import { YouthPersonalDetails } from "src/app/@types/interfaces/youth.personal.details";
import SignatureCanvas from "react-signature-canvas";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import { ParentSign } from "src/app/@types/interfaces/parent.consent.form.sign";
import { youthJobApplicationService } from "src/app/services/api/youth,jobapplication.service";
import BackIcon from "@mui/icons-material/Backspace";

import { setDate } from "date-fns";

const defaultValues = {
  parentEmail: "",
  applicantFullName: "",
  applicantSSN: "",
  date: "",
  signature: "",
  parentSignature: "",
  parentName: "",
  relationshipToApplicant: "",
  ParentSignedDate: "",

  signedDate: undefined,
  acceptTerms: false,
};
const refreshPage = () => {
  window.location.reload();
};
const schema = yup.object().shape({});
interface MyComponentProps {
  isParent: boolean;
  isAdmin: boolean;
  userId?: any;
}
export default function ParentConsentFormAddPage(props: MyComponentProps) {
  const routeParams = useParams();
  const { id } = routeParams;

  const { control, handleSubmit, watch, setValue, getValues, formState } =
    useForm({
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema),
    });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();
  const signatureRef = useRef<any>(null);

  const [showQRCode, setShowQRCode] = useState(false);
  const [signed, setSigned] = useState<boolean>(false);
  const [signedByParent, setSignedByParent] = useState<boolean>(false);
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [msg, setMsg] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [relationship, setRelationship] = useState("");
  const [name, setName] = useState("");
  const [signature, setSignature] = useState("");
  const [parentSigned, setParentSigned] = useState<boolean>(false);

  const currentDate = new Date();
  const dateString = currentDate.toISOString().split("T")[0];
  const [date, setDate] = useState(dateString);

  const handleRelationshipChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRelationship(event.target.value);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleGenerateQR = () => {
    setShowQRCode(true);
  };

  console.log(authDataService.userId);
  const userIdConst = id !== undefined ? id : authDataService.userId;
  const refreshPage = () => {
    window.location.reload();
  };
  useEffect(() => {
    getReviewAndSignInfo(userIdConst);
  }, []);

  const getReviewAndSignInfo = async (userId?: string) => {
    setSigned(false);
    try {
      if (userId) {
        const signatureResponse =
          await youthSignUpService.getReviewAndSignInformationByUserId(userId);
        const PersonalDetailsResponse =
          await youthSignUpService.getPersonalDetailsByUserId(userId);

        const parentDetailsResponse =
          await youthJobApplicationService.getParentSignInformationByUserId(
            userId
          );
        setRelationship(parentDetailsResponse.data.relationship);
        setName(parentDetailsResponse.data.name ?? "");

        const formattedDate = parentDetailsResponse.data.signedDate.toString();

        setDate(formattedDate);
        setSignature(parentDetailsResponse.data.signature);
        setParentSigned(parentDetailsResponse.data.signed ?? false);
        initForm(signatureResponse.data, PersonalDetailsResponse.data);
      }
    } catch (error: any) {}
  };

  const initForm = (
    signatureData: YouthReviewAndSign,
    personalData: YouthPersonalDetails
  ) => {
    setSigned(signatureData.signed ?? false);
    setValue("signature", signatureData.signature);
    setValue(
      "applicantFullName",
      personalData.legalFirstName + " " + personalData.legalLastName
    );
    if (signatureData.signedDate) {
      const formattedDate = signatureData.signedDate.toString();
      setValue("date", formattedDate);
    } else {
      setValue("date", "");
    }
  };
  const today = new Date();

  const { t } = useTranslation("JobApplication");

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
  };

  const onSubmitSignature = async (data: ParentSign) => {
    setIsLoading(true);
    setIsError(false);
    setMsg("");
    try {
      const msg = await youthJobApplicationService.addParentSignInformation({
        userId: userId,
        signature: data.signature,
        signedDate: data.signedDate,
        name: data.name,
        relationship: data.relationship,
        signed: true,
      });
      setMsg(msg);
      dispatch(showMessage({ message: msg, variant: "success" }));
    } catch (error: any) {
      dispatch(showMessage({ message: error, variant: "error" }));
      setIsError(true);
      setMsg(error);
    }
    setIsLoading(false);
  };

  const parentEmailSubmit = (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    event.preventDefault();

    return new Promise<void>(async (resolve, reject) => {
      setIsLoading(true);
      setIsError(false);
      setMsg("");
      try {
        const msg = await youthJobApplicationService.sendParentConsentFormEmail(
          {
            userId: authDataService.userId,
            parentEmail: getValues("parentEmail"),
          }
        );
        setMsg(msg);
        dispatch(showMessage({ message: msg, variant: "success" }));
        resolve();
      } catch (error: any) {
        dispatch(showMessage({ message: error, variant: "error" }));
        setIsError(true);
        setMsg(error);
        reject(error);
      }
      setIsLoading(false);
    });
  };

  const parentDetailSubmit = (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    event.preventDefault();

    return new Promise<void>((resolve, reject) => {
      if (signatureRef.current) {
        const base64Signature = signatureRef.current.toDataURL();
        const parentSignModel: ParentSign = {
          signature: base64Signature,
          signedDate: new Date(),
          name: name,
          relationship: relationship,
          signed: true,
        };

        setIsLoading(true);
        setIsError(false);
        setMsg("");

        onSubmitSignature(parentSignModel)
          .then(() => {
            dispatch(
              showMessage({
                message: "Signature submitted successfully",
                variant: "success",
              })
            );

            setIsLoading(false);
            resolve();
          })
          .catch((error) => {
            dispatch(
              showMessage({
                message: "Signature submission failed",
                variant: "error",
              })
            );

            setIsLoading(false);
            reject(error);
          });
      } else {
        resolve();
      }
    });
  };
  const parentEmailPlaceHolder = t("PARENT_EMAIL_LABEL") || "";
  return (
    <>
      <Typography className="text-20 ml-32 mt-8 font-semibold tracking-tight leading-tight">
        Parent Consent Form
      </Typography>
      <Card variant="outlined" className="m-24 mb-12">
      <div className="m-8 mt-8 ml-24">
      <Typography className="text-15  mt-8 font-semibold tracking-tight leading-tight">
        Parent Consent Process for the Youth Employment Program
      </Typography>
      <Typography className="text-15  mt-16 font-bold tracking-tight leading-tight underline">
        Step 1: Youth Information and Signature
      </Typography>
      <ul >
        <li className="text-12  mt-16 font-semibold tracking-tight leading-tight">•	By default, the interface will list down the basic information of you along with your signature. Please verify whether the displayed information is correct.</li>
      </ul>

      <Typography className="text-15  mt-16 font-bold tracking-tight leading-tight underline">
        Step 2: Parent's Email Submission
      </Typography>
      <ul >
        <li className="text-12  mt-16 font-semibold tracking-tight leading-tight">•	Provide the email address of your parent who should receive the Parent Consent Form and then click on ‘Submit’ button.</li>
        <li className="text-12  mt-8 font-semibold tracking-tight leading-tight">•	Upon submitting the parent's email, the status will be changed to 'Parent Approval Pending.'</li>
      </ul>

      <Typography className="text-15  mt-16 font-bold tracking-tight leading-tight underline">
        Step 3: Parent Consent Form
      </Typography>
      <ul >
        <li className="text-12  mt-16 font-semibold tracking-tight leading-tight">•	Once the parent's email is submitted, an automated email will be sent to the provided address.</li>
        <li className="text-12  mt-8 font-semibold tracking-tight leading-tight">•	It is recommended for you to inform your parents to check the email and request them to fill out the Parent Consent Form.</li>
      </ul>

      <Typography className="text-15  mt-16 font-bold tracking-tight leading-tight underline">
        Step 4: YPMS Admin Approval
      </Typography>
      <ul >
        <li className="text-12  mt-16 font-semibold tracking-tight leading-tight">•	After the parent fills and submits the form, the status will change to 'YPMS Admin Approval Pending.'</li>
        <li className="text-12  mt-8 font-semibold tracking-tight leading-tight">•	The form will be sent to the YPMS (Youth Program Management System) admin for review.</li>
      </ul>

      <Typography className="text-15  mt-16 font-bold tracking-tight leading-tight underline">
        Step 5: YPMS Admin Approval Decision
      </Typography>
      <ul >
        <li className="text-12  mt-16 font-semibold tracking-tight leading-tight">•	The YPMS admin will either approve or reject the submitted Parent Consent Form.</li>
        <li className="text-12  mt-8 font-semibold tracking-tight leading-tight">•	If approved, the status will be changed to 'Approved,' and the youth can proceed with the next steps of the program.</li>
        <li className="text-12  mt-8 font-semibold tracking-tight leading-tight">•	If rejected, the status will be changed to 'Rejected,' and the youth will receive an email notification.</li>
      </ul>

      <Typography className="text-15  mt-16 font-bold tracking-tight leading-tight underline">
      Step 6: Re-submission (if applicable)
      </Typography>
      <ul >
        <li className="text-12  mt-16 font-semibold tracking-tight leading-tight">•	In the case of a rejection, you are required to resubmit the Parent Consent Form with any necessary updates or corrections.</li>
        <li className="text-12  mt-8 font-semibold tracking-tight leading-tight">•	Upon resubmission, the process will repeat, and the form will go through the approval process again.</li>
      </ul>
      </div>
      </Card>
      
      <Card variant="outlined" className="m-24 mb-12">
        <div className="m-24 mt-8">
          {(!props.isParent || props.isAdmin) && (
            <div className="flex grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-16 content-start">
              <Controller
                control={control}
                name="applicantFullName"
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-16 w-full"
                    label="Applicant's Full Name"
                    placeholder="Applicant's Full Name"
                    variant="outlined"
                    fullWidth
                    size="small"
                    disabled
                  />
                )}
              />
              <Controller
                control={control}
                name="date"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Date"
                    defaultValue={today}
                    disabled
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
              {signed && (
                <>
                  <Controller
                    name="signature"
                    control={control}
                    render={({ field }) => (
                      <Box>
                        {field.value && (
                          <>
                            <img
                              src={field.value}
                              alt="Signature"
                              style={{ height: "100px", width: "auto" }}
                            />{" "}
                            <Divider />
                            <Typography variant="body2" align="center">
                              {t("SIGNATURE")}
                            </Typography>
                          </>
                        )}
                      </Box>
                    )}
                  />
                </>
              )}
            </div>
          )}

          {!props.isAdmin && !props.isParent && (
            <>
              <div className="flex grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-16 content-start">
                <Controller
                  control={control}
                  name="parentEmail"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-16 w-full"
                      label={t("PARENT_EMAIL_LABEL")}
                      placeholder={parentEmailPlaceHolder}
                      variant="outlined"
                      fullWidth
                      size="small"
                      error={!!errors.parentEmail}
                      helperText={errors?.parentEmail?.message}
                      required
                    />
                  )}
                />
                <div className="p-4 md:p-16 mt-10">
                  <div
                    className={clsx(
                      "inline text-12 font-semibold py-4 px-12 rounded-full truncate",
                      "bg-green-800 text-white"
                    )}
                  >
                    Pending
                  </div>
                </div>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  className="mt-24 w-60"
                  aria-label="Register"
                  type="submit"
                  size="medium"
                  onClick={parentEmailSubmit}
                >
                  {t("SUBMIT")}
                </Button>
              </div>
            </>
          )}

          {(props.isParent || props.isAdmin) && (
            <>
              <div className=" mt-20 mb-5 flex items-center">
                <p className="text-justify font-500">
                  I,{" "}
                  <TextField
                    type="text"
                    className="mb-10 border border-gray-300 rounded text-xs"
                    placeholder="Please enter your name"
                    value={name}
                    onChange={handleNameChange}
                    size="small"
                    disabled={parentSigned}
                  />
                  ,{t("CERTIFY_BODY")}
                </p>
              </div>

              <div className="flex grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-16 content-start">
                <TextField
                  className="mt-16 w-full"
                  label="Relationship to Applicant"
                  placeholder="Relationship to Applicant"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={relationship}
                  onChange={handleRelationshipChange}
                  disabled={parentSigned}
                />

                <DatePicker
                  className="mt-16 w-full"
                  label="Date"
                  slotProps={{ textField: { size: "small" } }}
                  defaultValue={today}
                  value={new Date(date)}
                  disabled={parentSigned}
                />

                {props.isParent && (
                  <>
                    <div>
                      <div className="rounded-lg border-1 border-gray w-fit">
                        <SignatureCanvas
                          ref={signatureRef}
                          canvasProps={{
                            className: "w-full",
                            width: 300,
                            height: 200,
                          }}
                        />
                        <div className="flex justify-end">
                          <IconButton aria-label="edit" onClick={handleClear}>
                            <BackIcon fontSize="inherit" />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {props.isAdmin && (
                  // {parentSigned()}
                  <>
                    <Controller
                      name="signature"
                      control={control}
                      render={({ field }) => (
                        <Box>
                          {field.value && parentSigned ? (
                            <>
                              <img
                                src={signature}
                                alt="Signature"
                                style={{ height: "100px", width: "auto" }}
                              />
                              <Divider />
                              <Typography variant="body2" align="center">
                                {t("SIGNATURE")}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <div className="mt-60">
                                <Divider />
                                <Typography variant="body2" align="center">
                                  {t("SIGNATURE")}
                                </Typography>
                              </div>
                            </>
                          )}
                        </Box>
                      )}
                    />
                  </>
                )}
              </div>

              {props.isParent && (
                <div className="flex justify-start">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="mt-24 w-60"
                    aria-label="Register"
                    type="submit"
                    size="medium"
                    onClick={parentDetailSubmit}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </Card>
    </>
  );
}
