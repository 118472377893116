import React from "react";
import { Navigate } from "react-router-dom";
import SubmitSalaryDetails from "./submit-salary-details";
import SubmitSalaryDetailsIndexPage from "./submit-salary-details-index/SubmitSalaryDetailsIndexPage";
import { UserRole } from "src/app/@types/enums/user.roles";
import i18next from "i18next";
import english from "./i18n/english";

i18next.addResourceBundle("en", "submitSalaryDetails", english);

export const SubmitSalaryDetailsRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Admin],
  routes: [
    {
      path: "submit-salary-details",
      element: <SubmitSalaryDetails />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <SubmitSalaryDetailsIndexPage />,
        },
      ],
    },
  ],
};
