import IndexHeader from "app/shared-components/PageHeader";
import * as React from "react";
import TransferFormAddUpdate from "./components/TransferFormAddUpdate";

export default function TransferFormAddUpdatePage() {
    return (
    <>
      <TransferFormAddUpdate />
    </>
  );
}
