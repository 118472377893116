import * as React from "react";
import { styled } from "@mui/material/styles";
import { Button, ButtonPropsColorOverrides } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

type buttonVariant = "text" | "outlined" | "contained";
type colorType = "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
interface Props {
    name: string;
    className: string;
    color?: colorType;
    StartIcon: React.ReactNode;
    disabled: boolean;
    onClick: () => void;
    variant: buttonVariant
  }

const ColorButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.common.black};
  color: ${({ theme }) => theme.palette.common.white};

  &:hover {
    background-color: ${({ theme }) => blueGrey[900]};
  }
`;

const CustomButton = (props:Props) => {
  return (
    <>
      <ColorButton className={props.className} color= {props.color} disabled = {props.disabled} startIcon = {props.StartIcon} onClick={props.onClick}  variant={props.variant}>{props.name}</ColorButton>
    </>
  );
};

export default CustomButton;
