import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import BackIcon from "@mui/icons-material/Backspace";
import { FormControl, InputLabel, Select, IconButton, Card, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";


const defaultValues = { name: "",};
const schema = yup.object().shape({
  name: yup.string().required("NAME_ERROR"),
});

function HostJobApplicationFilter() {
 const { t } = useTranslation("hostJobApplication"); 
 const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  if (_.isEmpty(form)) {
    return null;
  }

  return (
    <>
<Card variant="outlined" className="m-24 mb-0">
        <div className="flex content-between justify-start m-24">
        <div className="w-1/3 mt-16 mr-16">
            <Box sx={{ minWidth: 120 }} >
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  {t("STATUS")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Week"
                  
                >
                  <MenuItem value={"1"}>Hired </MenuItem>
                  <MenuItem value={"2"}>Pending</MenuItem>
                  <MenuItem value={"3"}>Rejected</MenuItem>
                  
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="w-1/3 mr-16">
          <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label={t("COMPANY")}
                  placeholder="Search by Company Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.name}
                  helperText={t(errors?.name?.message ?? "")}
                  
                />
              )}
            />
          </div>

          <div className="mt-20">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <SearchIcon />
            </IconButton>
          </div>
          <div className="mt-20  mr-1">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <BackIcon />
            </IconButton>
          </div>
        </div>
      </Card>
    </>
  );
}

export default HostJobApplicationFilter;
