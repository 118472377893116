const locale ={
    TITLE:"D-4 DC Withholding Allowance Certificate",
    YEAR:"Year",
    FIRST_NAME_ERROR:"Please Add your First Name",
    FIRST_NAME:"First Name",
    SSN_ERROR:"Add your SSN",
    SSN:"Social Security Number",
    LAST_NAME_ERROR:"Please Add your Last Name",
    LAST_NAME:"Last Name",
    M_I:"M.I",
    HOME_ADDRESS:"Home Address",
    CITY:"City",
    STATE:"State",
    ZIP:"Zip",
    TAX_FILLING:"Tax Filling Status:",
    HEADER:"D-4 DC Withholding Allowance Worksheet",
    SINGLE:"Single",
    MARIED_JOINTLY:"Married /domestic partners filing jointly",
    HEAD:"Head of Household",
    MARRIED_SEPERATELY:"Married filing separately ",
    MARRIED_PARENTS:" Married/domestic partners filing separately on same return",
    TOTAL_ALLOWANCES:"Total number of withholding allowances from worksheet below.",
    TOTAL_A:"Enter total from Sec. A, Line i",
    TOTAL_B:"Enter total from Sec. B, Line n",
    TOTAL_W:"Total number of withholding allowances from Line o",
    ADDITIONAL_AMOUNT:"Additional amount, if any, you want withheld from each paycheck",
    AMOUNT:"Amount",
    EXEMPT:"Before claiming exemption from withholding, read below. If qualified, write “EXEMPT” in this box.",
    DISTRICT:"My domicile is a state other than the District of Columbia",
    DC_INCOME:"I am exempt because: last year I did not owe any DC income tax and had a right to a full refund of all DC income tax withheld from me; and this year I do not expect to owe any DC income tax and expect a full refund of all DC income tax withheld from me; and I qualify for exempt status on federal Form W-4.",
    FULL_TIME_STUDENT:"If claiming exemption from withholding, are you a full-time student?",
    SIGNATURE:"Signature",
    UNDER:"Under penalties of law, I declare that the information provided on this certificate is, to the best of my knowledge, correct.",
    EMPLOYER:"Employer",
    KEEP:"Keep this certificate with your records. If 10 or more exemptions are claimed or if you suspect this certificate contains false information please send a copy to: Office of Tax and Revenue, 1101 4th St., SW, Washington, DC 20024 Attn: Compliance Administration",
    DETACH:"Detach and give the top portion to your employer. Keep the bottom portion for your records.",
    SECA_TITLE:"Section A Number of withholding allowances",
    A:"a Enter 1 for yourself",
    B:"b Enter 1 if you are filing as a head of household",
    C:"c Enter 1 if you are 65 or over",
    D:"d Enter 1 if you are blind",
    E:"e Enter number of dependents",
    F:"f Enter 1 for your spouse/registered domestic partner if filing jointly",
    G:"g Enter 1 if married/registered domestic partners filing jointly and your spouse/registered domestic partner is 65 or over",
    H:"h Enter 1 if married/registered domestic partners filing jointly and your spouse/registered domestic partner is blind",
    I:"i Number of allowances . Add Lines a through h and enter on Line 2 above. If you want to claim additional withholding i allowances, complete section B below.",
    SECB_TITLE:"Section B Additional withholding allowances",
    J:"j Enter estimate of your itemized deductions",
    K:"k Enter $2,000 if married/registered domestic partners filing separately; all others enter $4,000  ",
    L:"l Subtract Line k from Line j  ",
    M:"m Multiply $1,675 by the number of allowances on Line i   ",
    N:"n Divide Line l by Line m. Round to the nearest whole number.  ",
    O:"o Add Lines n and i and enter on Line 2 above.  ",
}
export default locale;