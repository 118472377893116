import React, { ChangeEvent, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Card, Button, Typography, Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useParams } from "react-router-dom";
import UploadDocumentsTable from "./components/uploaddocumentsTable";

const defaultValues = {
  documentName: "",
  file: "",
};

const schema = yup.object().shape({});

export default function UploadDocumentsPage() {
  const routeParams = useParams();
  const { id } = routeParams;

  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  const [values, setValue] = useState({
    employeeType: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue({
      ...values,
      [name]: value,
    });
  };
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] as File;
    setSelectedFile(file);
  };

  const handleClearFile = () => {
    setSelectedFile(null);
  };
  return (
    <>
      <Card variant="outlined" className="m-24 mb-0">
        <div className="m-24 mt-8">
          <div className="flex grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start">

            <Controller
              control={control}
              name="documentName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Document Name"
                  placeholder="Document Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.documentName}
                  helperText={errors?.documentName?.message}
                />
              )}
            />

            <Button
              className="mt-16"
              variant="contained"
              color="secondary"
              disabled={false}
            >
              Attach Document
            </Button>
          </div>
          <div className="flex mt-24">
            <Button
              className="mx-8"
              variant="contained"
              color="secondary"
              disabled={false}
            >
              Add
            </Button>
          </div>
          <UploadDocumentsTable />
          <div className="flex items-center justify-end mt-32">
            <Button
              className="mx-8"
              variant="contained"
              color="secondary"
              disabled={false}
            >
              Save
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
}
