const locale = {
  //select Youth UI
  SELECT_YOUTH: "Select Youth",
  ALL: "All",
  RETURNING: "Returning",
  ELIGIBLE: "Eligible",
  COMPLETED_ORIENTATION: "Completed Orientation",
  CERTIFIED: "Certified",
  SAA_VERIFIED: "SAA Verified",
  SAA_UNVERIFIED: "SAA Unverified",
  ADDRESS_VERIFIED: "Address Verified",
  ADDRESS_UNVERIFIED: "Address Unverified",
  BANK_COMPLETED: "Bank Completed",
  UNCOMPLETED_ORIENTATION: "Uncompleted Orientation",
  UNCOMPLETED_CERTIFICATION: "Uncompleted Certification",
  UNCOMPLETED_BANKING: "Uncompleted Banking",
  EMPLOYED: "Employed",
  UNEMPLOYED: "Unemployed",

  //select Host UI
  SELECT_HOST: "Select Host",
  POSTED_JOBS: "Posted Jobs",
  HIRED_YOUTH: "Hired Youths",
  AGREED_SUBMITTED: "Agree Submitted",
  AGREED_NOT_SUBMITTED: "Agree Not Submitted",

  //Admin Messages UI
  MAIL_BOX: "Mail Box",
  COMPOSE: "Compose",
  NEW_MESSAGE: "New Message",

  DISCARD: "Discard",
  SAVED_AS_GRAPH: "Saved as Draft",
  SEND: "Send",
  SUBJECT: "Subject",

  CC: "CC",
  //select Mail Message UI
  SELECTED_MAIL: "Select a mail to read",
};

export default locale;
