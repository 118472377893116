import React from "react";
import { Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import EnrollFormUpdate from "./components/enroll-form-update";

function EnrollFormPage() {
    return (
        <>
        <EnrollFormUpdate/>
        </>
    );

}
export default EnrollFormPage;