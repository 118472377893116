import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  FormControl,
  Select,
  Card,
  MenuItem,
  Button,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { paychexService } from "src/app/services/api/paychex.service";
import { alpha } from "@mui/material/styles";
import { authDataService } from "src/app/services/data/auth.data.service";
import { youthSignUpService } from "src/app/services/api/youth.signup.service";
import { YouthContactInformation } from "src/app/@types/interfaces/youth.contact.information";
import { PaychexChecksResponse } from "src/app/@types/interfaces/paychex.interface";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const defaultValues = {
  program: "",
  companyname: "",
  week: "",

  total: "",
  hours_worked: "",
  hourly_rate: "",
};

const schema = yup.object().shape({});

function YouthSalaryDetailsFormFilter() {
  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation("YouthSalaryDetailsFormIndexPage");

  const dispatch = useDispatch();
  const [msg, setMsg] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileId, setProfileId] = useState<any>("");
  const { isValid, dirtyFields, errors } = formState;
  const [userInfo, setUserInfo] = useState<YouthContactInformation | null>(
    null
  );
  const [payStubInfo, setPayStubInfo] = useState<PaychexChecksResponse | null>(
    null
  );

  const downloadPDF = () => {
    const capture = document.querySelector(".payStub");
    if (capture instanceof HTMLElement) {
      const captureOptions = {
        scale: 1, // Adjust the scale factor as needed
        useCORS: true, // Enable CORS if capturing elements from different origins
      };

      html2canvas(capture, captureOptions).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const doc = new jsPDF("p", "mm", "a4");
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const aspectRatio = canvas.width / canvas.height;
        let width = pageWidth;
        let height = width / aspectRatio;

        if (height > pageHeight) {
          height = pageHeight;
          width = height * aspectRatio;
        }

        doc.addImage(imgData, "PNG", 0, 0, width, height);
        doc.save("payStub.pdf");
      });
    }
  };
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const userId = localStorage.getItem("ms_uid") ?? undefined;
      const response = await paychexService.getPaychexChecksDataByUserId(
        userId
      );
      setPayStubInfo(response.data[0]);
    } catch (error: any) {
      setIsError(true);
      setMsg(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUserInfo(authDataService.userId);
  }, []);

  const getUserInfo = async (userId?: string) => {
    try {
      if (userId) {
        const response = await youthSignUpService.getContactDetailsByUserId(
          userId
        );
        setUserInfo(response.data);
      }
    } catch (error: any) {}
  };
  return (
    <>
      <Card variant="outlined" className="m-24 mb-0">
        <form
          name="hostInfoForm"
          noValidate
          className="flex flex-col justify-center w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col  grid ml-32 mr-32 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 gap-32 content-start m-32">
            <Controller
              name="program"
              control={control}
              render={({ field }) => (
                <FormControl className="w-full">
                  <Select
                    {...field}
                    displayEmpty
                    size="small"
                    variant="outlined"
                    required
                    value={field.value || ""}
                  >
                    {" "}
                    <MenuItem value="" disabled>
                      {t("PROGRAME")}
                    </MenuItem>
                    <MenuItem value={"1"}>
                      Summer Youth Employment Program 2023
                    </MenuItem>
                    <MenuItem value={"2"}>
                      In School Youth Program 2023
                    </MenuItem>
                    <MenuItem value={"3"}>
                      Out-of-School Youth Program 2023
                    </MenuItem>
                    <MenuItem value={"4"}>
                      Youth Leadership Program 2023
                    </MenuItem>
                    <MenuItem value={"5"}>
                      Summer Intensive Training 2023
                    </MenuItem>
                    <MenuItem value={"6"}>
                      Pathways for Young Adults Program 2023
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />

            <Controller
              name="companyname"
              control={control}
              render={({ field }) => (
                <FormControl className="w-full">
                  <Select
                    {...field}
                    displayEmpty
                    size="small"
                    variant="outlined"
                    required
                    value={field.value || ""}
                  >
                    {" "}
                    <MenuItem value="" disabled>
                      {t("COMPANY")}
                    </MenuItem>
                    <MenuItem value={"1"}>Codice</MenuItem>
                    <MenuItem value={"2"}>Yahoo</MenuItem>
                    <MenuItem value={"3"}>Google</MenuItem>
                    <MenuItem value={"4"}>Meta</MenuItem>
                  </Select>
                </FormControl>
              )}
            />

            <Controller
              name="week"
              control={control}
              render={({ field }) => (
                <FormControl className="w-full">
                  <Select
                    {...field}
                    displayEmpty
                    size="small"
                    variant="outlined"
                    required
                    value={field.value || ""}
                  >
                    {" "}
                    <MenuItem value="" disabled>
                      {t("WEEK")}
                    </MenuItem>
                    <MenuItem value={"1"}>
                      {t("WEEK")} 1 (3/20/2023 - 3/24/2023)
                    </MenuItem>
                    <MenuItem value={"2"}>
                      {t("WEEK")} 2 (3/27/2023 - 3/31/2023)
                    </MenuItem>
                    <MenuItem value={"3"}>
                      {t("WEEK")} 3 (4/3/2023 - 4/7/2023)
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />

            <div className="w-full mr-16">
              <Button
                variant="contained"
                color="secondary"
                className="mt-24 min-w-96"
                aria-label="Register"
                type="submit"
                size="medium"
              >
                {t("VIEW_BTN")}
              </Button>
            </div>
          </div>
        </form>
      </Card>

      {payStubInfo && (
        <div>
          <Card variant="outlined" className="payStub m-24 mb-5">
            <CardContent className="">
              <div className="flex items-start justify-between">
                <div>
                  <div className="grid grid-cols-2 gap-x-2 gap-y-2">
                    <Typography
                      className="font-medium tracking-tight"
                      color="text.secondary"
                    >
                      PayStub
                    </Typography>
                    <Typography className="font-medium">01</Typography>
                    <Typography
                      className="font-medium tracking-tight"
                      color="text.secondary"
                    >
                      PayStub Date
                    </Typography>
                    <Typography className="font-medium">
                      {payStubInfo?.createdAt
                        ? new Date(payStubInfo.createdAt).toLocaleDateString()
                        : null}
                    </Typography>
                    <Typography
                      className="font-medium tracking-tight"
                      color="text.secondary"
                    >
                      PayStub Period
                    </Typography>
                    <Typography className="font-medium">
                      {" "}
                      {t("WEEK")} 1 (3/20/2023 - 3/24/2023)
                    </Typography>
                  </div>
                  <div className="text-md mt-10">
                    <Typography className="text-xl font-medium">
                      {authDataService.userName}
                    </Typography>
                    <Typography>
                      {[
                        userInfo?.permanentAddressSuite,
                        userInfo?.permanentAddressLine,
                        userInfo?.permanentAddressState,
                        userInfo?.permanentAddressPostalCode,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </Typography>
                    <Typography>{authDataService.userEmail}</Typography>
                    <Typography>{userInfo?.emergencyPhoneNumber}</Typography>
                  </div>
                </div>

                <Box
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.dark,
                    color: (theme) =>
                      theme.palette.getContrastText(theme.palette.primary.dark),
                  }}
                  className="grid auto-cols-max grid-flow-col gap-x-32 -mr-50 py-24 px-32 rounded-l-2xl"
                >
                  <div className="place-self-center w-96">
                    <img
                      className="w-96"
                      src="assets/images/logo/DOES-logo.png"
                      alt="logo"
                    />
                  </div>
                  <Box
                    className="pl-40 border-l text-md"
                    sx={{
                      borderColor: (theme) =>
                        alpha(
                          theme.palette.getContrastText(
                            theme.palette.primary.dark
                          ),
                          0.25
                        ),
                    }}
                  >
                    <Typography className="font-medium">DOES</Typography>
                    <Typography> 4058 Minnesota Avenue, NE,</Typography>
                    <Typography>Washington, DC 20019</Typography>
                    <Typography>(202) 724-7000</Typography>
                    <Typography>does@dc.gov</Typography>
                    <Typography>https://does.dc.gov/</Typography>
                  </Box>
                </Box>
              </div>
              <Table className="simple">
                <TableHead>
                  <TableRow>
                    <TableCell>Hours Worked</TableCell>
                    <TableCell> Hourly Rate</TableCell>

                    <TableCell align="right">TOTAL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payStubInfo?.earnings.map((earningsItem, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="subtitle1">
                          {earningsItem.payHours} Hours
                        </Typography>
                      </TableCell>
                      <TableCell> $ {earningsItem.payRate}</TableCell>
                      <TableCell align="right">
                        ${" "}
                        {(earningsItem.payHours || 0) *
                          (earningsItem.payRate || 0)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Button
            variant="contained"
            color="secondary"
            className="mt-24 min-w-96 m-24 mb-5"
            aria-label="Register"
            onClick={downloadPDF}
            size="medium"
          >
            Download PDF
          </Button>
        </div>
      )}
    </>
  );
}

export default YouthSalaryDetailsFormFilter;
