import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import IndexHeader from "app/shared-components/IndexHeader";
import SelectYouth from "./selectYouth";
import SelectHost from "./selectHost";
import SelectMailMessage from "./selectMailMessage";
import Mail from "./mail";

export default function AdminMessagesPage(props: any) {
  return (
    <div className="m-24">
      <SelectYouth />
      <SelectHost />
      <hr />
      <Mail />
      <div>
        {props.selectedEmail ? (
          <div>
            <p>{props.selectedItem.username}</p>
          </div>
        ) : (
          <SelectMailMessage />
        )}
      </div>
    </div>
  );
}
