import * as React from "react";
import IndexHeader from "app/shared-components/IndexHeader";
import CustomButton from "app/shared-components/CustomColorButton";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import history from "@history";
import AdminIncidentFormFilter from "./components/AdminIncidentFormFilter";
import AdminIncidentFormList from "./components/AdminIncidentFormTable";

  const handleClick = () => {
    history.push('incident-form/add');
  };


export default function AdminIncidentFormIndexPage() {
  
  return (
    <>
      <IndexHeader
        name={"Admin Incident Forms"}
        update={false}
        add={false}
      />
      <AdminIncidentFormFilter />
      <AdminIncidentFormList />
    </>
  );
}
