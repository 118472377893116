const locale = {
  //select Youth UI
  SELECT_YOUTH: "Selecciona Joven",
  ALL: "Toda",
  RETURNING: "Regresando",
  ELIGIBLE: "Elegible",
  COMPLETED_ORIENTATION: "Orientación Completada",
  CERTIFIED: "Certificada",
  SAA_VERIFIED: "Verificado por SAA",
  SAA_UNVERIFIED: "SAA no verificado",
  ADDRESS_VERIFIED: "Dirección verificada",
  ADDRESS_UNVERIFIED: "Dirección no verificada",
  BANK_COMPLETED: "Banco completado",
  UNCOMPLETED_ORIENTATION: "Orientación incompleta",
  UNCOMPLETED_CERTIFICATION: "Certificación incompleta",
  UNCOMPLETED_BANKING: "Banca incompleta",
  EMPLOYED: "Empleada",
  UNEMPLOYED: "Desempleadas",

  //select Youth UI
  SELECT_HOST: "Selegir anfitrión",
  POSTED_JOBS: "Empleos publicados",
  HIRED_YOUTH: "Jóvenes contratados",
  AGREED_SUBMITTED: "De acuerdo presentada",
  AGREED_NOT_SUBMITTED: "Aceptar No enviado",

  //Admin Messages UI
  MAIL_BOX: "Casilla de correo",
  COMPOSE: "Componer",
  NEW_MESSAGE: "Nuevo mensaje",
  DISCARD: "Desechar",
  SAVED_AS_GRAPH: "Guardado como borrador",
  SEND: "Enviar",
  SUBJECT: "Sujeto",

  CC: "CC",

  //select Mail Message UI
  SELECTED_MAIL: "Seleccione un correo para leer",
};

export default locale;
