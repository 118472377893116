import * as React from "react";
import AssignSupervisorFilter from "./components/AssignSupervisorFilter";
import AssignSupervisorTable from "./components/AssignSupervisorTable";
import PageHeader from "app/shared-components/PageHeader";
import { useState } from "react";

export default function AssignSupervisorIndex() {
  return (
    <>
      <PageHeader
        name={"Assign Supervisor Index"}
        update={false}
        add={false}
        redirectingPath={"/assign-supervisor-add"}
      />
      <AssignSupervisorFilter />
      <AssignSupervisorTable />
    </>
  );
}
