const locale = {
  CREATE_USER: "Tạo nên",
  GENERATE_QR: "Tạo QR",
  SIGNIN: "Đăng nhập",
  SIGNUP: "Đăng ký",
  LOGIN_INFORMATION: "Thông tin đăng nhập",
  EMAIL_ADDRESS: "Địa chỉ email",
  CONFIRM_EMAIL_ADDRESS: "Xác nhận địa chỉ Email",
  PASSWORD: "Mật khẩu",
  CONFIRM_PASSWORD: "Xác nhận mật khẩu",

  //Error messages
  EMAIL_ADDRESS_NOT_MATCHED_ERROR: "Địa chỉ email không khớp",
  EMAIL_NOT_VALID: "Nhập địa chỉ Email hợp lệ",
  PASSWORD_NOT_MATCHED_ERROR: "Mật khẩu không khớp",
  EMAIL_PRIMARY_ERROR:
    "Địa chỉ Email này là cách chính để liên lạc với bạn. Do đó, nếu bạn không có Địa chỉ email cá nhân, vui lòng tạo một Địa chỉ trước",
  CONFIRM_EMAIL_ERROR: "Xác nhận địa chỉ email cần thiết",
  CONFIRM_PASSWORD_ERROR: "Xác nhận mật khẩu là bắt buộc",

  //Button
  Next: "Sau",
  Back: "Lưng",

  //#PersonalInfo-----------------
  PERSONAL_DETAILS: "Thông tin cá nhân",

  //Error Message
  LEGAL_FIRST_NAME_ERROR: "Vui lòng thêm Tên hợp pháp của bạn",
  LEGAL_LAST_NAME_ERROR: "Vui lòng thêm Họ hợp pháp của bạn",
  MIDDLE_NAME_ERROR: "Vui lòng thêm tên đệm của bạn",
  MAIDEN_NAME_ERROR: "Vui lòng thêm tên thời con gái của bạn",

  SSN_ERROR: "SSN là bắt buộc",
  CONFIRM_SSN_ERROR: "Xác nhận SSN là bắt buộc",
  SSN_NOT_MATCHED_ERROR: "SSN không khớp",

  LEGAL_FIRST_NAME: "Tên pháp lý",
  LEGAL_LAST_NAME: "Họ hợp pháp",
  MIDDLE_NAME: "Tên đệm",
  MAIDEN_NAME: "Tên thời con gái",
  DATE_OF_BIRTH: "Ngày sinh",
  LANGUAGE: "Ngôn ngữ",
  GENDER: "Giống",
  RACE: "Chủng tộc",
  SUFFIX: "Hậu tố",
  T_SHIRT_SIZE: "Cỡ áo thun",
  SOCIAL_SECURITY_NUMBER: "Số an sinh xã hội",
  CONFIRM_SSN: "Xác nhận SSN",

  //-----------END of PersonalInfoPage------------

  //#EducationalInfo
  //Error Message
  REQUIRED_ERROR: "Trường này nên được điền",

  EDUCATIONAL_DETAILS: "Chi tiết giáo dục",
  CURRENT_EDUCATIONAL_STATUS: "Tình trạng giáo dục hiện tại",
  EDUCATIONAL_STUDENT_TYPE: "Loại sinh viên giáo dục",
  CURRENT_GRADE: "Lớp hiện tại hoặc lớp cuối cùng đã tham dự",
  CURRENT_SCHOOL: "Trường hiện tại hoặc trường cuối cùng đã theo học",

  //----------END of EducationalInfoPage---------------

  //#ContactInfo
  CONTACT_INFORMATION: "Thông tin liên lạc",
  PERMENANT_ADDRESS_DETAILS: "Chi tiết địa chỉ thường trú",
  ADDRESS_LINE: "Dòng địa chỉ",
  APT: "Số căn hộ hoặc Suite",
  STATE: "Tiểu bang",
  CITY: "Thành phố",
  ZIP: "Mã ZIP hoặc Mã bưu điện",
  CELL_PHONE: "Điện thoại di động",
  HOME_PHONE: "Điện thoại nhà",
  PARENT: "Cha mẹ hoặc người giám hộ",
  FIRST_NAME: "Tên thánh",
  LAST_NAME: "Họ",
  WORK_PHONE: "Điện thoại cơ quan",
  RELATIONSHIP: "Mối quan hệ",
  EMERGENCY: "Khẩn cấp",
  PHONE_NUMBER: "Số điện thoại",

  //--------END OF CONTACT INFO PAGE--------

  //#NotificationInfo
  NOTIFICATION_INFORMATION: "Thông tin thông báo",
  NOTIFICATION_DETAILS: "Chi tiết thông báo",
  TWITTER_USER_NAME: "Tên người dùng Twitter@",
  CONTACT_TEXT_MESSAGE:
    "Chúng tôi có thể liên hệ với bạn qua tin nhắn văn bản không?",
  COMPUTER_STATUS: "Bạn có máy tính không?",
  INTERNET_STATUS: "Bạn có truy cập internet trong nhà của bạn?",
  NETWORK_TYPE: "Loại mạng",

  //-----------END of Notification Info Page---------

  //#Career Interest
  CAREER_INTEREST: "Sở thích nghề nghiệp",
  JOB_ASSIGNMENTS: "Nhiệm vụ bạn muốn",
  WORK_EXPERIENCE: "Kinh nghiệm làm việc",
  WORK_TRAINING: "Sẵn sàng đào tạo công việc",
  JOB_MATCHES: "Công việc phù hợp",
  CLOSE_HOME: "Gần nhà tôi",
  MATCHES_CAREER: "Phù hợp với mục tiêu nghề nghiệp của tôi",
  CAREER_TYPE_1: "Mối quan tâm nghề nghiệp hàng đầu đầu tiên",
  CAREER_TYPE_2: "Mối quan tâm nghề nghiệp hàng đầu thứ hai",
  CAREER_TYPE_3: "Mối quan tâm nghề nghiệp hàng đầu thứ ba",
  AFTER_SYEP: "Bất kỳ kế hoạch nào cho tương lai, sau SYEP",
  CAREER_GOALS: "Mục tiêu nghề nghiệp dài hạn của bạn là gì?",

  //----------END of Career Interest Page----------------

  //#Supplemenatl Info
  SUPPLEMENTAL_INFORMATION: "Thông tin bổ sung",
  SPECIAL_ACCOMODATION: "Chỗ ở đặc biệt / Thông tin y tế",
  REQ_SPECIAL_ACCOMODATION: "Bạn có yêu cầu bất kỳ chỗ ở đặc biệt nào không?",
  MEDICAL_CONDITION:
    "Tình trạng y tế sẽ ngăn cản khả năng làm việc bên ngoài của bạn",
  DEMOGRAPHIC: "Thông tin nhân khẩu",
  OFFENDER: "Người phạm tội hoặc Tòa án liên quan",
  MILITARY: "Phục vụ trong quân đội",
  HOMELESS: "Vô gia cư",
  PARENT: "Cha mẹ",
  MYOWN: "Sống một mình",
  FOSTER: "Chăm sóc nuôi dưỡng",
  PUBLIC_ASSISTANCE: "Hỗ trợ công cộng",
  REQ_PUBLIC_ASSISTANCE:
    "Kiểm tra tại đây nếu bạn hoặc gia đình bạn hiện đang nhận được bất kỳ hỗ trợ công cộng nào",
  YES: "Có",
  INCOME: "Thu nhập và quy mô gia đình",
  FAMILY_INCOME: "Tổng thu nhập gia đình trong sáu tháng qua",
  FAMILY_SIZE: "Tổng số thành viên hiện tại trong Gia đình của bạn",

  //------END of Supplemental Page--------------

  //#Review Page
  REVIEW_SIGN: "Xem xét và ký tên",
  DIGITAL_SIGNATURE: "Chữ ký số",
  CHECKING_INFO:
    "Bằng cách đánh dấu vào ô bên dưới, tôi xác nhận tất cả thông tin đó miễn là biểu mẫu này là đúng sự thật và chính xác.",
  APPROVE_INFO:
    "Tôi hiểu rằng thông tin này phải được xác minh, tôi hiểu thêm rằng việc cung cấp bất kỳ thông tin sai lệch nào có thể dẫn đến việc tôi bị loại khỏi văn phòng chương trình thanh thiếu niên DOES và có thể khiến tôi phải rước kiệu dân sự và hình sự nếu có.",
  READ_CHECK:
    "Tôi đã đọc tuyên bố trên và tôi muốn nộp đơn cho Chương trình Việc làm Thanh niên Mùa hè của Thị trưởng",
  DATE: "Ngày",
  SIGNATURE: "Chữ ký",
  DOES: "DOES là Nhà tuyển dụng / Nhà cung cấp Cơ hội Bình đẳng ",
  LANGUAGE_SERVICE: "Dịch vụ thông dịch ngôn ngữ được cung cấp miễn phí",
  AUXILIARY_AIDS:
    "Các dịch vụ phụ trợ và hỗ trợ được cung cấp theo yêu cầu cho người khuyết tật",
  NOTICE_HEADER: "Thông báo không phân biệt đối xử",
  ACCORDANCE:
    "Theo Đạo luật Nhân quyền DC năm 1977, được sửa đổi, bộ luật chính thức của DC phần 2-1401-01 et seg., (Đạo luật) Quận Columbia không phân biệt đối xử dựa trên chủng tộc, màu da, tôn giáo, quốc gia, nguồn gốc, giới tính, tuổi tác, tình trạng hôn nhân, ngoại hình cá nhân, khuynh hướng tình dục, bản dạng hoặc biểu hiện giới tính, tình trạng gia đình, trách nhiệm gia đình, trúng tuyển, liên kết chính trị,  thông tin di truyền, khuyết tật, nguồn thu nhập hoặc nơi cư trú hoặc kinh doanh. Quấy rối tình dục dưới hình thức phân biệt đối xử cũng bị cấm bởi Đạo luật. Ngoài ra, quấy rối dựa trên bất kỳ danh mục được bảo vệ nào ở trên đều bị cấm theo Đạo luật. Phân biệt đối xử vi phạm Đạo luật sẽ không được dung thứ. Người vi phạm sẽ bị xử lý kỷ luật",
};

export default locale;
