import React from "react";
import AssignSupervisorIndex from "./assign-supervisor-index/AssignSupervisorIndex";
import AssignSupervisorAddPage from "./assign-supervisor-add/AssignSupervisoraAddPage";
import { UserRole } from "src/app/@types/enums/user.roles";

export const AssignSupervisorRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Host],
  routes: [
    {
      path: "assign-supervisor-index",
      element: <AssignSupervisorIndex />,
    },
    {
      path: "assign-supervisor-add",
      element: <AssignSupervisorAddPage />,
    },
  ],
};
