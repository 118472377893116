import i18next from 'i18next';
import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';
import Example from './Example';
import english from '../../../@fuse/core/DemoContent/i18n/english';
import amharic from '../../../@fuse/core/DemoContent/i18n/amharic';
import chinese from '../../../@fuse/core/DemoContent/i18n/chinese';
import korean from '../../../@fuse/core/DemoContent/i18n/korean';
import french from '../../../@fuse/core/DemoContent/i18n/french';
import spanish from '../../../@fuse/core/DemoContent/i18n/spanish';
import vietnamese from '../../../@fuse/core/DemoContent/i18n/vietnamese'

i18next.addResourceBundle('en', 'examplePage', en);
i18next.addResourceBundle('fr', 'examplePage', tr);
i18next.addResourceBundle('ar', 'examplePage', ar);
i18next.addResourceBundle('en', 'demoContentPage', english);
i18next.addResourceBundle('ar', 'demoContentPage', amharic);
i18next.addResourceBundle('cn', 'demoContentPage', chinese);
i18next.addResourceBundle('kr', 'demoContentPage', korean);
i18next.addResourceBundle('fr', 'demoContentPage', french);
i18next.addResourceBundle('es', 'demoContentPage', spanish);
i18next.addResourceBundle('vn', 'demoContentPage', vietnamese);

const ExampleConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'example',
      element: <Example />,
    },
  ],
};

export default ExampleConfig;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const Example = lazy(() => import('./Example'));

const ExampleConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'example',
      element: <Example />,
    },
  ],
};

export default ExampleConfig;
*/
