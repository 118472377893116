import React, { useState } from "react";
import { useParams } from "react-router-dom";
import IndexHeader from "app/shared-components/IndexHeader";
import { Stack, Button, Card } from "@mui/material";
import pdf from "../../../../../assets/pdf/Proof of Residencecy.pdf";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {TextField} from '@mui/material';

const AdminApprovalViewResidencyPDF = () => {
  const routeParams = useParams();
  const { id } = routeParams;

  return (
    <>
      <IndexHeader
        name={"Proof of DC Residency"}
        update={id ? true : false}
        add={id ? false : true}
        previousPath={"admin-approval-form/list"}
      />
      <Card className="m-24 mb-10">
        <div style={{ display: "flex", justifyContent: "center",flexDirection:"column" }}>
          <div
            style={{
              width: "685px",
              border: "3px solid gray",
              height: "500px",
              margin:'auto',
            }}
          >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
              <Viewer fileUrl={pdf} />
            </Worker>
          </div>
          <div className="mt-16 justify-center mx-auto w-3/5">
            <TextField
            id="outlined-multiline-static"
            label="Remarks"
            multiline
            rows={4}
            fullWidth
            />
        </div>
        </div>
        
        <Stack
          marginTop={2}
          justifyContent="center"
          spacing={30}
          direction="row"
          className="mb-10"
        >
          <Button
            className="w-15"
            variant="contained"
            color="secondary"
            aria-label="Register"
            type="submit"
            size="medium"
          >
            Download
          </Button>
          <Button
            className="w-15"
            variant="contained"
            color="secondary"
            aria-label="Register"
            type="submit"
            size="medium"
          >
            Accept
          </Button>
          <Button
            className="w-15"
            variant="contained"
            color="secondary"
            aria-label="Register"
            type="submit"
            size="medium"
          >
            Reject
          </Button>
        </Stack>
      </Card>
    </>
  );
};

export default AdminApprovalViewResidencyPDF;
