const locale = {
    INTRODUCTION: '介绍',
    INTRODUCTION_CONTENT:'青年就业计划是一项变革性举措，旨在赋予年轻人权力，并为他们提供在劳动力中取得成功所需的技能、经验和机会。认识到青年作为经济增长和社会发展驱动力的潜力，该计划旨在弥合教育和就业之间的差距，为繁荣的未来铺平道路。在当今竞争激烈的就业市场中，由于缺乏经验或技能，许多年轻人在试图进入劳动力市场时面临挑战。青年就业计划通过提供全面的支持和资源来帮助参与者克服这些障碍并实现他们的职业目标，从而解决了这些障碍。通过投资于青年的发展和赋权，我们不仅促进他们的个人成功，而且还为我们社区的整体成长和福祉做出贡献。',
    PROGRAM_OBJECTIVES :'计划目标',
    OBJECTIVE_CONTENT :'青年就业方案有三个核心目标。首先，该计划侧重于技能发展，提供研讨会，培训课程和实践经验，以提高参与者的能力。通过为他们提供沟通、解决问题和团队合作等基本技能，我们使他们能够成为所选领域的适应性强和有价值的贡献者。其次，该计划为参与者提供了有意义的工作经验。通过实习、学徒或暑期工作，参与者可以接触到现实世界的工作环境，使他们能够在实际环境中应用他们的技能和知识。这些经历不仅建立了信心，还培养了强大的职业道德和专业网络。最后，该计划提供了重要的职业指导和支持。参与者与导师配对，导师提供指导，分享他们的专业知识，并协助驾驭职业规划的复杂性。通过指导、职业咨询和探索活动，参与者可以清楚地了解他们的职业道路，做出明智的决定，并获得使他们能够有效实现目标的资源。',
    IMPACT:'影响和成功案例',
    IMPACT_CONTENT:'青年就业方案见证了重大影响和成功的故事。完成该计划的参与者继续在各个行业就业，接受高等教育，并成为社区的领导者。他们的成功不仅反映了他们的个人成长，也证明了这些计划有能力赋予年轻人权力并释放他们的潜力。此外，其影响不仅限于个人的成功案例。当参与者成为劳动力的生产成员时，他们为所在地区的经济增长和稳定做出了贡献。这些计划强调培养熟练的劳动力，产生积极的连锁反应，使企业、社区和整个社会受益。 ',
    CONCLUSION:'结论',
    CONCLUSION_CONTENT:'青年就业计划是对未来的宝贵投资。通过为年轻人提供他们所需的技能、经验和指导，我们正在培养一支熟练的劳动力队伍，促进经济增长，并为我们的社区创造更光明的未来。我们可以共同赋予青年权力，释放他们的全部潜力，因为他们成为繁荣明天的建筑师。',

};
  
export default locale;