import { Link } from "react-router-dom";
import _ from "@lodash";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import withRouter from "@fuse/core/withRouter";
import AuthService from "../../../services/api/auth.service";
import history from "@history";
import { CircularProgress, Typography, ListItemButton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { authDataService } from "../../../services/data/auth.data.service";

function TwoFactorVerificationSelectPage(props) {
  const { t } = useTranslation("2FaPage");
  function on2FASelect(type) {
    props.navigate(`/two-factor-verification/${type}`);
  }

  function handleOn2FASelect(id) {
    return function () {
      on2FASelect(id);
    };
  }

  const onSelect = async (type) => {
    const authService = new AuthService();
    try {
      const msg = await authService.generateOtp(type);
      history.push(`/two-factor-verification/${type}`);
    } catch (error) {}
  };

  return (
    <div className="flex flex-col sm:flex-row items-center sm:justify-center flex-1 min-w-0">
      <Paper className="h-full sm:h-auto w-full sm:w-auto py-8 px-16 sm:p-48 sm:rounded-2xl sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            className="h-52"
            src="assets/images/logo/logo-ypms-full.png"
            alt="logo"
          />

          <Typography className="mt-16 text-2xl font-semibold tracking-tight leading-tight">
            {t("TWO_STEP_VERIFICATION")}
          </Typography>

          <Chip
            className="mt-16 font-semibold"
            icon={
              <FuseSvgIcon className="text-48" size={24} color="action">
                heroicons-outline:user-circle
              </FuseSvgIcon>
            }
            label={authDataService.userName}
          />

          <Typography className="mt-16 font-semibold">
            {t("PLEASE_SELECT_AUTHENTICATION")}
          </Typography>

          <List
            className="mt-8 w-full"
            component="nav"
            aria-label="2FA options"
          >
            <Divider />

            <ListItemButton
              className="grid grid -ml-16"
              onClick={() => onSelect(1)}
            >
              <div className="flex row items-center mb-4 mt-8">
                <FuseSvgIcon className="text-48" size={24} color="action">
                  heroicons-outline:mail
                </FuseSvgIcon>
                <Typography className="ml-10 font-normal">
                  {" "}
                  {t("EMAIL")}
                </Typography>
              </div>
              <Typography className="mb-8 font-light">
                {t("GET_VERIFICATION_CODE")} {authDataService.userEmail}
              </Typography>
            </ListItemButton>

            <Divider />

            <ListItemButton
              className="grid grid -ml-16"
              onClick={() => onSelect(2)}
            >
              <div className="flex items-center mb-4 mt-8">
                <FuseSvgIcon className="text-48" size={24} color="action">
                  heroicons-outline:chat-alt
                </FuseSvgIcon>
                <Typography className="ml-10 font-normal">SMS</Typography>
              </div>
              <Typography className="mb-8 font-light">
                {t("GET_VERIFICATION_CODE")} {authDataService.phone}
              </Typography>
            </ListItemButton>
            <Divider />

            <div className="flex items-baseline mt-16 font-medium">
              <span>{t("OR_TRY")}</span>
              <Link className="ml-4" to="/sign-in">
                {t("SIGN_IN")}
              </Link>
              <span className="ml-2"> {t("AGAIN")} </span>
            </div>
          </List>
        </div>
      </Paper>
    </div>
  );
}

export default withRouter(TwoFactorVerificationSelectPage);
