import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";;
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Card,
  Typography,
  DialogTitle,
  Button,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from 'react-i18next';
import IndexHeader from "app/shared-components/IndexHeader";
import { useParams } from "react-router-dom";
import CustomButton from "app/shared-components/CustomColorButton";
import history from "@history";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PrintIcon from "@mui/icons-material/Print";
import ProgramService from "src/app/services/api/program.service";
import { log } from "console";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#202441",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

export default function EnrollProgramUpdate() {
  const routeParams = useParams();
  const { id } = routeParams;
  const {t} =useTranslation("hostProgram");

  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
  });

  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  interface Program {
    _id: string;
    code: number;
    name: string;
    createdAt: string;
    isActive: boolean;
    updatedAt: string;
  }

  const programData = [
    {
      _id: "1",
      code: 123,
      name: "Summer Youth Employment Program 2023",
      createdAt: "2022-01-01",
      isActive: true,
      updatedAt: "2022-01-01",
    },
    {
        _id: "2",
        code: 124,
        name: "Out-of School Youth Program 2023",
        createdAt: "2022-01-01",
        isActive: true,
        updatedAt: "2022-01-01",
      },
      {
        _id: "3",
        code: 125,
        name: "Summer Intensive Program 2022",
        createdAt: "2022-01-01",
        isActive: true,
        updatedAt: "2022-01-01",
      },
      {
        _id: "4",
        code: 126,
        name: "Youth Earn and Learn Program 2022",
        createdAt: "2022-01-01",
        isActive: true,
        updatedAt: "2022-01-01",
      },
      {
        _id: "5",
        code: 127,
        name: "Global Education Program 2022",
        createdAt: "2022-01-01",
        isActive: true,
        updatedAt: "2022-01-01",
      },
      {
        _id: "6",
        code: 128,
        name: "In-School Youth Program 2023",
        createdAt: "2022-01-01",
        isActive: true,
        updatedAt: "2022-01-01",
      },
      {
        _id: "7",
        code: 129,
        name: "Youth Leadership Program 2023",
        createdAt: "2022-01-01",
        isActive: false,
        updatedAt: "2022-01-01",
      },
      {
        _id: "8",
        code: 130,
        name: "Pathways for Young Adults program 2022",
        createdAt: "2022-01-01",
        isActive: false,
        updatedAt: "2022-01-01",
      },
      {
        _id: "9",
        code: 131,
        name: "School Year Program 2022",
        createdAt: "2022-01-01",
        isActive: false,
        updatedAt: "2022-01-01",
      },
    
  ];

  

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const myButtons = [
    <Button
      className="mx-8"
      variant="contained"
      disabled={false}
      startIcon={<CancelIcon />}
      onClick={handleClick}
    >
      {"Cancel"}
    </Button>,

    <CustomButton
      StartIcon={<SaveIcon />}
      className="mx-8"
      disabled={false}
      onClick={handleClick}
      name="Save"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];

  const [data, setData] = useState<Program[] | null>(null);
  const [enrolled, setEnrolled] = useState<Program[] | null>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleChange =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
      } else {
        setSelectedIds((prevSelectedIds) =>
          prevSelectedIds.filter((selectedId) => selectedId !== id)
        );
      }
    };


  useEffect(() => {
    setData(programData);
  }, []);

  return (
    <>
      <IndexHeader
        name={"Register for a Program"}
        update={id ? true : false}
        add={id ? false : true}
        previousPath={"/home"}
        buttons={myButtons}
      />

      <Card variant="outlined" className="m-24 mb-12">
        <Typography className="mt-16 text-2xl font-bold tracking-tight leading-tight ml-16">
          {t("ALL_PROGRAMS")}
        </Typography>
        <Typography className="mt-16 font-300 tracking-tight leading-tight ml-16">
          {t("SUB_HEADING")}
        </Typography>

        <div className="flex items-start justify-start gap-16 mb-16 ml-16">
          {data ? (
            <>
              <div className="w-1/3">
                <FormGroup>
                  {data.slice(0, 5).map((item: Program, index: number) => (
                    <FormControlLabel
                      key={index}
                      control={<Checkbox onChange={handleChange(item._id)} />}
                      label={item.name}
                    />
                  ))}
                </FormGroup>
              </div>

              <div className="w-1/3">
                <FormGroup>
                  {data.slice(5).map((item: Program, index: number) => (
                    <FormControlLabel
                      key={index + 5}
                      control={<Checkbox onChange={handleChange(item._id)} />}
                      label={item.name}
                    />
                  ))}
                </FormGroup>
              </div>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </Card>

      <div className="m-24 mb-12">
      <Typography className="mt-16 text-2xl font-bold tracking-tight leading-tight ml-16">
          {t("ONGOING_PROGRAMS")}
        </Typography>
        <Typography className="mt-8 font-300 tracking-tight leading-tight ml-16">
         {t("ONLY_PROGRAMS")}
        </Typography>
        <TableContainer className="w-2/5 m-16" component={Paper}>
            <Table sx={{ minWidth: 400 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t("PROGRAM")}</StyledTableCell>
                  <StyledTableCell>{t("NO_OF_YOUTH")}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {data && (
                  <>
                    {data
                      .filter((program) => program.isActive)
                      .map((program) => (
                        <StyledTableRow key={program._id}>
                          <StyledTableCell>{program.name}</StyledTableCell>
                          <StyledTableCell>
                            <TextField
                              type="number"
                              disabled={!selectedIds.includes(program._id)}
                              inputProps={{ min: 0 }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </>
                )}
              </TableBody>
              </Table>
              </TableContainer>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("MESSAGE")}</DialogTitle>
        <DialogContent>
          <p>{t("CONTENT")}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("CLOSE")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

