const locale={
    ALL_PROGRAMS:"All Programs",
    SUB_HEADING:"All Does Youth Employment Programs are listed beleow. Select the program you wish to enroll.",
    ONGOING_PROGRAMS:"Ongoing Programs",
    ONLY_PROGRAMS:" Only the Ongoing Programs are listed below. Please provide the number of Youths you wish to recruit under each programs.",
    PROGRAM:"Program",
    NO_OF_YOUTH:"No of Youth Required",
    MESSAGE:"Message",
    CONTENT:"For finalizing the enroll process, you must attend the qutionnnaire",
    CLOSE:"Close",
}
export default locale;