import SignInPage from "./SignInPage";

import i18next from "i18next";
import english from "./i18n/english";
import spanish from "./i18n/spanish";
import korean from "./i18n/korean";

i18next.addResourceBundle("en", "signInPage", english);
i18next.addResourceBundle("es", "signInPage", spanish);
i18next.addResourceBundle("kr", "signInPage", korean);

const SignInConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null,
  routes: [
    {
      path: "sign-in",
      element: <SignInPage />,
    },
  ],
};

export default SignInConfig;
