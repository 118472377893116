import React from "react";
import { Typography } from "@mui/material";
import { Outlet } from "react-router-dom";

function ISYPPage() {
    return (<div>
        ISYPPage Program Page
    </div>);

}
export default ISYPPage;