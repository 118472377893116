interface Language {
  code: string;
  name: string;
  country_code: string;
}
const languages: Language[] = [
  {
    code: "en",
    name: "English - US",
    country_code: "en",
  },
  {
    code: "ar",
    name: "Amharic (አማርኛ)",
    country_code: "ar",
  },
  {
    code: "cn",
    name: "Chinese (中文)",
    country_code: "cn",
  },
  {
    code: "fr",
    name: "French (Français)",
    country_code: "fr",
  },
  {
    code: "kr",
    name: "Korean (한국어)",
    country_code: "kr",
  },
  {
    code: "es",
    name: "Spanish (Español)",
    country_code: "es",
  },
  {
    code: "vn",
    name: "Vietnamese (Tiếng Việt)",
    country_code: "vn",
  },
];

export default languages;
