import React from "react";
import IndexHeader from "app/shared-components/PageHeader";
import AllownanceCertificate from "./components/w4AllowanceCertificate";
import AllownanceWorksheet from "./components/w4AllowanceWorksheet";

export default function W4TaxFormIndexPage() {
  return (
    <>
      <AllownanceCertificate />
      <AllownanceWorksheet />
    </>
  );
}

