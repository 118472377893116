import React, { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import {
  Button,
  Divider,
  IconButton,
  Menu,
  TableFooter,
  TablePagination,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
} from "@mui/material";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#202441",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default class AdminApprovalFormList extends Component {
  // Define state variables and their types
  state = {
    page: 0,
    rowsPerPage: 10,
    actionMenu: null,
  };

  // Initialize state variables using the useState hook
  setPage = (page: React.SetStateAction<number>) => {
    this.setState({ page });
  };

  setRowsPerPage = (rowsPerPage: number) => {
    this.setState({ rowsPerPage });
  };

  actionMenuClick = (type: string) => {
    if (type == "Transfer Form") {
      window.open("/admin-approval-form/transfer-view/1", "_blank");
    } else if (type == "Termination Form") {
      window.open("/admin-approval-form/termination-view/1", "_blank");
    } else if (type == "Parent Consent Form") {
      window.open(
        "/admin-approval-form/view/648f4035c9e424433c4304ec",
        "_blank"
      );
    }else if(type=="Proof of Work"){
      window.open("/admin-approval-form/proof-of-work-view/1", "_blank");
    }else if(type=="Proof of SSN"){
      window.open("/admin-approval-form/proof-of-ssn-view/1", "_blank");
    }else if(type=="Proof of Age"){
      window.open("/admin-approval-form/proof-of-age-view/1", "_blank");
    }else if(type=="Proof of DC Residency"){
      window.open("/admin-approval-form/proof-of-residency-view/1", "_blank");
    }
     else {
      window.open("/admin-approval-form/pdf-view/1", "_blank");
    }
  };

  actionMenuClose = () => {
    this.setState({ actionMenu: null });
  };

  dataSet: any[] = [
    {
      id: 1,
      programName: "SYEP",
      year: "2023",
      Type: "Parent Consent Form",
      FirstName: "Clark",
      LastName: "Walker",
    },
    {
      id: 2,
      programName: "SYEP",
      year: "2023",
      Type: "Proof of Age",
      FirstName: "Scott",
      LastName: "Nelson",
    },
    {
      id: 3,
      programName: "SYEP",
      year: "2023",
      Type: "Proof of DC Residency",
      FirstName: "John",
      LastName: "Carter",
    },
    {
      id: 4,
      programName: "SYEP",
      year: "2023",
      Type: "Proof of SSN",
      FirstName: "Adam",
      LastName: "Hall",
    },
    {
      id: 5,
      programName: "ISY",
      year: "2023",
      Type: "Proof of Work",
      FirstName: "Johnson",
      LastName: "Smith",
    },
    {
      id: 6,
      programName: "ISY",
      year: "2023",
      Type: "Transfer Form",
      FirstName: "Thomas",
      LastName: "Brown",
    },
    {
      id: 7,
      programName: "ISY",
      year: "2023",
      Type: "Termination Form",
      FirstName: "Martin",
      LastName: "Lee",
    },
  ];

  // Avoid a layout jump when reaching the last page with empty rows.
  emptyRows: number =
    this.state.page > 0
      ? Math.max(
          0,
          (1 + this.state.page) * this.state.rowsPerPage - this.dataSet.length
        )
      : 0;

  handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    this.setRowsPerPage(parseInt(event.target.value, 10));
    this.setPage(0);
  };

  render() {
    return (
      <>
        <div className="m-24">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Program</StyledTableCell>
                  <StyledTableCell>Year</StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>FirstName</StyledTableCell>
                  <StyledTableCell>LastName</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.dataSet.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>{row.programName}</StyledTableCell>
                    <StyledTableCell>{row.year}</StyledTableCell>
                    <StyledTableCell>{row.Type}</StyledTableCell>
                    <StyledTableCell>{row.FirstName}</StyledTableCell>
                    <StyledTableCell>{row.LastName}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        className="-m-6"
                        onClick={() => this.actionMenuClick(row.Type)}
                      >
                        <Button
                          size="small"
                          style={{
                            backgroundColor: "lightblue",
                            borderRadius: 5,
                          }}
                        >
                          VIEW
                        </Button>
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    colSpan={4}
                    count={this.dataSet.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  }
}
