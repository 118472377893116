import React from "react";
import { Navigate } from "react-router-dom";
import { UserRole } from "src/app/@types/enums/user.roles";
import i18next from "i18next";

import english from "./i18n/english";
import korean from "./i18n/korean";
import spanish from "./i18n/spanish";
import YouthMessages from "./youth-message-page";

i18next.addResourceBundle("en", "YouthMessages", english);
i18next.addResourceBundle("es", "YouthMessages", spanish);
i18next.addResourceBundle("kr", "YouthMessages", korean);

export const YouthMessageRoutes = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "youth-messages",
      element: <YouthMessages />,
    },
  ],
};
