import React from "react";
import { Navigate } from "react-router-dom";
import { UserRole } from "src/app/@types/enums/user.roles";
import AdminMessages from "./admin-messages";
import AdminMessagesPage from "./admin-messagePage";
import SelectMailMessage from "./selectMailMessage";
import i18next from "i18next";

import english from "./i18n/english";
import korean from "./i18n/korean";
import spanish from "./i18n/spanish";

i18next.addResourceBundle("en", "AdminMessages", english);
i18next.addResourceBundle("es", "AdminMessages", spanish);
i18next.addResourceBundle("kr", "AdminMessages", korean);

export const AdminMessageRoutes = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "admin-messages",
      element: <AdminMessages />,
      children: [
        {
          path: "",
          element: <SelectMailMessage />,
        },
        {
          path: "email/:id",
          element: <AdminMessagesPage />,
        },
      ],
    },
  ],
};
