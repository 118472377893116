import React, { useState } from "react";
import { PlayArrow, Stop } from "@mui/icons-material";
import {
  Card,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  styled,
  tableCellClasses,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";

type Record = {
  description: string;
  startTime: Date | null;
  endTime: Date | null;
};

const TimeRecorder: React.FC = () => {
  const [description, setDescription] = useState("");
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [records, setRecords] = useState<Record[]>([]);

  const pushStartAndEndDateToArray = () => {
    if (description && startTime && endTime) {
      const record: Record = {
        description,
        startTime,
        endTime,
      };
      setRecords([...records, record]);
      setDescription("");
      setStartTime(null);
      setEndTime(null);
    }
  };

  const calculateWorkedHours = (
    startTime: Date | null,
    endTime: Date | null
  ) => {
    if (startTime && endTime) {
      const timeDiff = Math.abs(endTime.getTime() - startTime.getTime());
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
      const seconds = Math.floor((timeDiff / 1000) % 60);
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
    return "";
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#202441",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      <Card variant="outlined" className="m-24">
        <div className="flex content-between justify-start m-24">
          <div className="mr-16">
            <TextField
              label="Enter your work description"
              placeholder="Enter your work description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="mr-16">
            <DateTimePicker
              label="Select Your start time"
              value={startTime}
              onChange={(date) => setStartTime(date)}
            />
          </div>
          <div className="mr-16">
            <DateTimePicker
              label="Select Your end time"
              value={endTime}
              onChange={(date) => setEndTime(date)}
            />
          </div>
          <div className="mr-16 mt-5">
            <Button
              variant="contained"
              color="secondary"
              aria-label="Register"
              type="submit"
              size="medium"
              onClick={pushStartAndEndDateToArray}
            >
              Add
            </Button>
          </div>
        </div>
      </Card>

      <div className="m-24">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell>Start Time</StyledTableCell>
                <StyledTableCell>End Time</StyledTableCell>
                <StyledTableCell>Worked Hours</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.length === 0 ? (
                <TableRow>
                  <StyledTableCell colSpan={4} align="center">
                    No data to display
                  </StyledTableCell>
                </TableRow>
              ) : (
                records.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.description}</StyledTableCell>
                    <StyledTableCell>
                      {row.startTime?.toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.endTime?.toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell>
                      {calculateWorkedHours(row.startTime, row.endTime)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default TimeRecorder;
