const locale = {
    INTRODUCTION: '소개',
    INTRODUCTION_CONTENT:'청년 고용 프로그램은 청년들에게 권한을 부여하고 직장에서 성공하는 데 필요한 기술, 경험 및 기회를 제공하기 위해 고안된 혁신적인 이니셔티브입니다.  경제 성장과 사회 발전의 원동력으로서 청소년의 잠재력을 인식하는 이 프로그램은 교육과 고용 간의 격차를 해소하여 번영하는 미래를 위한 길을 닦는 것을 목표로 합니다. 오늘날의 경쟁이 치열한 취업 시장에서 많은 젊은이들이 경험이나 기술 부족으로 인해 취업을 시도할 때 어려움에 직면합니다.   청년 고용 프로그램은 참가자들이 이러한 장애물을 극복하고 경력 목표를 달성할 수 있도록 포괄적인 지원과 자원을 제공함으로써 이러한 장벽을 해결합니다. 청소년의 개발과 권한 부여에 투자함으로써 우리는 개인의 성공을 촉진할 뿐만 아니라 지역 사회의 전반적인 성장과 웰빙에 기여합니다.',
    PROGRAM_OBJECTIVES :'프로그램 목표',
    OBJECTIVE_CONTENT :'청년 고용 프로그램에는 세 가지 핵심 목표가 있습니다. 첫째, 이 프로그램은 기술 개발에 중점을 두고 참가자의 능력을 향상시키기 위한 워크샵, 교육 세션 및 실습 경험을 제공합니다. 의사 소통, 문제 해결 및 팀워크와 같은 필수 기술을 갖추어 그들이 선택한 분야에서 적응력 있고 가치 있는 기여자가 될 수 있도록 합니다. 둘째, 이 프로그램은 참가자들에게 의미 있는 업무 경험을 제공합니다. 인턴십, 견습 또는 여름 직업을 통해 참가자는 실제 작업 환경에 노출되어 실제 환경에서 자신의 기술과 지식을 적용할 수 있습니다. 이러한 경험은 자신감을 키울 뿐만 아니라 강력한 직업 윤리와 전문 네트워크를 육성합니다. 마지막으로, 이 프로그램은 중요한 진로 지도와 지원을 제공합니다. 참가자는 지침을 제공하고 전문 지식을 공유하며 경력 계획의 복잡성을 탐색하는 데 도움을 주는 멘토와 짝을 이룹니다. 멘토링, 진로 상담 및 탐색 활동을 통해 참가자는 자신의 진로에 대한 명확성을 얻고, 정보에 입각한 결정을 내리고, 목표를 효과적으로 추구할 수 있는 리소스에 액세스합니다.',
    IMPACT:'영향 및 성공 사례',
    IMPACT_CONTENT:'청년 고용 프로그램은 상당한 영향과 성공 사례를 목격했습니다. 프로그램을 이수한 참가자들은 다양한 산업 분야에서 취업을 하고 고등 교육을 받았으며 지역 사회의 리더가 되었습니다. 그들의 성공은 개인의 성장을 반영할 뿐만 아니라 청소년에게 권한을 부여하고 잠재력을 발휘할 수 있는 프로그램 능력을 보여줍니다. 또한 그 영향은 개인의 성공 사례를 넘어 확장됩니다. 참가자들은 생산적인 노동력의 구성원이 되면서 지역의 경제 성장과 안정에 기여합니다. 숙련된 인력 개발에 중점을 둔 프로그램은 긍정적인 파급 효과를 창출하여 기업, 지역 사회 및 사회 전반에 혜택을 줍니다. ',
    CONCLUSION:'결론',
    CONCLUSION_CONTENT:'청년 고용 프로그램은 미래에 대한 귀중한 투자입니다. 젊은이들에게 필요한 기술, 경험 및 지도를 제공함으로써 우리는 숙련된 인력을 육성하고 경제 성장을 촉진하며 지역 사회의 더 밝은 미래를 만들고 있습니다. 우리는 함께 청소년들에게 힘을 실어주고 그들이 번영하는 내일의 건축가가 될 때 잠재력을 최대한 발휘할 수 있습니다.',

};
  
export default locale;