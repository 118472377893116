import React, { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { withTranslation, WithTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";

import IndexHeader from "app/shared-components/PageHeader";
import {
  Button,
  Divider,
  IconButton,
  Menu,
  TableFooter,
  TablePagination,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  CircularProgress,
} from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Link, NavLink } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import { PayRollSingleWorkerAdd } from "src/app/@types/interfaces/paychex.interface";
import { paychexService } from "src/app/services/api/paychex.service";
import { showMessage } from "app/store/fuse/messageSlice";
import { connect } from "react-redux";

interface MyComponentProps extends WithTranslation {}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#202441",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

class SubmitSalaryDetails extends Component<
  MyComponentProps & { dispatch: any }
> {
  dataSet: any[] = [
    {
      id: 1,
      programName: "SYEP",
      year: "2023",
      date: "5/6/2023",
      companyID: "1",
      youthID: "2",
      hours: 3,
      userId: "648f4035c9e424433c4304ec",
    },
  ];

  // Define state variables and their types
  state = {
    page: 0,
    rowsPerPage: 10,
    actionMenu: null,
    isLoading: false,
    success: false,
    msg: "",
  };
  handleSubmit = async () => {
    const { dispatch } = this.props;
    this.setState({ isLoading: true });
    const paychexUserDetails = await paychexService.getPaychexUSerDataByUserId(
      this.dataSet[0].userId
    );
    const payrollData: PayRollSingleWorkerAdd = {
      workerId: paychexUserDetails.data.workerId,
      userId: this.dataSet[0].userId,
      payrollData: {
        payPeriodId: "1140032616439195",
        earnings: [
          {
            componentId: paychexUserDetails.data.componentId,
            payRate: 150,
            payHours: this.dataSet[0].hours,
          },
        ],
      },
    };
    const response = await paychexService.submitUserPayroll(payrollData);
    this.setState({ msg: response });
    dispatch(showMessage({ message: this.state.msg, variant: "success" }));
    this.setState({ isLoading: false });
  };
  // Initialize state variables using the useState hook
  setPage = (page: React.SetStateAction<number>) => {
    this.setState({ page });
  };

  setRowsPerPage = (rowsPerPage: number) => {
    this.setState({ rowsPerPage });
  };

  actionMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ actionMenu: event.currentTarget });
  };

  actionMenuClose = () => {
    this.setState({ actionMenu: null });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  emptyRows: number =
    this.state.page > 0
      ? Math.max(
          0,
          (1 + this.state.page) * this.state.rowsPerPage - this.dataSet.length
        )
      : 0;

  handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    this.setRowsPerPage(parseInt(event.target.value, 10));
    this.setPage(0);
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="m-24">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t("PROGRAM")}</StyledTableCell>
                  <StyledTableCell>{t("YEAR")}</StyledTableCell>
                  <StyledTableCell>{t("DATE")}</StyledTableCell>
                  <StyledTableCell>{t("COMPANY_ID")}</StyledTableCell>
                  <StyledTableCell>{t("YOUTH_ID")}</StyledTableCell>
                  <StyledTableCell>{t("HOURS")}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.dataSet.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>{row.programName}</StyledTableCell>
                    <StyledTableCell>{row.year}</StyledTableCell>
                    <StyledTableCell>{row.date}</StyledTableCell>
                    <StyledTableCell>{row.companyID}</StyledTableCell>
                    <StyledTableCell>{row.youthID}</StyledTableCell>
                    <StyledTableCell>{row.hours}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    colSpan={4}
                    count={this.dataSet.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            color="secondary"
            className="mt-24 min-w-96"
            aria-label="Register"
            type="submit"
            size="medium"
            onClick={this.handleSubmit}
          >
            {t("SUBMIT")}
            {this.state.isLoading && (
              <CircularProgress size={15} className="ml-10 text-white" />
            )}
            {this.state.success && <CheckIcon className="ml-10" />}
          </Button>
        </div>
      </>
    );
  }
}

export default withTranslation("submitSalaryDetails")(SubmitSalaryDetails);
