import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Paper, Typography } from "@mui/material";
import SignaturePad from "app/shared-components/SigningPad";
import { useParams } from "react-router-dom";
import { youthSignUpService } from "src/app/services/api/youth.signup.service";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import { YouthReviewAndSign } from "src/app/@types/interfaces/youth.review.and.sign";

function YouthSignaturePad() {
  const { handleSubmit } = useForm();
  const { t } = useTranslation("youthLoginPage");

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <Paper className="h-full sm:h-auto w-full sm:w-auto py-8 px-16 sm:p-48 sm:rounded-2xl sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="text-center">
          <img
            className="h-32 mx-auto mb-4"
            src="assets/images/logo/logo-ypms-full.png"
            alt="logo"
          />
          <Typography variant="h5" className="mb-4">
            Please enter your signature
          </Typography>
        </div>

        <form>
          <div className="flex justify-center">
            <div className="w-full max-w-lg">
              <SignaturePad
                canvasProps={{
                  className: "border rounded-lg p-2 w-full h-80 sm:h-96",
                  style: { maxHeight: "100%", maxWidth: "100%" },
                }}
              />
            </div>
          </div>
        </form>
      </Paper>
    </div>
  );
}

export default YouthSignaturePad;
