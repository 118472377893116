import IndexHeader from "app/shared-components/PageHeader";
import * as React from "react";
import AdminIncidentFormView from "./components/AdminIncidentFormView";

export default function AdminIncidentFormViewPage() {
    return (
    <>
      <AdminIncidentFormView />
    </>
  );
}