import React from "react";
import { Navigate } from "react-router-dom";
import { UserRole } from "src/app/@types/enums/user.roles";
import i18next from "i18next";

import english from "./i18n/english";
import korean from "./i18n/korean";
import spanish from "./i18n/spanish";
import HostMessages from "./host-message-page";

i18next.addResourceBundle("en", "HostMessages", english);
i18next.addResourceBundle("es", "HostMessages", spanish);
i18next.addResourceBundle("kr", "HostMessages", korean);

export const HostMessageRoutes = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "host-messages",
      element: <HostMessages />,
    },
  ],
};
