import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
const defaultValues = {
  all: "",
  returning: "",
  address_verified: "",
  address_unverified: "",
  posted_jobs: "",
  hired_youth: "",
  agree_submitted: "",
  agree_notsubmited: "",
};

const schema = yup.object().shape({});
export default function SelectHost() {
  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();
  const { t } = useTranslation("AdminMessages");

  return (
    <div className="m-32">
      <Typography className="ml-10 mt-16 font-bold text-black text-lg w-full text-left items-start">
        {t("SELECT_HOST")}
      </Typography>
      <div className="flex grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2  content-start">
        <div className="flex items-start  mt-0 ml-10 mr-10 mb-0">
          <Controller
            control={control}
            name="all"
            render={({ field }) => (
              <FormControlLabel
                value="all"
                control={<Checkbox />}
                label={t("ALL")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start  mt-0 ml-10 mr-10 mb-0">
          <Controller
            control={control}
            name="returning"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("RETURNING")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start  mt-0 ml-10 mr-10 mb-0">
          <Controller
            control={control}
            name="address_verified"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("ADDRESS_VERIFIED")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start  mt-0 ml-10 mr-10 mb-0">
          <Controller
            control={control}
            name="address_unverified"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("ADDRESS_UNVERIFIED")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start mt-0 ml-10 mr-10 mb-0">
          <Controller
            control={control}
            name="posted_jobs"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("POSTED_JOBS")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start  mt-0 ml-10 mr-10 mb-0">
          <Controller
            control={control}
            name="hired_youth"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("HIRED_YOUTH")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start  mt-0 ml-10 mr-10 mb-0">
          <Controller
            control={control}
            name="agree_submitted"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("AGREED_SUBMITTED")}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <div className="flex items-start  mt-0 ml-10 mr-10 mb-0">
          <Controller
            control={control}
            name="agree_notsubmited"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label={t("AGREED_NOT_SUBMITTED")}
                labelPlacement="end"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
