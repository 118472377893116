import React from "react";
import { Navigate } from "react-router-dom";
import AdminApprovalForm from "./admin-approvals";
import AdminApprovalsFormIndexPage from "./admin-approvals-index/AdminApprovalsIndexPage";
import AdminApprovalViewPage from "./admin-approval-view/AdminApprovalViewPage";
import AdminApprovalTransferViewPage from "./admin-approval-transfer/AdminApprovalTransferPage";
import AdminApprovalTerminationViewPage from "./apmin-approval-termination/AdminApprovalTerminationPage";
import { UserRole } from "src/app/@types/enums/user.roles";
import AdminApprovalViewPDF from "./admin-approval-view/components/AdminApprovalCommon";
import AdminWorkApprovalViewPage from "./admin-approval-view/AdminWorkApprovalViewPage";
import AdminSSNApprovalViewPage from "./admin-approval-view/AdminSSNApprovalViewPage";
import AdminResidencyApprovalViewPage from "./admin-approval-view/AdminResidencyApprovalViewPage";
import AdminAgeApprovalViewPage from "./admin-approval-view/AdminAgeApprovalViewPage";

export const AdminApprovalFormRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Admin],
  routes: [
    {
      path: "admin-approval-form",
      element: <AdminApprovalForm />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <AdminApprovalsFormIndexPage />,
        },
        {
          path: "view/:id",
          element: <AdminApprovalViewPage />,
        },
        {
          path: "pdf-view/:id",
          element: <AdminApprovalViewPDF />,
        },
        {
          path: "transfer-view/:id",
          element: <AdminApprovalTransferViewPage />,
        },
        {
          path: "termination-view/:id",
          element: <AdminApprovalTerminationViewPage />,
        },
        {
          path: "proof-of-work-view/:id",
          element: <AdminWorkApprovalViewPage />,
        },
        {
          path: "proof-of-ssn-view/:id",
          element: <AdminSSNApprovalViewPage />,
        },
        {
          path: "proof-of-residency-view/:id",
          element: <AdminResidencyApprovalViewPage />,
        },
        {
          path: "proof-of-age-view/:id",
          element: <AdminAgeApprovalViewPage />,
        },
      ],
    },
  ],
};
