import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import React from "react";
import { Card } from "@mui/material";

export default function MailDetails(props: MailData) {
  return (
    <>
      <div className="flex flex-col py-32 px-24">
        <div className="flex items-center w-full h-full">
          <Avatar src={props.avatar} />
          <div className="ml-16 min-w-0">
            <Typography className="font-semibold truncate">
              {props.username.split("<")[0].trim()}
            </Typography>
          </div>
        </div>
        <Typography
          className="flex mt-32 whitespace-pre-line leading-relaxed"
          variant="body2"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </>
  );
}
