import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  InputLabel,
  Button,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import history from "@history";
import IndexHeader from "app/shared-components/IndexHeader";
import CustomButton from "app/shared-components/CustomColorButton";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

//#region form declaration
const schema = yup.object().shape({
  worksite: yup.string(),
  supervisor: yup.string(),
});

const defaultValues = {
  worksite: "",
  supervisor: "",
};
//#endregion

function AssignSupervisorAdd() {
  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function onSubmit(data: any): void {
    history.push(`/sign-up/host/contact-info`);
  }

  const handleClick = () => {
    history.push("/assign-supervisor-index");
  };
  const myButtons = [
    <Button
      className="mx-8"
      variant="contained"
      disabled={false}
      startIcon={<CancelIcon />}
      onClick={handleClick}
    >
      {"Cancel"}
    </Button>,

    <CustomButton
      StartIcon={<SaveIcon />}
      className="mx-8"
      disabled={false}
      onClick={handleClick}
      name="Save"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];

  return (
    <>
      <IndexHeader
        name={"Assign Supervisor"}
        update={false}
        add={true}
        previousPath={"/assign-supervisor-index"}
        buttons={myButtons}
      />

      <div className="flex mt-10 mb-10 h-400 ">
        <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
          <div className="flex items-start justify-between">
            <form
              name="hostInfoForm"
              noValidate
              className="flex flex-col justify-center w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex items-start  gap-16 mt-16 ml-10 mr-10">

                <Controller
                  control={control}
                  name="worksite"
                  render={({ field }) => (
                    <FormControl className="w-1/3" size="small">
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Worksite
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        autoWidth
                        label="Worksite"
                      >
                        <MenuItem value={"10"}>Head Office</MenuItem>
                        <MenuItem value={"20"}>Finance Division</MenuItem>
                        <MenuItem value={"30"}>Marketing Division</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="supervisor"
                  render={({ field }) => (
                    <FormControl className="w-1/3" size="small">
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Supervisor (First Name + Last Name)
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        autoWidth
                        label="Supervisor (First Name + Last Name)"
                      >
                        <MenuItem value={"10"}>John Doe</MenuItem>
                        <MenuItem value={"20"}>Emma Watson</MenuItem>
                        <MenuItem value={"30"}>Andrew Smith</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
            </form>
          </div>
        </Paper>
      </div>
    </>
  );
}

export default AssignSupervisorAdd;
