import IndexHeader from "app/shared-components/PageHeader";
import * as React from "react";
import AdminApprovalTransferView from "./components/AdminApprovalTransferView";

export default function AdminApprovalTransferViewPage() {
    return (
    <>
      <AdminApprovalTransferView />
    </>
  );
}
