import IndexHeader from "app/shared-components/PageHeader";
import * as React from "react";
import AssignSupervisorAdd from "./components/AssignSupervisorAdd";

export default function AssignSupervisorAddPage() {
    return (
    <>
      {" "}
      <div className="m-10">
     
      <AssignSupervisorAdd />
      </div>
    </>
  );
}
