import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  Alert,
  Button,
  CircularProgress,
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import * as yup from "yup";
import _ from "@lodash";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import jwtService from "../../../auth/services/jwtService";
import withRouter from "@fuse/core/withRouter";
import InnerFooter from "app/shared-components/InnerFooter";
import AuthService from "../../../services/api/auth.service";
import history from "@history";
import { useDispatch } from "react-redux";
import { setUserData } from "app/store/userSlice";
import { useTranslation } from "react-i18next";
/**
 * Form Validation Schema
 */
import { authDataService } from "../../../services/data/auth.data.service";
import { UserRole } from "../../../../app/@types/enums/user.roles";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("VALID_EMAIL_ERROR")
    .required("MUST_ENTER_EMAIL_ERROR"),
  password: yup.string().required("PASSWORD_ERROR").min(4, "PASSWORD_SHORT"),
});

const defaultValues = {
  email: "",
  password: "",
  remember: true,
};

function SignInPage(props) {
  const { t } = useTranslation("signInPage");

  const [isLoading, setIsLoading] = useState(false);

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    setValue("email", "admin@ypms.com", {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("password", "admin", { shouldDirty: true, shouldValidate: true });
  }, [setValue]);

  const dispatch = useDispatch();

  const onSubmit = async ({ email, password }) => {
    const authService = new AuthService();
    setIsLoading(true);
    try {
      const { message, is2FAEnabled, tokenPayload } = await authService.signIn(
        email,
        password
      );

      if (is2FAEnabled) {
        history.push("/two-factor-verification-select");
      } else {
        const userState = {
          role: [tokenPayload.roleCode],
          data: {
            displayName: tokenPayload.userName,
            email: tokenPayload.email,
            role: tokenPayload.roleName,
            photoURL:
              authDataService.userRoleId === UserRole.Admin
                ? "assets/images/avatars/male-14.jpg"
                : authDataService.userRoleId === UserRole.Youth
                ? "assets/images/avatars/male-07.jpg"
                : authDataService.userRoleId === UserRole.Host
                ? "assets/images/avatars/male-11.jpg"
                : "assets/images/avatars/brian-hughes.jpg",
          },
        };
        Promise.all([dispatch(setUserData(userState))]).then((values) => {
          history.push("/home");
        });
      }
    } catch (error) {}
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            className="h-52"
            src="assets/images/logo/logo-ypms-full.png"
            alt="logo"
          />

          <Typography className="mt-16 text-3xl font-semibold tracking-tight leading-tight">
            {t("SIGN_IN")}
          </Typography>

          <div className="flex items-baseline mt-16">
            <Typography className="font-semibold">
              {t("DONT_HAVE_ACCOUNT")}
            </Typography>
            <Link className="ml-4" to="/sign-up">
              {t("SIGNUP")}
            </Link>
          </div>

          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32 mb-24"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label={t("EMAIL")}
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={t(errors?.email?.message ?? "")}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label={t("PASSWORD")}
                  type="password"
                  error={!!errors.password}
                  helperText={t(errors?.password?.message ?? "")}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
              <Controller
                name="remember"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormControlLabel
                      label={t("REMEMBER_ME")}
                      control={<Checkbox size="small" {...field} />}
                    />
                  </FormControl>
                )}
              />

              <Link
                className="text-md font-medium"
                to="/pages/auth/forgot-password"
              >
                {t("FORGOT_PASSWORD")}
              </Link>
            </div>

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-16"
              aria-label="Sign in"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
            >
              {t("SIGN_IN")}
              {isLoading && <CircularProgress size={15} className="ml-10" />}
            </Button>
          </form>
          <InnerFooter />
        </div>
      </Paper>

      <Box
        className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
        sx={{ backgroundColor: "primary.main" }}
      >
        <svg
          className="absolute inset-0 pointer-events-none"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: "primary.light" }}
            className="opacity-20"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
            <circle r="234" cx="790" cy="0" />
            <circle r="234" cx="210" cy="450" />
          </Box>
        </svg>
        <Box
          component="svg"
          className="absolute -top-64 -right-64 opacity-20"
          sx={{ color: "primary.light" }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width="220"
            height="192"
            fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
          />
        </Box>

        <div className="z-10 relative w-full max-w-2xl">
          <Typography className="text-7xl font-bold leading-none text-gray-100">
            Welcome to YPMS
          </Typography>
          <Typography className="mt-24 text-xl tracking-tight leading-6 text-gray-400">
            Streamline and Enhance the Youth Employment Program Experience with
            our Innovative Web Application. Connect Participants, Employers, and
            Program Administrators Seamlessly for Efficient Job Placement and
            Skill Development.
          </Typography>
          <div className="flex items-center mt-32">
            <AvatarGroup
              sx={{
                "& .MuiAvatar-root": {
                  borderColor: "primary.main",
                },
              }}
            >
              <Avatar src="assets/images/avatars/female-18.jpg" />
              <Avatar src="assets/images/avatars/female-11.jpg" />
              <Avatar src="assets/images/avatars/male-09.jpg" />
              <Avatar src="assets/images/avatars/male-16.jpg" />
              <Avatar src="assets/images/avatars/male-17.jpg" />
            </AvatarGroup>

            <div className="ml-16 font-medium tracking-tight text-gray-400">
              More than 17k people joined us, it's your turn
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default withRouter(SignInPage);
