import { YouthContactInformationAdd } from "./../../@types/interfaces/youth.contact.information";
import axios from "axios";
import { authDataService } from "../data/auth.data.service";
import { CoreService } from "./core.service";
import { User } from "src/app/@types/interfaces/user.interface";
import {
  YouthPersonalDetails,
  YouthPersonalDetailsAdd,
} from "src/app/@types/interfaces/youth.personal.details";
import {
  YouthEducationalDetails,
  YouthEducationalDetailsAdd,
} from "src/app/@types/interfaces/youth.educational.details";
import { YouthContactInformation } from "src/app/@types/interfaces/youth.contact.information";
import { ResponseData } from "src/app/@types/interfaces/get.response";
import {
  YouthNotificationInformation,
  YouthNotificationInformationAdd,
} from "src/app/@types/interfaces/youth.notification.information";
import {
  YouthCareerInterest,
  YouthCareerInterestAdd,
} from "src/app/@types/interfaces/youth.career.interests";
import {
  YouthSupplementalInformation,
  YouthSupplementalInformationAdd,
} from "src/app/@types/interfaces/youth.supplemental.information";
import {
  YouthReviewAndSign,
  YouthReviewAndSignAdd,
} from "src/app/@types/interfaces/youth.review.and.sign";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export default class YouthSignUpService extends CoreService {
  async addPersonalDetails(
    youthPersonalDetails: YouthPersonalDetailsAdd
  ): Promise<string> {
    const baseOrigin = window.location.origin;
    return new Promise((resolve, reject) => {
      this.axios
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/youth/personal-details/create-update`,
          {
            ...youthPersonalDetails,
          }
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(message);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }
  async getPersonalDetailsByUserId(
    userId?: string
  ): Promise<ResponseData<YouthPersonalDetails>> {
    return new Promise((resolve, reject) => {
      this.axios
        .get(
          `${process.env.REACT_APP_BASE_API_URL}/youth/personal-details/${userId}`
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(response.data);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }
  async addEducationalDetails(
    youthEducationalDetails: YouthEducationalDetailsAdd
  ): Promise<string> {
    const baseOrigin = window.location.origin;
    return new Promise((resolve, reject) => {
      this.axios
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/youth/personal-details/create-update`,
          {
            ...youthEducationalDetails,
          }
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(message);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }

  async addContactDetails(
    youthContactDetails: YouthContactInformationAdd
  ): Promise<string> {
    const baseOrigin = window.location.origin;
    return new Promise((resolve, reject) => {
      this.axios
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/youth/contact-info/create-update`,
          {
            ...youthContactDetails,
          }
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(message);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }

  async getContactDetailsByUserId(
    userId?: string
  ): Promise<ResponseData<YouthContactInformation>> {
    return new Promise((resolve, reject) => {
      this.axios
        .get(
          `${process.env.REACT_APP_BASE_API_URL}/youth/contact-info/${userId}`
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(response.data);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }

  async addNotificationDetails(
    youthNotificationDetails: YouthNotificationInformationAdd
  ): Promise<string> {
    const baseOrigin = window.location.origin;
    return new Promise((resolve, reject) => {
      this.axios
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/youth/contact-info/create-update`,
          {
            ...youthNotificationDetails,
          }
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(message);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }

  async addCareerInterest(
    youthCareerInterest: YouthCareerInterestAdd
  ): Promise<string> {
    const baseOrigin = window.location.origin;
    return new Promise((resolve, reject) => {
      this.axios
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/youth/career-interest/create-update`,
          {
            ...youthCareerInterest,
          }
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(message);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }

  async getCareerInterestByUserId(
    userId?: string
  ): Promise<ResponseData<YouthCareerInterest>> {
    return new Promise((resolve, reject) => {
      this.axios
        .get(
          `${process.env.REACT_APP_BASE_API_URL}/youth/career-interest/${userId}`
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(response.data);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }

  async addSupplementalInformation(
    youthEducationalDetails: YouthSupplementalInformationAdd
  ): Promise<string> {
    const baseOrigin = window.location.origin;
    return new Promise((resolve, reject) => {
      this.axios
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/youth/supplemental-info/create-update`,
          {
            ...youthEducationalDetails,
          }
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(message);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }

  async getSupplementalInformationByUserId(
    userId?: string
  ): Promise<ResponseData<YouthSupplementalInformation>> {
    return new Promise((resolve, reject) => {
      this.axios
        .get(
          `${process.env.REACT_APP_BASE_API_URL}/youth/supplemental-info/${userId}`
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(response.data);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }

  async addReviewAndSignInformation(
    youthReviewAndSignInformation: YouthReviewAndSignAdd
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.axios
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/youth/review-info/create-update`,
          {
            ...youthReviewAndSignInformation,
          }
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(message);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }

  async getReviewAndSignInformationByUserId(
    userId?: string
  ): Promise<ResponseData<YouthReviewAndSign>> {
    return new Promise((resolve, reject) => {
      this.axios
        .get(
          `${process.env.REACT_APP_BASE_API_URL}/youth/review-info/${userId}`
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(response.data);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }
}
export const youthSignUpService = new YouthSignUpService();
