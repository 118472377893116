import React, { Component, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import BackIcon from "@mui/icons-material/Backspace";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Card,
  Typography,
  FormHelperText,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import IndexHeader from "app/shared-components/PageHeader";
import { useParams } from "react-router-dom";
import YouthImportantDaysTable from "./components/youthImportantDaysTable";

const defaultValues = {
  programStartDate: "",
  programEndDate: "",
  registrationStartDate: "",
  registrationEndDate: "",
  eligibilityStartDate: "",
  eligibilityEndDate: "",
  certificationStartDate: "",
  certificationEndDate: "",
  orientationStartDate: "",
  orientationEndDate: "",
  bankingStartDate: "",
  bankingEndDate: "",
  jobApplicationStartDate: "",
  jobApplicationEndDate: "",
  resumeUploadStartDate: "",
  resumeUploadEndDate: "",
  description: "",
  date: "",
};

const schema = yup.object().shape({});

export default function YouthImportantDays() {
  const routeParams = useParams();
  const { id } = routeParams;

  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  const [values, setValue] = useState({
    employeeType: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue({
      ...values,
      [name]: value,
    });
  };

  return (
    <>
      <Card variant="outlined" className="m-24 mb-0">
        <div className="m-24 mt-8">
          <div className="flex grid lg:grid-cols-2 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start">
            <div className="flex">
              <Controller
                control={control}
                name="programStartDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Program Start Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>
            <div className="flex">
              <Controller
                control={control}
                name="programEndDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Program End Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="registrationStartDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Registration Start Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>
            <div className="flex">
              <Controller
                control={control}
                name="registrationEndDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Registration End Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="eligibilityStartDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Eligibility Start Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>
            <div className="flex">
              <Controller
                control={control}
                name="eligibilityEndDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Eligibility End Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="certificationStartDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Certification Start Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>
            <div className="flex">
              <Controller
                control={control}
                name="certificationEndDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Certification End Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="orientationStartDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Orientation Start Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>
            <div className="flex">
              <Controller
                control={control}
                name="orientationEndDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Orientation End Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="bankingStartDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Banking Start Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>
            <div className="flex">
              <Controller
                control={control}
                name="bankingEndDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Banking End Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="jobApplicationStartDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Job Application Start Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>
            <div className="flex">
              <Controller
                control={control}
                name="jobApplicationEndDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Job Application End Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="resumeUploadStartDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Resume Upload Start Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>
            <div className="flex">
              <Controller
                control={control}
                name="resumeUploadEndDate"
                render={({ field }) => (
                  <DatePicker
                    className="mt-16 w-full"
                    label="Resume Upload EndDate"
                    slotProps={{ textField: { size: "small" } }}
                  />
                )}
              />
            </div>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Description"
                  placeholder="Description"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.description}
                  helperText={errors?.description?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <DatePicker
                  className="mt-16 w-full"
                  label="Date"
                  slotProps={{ textField: { size: "small" } }}
                />
              )}
            />
          </div>
          <div className="flex mt-24">
            <Button
              className="mx-8"
              variant="contained"
              color="secondary"
              disabled={false}
            >
              Add
            </Button>
          </div>
          <YouthImportantDaysTable />
          <div className="flex items-center justify-end mt-32">
            <Button
              className="mx-8"
              variant="contained"
              color="secondary"
              disabled={false}
            >
              Save
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
}
