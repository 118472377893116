const locale = {
    INTRODUCTION: 'Introducción',
    INTRODUCTION_CONTENT:'El Programa de Empleo Juvenil es una iniciativa transformadora diseñada para empoderar a los jóvenes y equiparlos con las habilidades, la experiencia y las oportunidades que necesitan para tener éxito en la fuerza laboral. Reconociendo el potencial de la juventud como una fuerza impulsora para el crecimiento económico y el desarrollo social, este programa tiene como objetivo cerrar la brecha entre la educación y el empleo, allanando el camino para un futuro próspero. En el competitivo mercado laboral actual, muchos jóvenes enfrentan desafíos cuando intentan ingresar a la fuerza laboral debido a la falta de experiencia o habilidades.El Programa de Empleo Juvenil aborda estas barreras al proporcionar apoyo y recursos integrales para ayudar a los participantes a superar estos obstáculos y alcanzar sus objetivos profesionales. Al invertir en el desarrollo y el empoderamiento de los jóvenes, no solo fomentamos su éxito individual, sino que también contribuimos al crecimiento general y al bienestar de nuestras comunidades.',
    PROGRAM_OBJECTIVES :'Objetivos del programa',
    OBJECTIVE_CONTENT :'El Programa de Empleo Juvenil tiene tres objetivos básicos. En primer lugar, el programa se centra en el desarrollo de habilidades, ofreciendo talleres, sesiones de capacitación y experiencias prácticas para mejorar las habilidades de los participantes. Al equiparlos con habilidades esenciales como la comunicación, la resolución de problemas y el trabajo en equipo, les permitimos convertirse en contribuyentes adaptables y valiosos en los campos elegidos. En segundo lugar, el programa ofrece a los participantes experiencias laborales significativas.  A través de pasantías, aprendizajes o trabajos de verano, los participantes obtienen exposición a entornos de trabajo del mundo real, lo que les permite aplicar sus habilidades y conocimientos en entornos prácticos. Estas experiencias no solo generan confianza, sino que también fomentan una fuerte ética de trabajo y una red profesional. Por último, el programa proporciona orientación y apoyo profesional crucial.   Los participantes son emparejados con mentores que ofrecen orientación, comparten su experiencia y ayudan a navegar por las complejidades de la planificación de carrera. A través de la tutoría, el asesoramiento profesional y las actividades de exploración, los participantes obtienen claridad sobre sus trayectorias profesionales, toman decisiones informadas y acceden a recursos que les permiten alcanzar sus objetivos de manera efectiva.',
    IMPACT:'Impacto e historias de éxito',
    IMPACT_CONTENT:'El Programa de Empleo Juvenil ha sido testigo de un impacto significativo y casos de éxito. Los participantes que han completado el programa han asegurado empleo en diversas industrias, han seguido una educación superior y se han convertido en líderes en sus comunidades. Su éxito no solo refleja su crecimiento individual, sino que también demuestra la capacidad de los programas para empoderar a los jóvenes y desbloquear su potencial. Además, el impacto se extiende más allá de las historias de éxito individuales. A medida que los participantes se convierten en miembros productivos de la fuerza laboral, contribuyen al crecimiento económico y la estabilidad de sus regiones. El énfasis de los programas en el desarrollo de una fuerza laboral calificada crea un efecto dominó positivo, beneficiando a las empresas, las comunidades y la sociedad en general.',
    CONCLUSION:'Conclusión',
    CONCLUSION_CONTENT:'El Programa de Empleo Juvenil es una inversión invaluable en el futuro. Al proporcionar a los jóvenes las habilidades, la experiencia y la orientación que necesitan, estamos fomentando una fuerza laboral calificada, fomentando el crecimiento económico y creando un futuro más brillante para nuestras comunidades. Juntos, podemos empoderar a los jóvenes y liberar todo su potencial a medida que se convierten en los arquitectos de un mañana próspero.',

};
  
export default locale;