const locale = {
  SALARY_DETAILS: "Detalles del salario",
  PROGRAME: "programa",
  COMPANY: "Compañía",
  YEAR: "Año",
  WEEK: "Semana",
  VIEW_BTN: "Vista",
  DOWNLOAD_BTN: "Descargar",
  HOURS_WORKED: "Horas trabajadas",
  HOURLY_RATE: "Tarifa por hora",
  TOTAL: "Total",
};
export default locale;
