import React from "react";
import TimeSheetPage from "./timesheet-index/TimeSheetPage";
import { UserRole } from "src/app/@types/enums/user.roles";

export const TimeSheetRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Host],
  routes: [
    {
      path: "timesheet",
      element: <TimeSheetPage />,
    },
  ],
};
