import React, { Component } from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import IndexHeader from "app/shared-components/PageHeader";
import {
  Button,
  Divider,
  IconButton,
  Card,
  TableFooter,
  TablePagination,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material";
import pdf from '../../../../../assets/pdf/fake CV.pdf';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { withTranslation, WithTranslation } from "react-i18next";
import { BorderAll } from "@mui/icons-material";

interface MyComponentProps extends WithTranslation {
    showPopup: boolean;
  }
  
  interface MyComponentState {
    page: any;
    rowsPerPage: number;
    actionMenu: EventTarget & HTMLButtonElement | null;
    isPopupOpen: boolean;
    selectedRow: any;
  }

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#202441",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));





 class HostJobApplicationTable extends Component<
  MyComponentProps,
  MyComponentState
> {
  state: MyComponentState = {
    page: 0,
    rowsPerPage: 10,
    actionMenu: null,
    isPopupOpen: false,
    selectedRow: null,
  };
  
  setPage = (page: React.SetStateAction<number>) => {
    this.setState({ page });
  };

  setRowsPerPage = (rowsPerPage: number) => {
    this.setState({ rowsPerPage });
  };

  actionMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ actionMenu: event.currentTarget });
  };

  actionMenuClose = () => {
    this.setState({ actionMenu: null });
  };


  dataSet: any[] = [
    {
      id: 1,
      programName: "SYEP",
      year: "2023",
      jobTitle:"Front Desk Assistant",
      hourlyRate:"16",
      openPositions:"3",
      positionsAssigned:"1",
      fullName: "Andrew Simon",
      status: "App.Pending",
    },
    {
        id: 2,
        programName: "SYEP",
        year: "2023",
        jobTitle:"Finance Assistance",
        hourlyRate:"14",
        openPositions:"2",
        positionsAssigned:"0",
        fullName: "Peter Parker",
        status: "App.Pending",
    },
    {
        id: 3,
        programName: "SYEP",
        year: "2023",
        jobTitle:"Marketing Assistance",
        hourlyRate:"16",
        openPositions:"3",
        positionsAssigned:"1",
        fullName: "Moana Smith",
        status: "Hired",
    },
    {
        id: 4,
        programName: "ISYP",
        year: "2023",
        jobTitle:"Front Desk Assistant",
        hourlyRate:"16",
        openPositions:"3",
        positionsAssigned:"1",
        fullName: "Emma Watson",
        status: "Rejected",
    },
  ];

  // Avoid a layout jump when reaching the last page with empty rows.
  emptyRows: number =
    this.state.page > 0
      ? Math.max(
          0,
          (1 + this.state.page) * this.state.rowsPerPage - this.dataSet.length
        )
      : 0;

  handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    this.setRowsPerPage(parseInt(event.target.value, 10));
    this.setPage(0);
  };

  handleClickView = (row: any) => {
    this.setState({
      isPopupOpen: true,
      selectedRow: row,
    });
  };

  handleClosePopup = () => {
    this.setState({
      isPopupOpen: false,
      selectedRow: null,
    });
  };

  handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdf;
    link.download = 'sample.pdf';
    link.click();
  };

  handleAccept = (row: any) => {
    const updatedDataSet = this.dataSet.map((data) =>
      data.id === row.id ? { ...data, status: "Accept" } : data
    );
    this.dataSet = updatedDataSet;
    this.forceUpdate(); 
  };

  render() {
    const { isPopupOpen, selectedRow } = this.state;
    const { t } = this.props;
    return (
      <>
        <div className="m-24">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t("PROGRAM")}</StyledTableCell>
                  <StyledTableCell>{t("YEAR")}</StyledTableCell>
                  <StyledTableCell>{t("JOB_TITLE")}</StyledTableCell>
                  <StyledTableCell>{t("HOURLY_RATE")}</StyledTableCell>
                  <StyledTableCell>{t("OPEN_POSITIONS")}</StyledTableCell>
                  <StyledTableCell>{t("POSITIONS_ASSIGNED")}</StyledTableCell>
                  <StyledTableCell>{t("FULL_NAME")}</StyledTableCell>
                  <StyledTableCell>{t("STATUS")}</StyledTableCell>
                  <StyledTableCell>{t("ACTION")}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.dataSet.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="left">{row.programName}</StyledTableCell>
                    <StyledTableCell align="left">{row.year}</StyledTableCell>
                    <StyledTableCell align="left">{row.jobTitle}</StyledTableCell>
                    <StyledTableCell align="center">{row.hourlyRate}</StyledTableCell>
                    <StyledTableCell align="center">{row.openPositions}</StyledTableCell>
                    <StyledTableCell align="center">{row.positionsAssigned}</StyledTableCell>
                    <StyledTableCell align="left">{row.fullName}</StyledTableCell>
                    <StyledTableCell align="left">{row.status}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        className="-m-8"
                      >
                          <Button  
                            size="small"
                            style={{ borderRadius: 5 ,backgroundColor:"lightblue"} }
                            onClick={() => this.handleClickView(row)}
                          >
                            {t("VIEW")}
                          </Button>
                        
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    colSpan={4}
                    count={this.dataSet.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        {isPopupOpen && (
          <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-opacity-100 z-50 p-6 rounded-md'>
            <Card variant='outlined'  >
            <IconButton
                className="absolute top-0 right-0 "
                onClick={this.handleClosePopup}
                size="small"
             >
                <CancelIcon />
            </IconButton>
            
            <div className="popup-content">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '685px', border: '3px solid gray', height:'450px', margin:"20px"}}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                    <Viewer fileUrl={pdf} />
                    </Worker>
                </div>
            </div>
            <Stack  justifyContent="center" spacing={5} direction="row" margin="auto" width="685px" marginBottom="5px">
                <Button className="rounded-4 "variant="contained"color="secondary"disabled={false} size="small" fullWidth onClick={this.handleDownload}>{t("DOWNLOAD")}</Button>
                <Button className="rounded-4 "variant="contained"color="secondary"disabled={false} size="small" fullWidth>{t("SHORT")}</Button>
                <Button className="rounded-4 "variant="contained"color="secondary"disabled={false} size="small" fullWidth>{t("INTERVIEW")}</Button>
                <Button className="rounded-4 "variant="contained"color="secondary"disabled={false} size="small" fullWidth onClick={this.handleAccept}>{t("ACCEPT")}</Button>
                <Button className="rounded-4 "variant="contained"color="secondary"disabled={false} size="small" fullWidth>{t("REJECT")}</Button>
            </Stack>
            </div>
            </Card>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation("hostJobApplication")(HostJobApplicationTable);


