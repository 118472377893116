import React from "react";
import { authRoles } from "src/app/auth";
import SignUp from "./SignUp";
import HostSignUpRoutes from "./host-sign-up/HostSignUp.Routes";
import HostSignUp from "./host-sign-up/HostSignUp";
import YouthSignUp from "./youth-sign-up/YouthSignUp";
import YouthSignUpRoutes from "./youth-sign-up/YouthSignUp.Routes";

export const SignUpRoutes = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null,
  routes: [
    {
      path: "sign-up",
      element: <SignUp />,
    },
    {
      path: "sign-up/host",
      element: <HostSignUp />,
      children: [...HostSignUpRoutes],
    },
    {
      path: "sign-up/youth",
      element: <YouthSignUp />,
      children: [...YouthSignUpRoutes],
    },
  ],
};
