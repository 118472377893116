import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  FormGroup,
} from "@mui/material";
import * as yup from "yup";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import React from "react";
import { useTranslation } from "react-i18next";

function SampleQuestion(props: any) {
  const defaultValues = {
    question: "",
  };
  const schema = yup.object().shape({
    question: yup.string().required("This field is required"),
  });
  const { control, handleSubmit, watch, formState, register } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();
  return (
    <div className="space-y-32 m-24">
      <div>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            {props.question}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            className="space-x-24"
          >
            <FormControlLabel
              value={props.radOne}
              control={<Radio />}
              label={props.radOne}
              {...register("question")}
            />
            <FormControlLabel
              value={props.radTwo}
              control={<Radio />}
              label={props.radTwo}
            />
            <FormControlLabel
              value={props.radThree}
              control={<Radio />}
              label={props.radThree}
            />
            <FormControlLabel
              value={props.radFour}
              control={<Radio />}
              label={props.radFour}
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
}

export default SampleQuestion;
