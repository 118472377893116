import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import BackIcon from "@mui/icons-material/Backspace";
import { FormControl, InputLabel, Select, IconButton, Card, MenuItem } from "@mui/material";

const defaultValues = { subject: ""};
const schema = yup.object().shape({
  
    subject: yup.string(),
  
});

function AdminApprovalFormFilter() {
  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  if (_.isEmpty(form)) {
    return null;
  }

  return (
    <>

      <Card variant="outlined" className="m-24 mb-0">
        <div className="flex content-between justify-start m-24">
        <div className="w-1/3  mr-16">
            <Controller
              control={control}
              name="subject"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Search by Participant Name (First + Last)"
                  placeholder="Search by Participant Name (First + Last)"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.subject}
                  helperText={errors?.subject?.message}
                  
                />
              )}
            />
          </div>

          <div className="w-1/3 mt-16 mr-16">
            <Box sx={{ minWidth: 120 }} >
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                Year
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select status"
                  
                >
                  <MenuItem value={"1"}>2023</MenuItem>
                  <MenuItem value={"2"}>2022</MenuItem>
                  <MenuItem value={"3"}>2021</MenuItem>
                  <MenuItem value={"4"}>2020</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="w-1/3 mt-16 mr-16">
            <Box sx={{ minWidth: 120 }} >
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select status"
                  
                >
                  <MenuItem value={"1"}>Parent Consent Form</MenuItem>
                  <MenuItem value={"2"}>Proof of Age</MenuItem>
                  <MenuItem value={"3"}>Proof of DC Residency</MenuItem>
                  <MenuItem value={"4"}>Proof of SSN</MenuItem>
                  <MenuItem value={"5"}>Proof of Work</MenuItem>
                  <MenuItem value={"6"}>Transfer Form</MenuItem>
                  <MenuItem value={"7"}>Termination Form</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="mt-20">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <SearchIcon />
            </IconButton>
          </div>
          <div className="mt-20  mr-1">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <BackIcon />
            </IconButton>
          </div>
        </div>
      </Card>

    </>
  );
}

export default AdminApprovalFormFilter;
