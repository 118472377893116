const locale = {
    INTRODUCTION: 'Introduction',
    INTRODUCTION_CONTENT:'The Youth Employment Program is a transformative initiative designed to empower young individuals and equip them with the skills, experience, and opportunities they need to succeed in the workforce. Recognizing the potential of youth as a driving force for economic growth and societal development, this program aims to bridge the gap between education and employment, paving the way for a prosperous future. In todays competitive job market, many young individuals face challenges when trying to enter the workforce due to a lack of experience or skills. The Youth Employment Program addresses these barriers by providing comprehensive support and resources to help participants overcome these obstacles and achieve their career goals. By investing in the development and empowerment of youth, we not only foster their individual success but also contribute to the overall growth and well-being of our communities.',
    PROGRAM_OBJECTIVES :'Program Objectives',
    OBJECTIVE_CONTENT :'The Youth Employment Program has three core objectives. Firstly, the program focuses on skill development, offering workshops,training sessions, and hands-on experiences to enhance participants abilities. By equipping them with essential skills such as communication, problem-solving, and teamwork, we enable them to become adaptable and valuable contributors in their chosen fields. Secondly, the program offers participants meaningful work experiences. Through internships, apprenticeships, or summer jobs, participants gain exposure to real-world work environments, allowing them to apply their skills and knowledge in practical settings. These experiences not only build confidence but also foster a strong work ethic and professional network. Lastly, the program provides crucial career guidance and support. Participants are paired with mentors who offer guidance, share their expertise, and assist in navigating the complexities of career planning. Through mentorship, career counseling, and exploration activities, participants gain clarity about their career paths, make informed decisions, and access resources that enable them to pursue their goals effectively.',
    IMPACT:'Impact and Success Stories',
    IMPACT_CONTENT:'The Youth Employment Program has witnessed significant impact and success stories. Participants who have completed the program have gone on to secure employment in various industries, pursued higher education, and become leaders in their communities. Their success not only reflects their individual growth but also demonstrates the programs ability to empower youth and unlock their potential. Moreover, the impact extends beyond individual success stories. As participants become productive members of the workforce, they contribute to the economic growth and stability of their regions. The programs emphasis on developing a skilled workforce creates a positive ripple effect, benefiting businesses, communities, and society at large. ',
    CONCLUSION:'Conclusion',
    CONCLUSION_CONTENT:'The Youth Employment Program is an invaluable investment in the future. By providing young individuals with the skills, experience, and guidance they need, we are nurturing a skilled workforce, fostering economic growth, and creating a brighter future for our communities. Together, we can empower youth and unlock their full potential as they become the architects of a prosperous tomorrow.',

};
  
export default locale;