import React from "react";
import { Navigate } from "react-router-dom";
import AdminYouthList from "./admin-youth-list";
import AdminYouthListIndexPage from "./admin-youth-list-index/AdminYouthListIndexPage";
import { UserRole } from "src/app/@types/enums/user.roles";
import i18next from "i18next";
import english from "./i18n/english";

i18next.addResourceBundle("en", "youthList", english);

export const AdminYouthListRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Admin],
  routes: [
    {
      path: "youth-details/admin-youth-list",
      element: <AdminYouthList />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <AdminYouthListIndexPage />,
        },
        
      ],
    },
  ],
};
