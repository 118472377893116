const locale = {
  CREATE_USER: "ፍጠር",
  GENERATE_QR: "QR ፍጠር",
  SIGNIN: "ስግን እን",
  SIGNUP: "ተመዝገብ",
  LOGIN_INFORMATION: "የመግቢያ መረጃ",
  EMAIL_ADDRESS: "ኢሜይል አድራሻ",
  CONFIRM_EMAIL_ADDRESS: "የኢሜይል አድራሻ አረጋግጥ",
  PASSWORD: "የይለፍ ቃል",
  CONFIRM_PASSWORD: "የይለፍ ቃል አረጋግጥ",

  //Error messages
  EMAIL_ADDRESS_NOT_MATCHED_ERROR: "የኢሜይል አድራሻ አይጣጣምም",
  EMAIL_NOT_VALID: "ትክክለኛ የኢሜይል አድራሻ ያስገቡ",
  PASSWORD_NOT_MATCHED_ERROR: "የይለፍ ቃል አይጣጣምም",
  EMAIL_PRIMARY_ERROR:
    "ይህ የኢሜይል አድራሻ ከእርስዎ ጋር ለመግባባት ዋና መንገድ ነው. እንግዲህ የግለሰብ ኢሜይል አድራሻ ከሌለዎት እባክዎ አንድ ቀደም ሲል ይፍጠሩ",
  CONFIRM_EMAIL_ERROR: "አስፈላጊውን የኢሜይል አድራሻ አረጋግጥ",
  CONFIRM_PASSWORD_ERROR: "የይለፍ ቃል ያስፈልጋል",

  //Button
  Next: "ቀጣይ",
  Back: "ወደ ኋላ",

  //#PersonalInfo
  PERSONAL_DETAILS: "የግል ዝርዝሮች",

  //Error Message
  LEGAL_FIRST_NAME_ERROR: "እባክዎን የሕግ የመጀመሪያ ስምዎን ይጨምሩ",
  LEGAL_LAST_NAME_ERROR: "እባክዎን የሕግ የመጨረሻ ስምዎን ይጨምሩ",
  MIDDLE_NAME_ERROR: "እባክህ መካከለኛ ስምህን ጨምር",
  MAIDEN_NAME_ERROR: "እባክህ የሴት ስምህን ጨምር",

  SSN_ERROR: "SSN ያስፈልጋል",
  CONFIRM_SSN_ERROR: "ማረጋገጥ SSN ያስፈልጋል",
  SSN_NOT_MATCHED_ERROR: "SSN አይጣጣምም",

  LEGAL_FIRST_NAME: "ሕጋዊ የመጀመሪያ ስም",
  LEGAL_LAST_NAME: "ሕጋዊ የመጨረሻ ስም",
  MIDDLE_NAME: "መካከለኛ ስም",
  MAIDEN_NAME: "የልጃገረድ ስም",
  DATE_OF_BIRTH: "የትውልድ ቀን",
  LANGUAGE: "ቋንቋ",
  GENDER: "ፆታ",
  RACE: "ዘር",
  SUFFIX: "ሱፊክስ",
  T_SHIRT_SIZE: "ቲ-ሸርት መጠን",
  SOCIAL_SECURITY_NUMBER: "ማህበራዊ ዋስትና ቁጥር",
  CONFIRM_SSN: "SSN አረጋግጥ",

  //-----------END of PersonalInfoPage------------

  //#EducationalInfo
  //Error Message
  REQUIRED_ERROR: "ይህ መስክ መሞላት አለበት",

  EDUCATIONAL_DETAILS: "የትምህርት ዝርዝር",
  CURRENT_EDUCATIONAL_STATUS: "ወቅታዊ የትምህርት ደረጃ",
  EDUCATIONAL_STUDENT_TYPE: "የትምህርት ተማሪ አይነት",
  CURRENT_GRADE: "የአሁኑ ክፍል ወይም የመጨረሻ ክፍል ተገኝ",
  CURRENT_SCHOOL: "የአሁኑ ትምህርት ቤት ወይም የመጨረሻው ትምህርት ቤት ተከታተሉ",

  //----------END of EducationalInfoPage---------------

  //#ContactInfo
  CONTACT_INFORMATION: "አገናኝ መረጃ",
  PERMENANT_ADDRESS_DETAILS: "ቋሚ አድራሻ ዝርዝር",
  ADDRESS_LINE: "አድራሻ መስመር",
  APT: "አፓርትመንት ቁጥር ወይም Suite",
  STATE: "ሀገር",
  CITY: "ከተማ",
  ZIP: "ዚፕ ወይም የፖስታ ኮድ",
  CELL_PHONE: "ሞባይል ስልክ",
  HOME_PHONE: "የቤት ስልክ",
  PARENT: "ወላጅ ወይም ጠባቂ",
  FIRST_NAME: "የመጀመሪያው ስም",
  LAST_NAME: "የመጨረሻ ስም",
  WORK_PHONE: "የስራ ስልክ",
  RELATIONSHIP: "ግንኙነት",
  EMERGENCY: "ድንገተኛ አደጋ",
  PHONE_NUMBER: "ስልክ ቁጥር",

  //--------END OF CONTACT INFO PAGE--------

  //#NotificationInfo
  NOTIFICATION_INFORMATION: "የማሳወቂያ መረጃ",
  NOTIFICATION_DETAILS: "ማሳወቂያ ዝርዝር",
  TWITTER_USER_NAME: "ትዊተር የተጠቃሚ ስም@",
  CONTACT_TEXT_MESSAGE: "በሞባይል መልእክት አማካኝነት እናነጋግርህ?",
  COMPUTER_STATUS: "ኮምፒውተር አለህ?",
  INTERNET_STATUS: "በቤታችሁ የኢንተርኔት አገልግሎት አለዎት?",
  NETWORK_TYPE: "የአውታረ መረብ አይነት",

  //-----------END of Notification Info Page---------

  //#Career Interest
  CAREER_INTEREST: "የሥራ ፍላጎቶች",
  JOB_ASSIGNMENTS: "የምትመርጠው የሥራ ምድብ ነው ።",
  WORK_EXPERIENCE: "የስራ ልምድ",
  WORK_TRAINING: "የስራ ስልጠና ዝግጁነት",
  JOB_MATCHES: "የሚጣጣም ኢዮብ።",
  CLOSE_HOME: "ወደ ቤቴ ቅርብ",
  MATCHES_CAREER: "ከሥራዬ ግብ ጋር ይጣጣማል",
  CAREER_TYPE_1: "የመጀመሪያው ከፍተኛ የሥራ ፍላጎት",
  CAREER_TYPE_2: "ሁለተኛው ከፍተኛ የሥራ ፍላጎት",
  CAREER_TYPE_3: "ሦስተኛው ከፍተኛ የሥራ ፍላጎት",
  AFTER_SYEP: "ማንኛውም የወደፊት ዕቅድ, ከ SYEP በኋላ",
  CAREER_GOALS: "የረጅም ጊዜ የሥራ ግቦችህ ምንድን ናቸው?",

  //----------END of Career Interest Page----------------

  //#Supplemenatl Info
  SUPPLEMENTAL_INFORMATION: "ተጨማሪ መረጃ",
  SPECIAL_ACCOMODATION: "ልዩ ማረፊያዎች / የህክምና መረጃ",
  REQ_SPECIAL_ACCOMODATION: "ልዩ ማረፊያ ያስፈልግዎት ይሆን?",
  MEDICAL_CONDITION: "ከውጪ የመሥራት ችሎታህን የሚከለክል ህክምና።",
  DEMOGRAPHIC: "የህዝብ መረጃ",
  OFFENDER: "ጥፋተኛ ወይም ፍርድ ቤት",
  MILITARY: "በጦር ሠራዊት ውስጥ ማገልገል",
  HOMELESS: "ቤት አልባ",
  PARENT: "ወላጅ",
  MYOWN: "ብቻዬን ኑር",
  FOSTER: "የፎስተር እንክብካቤ",
  PUBLIC_ASSISTANCE: "የህዝብ እርዳታ",
  REQ_PUBLIC_ASSISTANCE:
    "በአሁኑ ጊዜ አንተም ሆንክ ቤተሰብህ የሕዝብ እርዳታ እያገኙ እንደሆነ ናችሁን እዚህ ላይ አረጋግጡ",
  YES: "አዎ",
  INCOME: "ገቢ እና የቤተሰብ መጠን",
  FAMILY_INCOME: "ባለፉት ስድስት ወራት ጠቅላላ የቤተሰብ ገቢ",
  FAMILY_SIZE: "በአሁኑ ጊዜ ያሉ ጠቅላላ አባላት በቤትዎ ውስጥ",

  //------END of Supplemental Page--------------

  //#Review Page
  REVIEW_SIGN: "ክለሳ እና ምልክት",
  DIGITAL_SIGNATURE: "ዲጂታል ፊርማ",
  CHECKING_INFO:
    "ከታች ያለውን ሳጥን በመፈተሽ ይህ ቅጽ የተሰጠው መረጃ ሁሉ እውነትና ትክክለኛ መሆኑን አረጋግጣለሁ።",
  APPROVE_INFO:
    "ይህ መረጃ ለምርመራ የሚቀርብ መሆኑን ተረድቻለሁ፣ ማንኛውንም የሐሰት መረጃ መስጠት ከዲ ኤስ የወጣቶች ፕሮግራም ቢሮ እንድወገድ ና በተቻለ መጠን ለሲቪል እና ለወንጀል ሰልፍ ሊገዛኝ እንደሚችል ተጨማሪ ተረድቻለሁ።",
  READ_CHECK: "ከላይ ያለውን መግለጫ አንብቤ ማመልከቻዬን ለከንቲባ ሰመራ ወጣቶች የስራ ፕሮግራም ማቅረብ እፈልጋለሁ",
  DATE: "ቀን",
  SIGNATURE: "ፊርማ",
  DOES: "DOES እኩል እድል አሠሪ / አቅራቢ ነው ",
  LANGUAGE_SERVICE: "የቋንቋ ትርጓሜ አገልግሎት ያለ ወጪ ይገኛል",
  AUXILIARY_AIDS: "የአካል ጉዳተኛ ለሆኑ ግለሰቦች በሚጠየቅበት ጊዜ ረዳት አገልግሎትና እርዳታ ማግኘት ይቻላል",
  NOTICE_HEADER: "አድልዎ የሌለበትን ነገር ልብ በል",
  ACCORDANCE:
    "በዲሲ የሰብዓዊ መብቶች ሕግ መሠረት 1977 እንደተሻሻለው, ዲሲ ኦፊሴላዊ ኮድ ክፍል 2-1401-01 et seg., (አክት) የኮሎምቢያ አውራጃ በዘር, ቀለም, ሃይማኖት, ብሔር, መነሻ, ጾታ, ዕድሜ, የጋብቻ ሁኔታ, የግል ገጽታ, የፆታ ማንነት ወይም መግለጫ, የቤተሰብ ሁኔታ, የቤተሰብ ኃላፊነት, ማቴሪያል, ፖለቲካዊ affiliation,  በዘር የሚተላለፍ መረጃ, የአካል ጉዳት, የገቢ ምንጭ ወይም የመኖሪያ ወይም የንግድ ቦታ.  ፆታዊ ትንኮሳ በአዋጁ ምክኒያት በሚከለከል መልኩ ነው። በተጨማሪም ከላይ በተጠበቁ ማናቸውም ምድቦች ላይ የተመሰረተ ጥቃት በአዋጁ የተከለከለ ነው። አዋጁን በመጣስ የሚደረግ መድልዎ በቸልታ አይታለፍም። ጥሰት የፈፀሙ ሰዎች የቅጣት እርምጃ ይወሰድባቸዋል",
};

export default locale;
