import React, { Component, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import BackIcon from "@mui/icons-material/Backspace";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Card,
  Typography,
  FormHelperText,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import {
  DatePicker,
  DateTimePicker,
  DesktopTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import IndexHeader from "app/shared-components/PageHeader";
import { useParams } from "react-router-dom";
const defaultValues = {
  from: "",
  to: "",
  date: "",
};

const schema = yup.object().shape({});

export default function CareerExpoInfoPage() {
  const routeParams = useParams();
  const { id } = routeParams;

  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  const [values, setValue] = useState({
    employeeType: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue({
      ...values,
      [name]: value,
    });
  };

  return (
    <>
      <Card variant="outlined" className="m-24 mb-0">
        <div className="m-24 mt-8">
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <DatePicker
                className="mt-16 w-1/2"
                label="Date"
                slotProps={{ textField: { size: "small" } }}
              />
            )}
          />
          <div className="flex grid mt-16 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-16 content-start">
            <Controller
              control={control}
              name="from"
              render={({ field }) => (
                <TimePicker
                  className="w-1/3"
                  data-mdb-with-icon="true"
                  label="From"
                  slotProps={{ textField: { size: "small" } }}
                />
              )}
            />
            <Controller
              control={control}
              name="to"
              render={({ field }) => (
                <TimePicker
                  className="w-1/3"
                  data-mdb-with-icon="true"
                  label="To"
                  slotProps={{ textField: { size: "small" } }}
                />
              )}
            />
          </div>

          <div className="flex items-center justify-end mt-32">
            <Button
              className="mx-8"
              variant="contained"
              color="secondary"
              disabled={false}
            >
              Save
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
}
