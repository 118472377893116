import React, { useState } from "react";
import { useParams } from "react-router-dom";
import IndexHeader from "app/shared-components/IndexHeader";
import { Stack, Button, Card } from "@mui/material";
import pdf from "../../../../../assets/pdf/sample-1.pdf";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import ParentConsentFormAddPage from "src/app/features/youth-job-application/parentConsentFormAdd";

const AdminApprovalView = () => {
  const routeParams = useParams();
  const { id } = routeParams;

  return (
    <>
      <IndexHeader
        name={"Approvals"}
        update={id ? true : false}
        add={id ? false : true}
        previousPath={"admin-approval-form/list"}
      />
      <Card className="m-24 mb-10">
        <ParentConsentFormAddPage isParent={false} userId={id} isAdmin={true} />

        <Stack
          marginTop={2}
          justifyContent="center"
          spacing={30}
          direction="row"
          className="mb-10"
        >
          <Button
            className="w-15"
            variant="contained"
            color="secondary"
            aria-label="Register"
            type="submit"
            size="medium"
          >
            Download
          </Button>
          <Button
            className="w-15"
            variant="contained"
            color="secondary"
            aria-label="Register"
            type="submit"
            size="medium"
          >
            Accept
          </Button>
          <Button
            className="w-15"
            variant="contained"
            color="secondary"
            aria-label="Register"
            type="submit"
            size="medium"
          >
            Reject
          </Button>
        </Stack>
      </Card>
    </>
  );
};

export default AdminApprovalView;
