import IndexHeader from "app/shared-components/PageHeader";
import * as React from "react";
import IncidentFormAddUpdate from "./components/IncidentFormAddUpdate";

export default function TerminationFormAddUpdatePage() {
    return (
    <>
      <IncidentFormAddUpdate />
    </>
  );
}
