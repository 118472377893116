import React from "react";
import Program from "./Program";
import SYEPPage from "./syep-program/SYEP.Page";
import ISYPPage from "./isyp-program/ISYP.Page";
import EnrollFormPage from "./enroll/EnrollPage";
import { UserRole } from "src/app/@types/enums/user.roles";

import i18next from "i18next";
import english from "./i18n/english";

i18next.addResourceBundle("en", "hostProgram", english);


export const ProgramRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Host],
  routes: [
    {
      path: "program",
      element: <Program />,
      children: [
        {
          path: "syep",
          element: <SYEPPage />,
        },
        {
          path: "isyp",
          element: <ISYPPage />,
        },
        {
          path:"enroll",
          element:<EnrollFormPage/>
        },

      ],
    },
  ],
};
