import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import BackIcon from "@mui/icons-material/Backspace";
import {
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Card,
  MenuItem,
} from "@mui/material";

const defaultValues = {
  program: "",

  type: "",
  phone: "",
  email: "",
};

const schema = yup.object().shape({
  phone: yup.string().required("You must enter a phone number"),
  type: yup.string().required("you must select a type"),
  program: yup.string().required("you must select a program"),
  message: yup.string().required("You must enter a message"),
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
});

function AdminViewAnswersFilter() {
  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  if (_.isEmpty(form)) {
    return null;
  }

  return (
    <>
      <Card variant="outlined" className="m-24 mb-0">
        <div className="flex content-between justify-start m-24  ">
          <div className="w-1/5 mt-16 mr-16">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="type"
                >
                  <MenuItem value={"programQuestions"}>
                    Program Questions
                  </MenuItem>
                  <MenuItem value={"banking"}>Banking</MenuItem>
                  <MenuItem value={"certification"}>Certification</MenuItem>
                  <MenuItem value={"orientation"}>Orientation</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="w-1/5 mt-16 mr-16">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Program</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                >
                  <MenuItem value={"1"}>
                    Summer Youth Employment Program
                  </MenuItem>
                  <MenuItem value={"2"}>In School Youth Program</MenuItem>
                  <MenuItem value={"3"}>Out-of-School Youth Program</MenuItem>
                  <MenuItem value={"4"}>Youth Leadership Program</MenuItem>
                  <MenuItem value={"5"}>Summer Intensive Training</MenuItem>
                  <MenuItem value={"6"}>
                    Pathways for Young Adults Program
                  </MenuItem>
                  <MenuItem value={"7"}>Youth Earn and Learn Program</MenuItem>
                  <MenuItem value={"8"}>School Year Program</MenuItem>
                  <MenuItem value={"8"}>Global Education Program</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="w-1/5  mr-16">
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="phone"
                  placeholder="phone"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.phone}
                  helperText={errors?.phone?.message}
                />
              )}
            />
          </div>
          <div className="w-1/5  mr-16 ">
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="email"
                  placeholder="email"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                />
              )}
            />
          </div>
          <div className="mt-20">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <SearchIcon />
            </IconButton>
          </div>
          <div className="mt-20  mr-1">
            {" "}
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <BackIcon />
            </IconButton>
          </div>
        </div>
      </Card>
    </>
  );
}

export default AdminViewAnswersFilter;
