import React from "react";
import AdminViewAnswersFilter from "./admin-view-answers-index/components/AdminViewAnswersFilter";
import AdminViewAnswersTable from "./admin-view-answers-index/components/AdminViewAnswersTable";
import AdminViewAnswers from "./AdminViewAnswers";
import { Navigate } from "react-router-dom";
import AdminViewAnswersIndexPage from "./admin-view-answers-index/AdminViewAnswersIndexPage";
import { UserRole } from "src/app/@types/enums/user.roles";
export const AdminViewAnswersRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Admin],
  routes: [
    {
      path: "admin-view-answers",
      element: <AdminViewAnswers />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <AdminViewAnswersIndexPage />,
        },
      ],
    },
  ],
};
