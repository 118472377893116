import * as React from "react";
import IndexHeader from "app/shared-components/IndexHeader";
import CustomButton from "app/shared-components/CustomColorButton";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import history from "@history";
import AdminYouthListFilter from "./components/AdminYouthListFilter";
import AdminYouthListTable from "./components/AdminYouthListTable";

  const handleClick = () => {
    history.push('incident-form/add');
  };


export default function AdminYouthListIndexPage() {
  
  return (
    <>
      <IndexHeader
        name={"Youth List"}
        update={false}
        add={false}
      />
      <AdminYouthListFilter />
      <AdminYouthListTable />
    </>
  );
}

