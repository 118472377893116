import AppBar from "@mui/material/AppBar";
import { ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { memo } from "react";
import { useSelector } from "react-redux";
import { selectFooterTheme } from "app/store/fuse/settingsSlice";
import clsx from "clsx";
import InnerFooter from "app/shared-components/InnerFooter";
import { Card } from "@mui/material";
import React, { useState } from "react";
import YouthChatBot from "../../../features/youth-chat-bot/youth-chat-bot";
import { authDataService } from "../../../services/data/auth.data.service";
import { UserRole } from "../../../@types/enums/user.roles";

function FooterLayout1(props) {
  const footerTheme = useSelector(selectFooterTheme);

  //#region Chat Bot
  const [isChatOpen, setChatOpen] = useState(false);
  const handleChatButtonClick = () => {
    setChatOpen(true);
  };

  const handleCloseChat = () => {
    setChatOpen(false);
  };
  //#endregion Chat Bot

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className={clsx("relative z-20 shadow-md", props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? footerTheme.palette.background.paper
              : footerTheme.palette.background.default,
        }}
      >
        <Toolbar className="min-h-48 md:min-h-64 px-8 sm:px-12 py-0 flex items-center justify-between overflow-x-auto">
          <div className="flex items-center">
            <span className="mr-2">
              Codice YPMS © {new Date().getFullYear()}
            </span>
          </div>
          {console.log(authDataService.userRoleId)}
          {authDataService.userRoleId == UserRole.Youth && (
            <div>
              <YouthChatBot />
            </div>
          )}
          <div className="flex items-center">
            <InnerFooter />
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(FooterLayout1);
