import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import history from "@history";

//#region form declaration
const schema = yup.object().shape({
  field1: yup.string(),
  // form values goes here
});

const defaultValues = {
  field1: "",
  // form values goes here
};
//#endregion

function ContactInfo() {
  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  function onSubmit(data: any): void {
    history.push(`/sign-up/host/program-info`);
  }

  const { isValid, dirtyFields, errors } = formState;

  const [values, setValue] = useState({
    companyIdentifierType: "",
    organizationalType: "",
    isYouthToBeVaccinated: "",
    operateType: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue({
      ...values,
      [name]: value,
    });
  };

  return (
    <>
      <form name="hostInfoForm" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Typography
          className="mb-16 text-lg text-muted font-semibold"
          color="gray"
        >
          Primary Business Address
        </Typography>

        <div className="flex grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start">
          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Address Line 1"
                size="small"
                variant="outlined"
              />
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Appt. No/ Suite"
                size="small"
                variant="outlined"
              />
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <FormControl>
                <Select
                  {...field}
                  value={values.companyIdentifierType}
                  onChange={handleChange}
                  displayEmpty
                  size="small"
                >
                  <MenuItem value="">State</MenuItem>
                  <MenuItem value={"10"}>Ten</MenuItem>
                  <MenuItem value={"20"}>Twenty</MenuItem>
                  <MenuItem value={"30"}>Thirty</MenuItem>
                </Select>
                <FormHelperText></FormHelperText>
              </FormControl>
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <FormControl>
                <Select
                  {...field}
                  value={values.companyIdentifierType}
                  onChange={handleChange}
                  displayEmpty
                  size="small"
                >
                  <MenuItem value="">City</MenuItem>
                  <MenuItem value={"10"}>Ten</MenuItem>
                  <MenuItem value={"20"}>Twenty</MenuItem>
                  <MenuItem value={"30"}>Thirty</MenuItem>
                </Select>
                <FormHelperText></FormHelperText>
              </FormControl>
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Postal Code (Zip)"
                size="small"
                variant="outlined"
              />
            )}
          />
        </div>

        <Typography
          className="mt-24 text-lg text-muted font-semibold mb-16"
          color="gray"
        >
          Primary Contact
        </Typography>

        <div className="flex grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start mb-16">
          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="First Name"
                size="small"
                variant="outlined"
              />
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Last Name"
                size="small"
                variant="outlined"
              />
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email Address"
                size="small"
                variant="outlined"
              />
            )}
          />
        </div>

        <div className="flex grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 gap-16 content-start">
          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Primary Phone"
                size="small"
                variant="outlined"
              />
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Extension (If Applicable)"
                size="small"
                variant="outlined"
              />
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Secondary Phone"
                size="small"
                variant="outlined"
              />
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Extension (If Applicable)"
                size="small"
                variant="outlined"
              />
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Fax Number"
                size="small"
                variant="outlined"
              />
            )}
          />
        </div>

        <Typography
          className="mt-24 text-lg text-muted font-semibold"
          color="gray"
        >
          Secondary Contact
        </Typography>

        <div className="flex grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start mb-16">
          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="First Name"
                size="small"
                variant="outlined"
              />
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Last Name"
                size="small"
                variant="outlined"
              />
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email Address"
                size="small"
                variant="outlined"
              />
            )}
          />
        </div>

        <div className="flex grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 gap-16 content-start">
          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Primary Phone"
                size="small"
                variant="outlined"
              />
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Extension (If Applicable)"
                size="small"
                variant="outlined"
              />
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Secondary Phone"
                size="small"
                variant="outlined"
              />
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Extension (If Applicable)"
                size="small"
                variant="outlined"
              />
            )}
          />

          <Controller
            name="field1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Fax Number"
                size="small"
                variant="outlined"
              />
            )}
          />
        </div>

        <div className="flex items-end place-content-end">
          <Button
            variant="contained"
            color="secondary"
            className="mt-24 min-w-96"
            aria-label="Register"
            type="submit"
            size="medium"
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
}

export default ContactInfo;
