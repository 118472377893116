const locale = {
    PROGRAM:"Program",
    COMPANY:"Company",
    SELECTED_DATE:"Selected Date",
    WORKING_DAY:"Working Day",
    NON_WORKING_DAY:"Non Working Day",
    HOLIDAY:"Holiday",
    IN_TIME:"In Time",
    OUT_TIME:"Out Time",
    TIME_DIFFERENCE:"Time Differnce",
    WORKDONE:"Work Done",
    SUBMIT:"Submit",
}
export default locale;