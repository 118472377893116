const locale = {
  CREATE_USER: "Create",
  GENERATE_QR: "Generate QR",
  SIGNIN: "Sign In",
  SIGNUP: "Sign Up",
  LOGIN_INFORMATION: "Login Information",
  EMAIL_ADDRESS: "Email address",
  CONFIRM_EMAIL_ADDRESS: "Confirm Email address",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm Password",

  //Error messages
  EMAIL_ADDRESS_NOT_MATCHED_ERROR: "Email Address you entered do not match",
  EMAIL_NOT_VALID:
    "Please enter a valid email address (e.g., example@example.com).",
  PASSWORD_NOT_MATCHED_ERROR: "The password entered do not match.",
  EMAIL_PRIMARY_ERROR:
    "This Email Address is the primary way to communicate with you.Therefore if you do not have a personal email Address, please create one prior",
  CONFIRM_EMAIL_ERROR: "Please confirm your email address.",
  CONFIRM_PASSWORD_ERROR: "Please confirm your password.",

  //Button
  Next: "Next",
  Back: "Back",

  //#PersonalInfo-----------------
  PERSONAL_DETAILS: "Personal Details",

  //Error Message
  LEGAL_FIRST_NAME_ERROR: "Please add your Legal First Name",
  LEGAL_LAST_NAME_ERROR: "Please add your Legal Last Name",
  MIDDLE_NAME_ERROR: "Please add your Middle Name",
  MAIDEN_NAME_ERROR: "Please add your Maiden Name",

  SSN_ERROR: "SSN must be in the format XXX-XX-XXXX",
  CONFIRM_SSN_ERROR: "Confirm SSN is Required",
  SSN_NOT_MATCHED_ERROR: "The SSN entered does not match",

  LEGAL_FIRST_NAME: "Legal First Name",
  LEGAL_LAST_NAME: "Legal Last Name",
  MIDDLE_NAME: "Middle Name",
  MAIDEN_NAME: "Maiden Name",
  DATE_OF_BIRTH: "Date of Birth",
  LANGUAGE: "Language",
  GENDER: "Gender",
  RACE: "Race",
  SUFFIX: "Suffix",
  T_SHIRT_SIZE: "T-Shirt Size",
  SOCIAL_SECURITY_NUMBER: "Social Security Number",
  CONFIRM_SSN: "Confirm SSN",

  SSN_ERROR: "SSN is Required",
  CONFIRM_SSN_ERROR: "Confirm SSN is Required",
  SSN_NOT_MATCHED_ERROR: "SSN not match",

  LEGAL_FIRST_NAME: "Legal First Name",
  LEGAL_LAST_NAME: "Legal Last Name",
  MIDDLE_NAME: "Middle Name",
  MAIDEN_NAME: "Maiden Name",
  DATE_OF_BIRTH: "Date of Birth",
  LANGUAGE: "Language",
  GENDER: "Gender",
  RACE: "Race",
  SUFFIX: "Suffix",
  T_SHIRT_SIZE: "T-Shirt Size",
  SOCIAL_SECURITY_NUMBER: "Social Security Number",
  CONFIRM_SSN: "Confirm SSN",

  //-----------END of PersonalInfoPage------------

  //#EducationalInfo
  //Error Message
  REQUIRED_ERROR: "This field should be filled",

  EDUCATIONAL_DETAILS: "Educational Details",
  CURRENT_EDUCATIONAL_STATUS: "Current Educational Status",
  EDUCATIONAL_STUDENT_TYPE: "Educational Student Type",
  CURRENT_GRADE: "Current Grade or Last Grade Attended",
  CURRENT_SCHOOL: "Current School or Last School Attended",

  CURRENT_EDUCATION_REQUIRED_ERROR: "Please select current educational status ",
  STUDENT_TYPE_REQUIRED_ERROR: "Please select educational student type",
  CURRENT_GRADE_REQUIRED_ERROR:
    "Please select current grade or last grade attended",
  CURRENT_SCHOOL_REQUIRED_ERROR:
    "Please select current school or last school attended",

  //----------END of EducationalInfoPage---------------

  //#ContactInfo
  CONTACT_INFORMATION: "Contact Information",
  PERMENANT_ADDRESS_DETAILS: "Permanent Address Details",
  ADDRESS_LINE: "Address Line",
  APT: "Apt.No or Suite",
  STATE: "State",
  CITY: "City",
  ZIP: "ZIP or Postal Code",
  CELL_PHONE: "Cell Phone",
  HOME_PHONE: "Home Phone",
  PARENT: "Parent or Guardian",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  WORK_PHONE: "Work Phone",
  RELATIONSHIP: "Relationship",
  EMERGENCY: "Emergency",
  PHONE_NUMBER: "Phone number",

  VALID_POSTAL_ERROR: "Please enter a valid postal code",
  ADDRESS_LINE_REQUIRED_ERROR: "Please enter Address Line",
  FIRST_NAME_REQUIRED_ERROR: "Please add First Name",
  LAST_NAME_REQUIRED_ERROR: "Please add Last Name",
  PHONE_NUMBER_REQUIRED_ERROR: "Please add Phone Number",
  CELL_NUMBER_REQUIRED_ERROR: "Please add Cell Phone Number",
  PHONE_NUMBER_REQUIRED_ERROR: "Please add Phone Number",
  HOME_NUMBER_REQUIRED_ERROR: "Please add Home Phone Number",

  //--------END OF CONTACT INFO PAGE--------

  //#NotificationInfo
  NOTIFICATION_INFORMATION: "Notification Information",
  NOTIFICATION_DETAILS: "Notification Details",
  TWITTER_USER_NAME: "Twitter @UserName",
  CONTACT_TEXT_MESSAGE: "May we contact you through text message?",
  COMPUTER_STATUS: "Do you have a computer?",
  INTERNET_STATUS: "Do you have internet access in your home?",
  NETWORK_TYPE: "Network Type",

  //-----------END of Notification Info Page---------

  //#Career Interest
  CAREER_INTEREST: "Career Interests",
  JOB_ASSIGNMENTS: "Job Assignments you would prefer",
  WORK_EXPERIENCE: "Work Experience",
  WORK_TRAINING: "Work Training Readiness",
  JOB_MATCHES: "Job that matches",
  CLOSE_HOME: "Close to my home",
  MATCHES_CAREER: "Matches my career goal",
  CAREER_TYPE_1: "First top career interest",
  CAREER_TYPE_2: "Second top career interest",
  CAREER_TYPE_3: "Third top career interest",
  AFTER_SYEP: "Any plan for future, after YPMS",
  CAREER_GOALS: "What are your long-term career goals?",

  //----------END of Career Interest Page----------------

  //#Supplemenatl Info
  SUPPLEMENTAL_INFORMATION: "Supplemental Information",
  SPECIAL_ACCOMODATION: "Special Accommodations / Medical Information",
  REQ_SPECIAL_ACCOMODATION: "Do you require any special Accommodation?",
  MEDICAL_CONDITION:
    "Medical Condition that would prohibit your ability to work outside",
  DEMOGRAPHIC: "Demographic Information",
  OFFENDER: "Offender or Court Involved",
  MILITARY: "Served in Military",
  HOMELESS: "Homeless",
  PARENT: "Parent",
  MYOWN: "Live on My own",
  FOSTER: "Foster Care",
  PUBLIC_ASSISTANCE: "Public Assistance",
  REQ_PUBLIC_ASSISTANCE:
    "Check here if you or your family currently receiving any public assistance",
  YES: "Yes",
  INCOME: "Income And Family Size",
  FAMILY_INCOME: "Total family income for last six months",
  FAMILY_SIZE: "Current total members in your Household",

  //------END of Supplemental Page--------------

  //#Review Page
  REVIEW_SIGN: "Review and Sign",
  DIGITAL_SIGNATURE: "Digital Signature",
  CHECKING_INFO:
    "By checking the box below, I certify all that information provided this form is true and accurate.",
  APPROVE_INFO:
    "I understand that this information is subjected to verification, I further understand that providing any false information may result in my removal from a DOES office of youth program and may subject me to civil and criminal procession as applicable.",
  READ_CHECK:
    "I have read the above statement and I would like to submit my application to Mayor Summer Youth Employment Program",
  DATE: "Date",
  SIGNATURE: "Signature",
  DOES: "DOES is an Equal Opportunity Employer / Provider ",
  LANGUAGE_SERVICE:
    "Language interpretation services are available without cost",
  AUXILIARY_AIDS:
    "Auxiliary services and aids are available upon request for individuals with disabilities",
  NOTICE_HEADER: "Notice of Non-Discrimination",
  ACCORDANCE:
    "In accordance with D.C Human Rights Act of 1977, as amended, D.C official code section 2-1401-01 et seg., (Act) the District of Columbia does not discriminate on the basis of race, color, religion, nation, origin, sex, age, marital status, personal appearance, sexual orientation, gender identity or expression, family status, family responsibilities, matriculation, political affiliation, genetic information, disability, source of income or place of residence or business. Sexual harassment in a form of  sec discrimination which is also prohibited by the Act. In addition, harassment based upon any of the above protected categories is prohibited by the Act. Discrimination in violation of the Act will not be tolerated. Violators will be subjected to disciplinary action",
};

export default locale;
