import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import BackIcon from "@mui/icons-material/Backspace";
import { FormControl, InputLabel, Select, IconButton, Card, MenuItem } from "@mui/material";

const defaultValues = { name: "", email: "", subject: "", message: "" };
const schema = yup.object().shape({
  name: yup.string().required("You must enter a name"),
  subject: yup.string().required("You must enter a subject"),
  message: yup.string().required("You must enter a message"),
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
});

function TimeSheetFilter() {
  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  if (_.isEmpty(form)) {
    return null;
  }

  return (
    <>
<Card variant="outlined" className="m-24 mb-0">
        <div className="flex content-between justify-start m-24">
        <div className="w-1/5 mt-16 mr-16">
            <Box sx={{ minWidth: 120 }} >
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Week
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Week"
                  
                >
                  <MenuItem value={"1"}>Week 1 (7/3/2023 – 7/7/2023)</MenuItem>
                  <MenuItem value={"2"}>Week 2 (7/10/2023 – 7/14/2023)</MenuItem>
                  <MenuItem value={"3"}>Week 3 (7/17/2023 – 7/21/2023)</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="w-1/5 mt-16 mr-16">
            <Box sx={{ minWidth: 120 }} >
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Worksite
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Worksite"
                  
                >
                  <MenuItem value={"1"}>Head Office</MenuItem>
                  <MenuItem value={"2"}>Finance Division</MenuItem>
                  <MenuItem value={"3"}>Marketing Division</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="w-1/5 mt-16 mr-16">
            <Box sx={{ minWidth: 120 }} >
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                  
                >
                  <MenuItem value={"1"}>Approval Pending</MenuItem>
                  <MenuItem value={"2"}>Approved</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="w-1/5 mr-16">
            <Controller
              control={control}
              name="subject"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Name"
                  placeholder="Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.subject}
                  helperText={errors?.subject?.message}
                />
              )}
            />
          </div>

          <div className="w-1/5  mr-16">
            <Controller
              control={control}
              name="subject"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="ID"
                  placeholder="ID"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.subject}
                  helperText={errors?.subject?.message}
                />
              )}
            />
          </div>

          <div className="mt-20">
            {" "}
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <SearchIcon />
            </IconButton>
          </div>
          <div className="mt-20  mr-1">
            {" "}
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <BackIcon />
            </IconButton>
          </div>
        </div>
      </Card>
    </>
  );
}

export default TimeSheetFilter;
