import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import SignInConfig from "../features/auth/sign-in/SignInConfig";
import SignOutConfig from "../features/auth/sign-out/SignOutConfig";
import Error404Page from "../main/404/Error404Page";
import ExampleConfig from "../main/example/ExampleConfig";
import TwoFAConfig from "../features/auth/2fa/TwoFAConfig";
import { ProgramRoutes } from "../features/programs/Program.Routes";
import { SignUpRoutes } from "../features/auth/sign-up/components/SignUp.Routes";
import { UserProfileRoutes } from "../features/user-profile/UserProfile.Routing";
import { WorkSiteRoutes } from "../features/work-site/Work-Site.Routes";
import { AssignSupervisorRoutes } from "../features/assign-supervisor/AssignSupervisor.Routes";
import { JobRoutes } from "../features/job/JobAdd.Routes";
import { EmployeeRoutes } from "../features/employees/Employees.Routing";
import { TimeSheetRoutes } from "../features/timesheet/TimeSheet.Routes";
import { TerminationFormRoutes } from "../features/form-termination/TerminationForm.Routes";
import { IncidentFormRoutes } from "../features/form-incident/IncidentForm.Routes";
import { TransferFormRoutes } from "../features/form-transfer/TransferForm.Routes";
import { QuestionnaireFormRoutes } from "../features/Questionnaire/questionnaireForm.Routes";
import { AdminIncidentFormRoutes } from "../features/admin-form-incident/AdminIncidentForm.Routes";
import { AdminTransferFormRoutes } from "../features/admin-form-transfer/AdminTransferForm.Routes";
import { AdminTerminationFormRoutes } from "../features/admin-form-termination/admin-form-TerminationForm.Routes";
import { AdminApprovalFormRoutes } from "../features/admin-approvals/AdminApprovals.Routes";
import { AdminFormRoutes } from "../features/form-admin/AdminForm.Routes";
import { AdminProgramRoutes } from "../features/admin-program/admin-program.routing";
import { JobApplicationsRoutes } from "../features/jobAplications/jobapplications.routes";
import { AdminViewAnswersRoutes } from "../features/admin-view-answers/AdminViewAnswers.Routes";
import { YouthJobApplicationRoutes } from "../features/youth-job-application/YouthJobApplication.routing";
import { YouthTimeSheetRoutes } from "../features/youth-timesheet/YouthTimesheet.Routes";

import { UserRole } from "../@types/enums/user.roles";
import ExamplePage from "../main/example/Example";
import { YouthProgramRoutes } from "../features/youth-programs/YouthPrograms.Routes";
import { AdminMessageRoutes } from "../features/admin-messages/admin-message.Routes";
import { HostJobApplicationRoutes } from "../features/host-job-application/HostJobApplication.Routes";
import { YouthSalaryDetailsFormRoutes } from "../features/youth-salary-details/youth-salary-detailsform.Routes";
import { SubmitSalaryDetailsRoutes } from "../features/submit-salary-details/SubmitSalaryDetails.Routing";
import { w4TaxFormRoutes } from "../features/w4-tax-form/w4TaxForm.Routes";
import { YouthOpportunitiesRoutes } from "../features/youth-opportunities/Youth-Opportunities.Routes";
import { AdminYouthListRoutes } from "../features/admin-youth-list/AdminYouthList.Routes";
import { AdminHostListRoutes } from "../features/admin-host-list/AdminHostList.Routes";
import { YouthMessageRoutes } from "../features/youth-messages/youth-message.routes";
import { HostMessageRoutes } from "../features/host-messages/host-message.routes";
import { ParentConsentFormParentViewRoutes } from "../features/youth-job-application/parent-consent-form-parentView.routes";

const routeConfigs = [
  ExampleConfig,
  SignOutConfig,
  SignInConfig,
  TwoFAConfig,
  SignUpRoutes,
  ProgramRoutes,
  WorkSiteRoutes,
  UserProfileRoutes,
  AssignSupervisorRoutes,
  JobRoutes,
  EmployeeRoutes,
  TimeSheetRoutes,
  TerminationFormRoutes,
  IncidentFormRoutes,
  TransferFormRoutes,
  AdminTransferFormRoutes,
  QuestionnaireFormRoutes,
  AdminIncidentFormRoutes,
  AdminTerminationFormRoutes,
  AdminApprovalFormRoutes,
  AdminFormRoutes,
  AdminProgramRoutes,
  JobApplicationsRoutes,
  AdminViewAnswersRoutes,
  YouthProgramRoutes,
  YouthJobApplicationRoutes,
  AdminMessageRoutes,
  YouthTimeSheetRoutes,
  HostJobApplicationRoutes,
  YouthSalaryDetailsFormRoutes,
  SubmitSalaryDetailsRoutes,
  w4TaxFormRoutes,
  YouthOpportunitiesRoutes,
  AdminYouthListRoutes,
  AdminHostListRoutes,
  YouthMessageRoutes,
  HostMessageRoutes,
  ParentConsentFormParentViewRoutes,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: "/",
    element: <Navigate to="/home" />,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  {
    path: "home",
    element: <ExamplePage />,
    auth: [UserRole.Admin, UserRole.Host, UserRole.Youth],
  },
  {
    path: "*",
    element: <Navigate to="/sign-in" />,
  },
];

export default routes;
