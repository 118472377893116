const locale = {
    STATUS:"Status",
    COMPANY:"Company",
    NAME_ERROR:"You must Enter a Company Name",
    PROGRAM:"Program",
    YEAR:"Year",
    JOB_TITLE:"Job Title",
    HOURLY_RATE:"Hourly Rate",
    OPEN_POSITIONS:"Open Positions",
    POSITIONS_ASSIGNED:"Positions Assigned",
    FULL_NAME:"Full Name",
    STATUS:"Status",
    ACTION:"Action",
    VIEW:"VIEW",
    DOWNLOAD:"Download",
    ACCEPT:"Accept",
    REJECT:"Reject",
    SHORT:"Short Listed",
    INTERVIEW:"Interview Scheduled",
}
export default locale;