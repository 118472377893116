import React from "react";
import { lazy } from "react";

const YouthLoginInfo = lazy(() => import("./LoginInfo"));
const PersonalInfo = lazy(() => import("./PersonalInfo"));
const EducationalInfo = lazy(() => import("./EducationalInfo"));
const ContactInfo = lazy(() => import("./ContactInfo"));
const NotificationInfo = lazy(() => import("./NotificationInfo"));
const CareerInterest = lazy(() => import("./CareerInterest"));
const SupplementalInfo = lazy(() => import("./SupplementalInfo"));
const ReviewInfo = lazy(() => import("./ReviewInfo"));

import i18next from "i18next";
import english from "./i18n/english";
import spanish from "./i18n/spanish";
import korean from "./i18n/korean";
import french from "./i18n/french";
import amharic from "./i18n/amharic";
import vietnamese from "./i18n/vietnamese";
import chinese from "./i18n/chinese";
import englishFooter from "../../../../../shared-components/i18n/english";
import koreanFooter from "../../../../../shared-components/i18n/korean";
import spanishFooter from "../../../../../shared-components/i18n/spanish";
import amharicFooter from "../../../../../shared-components/i18n/amharic";
import chineseFooter from "../../../../../shared-components/i18n/chinese";
import frenchFooter from "../../../../../shared-components/i18n/french";
import vietnameseFooter from "../../../../../shared-components/i18n/vietnamese";
import YouthSignaturePad from "./YouthSignRecording";

i18next.addResourceBundle("en", "youthLoginPage", english);
i18next.addResourceBundle("es", "youthLoginPage", spanish);
i18next.addResourceBundle("kr", "youthLoginPage", korean);
i18next.addResourceBundle("fr", "youthLoginPage", french);
i18next.addResourceBundle("ar", "youthLoginPage", amharic);
i18next.addResourceBundle("vn", "youthLoginPage", vietnamese);
i18next.addResourceBundle("cn", "youthLoginPage", chinese);
i18next.addResourceBundle("en", "innerFooter", englishFooter);
i18next.addResourceBundle("kr", "innerFooter", koreanFooter);
i18next.addResourceBundle("es", "innerFooter", spanishFooter);
i18next.addResourceBundle("ar", "innerFooter", amharicFooter);
i18next.addResourceBundle("cn", "innerFooter", chineseFooter);
i18next.addResourceBundle("fr", "innerFooter", frenchFooter);
i18next.addResourceBundle("vn", "innerFooter", vietnameseFooter);

const YouthSignUpRoutes = [
  {
    path: "login-info",
    element: <YouthLoginInfo />,
  },
  {
    path: "personal-info/:userId",
    element: <PersonalInfo />,
  },
  {
    path: "educational-info/:userId",
    element: <EducationalInfo />,
  },
  {
    path: "contact-info/:userId",
    element: <ContactInfo />,
  },
  {
    path: "notification-info/:userId",
    element: <NotificationInfo />,
  },
  {
    path: "career-interest/:userId",
    element: <CareerInterest />,
  },
  {
    path: "supplemental-info/:userId",
    element: <SupplementalInfo />,
  },
  {
    path: "review-info/:userId",
    element: <ReviewInfo />,
  },
  {
    path: "youth-sign/:userId",
    element: <YouthSignaturePad />,
  },
];

export default YouthSignUpRoutes;
