import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import BackIcon from "@mui/icons-material/Backspace";
import { FormControl, InputLabel, Select, IconButton, Card, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

const defaultValues = { name: "", ssn: "", phone: "", email: "" };
const schema = yup.object().shape({
  name: yup.string().required("NAME_ERROR"),
  phone:yup.number(),
  ssn: yup
    .string(),
  email: yup
    .string()
    .email("EMAIL_ERROR"),
});

function AdminYouthListFilter() {
  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  if (_.isEmpty(form)) {
    return null;
  }
  const {t} = useTranslation("youthList")

  return (
    <>

<Card variant="outlined" className="m-24 mb-0">
        <div className="flex content-between justify-start m-24  ">
        <div className="w-1/5  mr-16 ">
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label={t("NAME")}
                  placeholder="Full Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.name}
                  helperText={t(errors?.name?.message ?? "")}
                />
              )}
            />
          </div>

          <div className="w-1/5  mr-16 ">
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label={t("PHONE")}
                  placeholder="Phone Number"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.phone}
                  helperText={t(errors?.phone?.message ?? "")}
                />
              )}
            />
          </div>

          <div className="w-1/5  mr-16">
            <Controller
              control={control}
              name="ssn"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label={t("SSN")}
                  placeholder="SSN"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.ssn}
                  helperText={t(errors?.ssn?.message ?? "")}
                />
              )}
            />
          </div>

          <div className="w-1/5  mr-16 ">
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label={t("EMAIL")}
                  placeholder="Email"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.email}
                  helperText={t(errors?.email?.message ?? "")}
                />
              )}
            />
          </div>

          <div className="w-1/5 mt-16 mr-16">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={t("STATUS")}
                >
                  <MenuItem value={"1"}>Approved</MenuItem>
                  <MenuItem value={"2"}>Submitted</MenuItem>
                  <MenuItem value={"3"}>Draft</MenuItem>
                  <MenuItem value={"4"}>Denied</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="mt-20">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <SearchIcon />
            </IconButton>
          </div>
          <div className="mt-20  mr-1">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <BackIcon />
            </IconButton>
          </div>
        </div>
      </Card>

    </>
  );
}

export default AdminYouthListFilter;

