import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Divider,
  FormControlLabel,
  InputLabel,
  Card,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import * as yup from "yup";
import history from "@history";
import InnerFooter from "app/shared-components/InnerFooter";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { YouthCareerInterest } from "src/app/@types/interfaces/youth.career.interests";
import { number } from "prop-types";
import { showMessage } from "app/store/fuse/messageSlice";
import { youthSignUpService } from "src/app/services/api/youth.signup.service";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";

const schema = yup.object().shape({
  careerGoals: yup.string(),
});

const defaultValues: YouthCareerInterest = {
  workExperience: false,
  workTrainingReadiness: false,
  closeToMyHome: false,
  matchesMyCareerGoal: false,
  firstTopCareerInterest: 0,
  secondTopCareerInterest: 0,
  thirdTopCareerInterest: 0,
  anyPlanForFuture: 0,
  careerGoal: "",
};

function CareerInterest() {
  const { userId } = useParams();
  const { control, formState, handleSubmit, setValue, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const [msg, setMsg] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileId, setProfileId] = useState<any>("");
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    getContactDetails(userId);
  }, []);

  const getContactDetails = async (userId?: string) => {
    try {
      if (userId) {
        const response = await youthSignUpService.getCareerInterestByUserId(
          userId
        );
        initForm(response.data);
      }
    } catch (error: any) {}
  };

  const initForm = (data: YouthCareerInterest) => {
    Object.keys(data).forEach((key) => {
      setValue(
        key as keyof YouthCareerInterest,
        data[key as keyof YouthCareerInterest]
      );
    });
  };

  const onSubmit = async (data: YouthCareerInterest) => {
    setIsLoading(true);
    setIsError(false);
    setMsg("");
    try {
      const msg = await youthSignUpService.addCareerInterest({
        userId: userId,
        workExperience: data.workExperience,
        workTrainingReadiness: !data.workExperience,
        closeToMyHome: data.closeToMyHome,
        matchesMyCareerGoal: !data.closeToMyHome,
        firstTopCareerInterest: data.firstTopCareerInterest,
        secondTopCareerInterest: data.secondTopCareerInterest,
        thirdTopCareerInterest: data.thirdTopCareerInterest,
        anyPlanForFuture: data.anyPlanForFuture,
        careerGoal: data.careerGoal,
      });
      setMsg(msg);
      dispatch(showMessage({ message: msg, variant: "success" }));
      history.push(`/sign-up/youth/supplemental-info/${userId}`);
    } catch (error: any) {
      dispatch(showMessage({ message: error, variant: "error" }));
      setIsError(true);
      setMsg(error);
    }
    setIsLoading(false);
  };

  const { t } = useTranslation("youthLoginPage");

  return (
    <>
      {" "}
      <Typography className="text-20 ml-8 mt-8 font-semibold tracking-tight leading-tight">
        {"Enroll"}
      </Typography>
      <Card variant="outlined" className="mt-24">
        <div>
          <form
            name="careerInterestForm"
            noValidate
            className="ml-24 mr-24 mt-24"
          >
            <div className="flex items-start justify-start gap-16">
              <div className="w-1/3">
                <Controller
                  name="workExperience"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControl component="fieldset">
                      <FormLabel id="job-assignment">
                        {t("JOB_ASSIGNMENTS")}
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="job-assignments-group-label"
                        {...field}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label={t("WORK_EXPERIENCE")}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label={t("WORK_TRAINING")}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </div>

              <Controller
                name="closeToMyHome"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControl component="fieldset">
                    <FormLabel id="job-assignment">
                      {t("JOB_MATCHES")}
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="job-assignments-group-label"
                      {...field}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label={t("CLOSE_HOME")}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label={t("MATCHES_CAREER")}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </div>

            <div className="flex items-start justify-start gap-16 mt-16 ">
              <Controller
                name="firstTopCareerInterest"
                control={control}
                render={({ field }) => (
                  <FormControl
                    error={!!errors.firstTopCareerInterest}
                    className="w-1/3"
                  >
                    <Select
                      {...field}
                      displayEmpty
                      size="small"
                      required
                      value={field.value || ""}
                    >
                      <MenuItem value="" disabled>
                        <em>{t("CAREER_TYPE_1")}</em>
                      </MenuItem>

                      <MenuItem value={"10"}>
                        Building & Grounds Clening and Maintenance
                      </MenuItem>
                      <MenuItem value={"20"}>Child Care</MenuItem>
                      <MenuItem value={"30"}>Communication and Media</MenuItem>
                      <MenuItem value={"40"}>
                        Community and Social Service
                      </MenuItem>
                      <MenuItem value={"50"}>Construction Trade</MenuItem>
                      <MenuItem value={"60"}>Education and Training</MenuItem>
                      <MenuItem value={"70"}>
                        Environmental / Green Jobs
                      </MenuItem>
                      <MenuItem value={"80"}>
                        Fashion Design / Modeling
                      </MenuItem>
                      <MenuItem value={"90"}>Federal Government</MenuItem>
                      <MenuItem value={"80"}>Food Service</MenuItem>
                      <MenuItem value={"90"}>Health Care</MenuItem>
                    </Select>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                )}
              />

              <Controller
                name="secondTopCareerInterest"
                control={control}
                render={({ field }) => (
                  <FormControl
                    error={!!errors.secondTopCareerInterest}
                    className="w-1/3"
                  >
                    <Select
                      {...field}
                      displayEmpty
                      size="small"
                      required
                      value={field.value || ""}
                    >
                      <MenuItem value="" disabled>
                        <em>{t("CAREER_TYPE_2")}</em>
                      </MenuItem>
                      <MenuItem value={"10"}>
                        Building & Grounds Clening and Maintenance
                      </MenuItem>
                      <MenuItem value={"20"}>Child Care</MenuItem>
                      <MenuItem value={"30"}>Communication and Media</MenuItem>
                      <MenuItem value={"40"}>
                        Community and Social Service
                      </MenuItem>
                      <MenuItem value={"50"}>Construction Trade</MenuItem>
                      <MenuItem value={"60"}>Education and Training</MenuItem>
                      <MenuItem value={"70"}>
                        Environmental / Green Jobs
                      </MenuItem>
                      <MenuItem value={"80"}>
                        Fashion Design / Modeling
                      </MenuItem>
                      <MenuItem value={"90"}>Federal Government</MenuItem>
                      <MenuItem value={"80"}>Food Service</MenuItem>
                      <MenuItem value={"90"}>Health Care</MenuItem>
                    </Select>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                )}
              />

              <Controller
                name="thirdTopCareerInterest"
                control={control}
                render={({ field }) => (
                  <FormControl
                    error={!!errors.thirdTopCareerInterest}
                    className="w-1/3"
                  >
                    <Select
                      {...field}
                      displayEmpty
                      size="small"
                      required
                      value={field.value || ""}
                    >
                      <MenuItem value="" disabled>
                        <em>{t("CAREER_TYPE_3")}</em>
                      </MenuItem>
                      <MenuItem value={"10"}>
                        Building & Grounds Clening and Maintenance
                      </MenuItem>
                      <MenuItem value={"20"}>Child Care</MenuItem>
                      <MenuItem value={"30"}>Communication and Media</MenuItem>
                      <MenuItem value={"40"}>
                        Community and Social Service
                      </MenuItem>
                      <MenuItem value={"50"}>Construction Trade</MenuItem>
                      <MenuItem value={"60"}>Education and Training</MenuItem>
                      <MenuItem value={"70"}>
                        Environmental / Green Jobs
                      </MenuItem>
                      <MenuItem value={"80"}>
                        Fashion Design / Modeling
                      </MenuItem>
                      <MenuItem value={"90"}>Federal Government</MenuItem>
                      <MenuItem value={"80"}>Food Service</MenuItem>
                      <MenuItem value={"90"}>Health Care</MenuItem>
                    </Select>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                )}
              />
            </div>

            <div className="flex items-start justify-start gap-16 mt-16 ">
              <Controller
                name="anyPlanForFuture"
                control={control}
                render={({ field }) => (
                  <FormControl
                    error={!!errors.anyPlanForFuture}
                    className="w-1/3"
                  >
                    <Select
                      {...field}
                      displayEmpty
                      size="small"
                      variant="outlined"
                      required
                      value={field.value || ""}
                    >
                      <MenuItem value="" disabled>
                        <em>{t("AFTER_SYEP")}</em>
                      </MenuItem>
                      <MenuItem value={"10"}>Other</MenuItem>
                      <MenuItem value={"20"}>Return to School</MenuItem>
                      <MenuItem value={"30"}>
                        Seek Full-Time Employment
                      </MenuItem>
                      <MenuItem value={"20"}>
                        Seek Part-Time Employment
                      </MenuItem>
                      <MenuItem value={"30"}>Unsure at the moment</MenuItem>
                    </Select>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                )}
              />

              <Controller
                name="careerGoal"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-multiline-static"
                    label={t("CAREER_GOALS")}
                    size="small"
                    error={!!errors.careerGoal}
                    multiline
                    className="w-1/3"
                    required
                  />
                )}
              />
            </div>

            <div className="flex items-end place-content-between mb-24">
              {/* <Link
                  className="ml-4 font-semibold"
                  to={`/sign-up/youth/notification-info/${userId}`}
                >
                  {t("Back")}
                </Link> */}
              <Button
                variant="contained"
                color="secondary"
                className="mt-24 min-w-96"
                aria-label="Register"
                type="submit"
                size="medium"
              >
                {"Save"}
                {isLoading && (
                  <CircularProgress size={15} className="ml-10 text-white" />
                )}
              </Button>
            </div>
            {/* <InnerFooter /> */}
          </form>
        </div>
      </Card>
    </>
  );
}

export default CareerInterest;
