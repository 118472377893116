import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  FormControl,
  Select,
  MenuItem,
  Card,
  Typography,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  InputLabel,
  IconButton,
  ToggleButtonGroup,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IndexHeader from "app/shared-components/IndexHeader";
import { useParams } from "react-router-dom";
import CustomButton from "app/shared-components/CustomColorButton";
import history from "@history";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { ToggleOff } from "@mui/icons-material";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const defaultValues = {
  avatar: "",
  avatarDelete: "",
  avatarUpload: "",
  approvedBy: "",
  createdBy: "",
  createdOn: "",
  description: "",
  descriptionOne: "",
  active: "",
  copyFrom: "",
  title: "",
  upload: "",
  browse: "",
  untitledQuestions: "",
  video: "",
  delete: "",
};

const schema = yup.object().shape({
  approvedBy: yup.string().required("Approved By is required"),
  createdBy: yup.string().required("Created By is required"),

  description: yup.string().required("Description is required"),
  descriptionOne: yup.string().required("Description is required"),
  active: yup.string().required("Active is required"),
  copyFrom: yup.string().required("Copy From is required"),
  title: yup.string().required("Title is required"),
  upload: yup.string().required("Upload is required"),
  browse: yup.string().required("Browse is required"),
  untitledQuestions: yup.string().required("Untitled Questions is required"),
});

const changeHandler = (event: Event) => {
  setSelectedFile((event.target as HTMLInputElement)?.files?.[0]);
  setIsSelected(true);
};

export default function QuestionnaireFormAddUpdate() {
  const routeParams = useParams();
  const { id } = routeParams;
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] as File;
    setSelectedFile(file);
  };

  const handleClearFile = () => {
    setSelectedFile(null);
  };

  const { control, handleSubmit, watch, formState, register, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  const handleClick = () => {
    history.push("/questionnaire-form/list");
  };

  const myButtons = [
    <Button
      className="mx-8"
      variant="contained"
      disabled={false}
      onClick={handleClick}
      startIcon={<CancelIcon />}
    >
      {"Cancel"}
    </Button>,
    <CustomButton
      StartIcon={<SaveIcon />}
      className="mx-8"
      disabled={false}
      onClick={handleClick}
      name="Save"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];

  return (
    <>
      <IndexHeader
        name={"Create Questionnaire Form"}
        update={id ? true : false}
        add={id ? false : true}
        previousPath={"questionnaire-form/list"}
        buttons={myButtons}
      />

      <Card variant="outlined" className="m-24 mb-24 ">
        <div className="flex grid m-32 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 mt-16 gap-16 content-start">
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-16 w-full"
                label="Description"
                placeholder="Description"
                variant="outlined"
                fullWidth
                multiline
                size="small"
              />
            )}
          />
          <Controller
            control={control}
            name="createdBy"
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-16 w-full"
                label="Created By"
                placeholder="Created By"
                disabled={true}
                variant="outlined"
                fullWidth
                multiline
                size="small"
              />
            )}
          />
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small" className="w-full mt-16">
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Status"
              >
                <MenuItem value={"type1"}>Banking</MenuItem>
                <MenuItem value={"type2"}>Certification</MenuItem>
                <MenuItem value={"type3"}>Orientation</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Controller
            control={control}
            name="createdOn"
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-16 w-full"
                label="Created On"
                placeholder="Created On"
                variant="outlined"
                fullWidth
                multiline
                disabled={true}
                size="small"
              />
            )}
          />

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small" className="w-full mt-16">
              <InputLabel id="demo-simple-select-label">Program</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Status"
              >
                <MenuItem value={"1"}>Summer Youth Employment Program</MenuItem>
                <MenuItem value={"2"}>In School Youth Program</MenuItem>
                <MenuItem value={"3"}>Out-of-School Youth Program</MenuItem>
                <MenuItem value={"4"}>Youth Leadership Program</MenuItem>
                <MenuItem value={"5"}>Summer Intensive Training</MenuItem>
                <MenuItem value={"6"}>
                  Pathways for Young Adults Program
                </MenuItem>
                <MenuItem value={"7"}>Youth Earn and Learn Program</MenuItem>
                <MenuItem value={"8"}>School Year Program</MenuItem>
                <MenuItem value={"9"}>Global Education Program</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Controller
            control={control}
            name="active"
            render={({ field }) => (
              <TextField
                size="small"
                className="w-full mt-16 mb-16"
                fullWidth
                id="standard-bare"
                variant="outlined"
                defaultValue="Copy from"
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="active"
            render={({ field }) => (
              <FormControlLabel
                value="start"
                control={<Checkbox />}
                label="Active"
                labelPlacement="end"
              />
            )}
          />
        </div>
      </Card>

      <Card variant="outlined" className="ml-24 mt-24  mb-24">
        <div className="flex grid m-32 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 mt-16 gap-16 content-start">
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-16 w-1/2"
                label="Title"
                placeholder="Title"
                variant="outlined"
                fullWidth
                multiline
                size="small"
              />
            )}
          />
          <DeleteIcon className="mt-16 w-1/8  justify-self-end" />
        </div>
        <div className="flex grid m-32 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 mt-16 gap-16 content-start">
          <Controller
            control={control}
            name="descriptionOne"
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-16 w-full"
                label="Description"
                placeholder="Description"
                variant="outlined"
                fullWidth
                multiline
                size="small"
              />
            )}
          />
        </div>

        <div className="flex grid ml-32 mr-32 mb-0 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 mt-16 gap-16 content-start">
          <Typography className="mt-16">Video</Typography>
        </div>

        <Card variant="outlined" className="m-24 rounded hover:text-black">
          <Button
            variant="contained"
            className="w-1/8 ml-32 mt-16 mr-32 mb-32  mb-0  rounded-8 bg-black hover:bg-black text-white"
          >
            <Controller
              control={control}
              name="avatar"
              render={({ field: { onChange, value } }) => (
                <Box className=" mt-16 relative flex items-center justify-center w-128 h-128 rounded-1 overflow-hidden">
                  <div className="absolute inset-0 flex items-center justify-center z-20">
                    <label
                      htmlFor="button-upload"
                      className="hover:text-black
                       flex-auto w-full text-center  cursor-pointer mt-16 mb-16"
                    >
                      <Typography className="justify-center text-center w-full mb-16 items-center hover:bg-black text-white">
                        Browse
                      </Typography>
                      <input
                        onChange={handleFileChange}
                        accept="video/*"
                        className="hidden w-full mt-16"
                        id="button-upload"
                        type="file"
                      />
                    </label>
                  </div>
                </Box>
              )}
            />
          </Button>

          <Button
            disabled={false}
            className="w-1/8 ml-32 mr-32 mb-32 mt-16 mb-0 rounded-8 bg-black text-white hover:bg-black text-white"
          >
            <Controller
              control={control}
              name="avatarDelete"
              render={({ field: { onChange, value } }) => (
                <Box className="mt-16 relative flex items-center justify-center w-128 h-128 rounded-1 overflow-hidden">
                  <div className="absolute inset-0 flex items-center justify-center z-20">
                    <label
                      onClick={handleClearFile}
                      className="flex-auto w-full text-center  cursor-pointer mt-16 mb-16"
                    >
                       <Typography className="justify-center text-center w-full mb-16 items-center hover:bg-black text-white">
                        Delete
                      </Typography>
                    </label>
                  </div>
                </Box>
              )}
            />
          </Button>

          {/* <Button className="w-1/8 ml-32 mr-32 mb-32 mt-16 mb-0 rounded-8 bg-black text-white  hover:bg-black text-white">
            <Controller
              control={control}
              name="avatarUpload"
              render={({ field: { onChange, value } }) => (
                <Box className="mt-16 relative flex items-center justify-center w-128 h-128 rounded-1 overflow-hidden">
                  <div className="absolute inset-0 flex items-center justify-center z-20">
                    <label className="flex-auto w-full text-center  cursor-pointer mt-16 mb-16">
                      <UploadFileIcon className="justify-center text-center w-full mb-16 items-center" />
                    </label>
                  </div>
                </Box>
              )}
            />
          </Button> */}

          <Typography className="ml-32 mt-8 mb-32">
            {selectedFile?.name}
          </Typography>
        </Card>
      </Card>

      <Card variant="outlined" className="m-24 mb-24">
        <div className="flex grid m-32 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 mt-16 mb-8 gap-16 content-start">
          <Controller
            control={control}
            name="untitledQuestions"
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-16 w-full"
                label="Untitled Questions"
                placeholder="Untitled Questions"
                variant="outlined"
                fullWidth
                multiline
                size="small"
              />
            )}
          />
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small" className="mt-16 w-full mb-16">
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="type"
              >
                <MenuItem value={"choice1"} selected>Multiple Choice</MenuItem>
                <MenuItem value={"choice2"}>Checkboxes</MenuItem>
                
              </Select>
            </FormControl>
          </Box>
        </div>

        <div className="flex grid mr-32 ml-32 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2  gap-16 content-start">
        <div >
        <FormControl>
            <RadioGroup
              row={false}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Option 1"
              />
              <FormControlLabel
                value="0"
                className="mb-16"
                control={<Radio />}
                label="Option 2"
                disabled={true}
              />
            </RadioGroup>
          </FormControl>
          </div>
          
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small" className="w-full mb-16 content-center">
              <InputLabel id="demo-simple-select-label">Answer</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="type"
                className="mr-0"
              >
                <MenuItem value={"choice1"}>Option 1</MenuItem>
                <MenuItem value={"choice2"}>Option 2</MenuItem>
                
              </Select>
            </FormControl>
          </Box>
        </div>

        
        <ToggleButtonGroup
          value={1}
          aria-label="text formatting"
          className="flex items-center justify-between-2 justify-end  m-32 "
        >
          <AddCircleOutlineIcon className="m-8" />
          <FormatSizeIcon className="m-8" />
          <DeleteIcon className="m-8" />
          <Typography className="m-8">Required</Typography>
          <ToggleOff className="m-8" />
        </ToggleButtonGroup>
      </Card>
    </>
  );
}
function selectContact(state: unknown): unknown {
  throw new Error("Function not implemented.");
}
function convertHMS(duration: number): unknown {
  throw new Error("Function not implemented.");
}
function setSelectedFile(arg0: any) {
  throw new Error("Function not implemented.");
}

function setIsSelected(arg0: boolean) {
  throw new Error("Function not implemented.");
}
