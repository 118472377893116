import IndexHeader from "app/shared-components/PageHeader";
import React,{useState } from "react";
import YouthTimeSheetFilter from "./components/HostJobApplicationFilter";
import YouthTimeSheetTable from "./components/HostJobApplicationTable";
import HostJobApplicationFilter from "./components/HostJobApplicationFilter";
import HostJobApplicationTable from "./components/HostJobApplicationTable";
import { useTranslation } from "react-i18next";


export default function HostJobApplicationPage() {
    const { t } = useTranslation("hostJobApplication");
  return (
    <>
      <IndexHeader
        name={"Job Application"}
        update={false}
        add={false}
        redirectingPath={""}
        noButtons={true}
      />
      <HostJobApplicationFilter />
      <HostJobApplicationTable showPopup={true} />
    </>
  );
}