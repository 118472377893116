import * as React from "react";
import IndexHeader from "app/shared-components/IndexHeader";
import CustomButton from "app/shared-components/CustomColorButton";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import history from "@history";
import SubmitSalaryDetailsFilter from "./components/SubmitSalaryDetailsFilter";
import SubmitSalaryDetailsTable from "./components/SubmitSalaryDetailsTable";
import { useDispatch } from "react-redux";

const handleClick = () => {
  history.push("incident-form/add");
};

export default function SubmitSalaryDetailsIndexPage() {
  const dispatch = useDispatch();
  return (
    <>
      <IndexHeader name={"Submit Salary Details"} update={false} add={false} />
      <SubmitSalaryDetailsFilter />
      <SubmitSalaryDetailsTable dispatch={dispatch} />
    </>
  );
}
