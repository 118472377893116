import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import FusePageSimple from "@fuse/core/FusePageSimple";
import { useThemeMediaQuery } from "@fuse/hooks";
import Card from "@mui/material/Card";
import { motion } from "framer-motion";
import { OutlinedInput, Typography } from "@mui/material";
import _ from "@lodash";
import * as yup from "yup";
import SearchIcon from "@mui/icons-material/Search";

import { useTranslation } from "react-i18next";
import YouthMailListItem from "./youth-message-list";
import { authDataService } from "../../services/data/auth.data.service";
import DraftsIcon from "@mui/icons-material/Drafts";
import MailDetails from "./message";

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    "& > .container": {
      maxWidth: "100%",
    },
  },
}));

const schema = yup.object().shape({
  to: yup
    .string()
    .required("You must enter an e-mail")
    .email("You must enter a valid e-mail."),
});

const mailData: MailData[] = [
  {
    id: 1,
    username: "Ramesh Vithana",
    subject: "Test subject 1 ",
    date: "07/01/2023",
    content: `Hi ${authDataService.userName}, 
    
    I hope this email finds you well. I am writing to inform you about an exciting opportunity available exclusively for youth like you. The Youth Program for Job Market Success (YPMS) is designed to provide valuable job opportunities, skill development, and career advancement prospects to young individuals.

    YPMS aims to empower youth by offering a platform to gain practical work experience, enhance essential skills, and explore various career paths. We believe that participation in this program can significantly contribute to your personal and professional growth.
    
    Program Details:
    
    Program Name: Youth Program for Job Market Success (YPMS)
    Duration: 1 year
    Start Date: 07/01/2023
    End Date: 07/01/2024
    As a participant in YPMS, you will have the opportunity to engage in workshops, training sessions, and gain practical work experience through various projects and assignments. By joining this program, you will be equipped with the necessary skills and knowledge to excel in the job market and achieve your career goals.
    
    I highly encourage you to take advantage of this unique opportunity. The YPMS program has been meticulously designed to ensure your success and provide you with a competitive edge in today's job market.
    
    To enroll in the YPMS program, please visit our website [insert website link] and complete the registration process. Should you have any questions or require further information, please feel free to reach out to our dedicated support team at [insert contact details].
    
    We look forward to welcoming you to the Youth Program for Job Market Success (YPMS) and witnessing your growth and success.
    
    Best regards,
    
    Ramesh Vithana
    Program Administrator
    YPMS`,
    avatar: "assets/images/avatars/male-14.jpg",
    url: "email/1",
  },
  {
    id: 2,
    username: "Ramesh Vithana",
    subject: "Quote for a new web design project",
    date: "2022/10/10",
    content: `Hi ${authDataService.userName}, 
    
    I hope this email finds you well. As the administrator of the Youth Program for Job Market Success (YPMS), I am pleased to introduce you to our new digital assistant, Emma. Emma is a chat assistant designed to provide you with personalized support and assistance throughout your journey with YPMS.

When you visit the YPMS website, you will notice Emma in the bottom right corner of the screen. She is available to answer any questions you may have, provide guidance, and offer support in multiple languages. Whether you need help navigating the website, understanding program details, or seeking career advice, Emma is here to assist you.

To get started with Emma, simply click on the chat icon in the bottom right corner of the website. She will greet you with a warm welcome message and guide you through the available options. If you prefer a specific language, Emma will offer language selection to ensure seamless communication.

Please feel free to engage with Emma whenever you need assistance. She is programmed to provide relevant information, resources, and address your queries promptly. We believe that Emma will enhance your experience with YPMS and serve as a valuable companion on your journey toward job market success.

If you have any feedback or encounter any issues while interacting with Emma, please do not hesitate to reach out to me directly. Your input is valuable in helping us improve our services and ensure a seamless user experience.

Thank you for being a part of the Youth Program for Job Market Success (YPMS). We are excited to have you on board and look forward to supporting your personal and professional growth.

Best regards,

Ramesh Vithana
YPMS Administrator`,
    avatar: "assets/images/avatars/male-14.jpg",
  },
];

export default function YouthMessages() {
  const isMobile = useThemeMediaQuery((theme: any) =>
    theme.breakpoints.down("lg")
  );
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);

  const [selectedEmail, setSelectedEmail] = useState<MailData>();

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  const { t } = useTranslation("YouthMessages");

  return (
    <>
      <Root
        content={
          <>
            {selectedEmail === undefined ? (
              <Card
                variant="outlined"
                className="m-24 w-full h-full flex items-center justify-center "
              >
                <DraftsIcon
                  className="icon-size-128 mb-16 h-full"
                  color="disabled"
                />
              </Card>
            ) : (
              <Card variant="outlined" className="m-24 w-full overflow-auto ">
                <MailDetails {...selectedEmail} />
              </Card>
            )}
            {/* <div className="flex flex-col flex-1 items-center justify-center p-24 w-full">
              {selectedEmail === undefined ? (
                <DraftsIcon
                  className="icon-size-128 mb-16 h-full"
                  color="disabled"
                />
              ) : (
                <MailDetails {...selectedEmail} />
              )}
            </div> */}
            {/* <div className="m-24">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1, transition: { delay: 0.2 } }}
              >
                <div className="flex flex-col flex-1 items-center justify-center p-24 w-full">
                  {selectedEmail === undefined ? (
                    <DraftsIcon
                      className="icon-size-128 mb-16 h-full"
                      color="disabled"
                    />
                  ) : (
                    <MailDetails {...selectedEmail} />
                  )}
                </div>
                <Outlet></Outlet>
              </motion.div>
            </div> */}
          </>
        }
        leftSidebarContent={
          <div className="px-12 py-24 min-h-6xl mr-8">
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              className="text-4xl font-extrabold tracking-tight items-center leading-none mt-24 mb-24 ml-32 mr-32"
            >
              {t("MAIL_BOX")}
            </Typography>

            <OutlinedInput
              className="flex flex-1 items-center mt-24 px-16 rounded-full"
              fullWidth
              placeholder="Search here..."
              startAdornment={<SearchIcon />}
              inputProps={{
                "aria-label": "Search",
              }}
              size="small"
            />

            <div className="mb-24">
              {mailData.map((item: MailData) => (
                <Link
                  to=""
                  key={item.id}
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    setSelectedEmail(item);
                  }}
                >
                  <YouthMailListItem {...item} />
                </Link>
              ))}
            </div>
          </div>
        }
        leftSidebarOpen={leftSidebarOpen}
        scroll="content"
      />
    </>
  );
}
