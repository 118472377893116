const locale={
    NAME:"Name (First + Last)",
    PHONE:"Phone Number",
    SSN:"SSN",
    EMAIL:"Email",
    STATUS:"Status",
    NAME_ERROR:"You must enter a name",
    EMAIL_ERROR:"You must enter a valid email",
    ID:"ID",
    FIRST_NAME:"First Name",
    LAST_NAME:"Last Name",
    DOB:"DOB",
    AGE:"Age",
    PHONE:"Phone",
    SSN:"SSN",
    EMAIL:"Email",
    ACTION:"Action",
    VIEW:"VIEW",
}
export default locale;