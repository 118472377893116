import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";

import Typography from "@mui/material/Typography";

import React from "react";
import ReactPlayer from "react-player";
import Container from "@mui/material/Container";

export default function Video() {
  return (
    <>
      <ReactPlayer
        width={"100%"}
        url="https://www.youtube.com/watch?v=Y2v4v1A3fCU"
        controls={true}
      />
    </>
  );
}
