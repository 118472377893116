import React from "react";
import { Navigate } from "react-router-dom";
import IncidentForm from "./admin-incident-form";
import AdminIncidentFormIndexPage from "./admin-incident-form-index/AdminIncidentFormIndexPage";
import AdminIncidentFormViewPage from "./admin-incident-form-view/AdminIncidentFormViewPage";
import { UserRole } from "src/app/@types/enums/user.roles";

export const AdminIncidentFormRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Admin],
  routes: [
    {
      path: "admin-incident-form",
      element: <IncidentForm />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <AdminIncidentFormIndexPage />,
        },
        {
          path: "view/:id",
          element: <AdminIncidentFormViewPage />,
        },
      ],
    },
  ],
};
