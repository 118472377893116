import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Paper, TextField, Typography, Card } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import history from "@history";
import { useTranslation } from "react-i18next";
import { Label } from "@mui/icons-material";
import { json } from "stream/consumers";

const defaultValues = {
  cardNumber: "",
  cardFare: "",
  cardIssueDate: "",
};
const schema = yup.object().shape({
  cardNumber: yup
    .string()
    .required("MUST_ENTER_CARD_NUMBER")
    .min(4, "MUST_ENTER_CARD_NUMBER"),
  cardFare: yup.string().required("MUST_ENTER_CARD_FAIR"),
  cardIssueDate: yup.string().required("MUST_ENTER_CARD_ISSUEDATE"),
});
//#endregi
export default function TransportDetails() {
  const { t } = useTranslation("JobApplication");

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  function onSubmit(data: any): void {
    console.log(data);
    reset(defaultValues);
  }

  return (
    <>
      <Typography className="text-20 ml-8 mt-8 font-semibold tracking-tight leading-tight">
        {t("Index_Name")}
      </Typography>
      <Card variant="outlined" className="mt-24">
        <div className="w-full">
          <form
            name="transportDetails"
            noValidate
            className="flex flex-col justify-center w-fullfirst-letter items-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex items-start justify-start">
              <div className="flex flex-col w-full">
                <Controller
                  name="cardNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("Card_Number")}
                      size="small"
                      error={!!errors.cardNumber}
                      helperText={t(errors?.cardNumber?.message ?? "")}
                      variant="outlined"
                      className="mb-24 mt-32"
                      type="text"
                      required
                    />
                  )}
                />
                <Controller
                  name="cardFare"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("Card_Fair")}
                      size="small"
                      error={!!errors.cardFare}
                      helperText={t(errors?.cardFare?.message ?? "")}
                      variant="outlined"
                      className="mb-24"
                      required
                    />
                  )}
                />

                <Controller
                  name="cardIssueDate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      error={!!errors.cardIssueDate}
                      helperText={t(errors?.cardIssueDate?.message ?? "")}
                      variant="outlined"
                      className="mb-24"
                      type="date"
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex items-end place-content-between mb-24">
              <Button
                variant="contained"
                color="secondary"
                className="m-24 rounded-4 pl-4 pr-4 text-white"
                aria-label="Register"
                type="submit"
                size="small"
                disabled={!isValid}
              >
                {t("Submit_Btn")}
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </>
  );
}
