import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import FusePageSimple from "@fuse/core/FusePageSimple";
import { useThemeMediaQuery } from "@fuse/hooks";
import Card from "@mui/material/Card";
import { motion } from "framer-motion";
import { OutlinedInput, Typography } from "@mui/material";
import _ from "@lodash";
import * as yup from "yup";
import SearchIcon from "@mui/icons-material/Search";

import { useTranslation } from "react-i18next";

import { authDataService } from "../../services/data/auth.data.service";
import DraftsIcon from "@mui/icons-material/Drafts";
import MailDetails from "./message";
import HostMailListItem from "./host-message-list";

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    "& > .container": {
      maxWidth: "100%",
    },
  },
}));

const schema = yup.object().shape({
  to: yup
    .string()
    .required("You must enter an e-mail")
    .email("You must enter a valid e-mail."),
});

const mailData: MailData[] = [
  {
    id: 1,
    username: "Ramesh Vithana",
    subject: "Test subject 1 ",
    date: "2022/11/10",
    content: `Hi ${authDataService.userName}, 
    
    I hope this email finds you well. As the administrator of the Youth Program for Job Market Success (YPMS), I am reaching out to you regarding an important matter. It is regarding the submission of payroll to Paychex.

As you are aware, Paychex handles the payroll management for our program, and it is crucial to ensure timely and accurate submissions. I kindly request that you please submit the payroll for the specified period promptly. This will help us ensure that our youth participants receive their payments on time and without any delays.

If you have any questions or require any assistance with the payroll submission process, please don't hesitate to reach out to me. I am here to provide any necessary support and guidance to ensure a smooth and efficient payroll procedure.

Thank you for your attention to this matter and for your commitment to the success of the Youth Program for Job Market Success (YPMS). Your cooperation is greatly appreciated.

Best regards,

Ramesh Vithana
YPMS Administrator`,
    avatar: "assets/images/avatars/male-14.jpg",
    url: "email/1",
  },
  {
    id: 2,
    username: "Bobby Adams",
    subject: "Quote for a new web design project",
    date: "2022/10/10",
    content: `Hi ${authDataService.userName}, 
    
    I hope this email finds you well. As the administrator of the Youth Program for Job Market Success (YPMS), I would like to provide you with an overview of the program's rules and regulations. It is important that all hosts are familiar with these guidelines to ensure a smooth and successful experience for both the youth participants and the hosts.

Program Objectives: The YPMS aims to provide job opportunities and valuable work experience to youth in our community. By participating as a host, you play a crucial role in shaping their career development and fostering their professional growth.

Responsibilities of Hosts: As a host, you are responsible for providing a safe and inclusive work environment for the youth participants. This includes assigning appropriate tasks, offering guidance and mentorship, and ensuring compliance with relevant labor laws and regulations.

Communication and Feedback: Open and effective communication is essential for a successful host-youth partnership. Regular check-ins, providing constructive feedback, and addressing any concerns in a timely manner contribute to the overall development and progress of the youth participants.

Work Schedule and Hours: Hosts are expected to establish a reasonable work schedule for the youth participants, taking into consideration any legal restrictions and the participants' availability. It is important to adhere to the agreed-upon work hours and provide flexibility when necessary.

Workplace Safety: The safety and well-being of the youth participants are of utmost importance. Hosts must ensure a safe working environment, identify and address any potential hazards, and provide necessary training or equipment to mitigate risks.

Confidentiality and Privacy: Hosts should maintain confidentiality and respect the privacy of the youth participants. Any personal or sensitive information shared during the program should be handled with care and only used for program-related purposes.

Performance Evaluation: At the end of the program, hosts are requested to provide a comprehensive performance evaluation for each youth participant. This evaluation helps us assess their progress, identify areas of improvement, and recognize their achievements.

I encourage you to review the detailed program guidelines and policies, which are attached to this email. Should you have any questions or require further clarification, please do not hesitate to reach out to me. Your cooperation and adherence to the YPMS rules and regulations are greatly appreciated.

Thank you for your valuable support and commitment to the success of the Youth Program for Job Market Success (YPMS).

Best regards,

Bobby Adams
YPMS Administrator`,
    avatar: "assets/images/avatars/male-08.jpg",
  },
  {
    id: 3,
    username: "James Pierce",
    subject: "Quote for a new web design project",
    date: "2022/10/10",
    content: `Hi ${authDataService.userName}, 
    
    I hope this email finds you well. As a fellow administrator of the Youth Program for Job Market Success (YPMS), I would like to bring an important matter to your attention regarding the program's requirements.

YPMS program has recently implemented a Parent Consent Form, which is mandatory for all participating youth. This form ensures that the parents or guardians of the youth participants are aware of their involvement in the program and provide their consent for their participation.

I kindly request your assistance in reminding the hosts within the YPMS program about this new requirement. It is crucial that hosts inform their employees, who are involved in supervising the youth participants, about the Parent Consent Form. They should communicate the importance of obtaining consent from the parents or guardians of the youth participants and ensure that the necessary steps are taken to collect the forms.

The Parent Consent Form can be sent directly to the parent or guardian email addresses provided by the youth participants. Hosts should make sure that they have the updated email addresses of the parents or guardians to facilitate this process. It is essential to emphasize the significance of prompt communication and adherence to this requirement.

We greatly appreciate your cooperation in disseminating this information to the hosts and ensuring compliance with the Parent Consent Form. If you have any questions or need further assistance regarding this matter, please feel free to reach out to me.

Thank you for your dedication to the success of the Youth Program for Job Market Success (YPMS).

Best regards,

James Pierce
YPMS Administrator`,
    avatar: "assets/images/avatars/male-01.jpg",
  },
];

export default function HostMessages() {
  const isMobile = useThemeMediaQuery((theme: any) =>
    theme.breakpoints.down("lg")
  );
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);

  const [selectedEmail, setSelectedEmail] = useState<MailData>();

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  const { t } = useTranslation("YouthMessages");

  return (
    <>
      <Root
        content={
          <>
            {selectedEmail === undefined ? (
              <Card
                variant="outlined"
                className="m-24 w-full h-full flex items-center justify-center "
              >
                <DraftsIcon
                  className="icon-size-128 mb-16 h-full"
                  color="disabled"
                />
              </Card>
            ) : (
              <Card variant="outlined" className="m-24 w-full overflow-auto ">
                <MailDetails {...selectedEmail} />
              </Card>
            )}
          </>
        }
        leftSidebarContent={
          <div className="px-12 py-24 min-h-6xl mr-8">
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              className="text-4xl font-extrabold tracking-tight items-center leading-none mt-24 mb-24 ml-32 mr-32"
            >
              {t("MAIL_BOX")}
            </Typography>

            <OutlinedInput
              className="flex flex-1 items-center mt-24 px-16 rounded-full"
              fullWidth
              placeholder="Search here..."
              startAdornment={<SearchIcon />}
              inputProps={{
                "aria-label": "Search",
              }}
              size="small"
            />

            <div className="mb-24">
              {mailData.map((item: MailData) => (
                <Link
                  to=""
                  key={item.id}
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    setSelectedEmail(item);
                  }}
                >
                  <HostMailListItem {...item} />
                </Link>
              ))}
            </div>
          </div>
        }
        leftSidebarOpen={leftSidebarOpen}
        scroll="content"
      />
    </>
  );
}
