import React from "react";
import { Button, Divider, IconButton, styled } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { blueGrey } from "@mui/material/colors";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import history from "@history";

interface Props {
  buttons?: React.ReactNode[];
  name: string;
  update: boolean;
  add: boolean;
  redirectingPath?: string;
  previousPath?: string;
  noButtons?: boolean;
}

interface ButtonProps {
  onClick: () => void;
  text: string;
}

interface MyComponentProps {
  buttons?: React.ReactNode[];
  name: string;
  update: boolean;
  add: boolean;
  redirectingPath?: string;
  previousPath?: string;
  noButtons?: boolean;
}

export default function IndexHeader(props: MyComponentProps) {
  return (
    <>
      <div className="bg-white">
        <div className="flex items-baseline justify-between m-24 mb-12 mt-12 text-sm">
        <div className="flex">
            {(function () {
              if (props.add || props.update) {
                return (
                  <div>
                    <IconButton
                      onClick={() => history.push(props.previousPath ?? "")}
                    >
                      <ArrowBackIos />
                    </IconButton>
                  </div>
                );
              }
            })()}

            <div className="mt-8 text-4xl sm:text-3xl font-extrabold tracking-tight leading-tight">
              {props.name}
            </div>
          </div>
          <div className="flex flex-row gap-16">
            
              {props.buttons && props.buttons.map((button, index) => (
                <div key={index}>{button}</div>
              ))}

          </div>
        </div>
        <Divider />
      </div>
    </>
  );
}
