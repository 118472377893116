import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React from "react";
import Box from "@mui/material/Box";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  FormGroup,
} from "@mui/material";

const defaultValues = { name: "", email: "", subject: "", message: "" };
const schema = yup.object().shape({
  name: yup.string().required("You must enter a name"),
  subject: yup.string().required("You must enter a subject"),
  message: yup.string().required("You must enter a message"),
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
});

function CareerExpo() {
  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  if (_.isEmpty(form)) {
    return null;
  }

  return (
<div className="flex flex-col p-10 sm:p-10 container">
      <div className="flex flex-col w-full max-w-6xl">
        <div className="sm:mt-5"></div>

        <Paper className="mt-5 sm:mt-5 p-24 pb-28 sm:p-5 sm:pb-10 rounded-2xl">
          <form className="px-0 sm:px-24">
            <div className="mb-24"></div>
            <div className="space-y-32">
              <Typography className="mb-40" component="div">
              We will be hosting the MBSYEP Career Expo on Thursday, April 20, 2023 from 10:00 am – 2:00 pm.At this event, you will be able meet certified MBSYEP youth participants ages 14-24, conduct on-site interviews, and determine which candidates are the best fit for your worksite.
              </Typography>
              <div>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                  Please indicate whether or not your agency/organization would like to participate:
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="unsure"
                      control={<Radio />}
                      label="Unsure"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </form>
          <div className="flex items-center justify-end mt-32">
            <Button
              className="mx-8"
              variant="contained"
              color="secondary"
              disabled = {false}
            >
              Save
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default CareerExpo;
