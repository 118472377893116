const locale = {
    INTRODUCTION: 'Introduction',
    INTRODUCTION_CONTENT:'Le Programme emploi jeunesse est une initiative transformatrice conçue pour habiliter les jeunes et les doter des compétences, de l’expérience et des possibilités dont ils ont besoin pour réussir sur le marché du travail.  Reconnaissant le potentiel des jeunes en tant que moteur de la croissance économique et du développement de la société, ce programme vise à combler l’écart entre l’éducation et l’emploi, ouvrant ainsi la voie à un avenir prospère.  Dans le marché du travail concurrentiel d’aujourd’hui, de nombreux jeunes font face à des défis lorsqu’ils tentent d’entrer sur le marché du travail en raison d’un manque d’expérience ou de compétences.  Le Programme emploi jeunesse s’attaque à ces obstacles en offrant un soutien et des ressources complets pour aider les participants à surmonter ces obstacles et à atteindre leurs objectifs de carrière. En investissant dans le développement et l’autonomisation des jeunes, nous favorisons non seulement leur réussite individuelle, mais nous contribuons également à la croissance globale et au bien-être de nos communautés.',
    PROGRAM_OBJECTIVES :'Objectifs du programme',
    OBJECTIVE_CONTENT :'Le Programme emploi jeunesse a trois objectifs principaux. Tout d’abord, le programme met l’accent sur le développement des compétences, offrant des ateliers, des séances de formation et des expériences pratiques pour améliorer les capacités des participants. En les dotant de compétences essentielles telles que la communication, la résolution de problèmes et le travail d’équipe, nous leur permettons de devenir des contributeurs adaptables et précieux dans leurs domaines de prédilection. Deuxièmement, le programme offre aux participants des expériences de travail enrichissantes. Grâce à des stages, des apprentissages ou des emplois d’été, les participants sont exposés à des environnements de travail réels, ce qui leur permet d’appliquer leurs compétences et leurs connaissances dans des contextes pratiques. Ces expériences renforcent non seulement la confiance, mais favorisent également une solide éthique de travail et un réseau professionnel. Enfin, le programme fournit une orientation et un soutien professionnels essentiels.  Les participants sont jumelés à des mentors qui offrent des conseils, partagent leur expertise et les aident à naviguer dans les complexités de la planification de carrière. Grâce au mentorat, à l’orientation professionnelle et aux activités d’exploration, les participants apprennent à comprendre leur cheminement de carrière, à prendre des décisions éclairées et à accéder à des ressources qui leur permettent de poursuivre efficacement leurs objectifs.',
    IMPACT:'Impact et réussites',
    IMPACT_CONTENT:'Le Programme emploi jeunesse a connu des répercussions importantes et des réussites. Les participants qui ont terminé le programme ont obtenu un emploi dans diverses industries, ont poursuivi des études supérieures et sont devenus des leaders dans leurs communautés.  Leur succès reflète non seulement leur croissance individuelle, mais démontre également la capacité des programmes à autonomiser les jeunes et à libérer leur potentiel. De plus, l’impact s’étend au-delà des réussites individuelles. À mesure que les participants deviennent des membres productifs de la main-d’œuvre, ils contribuent à la croissance économique et à la stabilité de leur région. Les programmes mettent l’accent sur le développement d’une main-d’œuvre qualifiée crée un effet d’entraînement positif, ce qui profite aux entreprises, aux collectivités et à la société dans son ensemble. ',
    CONCLUSION:'Conclusion',
    CONCLUSION_CONTENT:'Le Programme emploi jeunesse est un investissement inestimable pour l’avenir. En fournissant aux jeunes les compétences, l’expérience et les conseils dont ils ont besoin, nous favorisons une main-d’œuvre qualifiée, favorisons la croissance économique et créons un avenir meilleur pour nos collectivités. Ensemble, nous pouvons autonomiser les jeunes et libérer leur plein potentiel alors qu’ils deviennent les architectes d’un avenir prospère.',

};
  
export default locale;