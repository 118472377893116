import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  "& > .logo-icon": {
    transition: theme.transitions.create(["width", "height"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  "& > .badge": {
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function Logo() {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    
    navigate("/home"); 
  };
  return (
    <Root className="flex items-center" onClick={handleLogoClick}>
      <img
        className="logo-icon w-32 h-32"
        src="assets/images/logo/logo-ypms.png"
        alt="logo"
      />

      <div
        className="badge flex items-center py-2 px-4 mx-8 rounded cursor-pointer"
        style={{ backgroundColor: "transparent", color: "#61DAFB" }}
      >
        <span className="react-text text-18 mx-4">Codice YPMS</span>
      </div>
    </Root>
  );
}

export default Logo;
