const locale = {
  CREATE_USER: "Crear",
  GENERATE_QR: "Generar código QR",
  SIGNIN: "Iniciar sesión",
  SIGNUP: "Inscribirse",
  LOGIN_INFORMATION: "Información Entrar",
  EMAIL_ADDRESS: "Dirección de correo electrónico",
  CONFIRM_EMAIL_ADDRESS: "Confirmar el correo",
  PASSWORD: "Contraseña",
  CONFIRM_PASSWORD: "Confirmar Contraseña",

  //Error messages
  EMAIL_ADDRESS_NOT_MATCHED_ERROR:
    "Dirección de correo electrónico no coincidente",
  EMAIL_NOT_VALID: "Introduzca una dirección de correo electrónico válida",
  PASSWORD_NOT_MATCHED_ERROR: "Contraseña no coincidente",
  EMAIL_PRIMARY_ERROR:
    "Esta dirección de correo electrónico es la forma principal de comunicarnos con usted. Por lo tanto, si no tiene una dirección de correo electrónico personal, cree una antes.",
  CONFIRM_EMAIL_ERROR: "Confirmar dirección de correo electrónico requerida",
  CONFIRM_PASSWORD_ERROR: "Se requiere confirmar contraseña",

  //Button
  Next: "Próximo",
  Back: "Atrás",

  //#PersonalInfo-----------------
  PERSONAL_DETAILS: "Datos personales",

  //Error Message
  LEGAL_FIRST_NAME_ERROR: "Por favor, agregue su nombre legal",
  LEGAL_LAST_NAME_ERROR: "Por favor, agregue su apellido legal",
  MIDDLE_NAME_ERROR: "Por favor agregue su segundo nombre",
  MAIDEN_NAME_ERROR: "Por favor agregue su nombre de soltera",

  SSN_ERROR: "Se requiere SSN",
  CONFIRM_SSN_ERROR: "Confirmar que se requiere SSN",
  SSN_NOT_MATCHED_ERROR: "SSN no coincide",

  LEGAL_FIRST_NAME: "Nombre legal",
  LEGAL_LAST_NAME: "Apellido legal",
  MIDDLE_NAME: "Segundo nombre",
  MAIDEN_NAME: "Apellido de soltera",
  DATE_OF_BIRTH: "Fecha de nacimiento",
  LANGUAGE: "Idioma",
  GENDER: "Género",
  RACE: "Raza",
  SUFFIX: "Sufijo",
  T_SHIRT_SIZE: "Tamaño de camiseta",
  SOCIAL_SECURITY_NUMBER: "Número de Seguro Social",
  CONFIRM_SSN: "Confirmar SSN",

  //-----------END of PersonalInfoPage------------

  //#EducationalInfo
  //Error Message
  REQUIRED_ERROR: "Este campo debe ser llenado",

  EDUCATIONAL_DETAILS: "Detalles educativos",
  CURRENT_EDUCATIONAL_STATUS: "Situación educativa actual",
  EDUCATIONAL_STUDENT_TYPE: "Tipo de estudiante educativo",
  CURRENT_GRADE: "Grado actual o último grado asistido",
  CURRENT_SCHOOL: "Escuela actual o última escuela a la que asistió",

  //----------END of EducationalInfoPage---------------

  //#ContactInfo
  CONTACT_INFORMATION: "Información de contacto",
  PERMENANT_ADDRESS_DETAILS: "Detalles de la dirección permanente",
  ADDRESS_LINE: "Línea de dirección",
  APT: "Apt.No o Suite",
  STATE: "Estado",
  CITY: "Ciudad",
  ZIP: "Código postal",
  CELL_PHONE: "Teléfono celular",
  HOME_PHONE: "Teléfono residencial",
  PARENT: "Padre o tutor",
  FIRST_NAME: "Nombre",
  LAST_NAME: "Apellido",
  WORK_PHONE: "Teléfono del trabajo",
  RELATIONSHIP: "Relación",
  EMERGENCY: "Emergencia",
  PHONE_NUMBER: "Número de teléfono",

  //--------END OF CONTACT INFO PAGE--------

  //#NotificationInfo
  NOTIFICATION_INFORMATION: "Información de notificación",
  NOTIFICATION_DETAILS: "Detalles de la notificación",
  TWITTER_USER_NAME: "Nombre de usuario de Twitter @",
  CONTACT_TEXT_MESSAGE:
    "¿Podemos comunicarnos con usted a través de un mensaje de texto?",
  COMPUTER_STATUS: "¿Tienes una computadora?",
  INTERNET_STATUS: "¿Tienes acceso a Internet en tu casa?",
  NETWORK_TYPE: "Tipo de red",

  //-----------END of Notification Info Page---------

  //#Career Interest
  CAREER_INTEREST: "Intereses profesionales",
  JOB_ASSIGNMENTS: "Asignaciones de trabajo que preferirías",
  WORK_EXPERIENCE: "Experiencia Laboral",
  WORK_TRAINING: "Preparación para el entrenamiento laboral",
  JOB_MATCHES: "Trabajo que coincide",
  CLOSE_HOME: "Cerca de mi casa",
  MATCHES_CAREER: "Coincide con mi objetivo profesional",
  CAREER_TYPE_1: "Primer interés profesional",
  CAREER_TYPE_2: "Segundo interés profesional",
  CAREER_TYPE_3: "Tercer interés profesional",
  AFTER_SYEP: "Cualquier plan para el futuro, después de SYEP",
  CAREER_GOALS: "¿Cuáles son sus objetivos profesionales a largo plazo?",

  //----------END of Career Interest Page----------------

  //#Supplemenatl Info
  SUPPLEMENTAL_INFORMATION: "Información complementaria",
  SPECIAL_ACCOMODATION: "Alojamiento especial o información médica",
  REQ_SPECIAL_ACCOMODATION: "¿Necesita algún alojamiento especial?",
  MEDICAL_CONDITION:
    "Condición médica que habitaría su capacidad para trabajar al aire libre",
  DEMOGRAPHIC: "Información demográfica",
  OFFENDER: "Delincuente o tribunal involucrado",
  MILITARY: "Sirvió en las Fuerzas Armadas",
  HOMELESS: "Sin hogar",
  PARENT: "Padre",
  MYOWN: "Vivir por mi cuenta",
  FOSTER: "Acogimiento",
  PUBLIC_ASSISTANCE: "Asistencia Pública",
  REQ_PUBLIC_ASSISTANCE:
    "Consulta aquí si tú o tu familia reciben actualmente alguna asistencia pública",
  YES: "Sí",
  INCOME: "Ingresos y tamaño de la familia",
  FAMILY_INCOME: "Ingreso familiar total de los últimos seis meses",
  FAMILY_SIZE: "Total de miembros actuales en su hogar",

  //------END of Supplemental Page--------------

  //#Review Page
  REVIEW_SIGN: "Revisar y firmar",
  DIGITAL_SIGNATURE: "Firma digital",
  CHECKING_INFO:
    "Al marcar la casilla blow, certifico toda aquella información siempre que este formulario sea verdadera y precisa.",
  APPROVE_INFO:
    "Entiendo que esta información está sujeta a verificación, además entiendo que proporcionar cualquier información falsa puede resultar en mi eliminación de una oficina de DOES del programa juvenil y puede someterme a una procesión civil y penal, según corresponda.",
  READ_CHECK:
    "He leído la declaración anterior y me gustaría enviar mi solicitud al Programa de Empleo Juvenil de Verano del Alcalde",
  DATE: "Fecha",
  SIGNATURE: "Firma",
  DOES: "DOES es un empleador / proveedor de igualdad de oportunidades ",
  LANGUAGE_SERVICE:
    "Los servicios de interpretación de idiomas están disponibles sin costo",
  AUXILIARY_AIDS:
    "Los servicios auxiliares y las ayudas están disponibles a petición de las personas con discapacidades",
  NOTICE_HEADER: "Aviso de no discriminación",
  ACCORDANCE:
    "De conformidad con la Ley de Derechos Humanos de D.C. de 1977, según enmendada, la sección 2-1401-01 del código oficial de D.C. y la Ley, el Distrito de Columbia no discrimina por motivos de raza, color, religión, nación, origen, sexo, edad, estado civil, apariencia personal, orientación sexual, identidad o expresión de género, estado familiar, responsabilidades familiares, matriculación, afiliación política,  información genética, discapacidad, fuente de ingresos o lugar de residencia o negocio. El acoso sexual en una forma de discriminación sec que también está prohibida por la ley. Además, el acoso basado en cualquiera de las categorías protegidas mencionadas anteriormente está prohibido por la Ley. No se tolerará la discriminación en violación de la Ley. Los infractores estarán sujetos a medidas disciplinarias",
};

export default locale;
