import IndexHeader from "app/shared-components/PageHeader";
import * as React from "react";
import AdminFormAddUpdate from "./components/AdminFormAddUpdate";

export default function AdminFormAddUpdatePage() {
    return (
    <>
      <AdminFormAddUpdate />
    </>
  );
}
