const locale = {
    INTRODUCTION: 'Giới thiệu',
    INTRODUCTION_CONTENT:'Chương trình Việc làm Thanh niên là một sáng kiến biến đổi được thiết kế để trao quyền cho các cá nhân trẻ và trang bị cho họ các kỹ năng, kinh nghiệm và cơ hội cần thiết để thành công trong lực lượng lao động.Nhận thấy tiềm năng của thanh niên là động lực thúc đẩy tăng trưởng kinh tế và phát triển xã hội, chương trình này nhằm thu hẹp khoảng cách giữa giáo dục và việc làm, mở đường cho một tương lai thịnh vượng. Trong thị trường việc làm cạnh tranh ngày nay, nhiều cá nhân trẻ phải đối mặt với những thách thức khi cố gắng gia nhập lực lượng lao động do thiếu kinh nghiệm hoặc kỹ năng.  Chương trình Việc làm Thanh niên giải quyết những rào cản này bằng cách cung cấp hỗ trợ và nguồn lực toàn diện để giúp người tham gia vượt qua những trở ngại này và đạt được mục tiêu nghề nghiệp của họ. Bằng cách đầu tư vào sự phát triển và trao quyền cho thanh niên, chúng tôi không chỉ thúc đẩy thành công cá nhân của họ mà còn đóng góp vào sự phát triển chung và hạnh phúc của cộng đồng chúng ta.',
    PROGRAM_OBJECTIVES :'Mục tiêu chương trình',
    OBJECTIVE_CONTENT :'Chương trình Việc làm Thanh niên có ba mục tiêu cốt lõi. Thứ nhất, chương trình tập trung vào phát triển kỹ năng, cung cấp các hội thảo, các buổi đào tạo và kinh nghiệm thực hành để nâng cao khả năng của người tham gia. Bằng cách trang bị cho họ những kỹ năng thiết yếu như giao tiếp, giải quyết vấn đề và làm việc theo nhóm, chúng tôi cho phép họ trở thành những người đóng góp có giá trị và thích nghi trong các lĩnh vực họ đã chọn. Thứ hai, chương trình mang đến cho người tham gia những trải nghiệm làm việc có ý nghĩa. Thông qua thực tập, học nghề hoặc công việc mùa hè, người tham gia được tiếp xúc với môi trường làm việc trong thế giới thực, cho phép họ áp dụng các kỹ năng và kiến thức của mình vào môi trường thực tế. Những kinh nghiệm này không chỉ xây dựng sự tự tin mà còn thúc đẩy tinh thần làm việc mạnh mẽ và mạng lưới chuyên nghiệp. Cuối cùng, chương trình cung cấp hướng dẫn và hỗ trợ nghề nghiệp quan trọng. Những người tham gia được ghép nối với các cố vấn cung cấp hướng dẫn, chia sẻ chuyên môn của họ và hỗ trợ điều hướng sự phức tạp của kế hoạch nghề nghiệp. Thông qua các hoạt động cố vấn, tư vấn nghề nghiệp và khám phá, người tham gia có được sự rõ ràng về con đường sự nghiệp của họ, đưa ra quyết định sáng suốt và tiếp cận các nguồn lực cho phép họ theo đuổi mục tiêu của mình một cách hiệu quả.',
    IMPACT:'Tác động và câu chuyện thành công',
    IMPACT_CONTENT:'Chương trình Việc làm Thanh niên đã chứng kiến những tác động và câu chuyện thành công đáng kể. Những người tham gia đã hoàn thành chương trình đã tiếp tục đảm bảo việc làm trong các ngành công nghiệp khác nhau, theo đuổi giáo dục đại học và trở thành nhà lãnh đạo trong cộng đồng của họ. Thành công của họ không chỉ phản ánh sự phát triển cá nhân của họ mà còn thể hiện khả năng của các chương trình để trao quyền cho thanh niên và mở khóa tiềm năng của họ. Hơn nữa, tác động vượt ra ngoài những câu chuyện thành công cá nhân. Khi những người tham gia trở thành thành viên hiệu quả của lực lượng lao động, họ đóng góp vào sự tăng trưởng kinh tế và ổn định của khu vực của họ. Các chương trình nhấn mạnh vào việc phát triển lực lượng lao động lành nghề tạo ra hiệu ứng lan tỏa tích cực, mang lại lợi ích cho các doanh nghiệp, cộng đồng và xã hội nói chung. ',
    CONCLUSION:'Kết thúc',
    CONCLUSION_CONTENT:'Chương trình Việc làm Thanh niên là một khoản đầu tư vô giá trong tương lai. Bằng cách cung cấp cho các cá nhân trẻ các kỹ năng, kinh nghiệm và hướng dẫn mà họ cần, chúng tôi đang nuôi dưỡng một lực lượng lao động lành nghề, thúc đẩy tăng trưởng kinh tế và tạo ra một tương lai tươi sáng hơn cho cộng đồng của chúng tôi. Cùng nhau, chúng ta có thể trao quyền cho giới trẻ và mở khóa tiềm năng đầy đủ của họ khi họ trở thành kiến trúc sư của một ngày mai thịnh vượng.',

};
  
export default locale;