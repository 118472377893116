import React,{useState,ChangeEvent} from 'react'
import _ from '@lodash'
import {Card,TextField,FormControl,Select,MenuItem,FormHelperText,Typography,Checkbox,Divider,InputAdornment,InputLabel,OutlinedInput,FormGroup,Switch,FormControlLabel} from '@mui/material'
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import states from "src/app/@types/static.data/states";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
const schema = yup.object().shape({
    
    socialSecurityNumber:yup.string().required("SSN_ERROR").matches(/^\d{3}-\d{2}-\d{4}$/, 'ZIP code must be in the format XXX-XX-XXXX'),
    state:yup.string(),
    number:yup.number()

})
const defaultValues = { number: "",}

function w4AllowanceWorksheet() {
    const {t} =useTranslation("w4AllowanceCertificate");
    const { control, handleSubmit, watch, formState } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
      });
      const { isValid, dirtyFields, errors } = formState;
      const form = watch();
    
      if (_.isEmpty(form)) {
        return null;
      }
  return (
    <>
    <Card variant="outlined" className="m-24">
    <div className="flex content-between justify-start m-8 ">
    <div className="w-1/3 mt-16 ">
    <img
            className="h-52"
            src="assets/images/logo/logo-ypms-full.png"
            alt="logo"
          />
    </div> 
    <div className=" flex w-1/2 mt-16 ">
    <Typography className='font-800 h-32 mt-8 ml-4'>{t("HEADER")}</Typography>
    </div>  
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
        <Typography className='font-600'>{t("SECA_TITLE")}</Typography>
    </div>
    <Divider className="border-b-3 border-black font-bold h-4 "/>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("A")} </Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/5 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("B")} </Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/5 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("C")} </Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/5 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("D")}  </Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/5 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("E")}  </Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/5 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("F")} </Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/5 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("G")}  </Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/5 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("H")}  </Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/5 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("I")} </Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/5 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
        <Typography className='font-600'>{t("SECB_TITLE")}</Typography>
    </div>
    <Divider className="border-b-3 border-black font-bold h-4 "/>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("J")} </Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/2 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("K")}</Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/2 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("L")}</Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/2 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("M")}</Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/2 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    <Divider/>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("N")}</Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/5 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    <div className="flex content-between justify-start m-8 ">
        <div className=" flex w-4/5 mt-16 ">
            <Typography>{t("O")}</Typography>
        </div>
        <div>
        <Controller
        control={control}
        name="number"
        render={({ field }) => (
            <TextField
                {...field}
                className="ml-16 w-1/5 text-center"
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.number}
                helperText={t(errors?.number?.message ?? "")}
                />
              )}
            />
        </div> 
    </div>
    </Card>
    </>
  )
}

export default w4AllowanceWorksheet
