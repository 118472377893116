import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Pagination,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

interface YouthOpportunityCardProps {
  jobData: JobData[];
}

interface JobData {
  _id: string;
  title: string;
  company: string;
  category: string;
  hourlyRate: string;
  address: string;
  mileage: number;
  startDate: string;
  endDate: string;
  description: string;
  isActive: boolean;
}

const YouthOpportunitiesCard: React.FC<YouthOpportunityCardProps> = ({ jobData }) => {

  const [open, setOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState<JobData | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const totalItems = jobData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const { t } = useTranslation("YouthOpportunitiesPage");
  const handleMoreClick = (job: JobData) => {
    setSelectedJob(job);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentJobs = jobData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Grid container spacing={2}>
      {jobData.map((job) => (
        <Grid item key={job._id} xs={12} sm={6} md={4}>
          <Card className='m-24 w-350'>
            <CardContent>
              <Typography variant="h5" component="div">
                {job.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("COMPANY")} {job.company}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("CATEGORY")} {job.category}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("HOURLY_RATE")} {job.hourlyRate}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("ADDRESS")} {job.address}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("MILEAGE")} {job.mileage}
              </Typography>
              <Button className="m-16 rounded-4" variant="outlined" color="primary" onClick={() => handleMoreClick(job)}>
                {t("MORE")}
              </Button>
              <Button  className="rounded-4" variant="contained" color="primary">
                {t("APPLY")}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Dialog open={open} onClose={handleClose}>
        {selectedJob && (
          <>
            <DialogTitle>{selectedJob.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("CATEGORY")} {selectedJob.category}
              </DialogContentText>
              <DialogContentText>
                {t("START_DATE")} {selectedJob.startDate}
              </DialogContentText>
              <DialogContentText>
                {t("END_DATE")} {selectedJob.endDate}
              </DialogContentText>
              <DialogContentText>
                {t("DESCRIPTION")} 
                <ul style={{ listStyleType: 'disc', marginLeft: '1.5em' }}>
            {selectedJob.description.split('•').map((item, index) => {
              const trimmedItem = item.trim();
              if (trimmedItem) {
                return <li key={index}>{trimmedItem}</li>;
              }
              return null;
            })}
          </ul>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {t("CLOSE")}
              </Button>
            </DialogActions>
          </>
        )}
        </Dialog>
        <Pagination
        count={Math.ceil(jobData.length / itemsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        className="m-auto mb-4"
      />
        
    </Grid>
  );
};

export default YouthOpportunitiesCard;

