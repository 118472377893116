import React from "react";
import { Navigate } from "react-router-dom";
import AdminForm from "./admin-form";
import AdminFormAddUpdatePage from "./admin-form-add-update/AdminFormAddUpdatePage";
import AdminFormIndexPage from "./admin-form-index/AdminFormIndexPage";
import { UserRole } from "src/app/@types/enums/user.roles";

export const AdminFormRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Admin],
  routes: [
    {
      path: "admin-form",
      element: <AdminForm />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <AdminFormIndexPage />,
        },
        {
          path: "add",
          element: <AdminFormAddUpdatePage />,
        },
        {
          path: "update/:id",
          element: <AdminFormAddUpdatePage />,
        },
      ],
    },
  ],
};
