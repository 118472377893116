import React, { Component } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
  TextField,
  Divider,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import history from "@history";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InnerFooter from "app/shared-components/InnerFooter";
import { CheckBoxRef } from "@ypms/types";

//#region form declaration
const schema = yup.object().shape({
  programList: yup.string(),
  companyIdentifierType: yup.string(),
  identificationNumber: yup.string(),
  organizationalType: yup.string(),
  isYouthToBeVaccinated: yup.string(),
  operateType: yup.string(),
  platform: yup.string(),
});

const defaultValues = {
  programList: "",
  companyIdentifierType: "",
  identificationNumber: "",
  organizationalType: "",
  isYouthToBeVaccinated: "",
  operateType: "",
  platform: "",
};
//#endregion

export default function ProgramInfo() {
  const programList: CheckBoxRef[] = [
    { id: 1, label: "Summer Youth Employment Program" },
    { id: 2, label: "In School Youth Program" },
    { id: 3, label: "Out-of-School Youth Program" },
    { id: 4, label: "Youth Leadership Program" },
    { id: 5, label: "Summer Intensive Training" },
    { id: 6, label: "Pathways for Young Adults Program" },
    { id: 7, label: "Youth Earn and Learn Program" },
    { id: 8, label: "School Year Program" },
    { id: 9, label: "Global Education Program" },
  ];

  const ongoingProgramList: any[] = [
    {
      id: 1,
      programName: "Summer Youth Employment Program",
      required: 20,
      joined: 18,
    },
    { id: 2, programName: "In School Youth Program", required: 10, joined: 8 },
    {
      id: 3,
      programName: "Out-of-School Youth Program",
      required: 15,
      joined: 15,
    },
  ];

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [state, setState] = React.useState({
    program: false,
  });

  const handleChange = (event: any) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <>
      <div className="flex grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-4 content-start">
        {programList.map((row: CheckBoxRef, i: number) => {
          return (
            <div key={i}>
              <Controller
                name="programList"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    label={row.label}
                    control={<Checkbox key={i} onChange={handleChange} />}
                  />
                )}
              />
            </div>
          );
        })}
      </div>

      {/* <Typography className="mb-4 mt-24 text-lg font-semibold" color="gray">
        Ongoing Programs
      </Typography>
      <Typography className="mb-16 text-md font-semibold" color="secondary">
        Only the ongoing programs are listed below.Please provide the number of
        youth you wish to recruit under each program.
      </Typography>

      <div className="lg:w-1/2 w-full">
        <div className="rounded-xl border-1 border-gray-400">
          <Table size="small" className="mb-8 mt-8">
            <TableHead>
              <TableRow>
                <TableCell>Program</TableCell>
                <TableCell>No of Youths Required</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ongoingProgramList.map((row) => (
                <TableRow key={row.id}>
                  <TableCell scope="row">{row.programName}</TableCell>
                  <TableCell>
                    <TextField size="small" variant="outlined" type="number" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>

      <div className="flex items-end place-content-end mb-12">
        <Button
          variant="contained"
          color="secondary"
          className="mt-24 min-w-96"
          aria-label="Register"
          type="submit"
          size="medium"
        >
          Save
        </Button>
      </div> */}
      <Divider />
      <Typography className="mb-12 mt-24 text-lg font-semibold" color="gray">
        Past Programs
      </Typography>
      <div className="lg:w-full w-full">
        <div className="rounded-xl border-1 border-gray-400">
          <Table size="small" className="mb-8 mt-8">
            <TableHead>
              <TableRow>
                <TableCell align="center">Program</TableCell>
                <TableCell align="center">No of Youths Required</TableCell>
                <TableCell align="center">No of Youths Joined</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ongoingProgramList.map((row) => (
                <TableRow key={row.id}>
                  <TableCell scope="row" align="left">{row.programName}</TableCell>
                  <TableCell  align="center">{row.required}</TableCell>
                  <TableCell  align="center">{row.joined}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
}
