const locale={
    NAME:"Company Name",
    IDENTIFICATION_NUMBER:"Identification Number",
    EMAIL:"Email",
    STATUS:"Status",
    COMPANY_NAME:"Company Name",
    ID:"ID",
    ID_TYPE:"ID Type",
    ID_NUMBER:"ID Number",
    ADDRESS:"Address",
    EMAIL:"Email",
    ACTION:"Action",
}
export default locale;