import * as React from "react";
import IndexHeader from "app/shared-components/IndexHeader";
import CustomButton from "app/shared-components/CustomColorButton";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import history from "@history";
import AdminProgramFilter from "./components/admin-program-filter";
import AdminProgramList from "./components/admin-program-list";

const handleClick = () => {
  history.push("admin-program-page/add");
};

export default function AdminProgramPageIndex() {
  const myButtons = [
    <CustomButton
      StartIcon={<AddIcon />}
      className="mx-9"
      disabled={false}
      onClick={handleClick}
      name="New"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];
  return (
    <>
      <IndexHeader
        name={"Program Index"}
        update={false}
        add={false}
        buttons={myButtons}
      />
      <AdminProgramFilter />
      <AdminProgramList />
    </>
  );
}
