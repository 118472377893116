import * as React from "react";
import IndexHeader from "app/shared-components/PageHeader";
import { useState } from "react";
import YouthTimeSheetFilter from "./components/YouthTimesheetFilter";
import YouthTimeSheetTable from "./components/YouthTimeSheetTable";


export default function TimeSheetPage() {
  return (
    <>
      <IndexHeader
        name={"Timesheet"}
        update={false}
        add={false}
        redirectingPath={""}
        noButtons={true}
      />
      <YouthTimeSheetFilter />
      <YouthTimeSheetTable />
    </>
  );
}