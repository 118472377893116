import { yupResolver } from "@hookform/resolvers/yup";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  FormGroup,
  Button,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import history from "@history";
import IndexHeader from "app/shared-components/IndexHeader";
import {
  DatePicker,
  DesktopTimePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import AccessibleIcon from "@mui/icons-material/Accessible";
import { TextFormat } from "@mui/icons-material";
import { Divider } from "@mui/material";
import jobCategories from "src/app/@types/static.data/jobCategory";
import workKinds from "src/app/@types/static.data/workKind";
import WYSIWYGEditor from "app/shared-components/WYSIWYGEditor";
import CustomButton from "app/shared-components/CustomColorButton";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

//#region form declaration
const schema = yup.object().shape({
  worksite: yup.string(),
  supervisor: yup.string(),
});

const defaultValues = {
  worksite: "",
  jobCategory: "",
  jobTitle: "",
  jobOfferToYouth: "",
  kindOfWork: "",
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  youthContact: "",
  totalSlots: "",
  ageRequirement: "",
  hourlyRate: "",
  sunday: "",
  monday: "",
  tuesday: "",
  wednesday: "",
  thursday: "",
  friday: "",
  saturday: "",
};

//#endregion

function JobAdd() {
  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function onSubmit(data: any): void {
    history.push(`/sign-up/host/contact-info`);
  }

  const handleClick = () => {
    history.push("/job-index");
  };
  const myButtons = [
    <Button
      className="mx-8"
      variant="contained"
      disabled={false}
      startIcon={<CancelIcon />}
      onClick={handleClick}
    >
      {"Cancel"}
    </Button>,

    <CustomButton
      StartIcon={<SaveIcon />}
      className="mx-8"
      disabled={false}
      onClick={handleClick}
      name="Save"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];

  return (
    <>
      <IndexHeader
        name={"Create Job"}
        update={false}
        add={true}
        previousPath={"/job-index"}
        buttons={myButtons}
      />

      <div className="flex m-24 mb-12 h-1000 ">
        <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
          <div className="flex items-start justify-between">
            <form
              name="hostInfoForm"
              noValidate
              className="flex flex-col justify-center w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex items-start justify-center gap-16 mt-16 ml-10 mr-10">
                <Controller
                  control={control}
                  name="worksite"
                  render={({ field }) => (
                    <FormControl className="w-1/3" size="small">
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Worksite
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        autoWidth
                        label="Worksite"
                      >
                        <MenuItem value={"10"}>Head Office</MenuItem>
                        <MenuItem value={"20"}>Finance Division</MenuItem>
                        <MenuItem value={"30"}>Marketing Division</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="jobCategory"
                  render={({ field }) => (
                    <FormControl className="w-1/3" size="small">
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Job Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        autoWidth
                        label="jobCategory"
                      >
                        {jobCategories.map((jobCategory) => (
                          <MenuItem
                            key={jobCategory?.code}
                            value={jobCategory?.code}
                          >
                            {jobCategory?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />

                <Controller
                  name="jobTitle"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Job Title"
                      size="small"
                      error={!!errors.jobTitle}
                      helperText={errors?.jobTitle?.message}
                      variant="outlined"
                      className="w-1/3"
                    />
                  )}
                />
              </div>
              <div className="flex items-start justify-center gap-16 mt-16 ml-10 mr-10">
                <Controller
                  control={control}
                  name="jobOfferToYouth"
                  render={({ field }) => (
                    <FormControl className="w-1/3" size="small">
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Work Type Offered
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        autoWidth
                        label="jobOfferToYouth"
                      >
                        <MenuItem value={"10"}>Work Experience</MenuItem>
                        <MenuItem value={"20"}>Work Training</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="kindOfWork"
                  render={({ field }) => (
                    <FormControl className="w-1/3" size="small">
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Work Kind One
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        autoWidth
                        label="kindOfWork"
                      >
                        {workKinds.map((workKind) => (
                          <MenuItem key={workKind?.code} value={workKind?.code}>
                            {workKind?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="kindOfWork"
                  render={({ field }) => (
                    <FormControl className="w-1/3" size="small">
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Work Kind Two
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        autoWidth
                        label="kindOfWork"
                      >
                        {workKinds.map((workKind) => (
                          <MenuItem key={workKind?.code} value={workKind?.code}>
                            {workKind?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
              <div className="flex items-start justify-center gap-16 mt-16 ml-10 mr-10">
                <Controller
                  control={control}
                  name="kindOfWork"
                  render={({ field }) => (
                    <FormControl className="w-1/3" size="small">
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Work Kind Three
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        autoWidth
                        label="kindOfWork"
                      >
                        {workKinds.map((workKind) => (
                          <MenuItem key={workKind?.code} value={workKind?.code}>
                            {workKind?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <DatePicker
                      className="w-1/3"
                      label="Start Date"
                      slotProps={{ textField: { size: "small" } }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field }) => (
                    <DatePicker
                      className="w-1/3"
                      label="End Date"
                      slotProps={{ textField: { size: "small" } }}
                    />
                  )}
                />
              </div>
              <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
                <Controller
                  control={control}
                  name="startTime"
                  render={({ field }) => (
                    <TimePicker
                      className="w-1/3"
                      data-mdb-with-icon="true"
                      label="Start Time"
                      slotProps={{ textField: { size: "small" } }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="endTime"
                  render={({ field }) => (
                    <TimePicker
                      className="w-1/3"
                      label="End Time"
                      slotProps={{ textField: { size: "small" } }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="youthContact"
                  render={({ field }) => (
                    <FormControl className="w-1/3" size="small">
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Youth Contact
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        autoWidth
                        label="Youth Contact"
                      >
                        <MenuItem value={"10"}>John Doe</MenuItem>
                        <MenuItem value={"20"}>Andrew Simon</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
              <Divider className="mt-16" />

              <Typography className="ml-10 mt-16 text-2xl font-semibold tracking-tight leading-tight text-blue-900">
                Job Description
              </Typography>
              <div className="flex items-start justify-center gap-16 mt-16 ml-10 mr-10">
                <Controller
                  render={({ field }) => <WYSIWYGEditor {...field} />}
                  name="youthContact"
                  control={control}
                />
              </div>

              <Typography className="ml-10 mt-16 text-xl font-semibold tracking-tight leading-tight">
                Position Information
              </Typography>

              <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox className="mb-8" />}
                    label=" I would like to interview and Screen youth for this position (Please note: By selecting YES you are electing to close your slots. Slots that are closed will not be automatically filled)"
                  />
                  <FormControlLabel
                    control={<Checkbox className="mb-8" />}
                    label=" I would like to select specific youth for this position (Please note: Hosts will only be able to select youth who have successfully applied and completed all necessary steps of registration before the posted deadline)"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label=" I would like for DOES to automatically assign interested youth to any space that I am unable to fill my self"
                  />
                </FormGroup>
              </div>

              <Typography className="ml-10 mt-16 text-xl font-semibold tracking-tight leading-tight">
                In order to fill this position, do you require your youth
                employees to:
              </Typography>
              <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Complete a criminal background check"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Complete a Drug/Alcohol/Controlled Substance Test"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Complete a TB Test"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Complete a Physical Examination"
                  />
                </FormGroup>
              </div>
              <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
                <Controller
                  name="totalSlots"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Total Slots"
                      size="small"
                      error={!!errors.totalSlots}
                      helperText={errors?.totalSlots?.message}
                      variant="outlined"
                      className="w-1/3"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="ageRequirement"
                  render={({ field }) => (
                    <FormControl className="w-1/3" size="small">
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Age Requirement
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        autoWidth
                        label="Age Requirement"
                      >
                        <MenuItem value={"10"}>
                          18-21(not to exceed 25 hours per week)
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  name="hourlyRate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Hourly Rate"
                      size="small"
                      error={!!errors.hourlyRate}
                      helperText={errors?.hourlyRate?.message}
                      variant="outlined"
                      className="w-1/3"
                    />
                  )}
                />
              </div>
              <Divider className="mt-16" />

              <Typography className="ml-10 mt-16 text-xl font-semibold tracking-tight leading-tight">
                Work Days
              </Typography>
              <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
                <Controller
                  control={control}
                  name="monday"
                  render={({ field }) => (
                    <FormControlLabel
                      value="start"
                      control={<Checkbox />}
                      label="Monday"
                      labelPlacement="end"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="tuesday"
                  render={({ field }) => (
                    <FormControlLabel
                      value="start"
                      control={<Checkbox />}
                      label="Tuesday"
                      labelPlacement="end"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="wednesday"
                  render={({ field }) => (
                    <FormControlLabel
                      value="start"
                      control={<Checkbox />}
                      label="Wednesday"
                      labelPlacement="end"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="thursday"
                  render={({ field }) => (
                    <FormControlLabel
                      value="start"
                      control={<Checkbox />}
                      label="Thursday"
                      labelPlacement="end"
                    />
                  )}
                />
              </div>
              <div className="flex items-start gap-16 mt-8 ml-10 mr-10 mb-8">
                <Controller
                  control={control}
                  name="friday"
                  render={({ field }) => (
                    <FormControlLabel
                      value="start"
                      control={<Checkbox />}
                      label="Friday"
                      labelPlacement="end"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="saturday"
                  render={({ field }) => (
                    <FormControlLabel
                      value="start"
                      control={<Checkbox />}
                      label="Saturday"
                      labelPlacement="end"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="sunday"
                  render={({ field }) => (
                    <FormControlLabel
                      value="start"
                      control={<Checkbox />}
                      label="Sunday"
                      labelPlacement="end"
                    />
                  )}
                />
              </div>
            </form>
          </div>
        </Paper>
      </div>
    </>
  );
}

export default JobAdd;
function watch(arg0: string) {
  throw new Error("Function not implemented.");
}
