import React from "react";
import { Navigate } from "react-router-dom";
import AdminTransferForm from "./admin-transfer-form";
import AdminTransferFormIndexPage from "./admin-transfer-form-index/AdminTransferFormIndexPage";
import AdminTransferFormView from "./admin-transfer-form-view/components/AdminTransferFormView";
import AdminTransferFormViewPage from "./admin-transfer-form-view/AdminTransferFormViewPage";
import { UserRole } from "src/app/@types/enums/user.roles";

export const AdminTransferFormRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Admin],
  routes: [
    {
      path: "admin-transfer-form",
      element: <AdminTransferForm />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <AdminTransferFormIndexPage />,
        },
        {
          path: "view/:id",
          element: <AdminTransferFormViewPage />,
        },
      ],
    },
  ],
};
