import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  FormControl,
  Select,
  MenuItem,
  Card,
  Typography,
  FormHelperText,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import IndexHeader from "app/shared-components/IndexHeader";
import { useParams } from "react-router-dom";
import CustomButton from "app/shared-components/CustomColorButton";
import history from "@history";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PrintIcon from "@mui/icons-material/Print";
const defaultValues = {
  participantName: "",
  hostAgency: "",
  worksiteName: "",
  participantPosition: "",
  worksiteSupervisor: "",
  date: "",
  ssn: "",
  safetyIssue: "",
  healthConcern: "",
  siteClosure: "",
  other: "",
  otherReason: "",
  detailedExplanationForTransfer: "",
  youthName: "",
  reasonForDenial: "",
};

const schema = yup.object().shape({});

export default function AdminApprovalTransferView() {
  const routeParams = useParams();
  const { id } = routeParams;

  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  const [values, setValue] = useState({
    employeeType: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue({
      ...values,
      [name]: value,
    });
  };
  const handleClick = () => {
    history.push("admin-approval-form/list");
  };

  const [radioValue, setRadioValue] = useState("");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue(event.target.value);
  };

  return (
    <>
      <IndexHeader
        name={"Transfer Form"}
        update={id ? true : false}
        add={id ? false : true}
        previousPath={"admin-approval-form/list"}
      />

<Card variant="outlined" className="m-24 mb-12">
        <div className="m-24 mt-8">
          <div className="flex grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start">
          <Controller
              control={control}
              name="participantName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Participant Name"
                  placeholder="Participant Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.participantName}
                  helperText={errors?.participantName?.message}
                  value={"David Williams"}
                />
              )}
            />
            <Controller
              control={control}
              name="ssn"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="SSN (Last four digits) XXX-XX-0000"
                  placeholder="SSN (Last four digits)"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.ssn}
                  helperText={errors?.ssn?.message}
                  value={"XXX-XX-1258"}
                />
              )}
            />
            <Controller
              control={control}
              name="hostAgency"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Host Agency"
                  placeholder="Host Agency"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.hostAgency}
                  helperText={errors?.hostAgency?.message}
                  value={"Codice"}
                />
              )}
            />
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <DatePicker
                  className="mt-16 w-full"
                  label="Date"
                  slotProps={{ textField: { size: "small" } }}
                  value={Date.now()}
                />
              )}
            />
            <Controller
              control={control}
              name="worksiteName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Worksite Name"
                  placeholder="Worksite Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.worksiteName}
                  helperText={errors?.worksiteName?.message}
                  value={"Head Office"}
                />
              )}
            />
            <Controller
              control={control}
              name="worksiteSupervisor"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Worksite Supervisor"
                  placeholder="Worksite Supervisor"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.worksiteSupervisor}
                  helperText={errors?.worksiteSupervisor?.message}
                  value={"Thomas Brown"}
                />
              )}
            />

            <Controller
              control={control}
              name="participantPosition"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Participant's Position"
                  placeholder="Participant's Position"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.participantPosition}
                  helperText={errors?.participantPosition?.message}
                  required
                  value={"Accountant Assistance"}
                />
              )}
            />

            <Controller
              control={control}
              name="participantPosition"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Status"
                  placeholder="Status"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.participantPosition}
                  helperText={errors?.participantPosition?.message}
                  required
                  value={"Approved"}
                />
              )}
            />
          </div>

          <Typography className="mt-16 text-2xl font-semibold tracking-tight leading-tight">
            Please check the box indicating the reason for the worksite
            transfer:
          </Typography>
          <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
            <Controller
              control={control}
              name="safetyIssue"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Safety issue (e.g. must have a police report, where applicable) "
                  labelPlacement="end"
                />
              )}
            />
          </div>
          <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
            <Controller
              control={control}
              name="healthConcern"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Health concern (Include a doctor's statement indicating the reason why tasks cannot be performed)"
                  labelPlacement="end"
                  checked
                />
              )}
            />
          </div>
          <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
            <Controller
              control={control}
              name="siteClosure"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Site closure"
                  labelPlacement="end"
                />
              )}
            />
          </div>

          <Typography className="text-1xl tracking-tight mt-16 leading-tight">
            Please provide a detailed explanation supporting your transfer
            request. You may include attachments. Please note that failure to
            provide supporting documentation regarding your transfer request
            will result in an immediate rejection.
          </Typography>
          <div>
            <Controller
              control={control}
              name="detailedExplanationForTransfer"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  variant="outlined"
                  fullWidth
                  multiline
                  size="small"
                  value={"Due to the health Concern David is requesting to transfer to a much closer location"}
                />
              )}
            />
          </div>
        </div>
      </Card>

      <Card variant="outlined" className="m-24 mb-12 p-32">
        <FormControl>
          <div className="flex items-center">
            <p className="mr-8">Transfer form request has been</p>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={radioValue}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Approved"
              />
              <FormControlLabel value="0" control={<Radio />} label="Denied" />
            </RadioGroup>
          </div>
        </FormControl>

        {radioValue != "0" && (
          <div className=" mt-6 flex grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 gap-16 content-start">
            <Controller
              control={control}
              name="youthName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Youth Name"
                  placeholder="Youth Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.youthName}
                  helperText={errors?.youthName?.message}
                />
              )}
            />

            <Controller
              control={control}
              name="hostAgency"
              render={({ field }) => (
                <FormControl error={!!errors.hostAgency}>
                  <Select
                    {...field}
                    value={field.value || ""}
                    onChange={handleChange}
                    displayEmpty
                    size="small"
                    className="mt-16 w-full"
                  >
                    <MenuItem value="" disabled>
                      New Host Agency
                    </MenuItem>
                    <MenuItem value={"10"}>Coco Cola</MenuItem>
                    <MenuItem value={"20"}>Yahoo</MenuItem>
                    <MenuItem value={"30"}>Walmart</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.hostAgency?.message}</FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="worksiteSupervisor"
              render={({ field }) => (
                <FormControl error={!!errors.worksiteSupervisor}>
                  <Select
                    {...field}
                    value={field.value || ""}
                    onChange={handleChange}
                    displayEmpty
                    size="small"
                    className="mt-16 w-full"
                  >
                    <MenuItem value="" disabled>
                      New Worksite Supervisor
                    </MenuItem>
                    <MenuItem value={"10"}>Andrew Smith</MenuItem>
                    <MenuItem value={"20"}>George Cooper</MenuItem>
                    <MenuItem value={"30"}>Will Smith</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.worksiteSupervisor?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="worksiteName"
              render={({ field }) => (
                <FormControl error={!!errors.worksiteName}>
                  <Select
                    {...field}
                    value={field.value || ""}
                    onChange={handleChange}
                    displayEmpty
                    size="small"
                    className="mt-16 w-full"
                  >
                    <MenuItem value="" disabled>
                      New Worksite Name
                    </MenuItem>
                    <MenuItem value={"10"}>Head Office</MenuItem>
                    <MenuItem value={"20"}>Financial Division</MenuItem>
                    <MenuItem value={"30"}>Marketing Division</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.worksiteName?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <DatePicker
                  className="mt-16 w-full"
                  label="Date"
                  slotProps={{ textField: { size: "small" } }}
                />
              )}
            />
          </div>
        )}
        {radioValue === "0" && (
          <Controller
            control={control}
            name="reasonForDenial"
            render={({ field }) => (
              <TextField
                {...field}
                className="w-full mt-16 "
                label="Reason for Denial"
                placeholder="Enter the reason for denial"
                multiline
                rows={4}
                variant="filled"
                fullWidth
                size="small"
                error={!!errors.reasonForDenial}
                helperText={errors?.reasonForDenial?.message}
              />
            )}
          />
        )}
        <div className="flex items-center gap-15">
          <Button
            variant="contained"
            color="secondary"
            className="mt-24 min-w-96"
            aria-label="Register"
            type="submit"
            size="medium"
          >
            Download
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className="mt-24 ml-10 min-w-96"
            aria-label="Register"
            type="submit"
            size="medium"
          >
            Save
          </Button>
        </div>
      </Card>
    </>
  );
}
