const locale = {
  SIGN_IN: "Iniciar sesión",
  DONT_HAVE_ACCOUNT: "¿No tienes una cuenta?",
  SIGNUP: "Inscribirse",
  EMAIL: "Correo electrónico",
  PASSWORD: "Contraseña",
  REMEMBER_ME: "Acuérdate de mí",
  FORGOT_PASSWORD: "¿Has olvidado tu contraseña?",

  //Error messages
  VALID_EMAIL_ERROR: "Debes ingresar un correo electrónico válido",
  MUST_ENTER_EMAIL_ERROR: "Debes ingresar un correo electrónico",
  PASSWORD_ERROR: "Por favor, introduzca su contraseña.",
  PASSWORD_SHORT:
    "La contraseña es demasiado corta; debe tener al menos 4 caracteres.",
};

export default locale;
