import React from "react";
import YouthTimeSheetPage from "./youth-timesheet-index/YouthTimesheetIndexPage";
import YouthTimeSheet from "./youth-timesheet";
import { UserRole } from "src/app/@types/enums/user.roles";
import i18next from "i18next";
import english from "./i18n/english";
import TimeSheetPage from "./youth-timesheet-index/YouthTimesheetIndexPage";
import TimeRecorder from "./youth-time-record/youth-time-record";

i18next.addResourceBundle("en", "youthTimeSheetPage", english);

export const YouthTimeSheetRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Youth],
  routes: [
    {
      path: "timesheet",
      element: <YouthTimeSheet />,
      children: [
        {
          path: "calender",
          element: <TimeSheetPage />,
        },
        {
          path: "time-record",
          element: <TimeRecorder />,
        },
      ],
    },
  ],
};
