import { Button, Box } from "@mui/material";
import React, { useRef, useState } from "react";
import SignatureCanvas, {
  ReactSignatureCanvasProps,
} from "react-signature-canvas";
import { YouthReviewAndSign } from "../@types/interfaces/youth.review.and.sign";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { youthSignUpService } from "../services/api/youth.signup.service";
import { showMessage } from "app/store/fuse/messageSlice";

const SignaturePad: React.FC<ReactSignatureCanvasProps> = (props) => {
  const signatureRef = useRef<any>(null);

  const { userId } = useParams();
  const dispatch = useDispatch();
  const [msg, setMsg] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileId, setProfileId] = useState<any>("");

  const onSubmitSignature = async (data: YouthReviewAndSign) => {
    setIsLoading(true);
    setIsError(false);
    setMsg("");
    try {
      const msg = await youthSignUpService.addReviewAndSignInformation({
        userId: userId,
        signature: data.signature,
        signedDate: data.signedDate,
        acceptTerms: data.acceptTerms,
        signed: true,
      });
      setMsg(msg);
      dispatch(showMessage({ message: msg, variant: "success" }));
    } catch (error: any) {
      dispatch(showMessage({ message: error, variant: "error" }));
      setIsError(true);
      setMsg(error);
    }
    setIsLoading(false);
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
  };

  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    event.preventDefault();

    return new Promise<void>((resolve, reject) => {
      if (signatureRef.current) {
        const base64Signature = signatureRef.current.toDataURL();
        const youthSignModel: YouthReviewAndSign = {
          signature: base64Signature,
          signedDate: new Date(),
          acceptTerms: false,
          signed: true,
        };

        setIsLoading(true);
        setIsError(false);
        setMsg("");

        onSubmitSignature(youthSignModel)
          .then(() => {
            // Handle successful submission or any other logic here

            // Example: Show a success message
            dispatch(
              showMessage({
                message: "Signature submitted successfully",
                variant: "success",
              })
            );

            setIsLoading(false);
            resolve();
          })
          .catch((error) => {
            // Handle any errors that occur during submission

            // Example: Show an error message
            dispatch(
              showMessage({
                message: "Signature submission failed",
                variant: "error",
              })
            );

            setIsLoading(false);
            reject(error);
          });
      } else {
        resolve();
      }
    });
  };

  return (
    <>
      <div className="mt-20">
        <SignatureCanvas ref={signatureRef} {...props} />
      </div>
      <div className="flex justify-center mt-20 gap-4">
        <Button
          variant="contained"
          color="secondary"
          className="mt-24 min-w-96"
          aria-label="Register"
          type="submit"
          size="medium"
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          className="mt-24 min-w-96"
          type="submit"
          size="medium"
          onClick={handleClear}
        >
          Clear
        </Button>
      </div>
    </>
  );
};

export default SignaturePad;
