import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import DraftsIcon from "@mui/icons-material/Drafts";
import React from "react";

const SelectMailMessage = () => {
  return (
    <div className="flex flex-col flex-1 items-center justify-center p-24 w-full">
      <DraftsIcon className="icon-size-128 mb-16 h-full" color="disabled" />
      <Typography
        className="mt-16 text-2xl font-semibold tracking-tight"
        color="text.secondary"
      ></Typography>
    </div>
  );
};

export default SelectMailMessage;
