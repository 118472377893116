import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import FusePageSimple from "@fuse/core/FusePageSimple";
import FuseNavigation from "@fuse/core/FuseNavigation";
import { useThemeMediaQuery } from "@fuse/hooks";
import Card from "@mui/material/Card";
import { motion } from "framer-motion";
import InnerFooter from "app/shared-components/InnerFooter";
import { any } from "prop-types";
import IndexHeader from "app/shared-components/IndexHeader";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import _ from "@lodash";
import WYSIWYGEditor from "app/shared-components/WYSIWYGEditor";
import { openDialog } from "app/store/fuse/dialogSlice";
import { isValid } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MailAttachment from "./mailAttachment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import SearchIcon from "@mui/icons-material/Search";
//import host,admin questions
import SelectYouth from "./selectYouth";
import SelectHost from "./selectHost";
import AdminMessagesPage from "./admin-messagePage";
import MailListItem from "./mailListItem";
import history from "@history";
import { useTranslation } from "react-i18next";
import { EmailProvider } from "./context/emailContext";
import Mail from "./mail";

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    "& > .container": {
      maxWidth: "100%",
    },
  },
}));

const schema = yup.object().shape({
  to: yup
    .string()
    .required("You must enter an e-mail")
    .email("You must enter a valid e-mail."),
});
const navigationData = [
  {
    id: "1",
    title: "OutBox",
    type: "group",
    children: [
      {
        id: "1",
        subject: "Resume",
        type: "item",
        icon: "heroicons-outline:document",
        url: "resume",
      },
      {
        id: "2",
        title: "Content",
        type: "item",
        icon: "heroicons-outline:chat-bubble-left",
        url: "email2",
      },
      {
        id: "3",
        title: "Content",
        type: "item",
        icon: "heroicons-outline:chat-bubble-left",
        url: "email2",
      },
    ],
  },
];

const mailData = [
  {
    id: 1,
    username: "Dejesus Michael",
    subject: "Test subject 1 ",
    date: "2022/11/10",
    content:
      "Do incididunt cillum duis eu pariatur enim proident minim officia amet proident consequat consequat qui consequat magna magna occaecat aliquip culpa pariatur velit nisi nostrud irure eu ullamco exercitation sint.</p><p>Cillum deserunt laborum laborum quis nisi enim et aliquip labore excepteur in excepteur labore amet in ipsum ipsum nostrud deserunt lorem nisi voluptate dolor minim enim ut eu cupidatat enim.",
    avatar: "assets/images/avatars/male-01.jpg",
    url: "email/1",
  },
  {
    id: 2,
    username: "Myra Dudley",
    subject: "Test subject",
    date: "2022/1/10",
    content:
      "Hi Brian,\n\nUllamco deserunt commodo esse deserunt deserunt quis eiusmod. Laborum sint excepteur non sit eiusmod sunt voluptate ipsum nisi ullamco magna. Lorem consectetur est dolor minim exercitation deserunt quis duis fugiat ipsum incididunt non. Anim aute ipsum cupidatat nisi occaecat quis sit nisi labore labore dolore do. Pariatur veniam culpa quis veniam nisi exercitation veniam ut. Quis do sint proident fugiat ad.\n\nNon id nisi commodo veniam. Veniam veniam minim ea laborum voluptate id duis deserunt. Anim ut ut amet et ullamco nulla fugiat id incididunt adipisicing excepteur amet. Ex amet eu cillum non fugiat velit dolore. Incididunt duis est eu et ex sunt consectetur cillum nisi aute proident.\n\nIncididunt excepteur laborum quis sit. Ex quis officia incididunt proident aliqua adipisicing. Irure ad in Lorem laborum deserunt nulla consequat. Pariatur excepteur exercitation cupidatat aute.\n\nCheers!\nMyra Dudley",
    avatar: "assets/images/avatars/female-01.jpg",
    url: "email/2",
  },
  {
    id: 3,
    username: "Karina Alford",
    subject: "Quote for a new web design project",
    date: "2022/10/10",
    content:
      "Hey Brian,\n\nNisi officia aliqua ex non cupidatat sint ullamco. Irure pariatur ullamco consequat ut eu anim. Ut ad elit pariatur est non sunt. Tempor dolore quis commodo dolore duis officia laboris nostrud sint. Exercitation ullamco laboris eiusmod culpa ut.\n\nAute Lorem aute occaecat dolore tempor ipsum proident fugiat deserunt non incididunt velit nulla. Dolor pariatur tempor amet qui eu exercitation. Tempor minim culpa proident nisi esse ea. Enim est fugiat aliqua aliqua aute velit laborum cupidatat irure nisi dolor deserunt aliqua.\n\nFugiat ut dolor tempor sunt aliquip dolor nostrud. Consequat incididunt ullamco cillum dolore excepteur deserunt est dolor aliquip irure do mollit officia. Consectetur cillum et non minim nisi. Esse quis sunt deserunt elit sint velit tempor et ullamco laboris officia excepteur. Veniam ad ut aliqua sunt consequat reprehenderit nostrud non in duis aute quis pariatur. Occaecat mollit anim non pariatur. Ad do ad id fugiat et culpa laborum esse cupidatat voluptate elit ut magna voluptate.\n\nBest Regards,\nKarina Alford",
    avatar: "assets/images/avatars/female-02.jpg",
  },
];

export default function AdminMessages() {
  const [programId, setProgram] = React.useState(0);

  const handleChange = (event: any) => {
    setProgram(event.target.value);
  };
  const [openDialog, setOpenDialog] = useState(false);
  const isMobile = useThemeMediaQuery((theme: any) =>
    theme.breakpoints.down("lg")
  );
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const { handleSubmit, formState, control } = useForm({
    mode: "onChange",
    defaultValues: {
      from: "johndoe@creapond.com",
      to: "",
      cc: "",
      bcc: "",
      subject: "",
      message: "",
    },
    resolver: yupResolver(schema),
  });

  //let selectedItem: any = null;
  let selectedItem: any[] = [];
  const [selectedEmail, setSelectedEmail] = useState([any]);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  function handleOpenDialog() {
    setOpenDialog(true);
  }

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  function handleDiscard() {
    setOpenDialog(false);
  }

  function onSubmit(data: any) {
    console.info(data);
    setOpenDialog(false);
  }

  const { isValid, dirtyFields, errors } = formState;
  const { t } = useTranslation("AdminMessages");

  return (
    <>
      <Root
        content={
          <Card variant="outlined" className="m-24 w-full">
            <div className="m-24">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1, transition: { delay: 0.2 } }}
              >
                <Outlet></Outlet>
              </motion.div>
            </div>
          </Card>
        }
        leftSidebarContent={
          <div className="px-12 py-24 min-h-6xl mr-8">
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              className="text-4xl font-extrabold tracking-tight items-center leading-none mt-24 mb-24 ml-32 mr-32"
            >
              {t("MAIL_BOX")}
            </Typography>

            <OutlinedInput
              className="flex flex-1 items-center mt-24 px-16 rounded-full"
              fullWidth
              placeholder="Search here..."
              startAdornment={<SearchIcon />}
              inputProps={{
                "aria-label": "Search",
              }}
              size="small"
            />
            <Button
              variant="contained"
              color="secondary"
              className="mt-24 mb-24 ml-32 mr-32 rounded-8 pl-32 pr-32 text-white items-center"
              aria-label="Register"
              type="submit"
              size="small"
              onClick={handleOpenDialog}
            >
              <AddIcon /> {t("COMPOSE")}
            </Button>
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              aria-labelledby="form-dialog-title"
              className="w-1000"
              scroll="body"
            >
              <AppBar
                position="static"
                color="secondary"
                elevation={0}
                className="w-full"
              >
                <Toolbar className="flex w-full">
                  <Typography variant="subtitle1" color="inherit">
                    {t("NEW_MESSAGE")}
                  </Typography>
                </Toolbar>
              </AppBar>

              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col"
              >
                <DialogContent>
                  <Select
                    size="small"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={programId}
                    defaultValue={0}
                    onChange={handleChange}
                    className="mt-16 w-full"
                  >
                    <MenuItem value={0} disabled>
                      Select Program
                    </MenuItem>
                    <MenuItem value={10}>
                      Summer Youth Employment Program - 2023
                    </MenuItem>
                    <MenuItem value={20}>
                      In-School Youth Programs - 2023
                    </MenuItem>
                    <MenuItem value={30}>
                      Youth Leadership Program - 2023
                    </MenuItem>
                    <MenuItem value={40}>
                      Pathways for Young Adults Program - 2023
                    </MenuItem>
                  </Select>
                  <Card variant="outlined" className="mt-32 mb-32">
                    <SelectYouth />
                  </Card>
                  <Card variant="outlined" className="mt-32 mb-32">
                    <SelectHost />
                  </Card>

                  <Controller
                    name="cc"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mt-8 mb-16"
                        label={t("CC")}
                        id="cc"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />

                  <Controller
                    name="subject"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mt-8 mb-16"
                        label={t("SUBJECT")}
                        id="subject"
                        name="subject"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />

                  <Controller
                    render={({ field }) => <WYSIWYGEditor {...field} />}
                    name="message"
                    control={control}
                  />

                  <div className="pt-8">
                    <MailAttachment fileName="attachment-2.doc" size="12 kb" />
                    <MailAttachment fileName="attachment-1.jpg" size="350 kb" />
                  </div>
                </DialogContent>

                <DialogActions className="flex flex-col sm:flex-row sm:items-center justify-between py-16 sm:py-24 px-24">
                  <div className="-mx-8">
                    <InsertLinkIcon />
                    <AttachFileIcon />
                    <AddPhotoAlternateIcon />
                  </div>

                  <div className="flex items-center space-x-8 mt-16 sm:mt-0">
                    <Button
                      className=""
                      variant="outlined"
                      color="secondary"
                      onClick={handleDiscard}
                    >
                      {t("DISCARD")}
                    </Button>
                    <Button className="" variant="outlined" color="secondary">
                      {t("SAVED_AS_GRAPH")}
                    </Button>

                    <Button
                      className=""
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={_.isEmpty(dirtyFields) || !isValid}
                    >
                      {t("SEND")}
                    </Button>
                  </div>
                </DialogActions>
              </form>
            </Dialog>

            <div className="mb-24">
              {mailData.map((item: any) => (
                <Link
                  to={`email/${item.id}`}
                  key={item.id}
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    selectedItem = item;
                    setSelectedEmail(selectedItem);
                    console.log(JSON.stringify(selectedEmail));
                    <Mail selectedEmail={selectedEmail} />;
                  }}
                >
                  <MailListItem key={item.id} item={item} />
                </Link>
              ))}
            </div>
          </div>
        }
        leftSidebarOpen={leftSidebarOpen}
        scroll="content"
      />
    </>
  );
}
