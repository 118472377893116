import React from "react";
import { Button, Divider, IconButton, styled } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { blueGrey } from "@mui/material/colors";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import history from "@history";

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: blueGrey[900],
  },
}));

interface Props {
  name: string;
  update: boolean;
  add: boolean;
  redirectingPath?: string;
  previousPath?: string;
  noButtons?: boolean;
}

export default function PageHeader(props: Props) {
  return (
    <>
      <div className="bg-white">
        <div className="flex items-baseline justify-between m-24 mb-12 mt-12 text-sm">
          <div className="flex">
            {(function () {
              if (props.add || props.update) {
                return (
                  <div>
                    <IconButton
                      onClick={() => history.push(props.previousPath ?? "")}
                    >
                      <ArrowBackIos />
                    </IconButton>
                  </div>
                );
              }
            })()}

            <div className="mt-8 text-4xl sm:text-3xl font-extrabold tracking-tight leading-tight">
              {props.name}
            </div>
          </div>
          <div className="flex flex-row gap-16">
            {(function () {
              if (props.add || props.update) {
                return (
                  <div>
                    <Button
                      className="mx-8"
                      variant="contained"
                      disabled={false}
                      startIcon={<CancelIcon />}
                    >
                      {"Cancel"}
                    </Button>

                    <ColorButton
                      className="mx-8"
                      variant="contained"
                      color="primary"
                      disabled={false}
                      startIcon={<SaveIcon />}
                    >
                      {props.update == true ? "Update" : "Save"}
                    </ColorButton>
                  </div>
                );
              } else {
                if(props.noButtons){
                  return (
                    <div></div>
                  );
                }else{
                return (
                  <ColorButton
                    className="mx-8"
                    variant="contained"
                    color="primary"
                    disabled={false}
                    startIcon={<AddIcon />}
                    onClick={() => history.push(props.redirectingPath ?? "")}
                  >
                    {"New"}
                  </ColorButton>
                );}
              }
            })()}
          </div>
        </div>
        <Divider />
      </div>
    </>
  );
}
