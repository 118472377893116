import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  FormControl,
  Select,
  MenuItem,
  Card,
  Typography,
  FormHelperText,
  Button,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import IndexHeader from "app/shared-components/IndexHeader";
import { useParams } from "react-router-dom";
import CustomButton from "app/shared-components/CustomColorButton";
import history from "@history";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PrintIcon from "@mui/icons-material/Print";
import ProgramService from "src/app/services/api/program.service";
import { log } from "console";

export default function EnrollFormUpdate() {
  const routeParams = useParams();
  const { id } = routeParams;

  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
  });

  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  interface Program {
    _id: string;
    code: number;
    name: string;
    createdAt: string;
    isActive: boolean;
    updatedAt: string;
  }

  const handleClick = async () => {
    //history.push("youth-program/enroll");
    try {
      const apiData = await ProgramService.enrollProgram(selectedIds);
      console.log(apiData);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelClick =async ()=>{
    history.push("/home")
  }

  const myButtons = [
    <Button
      className="mx-8"
      variant="contained"
      disabled={false}
      startIcon={<CancelIcon />}
      onClick={cancelClick}
    >
      {"Cancel"}
    </Button>,

    <CustomButton
      StartIcon={<SaveIcon />}
      className="mx-8"
      disabled={false}
      onClick={handleClick}
      name="Save"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];

  const [data, setData] = useState<Program[] | null>(null);
  const [enrolled, setEnrolled] = useState<Program[] | null>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleChange =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
      } else {
        setSelectedIds((prevSelectedIds) =>
          prevSelectedIds.filter((selectedId) => selectedId !== id)
        );
      }
    };

  const fetchData = async () => {
    try {
      const apiData = await ProgramService.getData();
      setData(apiData.data as Program[]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <IndexHeader
        name={"Register"}
        update={id ? true : false}
        add={id ? false : true}
        previousPath={"youth-program/enroll"}
        buttons={myButtons}
      />

      <Card variant="outlined" className="m-24 mb-12">
        <Typography className="mt-16 text-2xl font-semibold tracking-tight leading-tight ml-16">
          Ongoing Programs
        </Typography>

        <div className="flex items-start justify-start gap-16 mb-16 ml-16">
          {data ? (
            <>
              <div className="w-1/3">
                <FormGroup>
                  {data.slice(0, 5).map((item: Program, index: number) => (
                    <FormControlLabel
                      key={index}
                      control={<Checkbox onChange={handleChange(item._id)} />}
                      label={item.name}
                    />
                  ))}
                </FormGroup>
              </div>

              <div className="w-1/3">
                <FormGroup>
                  {data.slice(5).map((item: Program, index: number) => (
                    <FormControlLabel
                      key={index + 5}
                      control={<Checkbox onChange={handleChange(item._id)} />}
                      label={item.name}
                    />
                  ))}
                </FormGroup>
              </div>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </Card>
    </>
  );
}
