const locale = {
  //select Youth UI
  SELECT_YOUTH: "청소년 선택",
  ALL: "모두",
  RETURNING: "귀국",
  ELIGIBLE: "자격이 있는",
  COMPLETED_ORIENTATION: "완료된 오리엔테이션",
  CERTIFIED: "인증",
  SAA_VERIFIED: "SAA 인증",
  SAA_UNVERIFIED: "SAA 미확인",
  ADDRESS_VERIFIED: "주소 확인됨",
  ADDRESS_UNVERIFIED: "확인되지 않은 주소",
  BANK_COMPLETED: "은행 완료",
  UNCOMPLETED_ORIENTATION: "미완성 오리엔테이션",
  UNCOMPLETED_CERTIFICATION: "완료되지 않은 인증",
  UNCOMPLETED_BANKING: "미완성 은행",
  EMPLOYED: "고용",
  UNEMPLOYED: "한가한",

  //select Youth UI
  SELECT_HOST: "호스트 선택",
  POSTED_JOBS: "게시된 작업",
  HIRED_YOUTH: "고용된 청년",
  AGREED_SUBMITTED: "동의 제출됨",
  AGREED_NOT_SUBMITTED: "동의하지 않음",

  //Admin Messages UI
  MAIL_BOX: "우편함",
  COMPOSE: "구성하다",
  NEW_MESSAGE: "새로운 메시지",
  DISCARD: "버리다",
  SAVED_AS_GRAPH: "초안으로 저장됨",
  SEND: "보내다",
  SUBJECT: "주제",

  CC: "CC",
};

export default locale;
