import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  FormControl,
  Select,
  MenuItem,
  Card,
  Typography,
  FormHelperText,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputAdornment
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import IndexHeader from "app/shared-components/IndexHeader";
import { useParams } from "react-router-dom";
import CustomButton from "app/shared-components/CustomColorButton";
import history from "@history";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search"
const defaultValues = {
  firstName: "",
  lastName:"",
  email: "",
  password:"",
  confirmPassword:"",
  inActive:"",
  program:"",
  
};

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Enter valid Email address"),
  isUnder18: yup.boolean().default(false),
  isCreateSystemAccount: yup.boolean().default(false),
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), ""], "Password not matched"),
});

export default function AdminFormAddUpdate() {
  const routeParams = useParams();
  const { id } = routeParams;

  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  const [values, setValue] = useState({
    employeeType: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue({
      ...values,
      [name]: value,
    });
  };
  const handleClick = () => {
    history.push("admin-form/list");
  };

  const myButtons = [
    <Button
      className="mx-8"
      variant="contained"
      disabled={false}
      startIcon={<CancelIcon />}
      onClick={handleClick}
    >
      {"Cancel"}
    </Button>,

    <CustomButton
      StartIcon={<SaveIcon />}
      className="mx-8"
      disabled={false}
      onClick={handleClick}
      name="Save"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];

  const [selectedOption, setSelectedOption] = useState('AD Account');

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };


  return (
    <>
      <IndexHeader
        name={id ? "Update User" : "Create User"}
        update={id ? true : false}
        add={id ? false : true}
        previousPath={"admin-form/list"}
         
      />

      <Card variant="outlined" className="m-24 mb-12">
        <div className="m-24 mt-8">

          <div className="flex grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 mt-16 content-start">
          <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={selectedOption}
                        onChange={handleOptionChange}
                      >
                        <FormControlLabel
                          value="AD Account"
                          control={<Radio />}
                          label="AD Account"
                        />
                        <FormControlLabel
                          value="System Account"
                          control={<Radio />}
                          label="System Account"
                        />
            </RadioGroup>
          </div>
          <div className="flex grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 mt-16 gap-16 content-start">
          <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  InputProps={{
                    endAdornment: selectedOption === 'AD Account' ? (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ) : null,
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="firstName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="First Name"
                  placeholder="First Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.firstName}
                  helperText={errors?.firstName?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Last Name"
                  placeholder="Last Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.lastName}
                  helperText={errors?.lastName?.message}
                />
              )}
            />
          </div>

          <div style={{ display: selectedOption === 'System Account' ? 'block' : 'none' }}>
            <div className="flex grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 mt-16 gap-16 content-start">
            
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Password"
                  placeholder="Password"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="confirmPassword"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Confirm Password"
                  placeholder="Password"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.confirmPassword}
                  helperText={errors?.confirmPassword?.message}
                />
              )}
            />
            
            </div>
          </div>

          <div className="flex items-start gap-16 mt-16  mr-10">
            <Controller
              control={control}
              name="inActive"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Inactive"
                  labelPlacement="end"
                />
              )}
            />
          </div>
          <Typography className=" mt-16 text-2xl font-semibold tracking-tight leading-tight">
            Programs:
          </Typography>

          <div className="flex grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 mt-1 gap-16 content-start">
          <Controller
              control={control}
              name="program"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Summer Youth Employment Program"
                  labelPlacement="end"
                />
              )}
            />
            <Controller
              control={control}
              name="program"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="In-School-Youth Program"
                  labelPlacement="end"
                />
              )}
            />
            <Controller
              control={control}
              name="program"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Out-of-School-Youth Program"
                  labelPlacement="end"
                />
              )}
            />
            <Controller
              control={control}
              name="program"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Youth Leadership Program"
                  labelPlacement="end"
                />
              )}
            />
            <Controller
              control={control}
              name="program"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Summer Intensive Training"
                  labelPlacement="end"
                />
              )}
            />
            <Controller
              control={control}
              name="program"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Pathways for young Adults"
                  labelPlacement="end"
                />
              )}
            />
            <Controller
              control={control}
              name="program"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Youth Earn & Learn Program"
                  labelPlacement="end"
                />
              )}
            />
            <Controller
              control={control}
              name="program"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="School Year Program"
                  labelPlacement="end"
                />
              )}
            />
            <Controller
              control={control}
              name="program"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Global Education Program"
                  labelPlacement="end"
                />
              )}
            />
          </div>
        </div>
      </Card>
    </>
  );
}
