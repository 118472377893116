import { Segment } from "@mui/icons-material";
import { bottom } from "@popperjs/core";
import React from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";

interface ChatModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const YouthChatBot: React.FC<ChatModalProps> = ({ isOpen, onClose }) => {
  const steps = [
    {
      id: "1",
      message:
        "Hello! Welcome to Codice YPMS Portal. My name is Emma your Digital Assistant. Please select your language to proceed. ",
      trigger: "2",
    },
    {
      id: "2",
      options: [
        { value: 1, label: "English – US", trigger: "3" },
        { value: 2, label: "Chinese (中文)", trigger: "3" },
        { value: 3, label: "French (Français)", trigger: "3" },
        { value: 4, label: "Korean (한국어)", trigger: "3" },
        { value: 5, label: "Spanish (Español)", trigger: "3" },
        { value: 6, label: "Vietnamese (Tiếng Việt)", trigger: "3" },
      ],
    },
    {
      id: "3",
      message:
        "Please choose the program for which you wish to know information.",
      trigger: "4",
    },
    {
      id: "4",

      options: [
        {
          value: 1,
          label:
            "The Mayor Marion S. Barry Summer Youth Employment Program (MBSYEP)",
          trigger: "5",
        },
        { value: 2, label: "In-School Youth Programs (ISY)", trigger: "5" },
        { value: 3, label: "Out-of-School Youth Programs (OSY)", trigger: "5" },
        { value: 5, label: "Youth Leadership Program (MBYLI)", trigger: "5" },
        { value: 5, label: "Summer Intensive Training (SIT)", trigger: "5" },
        { value: 6, label: "School Year Program (SYP)", trigger: "5" },
        { value: 7, label: "Global Education Program (GEP)", trigger: "5" },
      ],
    },
    {
      id: "5",
      message: "Please select the program year.",
      trigger: "6",
    },
    {
      id: "6",

      options: [
        {
          value: 1,
          label: "2020",
          trigger: "5",
        },
        { value: 2, label: "2021", trigger: "7" },
        { value: 3, label: "2022", trigger: "7" },
        { value: 4, label: "2023", trigger: "7" },
        { value: 5, label: "2024", trigger: "7" },
      ],
    },
    {
      id: "7",
      message: "Select your question.",
      trigger: "8",
    },
    {
      id: "8",

      options: [
        {
          value: 1,
          label: "What are the MBSYEP Dates?",
          trigger: "9",
        },
        { value: 2, label: "When will the application open?", trigger: "10" },
        { value: 3, label: "When is the application deadline?", trigger: "11" },
        {
          value: 4,
          label: "How do I turn in documents for certification?",
          trigger: "12",
        },
        {
          value: 5,
          label: "What if I do not have an email account? ",
          trigger: "13",
        },
        {
          value: 6,
          label: "Will the program be in-person, virtual, or hybrid?",
          trigger: "14",
        },
        {
          value: 7,
          label: "What if I need to change my address?",
          trigger: "15",
        },
        {
          value: 7,
          label: "Will I be paid for the Holiday, July 4, 2023?",
          trigger: "16",
        },
        {
          value: 7,
          label: "When will I receive my US Bank Focus Card?",
          trigger: "17",
        },
      ],
    },
    {
      id: "9",
      message:
        "The 6-week MBSYEP program will run from Monday, June 26, 2023 to Friday, August 4, 2023.",
      trigger: "18",
    },
    {
      id: "10",
      message:
        "Monday, January 30, 2023, at noon. Please visit the home page at summerjobs.dc.gov to apply.",
      trigger: "18",
    },
    {
      id: "11",
      message: "Monday, March 6, 2023.",
      trigger: "18",
    },
    {
      id: "12",
      message:
        "Once you have completed the application you can attend one of our in-person certification events. The last in-person certification event is Saturday, March 11, 2023, at the Washington Convention Center. Certification invitations will go out to the e-mail you provided on your application. Virtual certification will open for all MBSYEP participants on  Monday, March 6, 2023, and will close on Saturday, March 11, 2023, at 11:59 PM. Please submit your documents virtually if you are not able to attend one of our in-person events. Please allow staff between 1 to 2 business days for review. Continue to check your youth portal for updates.  ",
      trigger: "18",
    },
    {
      id: "13",
      message:
        "All applicants must have an established email account, owned solely by them, to receive all correspondence regarding the 2023 MBSYEP. Emailed updates will include: the application status, information regarding your assigned worksite, instructions for selecting a method to receive your stipend and/or paycheck, general program information and updates. In some cases, you will also receive items through traditional mail. If you do not have a personal email address, please visit one of the following links below to get a free email address before registering.Gmail, AOL, Outlook, Zoho, Mail.com, ProtonMail, iCloud Mail, GMX Mail, Mozilla Thunderbird, Yandex Mail",
      trigger: "18",
    },
    {
      id: "14",
      message:
        "There will be a limited number of virtual and hybrid jobs. 90% of our jobs will be in person.",
      trigger: "18",
    },
    {
      id: "15",
      message:
        "You will be required to provide proof of residency for the new address. Please email us at summerjobs@dc.gov for further details.",
      trigger: "18",
    },
    {
      id: "16",
      message:
        "No, all MBSYEP programming will be closed on Tuesday, July 4, 2023, in observance of Independence Day.",
      trigger: "18",
    },
    {
      id: "17",
      message:
        "US Bank cards will be mailed to the address listed on your application. All cards will be mailed prior to your first pay date. If you do not have your US Bank Focus Card by the first pay date or if you have ANY problems with your card (e.g., lost or stolen card, forgotten PIN number, or card transaction problem) you must call US Bank at 1-877-474-0010. We strongly encourage all youth to open their own bank account locally and to sign up for Direct Deposit.",
      trigger: "18",
    },
    {
      id: "18",

      options: [
        {
          value: 1,
          label: "Please click here to view the question list again.",
          trigger: "8",
        },
        {
          value: 2,
          label: "Please click here to view the DOES contact details",
          trigger: "19",
        },
      ],
    },
    {
      id: "19",
      message: "Please email us at summerjobs@dc.gov for further details",
    },
  ];
  const theme = {
    background: "#f5f8fb",
    headerBgColor: "#202441",
    headerFontColor: "#fff",
    headerFontSize: "16px",
    botBubbleColor: "#202441",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
    footerBgColor: "#fff",
    footerFontColor: "#4a4a4a",
  };
  const floatingStyle = {
    bottom: "80px",
    transformOrigin: "bottom right",
  };
  return (
    <>
      <div className="mt-10">
        <ThemeProvider theme={theme}>
          <ChatBot
            steps={steps}
            userAvatar={"assets/images/avatars/male-07.jpg"}
            headerTitle={"Youth Support Chat"}
            floating={true}
            floatingStyle={floatingStyle}
            footerStyle={{
              backgroundColor: theme.footerBgColor,
              color: theme.footerFontColor,
            }}
          />
        </ThemeProvider>
      </div>
    </>
  );
};

export default YouthChatBot;
