import * as React from "react";
import IndexHeader from "app/shared-components/IndexHeader";
import CustomButton from "app/shared-components/CustomColorButton";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import history from "@history";
import YouthOpportunitiesFilter from "./components/YouthOpportunitiesFilter";
import YouthOpportunitiesCard from "./components/YouthOpportunitiesCard";


export default function YouthOpportunitiesViewPage() {
    const jobData = [
        {
          _id: "1",
          title: "Software Engineer",
          company: "Codice",
          category: "Information Technology",
          hourlyRate: "$10/hr",
          address: "Washington",
          mileage: 80,
          startDate: "7/6/2023",
          isActive: true,
          endDate: "8/20/2023",
          description:"•Analyze user requirements • Write and test code, refining and rewriting it as necessary and communicate with any programmers involved in the project    •Research, design and write new software programs (e.g. business applications or computer games) and computer operating systems •Evaluate the software and systems that make computers and hardware work •Develop existing programs by analyzing and identifying areas for modification •	Integrate existing software products and get incompatible platforms to work together     •Create technical specifications   •Write systems to control the scheduling of jobs or to control the access allowed to users or remote systems",
        },
        {
            _id: "2",
            title: "Data Engineer",
            company: "Codice",
            category: "Information Technology",
            hourlyRate: "$10/hr",
            address: "Washington",
            mileage: 80,
            startDate: "2022-01-01",
            isActive: true,
            endDate: "2022-01-01",
            description: "Test",
          },
          {
            _id: "3",
            title: "Quality Engineer",
            company: "ABC Pharma",
            category: "Information Technology",
            hourlyRate: "$15/hr",
            address: "Washington",
            mileage: 90,
            startDate: "2022-01-01",
            isActive: true,
            endDate: "2022-01-01",
            description: "Test",
          },
          {
            _id: "4",
            title: "Tech Lead",
            company: "ABC Pharma",
            category: "Information Technology",
            hourlyRate: "$30/hr",
            address: "Washington",
            mileage: 90,
            startDate: "2022-01-01",
            isActive: true,
            endDate: "2022-01-01",
            description: "Test",
          },
          
        
      ];
 
  return (
    <>
      <IndexHeader
        name={"Job Opportunities"}
        update={false}
        add={false}
        
      />
      <YouthOpportunitiesFilter />
      <YouthOpportunitiesCard jobData={jobData}/>
    </>
  );
}
