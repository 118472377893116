import React from "react";
import WorksiteIndex from "./work-site-index/WorksiteIndexPage";
import { Navigate } from "react-router-dom";
import WorkSite from "./Work-Site";
import WorksiteAddUpdate from "./worksite-add-update/WorksiteAddUpdate";
import WorksiteIndexPage from "./work-site-index/WorksiteIndexPage";
import { UserRole } from "src/app/@types/enums/user.roles";

export const WorkSiteRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Host],
  routes: [
    {
      path: "worksite",
      element: <WorkSite />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <WorksiteIndexPage />,
        },
        {
          path: "add",
          element: <WorksiteAddUpdate />,
        },
      ],
    },
  ],
};
