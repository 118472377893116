import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import BackIcon from "@mui/icons-material/Backspace";
import {
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Card,
  MenuItem,
  FormHelperText,
} from "@mui/material";

const defaultValues = { status: "", participantName: "" };
const schema = yup.object().shape({});

function JobApplicationFilter() {
  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  if (_.isEmpty(form)) {
    return null;
  }

  return (
    <>
      <Card variant="outlined" className="m-24 mb-0">
        <div className="flex content-between justify-start m-24">
          <div className="w-1/3  mr-16">
            <Controller
              control={control}
              name="participantName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Search by Participant Name (First + Last)"
                  placeholder="Search by Participant Name (First + Last)"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.participantName}
                  helperText={errors?.participantName?.message}
                  required
                />
              )}
            />
          </div>

          <div className="w-1/3 mt-16 mr-16">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select status"
                >
                  <MenuItem value={"1"}>Hired</MenuItem>
                  <MenuItem value={"2"}>Pending</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="mt-20">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <SearchIcon />
            </IconButton>
          </div>
          <div className="mt-20  mr-1">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <BackIcon />
            </IconButton>
          </div>
        </div>
      </Card>
    </>
  );
}

export default JobApplicationFilter;
