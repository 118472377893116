import IndexHeader from "app/shared-components/PageHeader";
import * as React from "react";
import AdminTerminationFormAddUpdate from "./components/AdminTerminationFormAddUpdate";

export default function HostTerminationFormAddPage() {
  return (
    <>
      <AdminTerminationFormAddUpdate />
    </>
  );
}
