import * as React from "react";
import { MenuItem, Select } from "@mui/material";
import { useLocation } from "react-router-dom";

const SelectProgram = () => {
  const location = useLocation();

  const selectedRoutes = [
    "/program/",
    "/employees/",
    "/my-profile/",
    "/admin-transfer-form",
    "/admin-incident-form",
    "/admin-termination-form/",
    "/admin-approval-form/",
    "/admin-form/",
    "/questionnaire-form/",
    "/admin-program-page/",
    "/youth-program/",
    "/home",
    "/timesheet",
    "/w4-tax-form",
    "/youth-salary-details",
    "/admin-messages",
    "/admin-view-answers",
    "/admin-youth-list",
    "/admin-host-list",
    "/submit-salary-details",
    "/my-profile",
  ];

  const isMatch = (route: string): boolean => {
    return location.pathname.includes(route);
  };
  const [programId, setProgram] = React.useState(0);

  const handleChange = (event: any) => {
    setProgram(event.target.value);
  };
  return (
    <>
      {/* {!selectedRoutes.some(isMatch) && (
        <Select
          size="small"
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={programId}
          defaultValue={0}
          onChange={handleChange}
          className="mt-16"
        >
          <MenuItem value={0} disabled>
            Select Program
          </MenuItem>
          <MenuItem value={10}>Summer Youth Employment Program - 2023</MenuItem>
          <MenuItem value={20}>In-School Youth Programs - 2023</MenuItem>
          <MenuItem value={30}>Youth Leadership Program - 2023</MenuItem>
          <MenuItem value={40}>
            Pathways for Young Adults Program - 2023
          </MenuItem>
        </Select>
      )} */}
    </>
  );
};

export default SelectProgram;
