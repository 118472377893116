import React from "react";
import JobIndex from "./job-index/JobIndexPage";
import JobAddPage from "./job-add/JobAddPage";
import { UserRole } from "src/app/@types/enums/user.roles";

export const JobRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Host],
  routes: [
    {
      path: "job-add",
      element: <JobAddPage />,
    },
    {
      path: "job-index",
      element: <JobIndex />,
    },
  ],
};
