import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/Add";
import GeneralInfo from "./components/generalInformation";
import { Button } from "@mui/material";
import CustomButton from "app/shared-components/CustomColorButton";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import IndexHeader from "app/shared-components/IndexHeader";
import { id } from "date-fns/locale";
import AgeRequirement from "./components/ageRequirment/ageRequirement";
import PayrollInformationPage from "./components/payrollInformation/payrollInformation";
import UploadDocumentsPage from "./components/uploadDocuments/uploadDocumentst";
import YouthImportantDays from "./components/youthImportantDays/youthImportantDates";
import HostImportantDays from "./components/hostImportantDays/hostImportantDates";
import CareerExpoInfoPage from "./components/careerExpoInfo";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

export default function adminProgramAddUpdatePage() {
  const routeParams = useParams();
  const { id } = routeParams;

  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
  });

  const { isValid, dirtyFields, errors } = formState;
  const form = watch();
  return (
    <>
      <IndexHeader
        name={"Create Program"}
        update={id ? true : false}
        add={id ? false : true}
        previousPath={"admin-program-page/list"}
        buttons={[]}
      />

      <div className="m-24">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="mt-8 text-2xl sm:text-1xl font-bold tracking-tight leading-tight">
              General Info
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <GeneralInfo />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <div className="mt-8 text-2xl sm:text-1xl font-bold tracking-tight leading-tight">
              Age Requirement
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <AgeRequirement />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <div className="mt-8 text-2xl sm:text-1xl font-bold tracking-tight leading-tight">
              Payroll Information
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <PayrollInformationPage />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <div className="mt-8 text-2xl sm:text-1xl font-bold tracking-tight leading-tight">
              Upload Documents
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <UploadDocumentsPage />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <div className="mt-8 text-2xl sm:text-1xl font-bold tracking-tight leading-tight">
              Youth Important Days
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <YouthImportantDays />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <div className="mt-8 text-2xl sm:text-1xl font-bold tracking-tight leading-tight">
              Host Important Days
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <HostImportantDays />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <div className="mt-8 text-2xl sm:text-1xl font-bold tracking-tight leading-tight">
              Career Expo Info
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <CareerExpoInfoPage />
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
