const locale = {
  CREATE_USER: "만들다",
  GENERATE_QR: "QR 생성",
  SIGNIN: "로그인",
  SIGNUP: "가입하기",
  LOGIN_INFORMATION: "로그인 정보",
  EMAIL_ADDRESS: "이메일 주소",
  CONFIRM_EMAIL_ADDRESS: "이메일 주소 확인",
  PASSWORD: "비밀번호",
  CONFIRM_PASSWORD: "비밀번호 확인",

  //Error messages
  EMAIL_ADDRESS_NOT_MATCHED_ERROR: "이메일 주소가 일치하지 않음",
  EMAIL_NOT_VALID: "유효한 이메일 주소를 입력하세요",
  PASSWORD_NOT_MATCHED_ERROR: "비밀번호가 일치하지 않음",
  EMAIL_PRIMARY_ERROR:
    "이 이메일 주소는 귀하와 소통하는 기본 수단입니다. 따라서 개인 이메일 주소가 없는 경우 먼저 이메일 주소를 만드십시오.",
  CONFIRM_EMAIL_ERROR: "이메일 주소 확인 필요",
  CONFIRM_PASSWORD_ERROR: "비밀번호 확인이 필요합니다",

  //Button
  Next: "다음",
  Back: "뒤로",

  //#PersonalInfo-----------------
  PERSONAL_DETAILS: "개인 정보",

  //Error Message
  LEGAL_FIRST_NAME_ERROR: "법적 이름을 추가하세요.",
  LEGAL_LAST_NAME_ERROR: "법적 성을 추가하십시오.",
  MIDDLE_NAME_ERROR: "중간 이름을 추가하세요.",
  MAIDEN_NAME_ERROR: "결혼 전 이름을 추가하세요.",

  SSN_ERROR: "SSN이 필요합니다.",
  CONFIRM_SSN_ERROR: "SSN이 필요한지 확인",
  SSN_NOT_MATCHED_ERROR: "SSN이 일치하지 않음",

  LEGAL_FIRST_NAME: "법적 이름",
  LEGAL_LAST_NAME: "법적 성",
  MIDDLE_NAME: "중간 이름",
  MAIDEN_NAME: "구성",
  DATE_OF_BIRTH: "생년월일",
  LANGUAGE: "언어",
  GENDER: "성별",
  RACE: "인종",
  SUFFIX: "접미사",
  T_SHIRT_SIZE: "티셔츠 사이즈",
  SOCIAL_SECURITY_NUMBER: "사회 보장 번호",
  CONFIRM_SSN: "SSN 확인",

  //-----------END of PersonalInfoPage------------

  //#EducationalInfo
  //Error Message
  REQUIRED_ERROR: "이 필드는 채워져야 합니다.",

  EDUCATIONAL_DETAILS: "교육 세부 사항",
  CURRENT_EDUCATIONAL_STATUS: "현재 교육 현황",
  EDUCATIONAL_STUDENT_TYPE: "학력 학생 유형",
  CURRENT_GRADE: "현재 학년 또는 마지막 학년 참석",
  CURRENT_SCHOOL: "현재 학교 또는 마지막으로 다녔던 학교",

  //----------END of EducationalInfoPage---------------

  //#ContactInfo
  CONTACT_INFORMATION: "연락처 정보",
  PERMENANT_ADDRESS_DETAILS: "영구 주소 세부 정보",
  ADDRESS_LINE: "주소 입력란",
  APT: "Apt.No 또는 스위트",
  STATE: "상태",
  CITY: "도시",
  ZIP: "우편 번호",
  CELL_PHONE: "핸드폰",
  HOME_PHONE: "집 전화",
  PARENT: "부모 또는 보호자",
  FIRST_NAME: "이름",
  LAST_NAME: "성",
  WORK_PHONE: "직장 전화",
  RELATIONSHIP: "관계",
  EMERGENCY: "비상",
  PHONE_NUMBER: "전화번호",

  //--------END OF CONTACT INFO PAGE--------

  //#NotificationInfo
  NOTIFICATION_INFORMATION: "알림 정보",
  NOTIFICATION_DETAILS: "통지 세부 정보",
  TWITTER_USER_NAME: "트위터 사용자 이름 @",
  CONTACT_TEXT_MESSAGE: "문자 메시지로 연락 할 수 있습니까?",
  COMPUTER_STATUS: "컴퓨터가 있습니까?",
  INTERNET_STATUS: "집에서 인터넷에 접속할 수 있습니까?",
  NETWORK_TYPE: "네트워크 유형",

  //-----------END of Notification Info Page---------

  //#Career Interest
  CAREER_INTEREST: "경력 관심 분야",
  JOB_ASSIGNMENTS: "선호하는 작업 할당",
  WORK_EXPERIENCE: "직장 경험",
  WORK_TRAINING: "작업 훈련 준비",
  JOB_MATCHES: "일치하는 작업",
  CLOSE_HOME: "내 집과 가까움",
  MATCHES_CAREER: "내 경력 목표와 일치",
  CAREER_TYPE_1: "첫 번째 최고 경력 관심",
  CAREER_TYPE_2: "두 번째로 높은 경력 관심",
  CAREER_TYPE_3: "세 번째로 높은 경력 관심",
  AFTER_SYEP: "SYEP 이후의 향후 계획",
  CAREER_GOALS: "장기적인 경력 목표는 무엇입니까?",

  //----------END of Career Interest Page----------------

  //#Supplemenatl Info
  SUPPLEMENTAL_INFORMATION: "보충 정보",
  SPECIAL_ACCOMODATION: "특별 숙박 시설 / 의료 정보",
  REQ_SPECIAL_ACCOMODATION: "특별한 숙소가 필요하신가요?",
  MEDICAL_CONDITION: "외부에서 일할 수 있는 능력을 유지하는 의학적 상태",
  DEMOGRAPHIC: "인구통계학적 정보",
  OFFENDER: "관련 범죄자 또는 법원",
  MILITARY: "군 복무",
  HOMELESS: "노숙자",
  PARENT: "부모",
  MYOWN: "혼자 살기",
  FOSTER: "양육",
  PUBLIC_ASSISTANCE: "공공부조",
  REQ_PUBLIC_ASSISTANCE:
    "귀하 또는 귀하의 가족이 현재 공공 지원을 받고 있는지 여기에서 확인하십시오",
  YES: "예",
  INCOME: "소득 및 가족 규모",
  FAMILY_INCOME: "지난 6개월 동안의 총 가족 소득",
  FAMILY_SIZE: "현재 가구의 총 구성원 수",

  //------END of Supplemental Page--------------

  //#Review Page
  REVIEW_SIGN: "검토 및 서명",
  DIGITAL_SIGNATURE: "디지털 서명",
  CHECKING_INFO:
    "상자 타격을 확인함으로써 이 양식이 사실이고 정확하다면 모든 정보를 인증합니다.",
  APPROVE_INFO:
    "본인은 이 정보가 검증에 사용된다는 것을 이해하며, 허위 정보를 제공하면 DOES 청소년 프로그램에서 제명될 수 있으며 해당되는 경우 민사 및 형사 소송을 당할 수 있음을 이해합니다.",
  READ_CHECK:
    "위의 진술을 읽었으며 시장 여름 청년 고용 프로그램에 신청서를 제출하고 싶습니다",
  DATE: "날짜",
  SIGNATURE: "서명",
  DOES: "DOS는 기회 균등 고용주/제공자입니다. ",
  LANGUAGE_SERVICE: "언어 통역 서비스를 무료로 이용하실 수 있습니다.",
  AUXILIARY_AIDS: "보조 서비스 및 지원은 장애인을 위한 요청 시 제공됩니다.",
  NOTICE_HEADER: "차별 금지 통지",
  ACCORDANCE:
    "1977 년 DC 인권법에 따라, 개정 된 DC 공식 코드 섹션 2-1401-01 이하 참조, (법) 컬럼비아 주 (Distict of Columbia)는 인종, 피부색, 종교, 국가, 출신, 성별, 나이, 결혼 여부, 외모, 성적 취향, 성 정체성 또는 지출, 가족 상태, 가족 자격, 가족 자격, 입학, 정치적 제휴,  유전 정보, 장애, 수입원 또는 거주지 또는 사업장.법에 의해 금지된 초차별의 한 형태의 성희롱. 또한, 위의 보호 대상 행위에 근거한 괴롭힘은 법에 의해 금지되며, 법을 위반하는 차별은 용납되지 않습니다. 위반자는 차별적 조치를 받게 됩니다 ",
};

export default locale;
