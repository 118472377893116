import IndexHeader from "app/shared-components/PageHeader";
import * as React from "react";
import TerminationFormAddUpdate from "./components/TerminationFormAddUpdate";

export default function TerminationFormAddUpdatePage() {
    return (
    <>
      <TerminationFormAddUpdate />
    </>
  );
}
