import { AxiosInstance } from "axios";
import { ApiInterceptor } from "../../../app/configs/interceptorts/api.interceptor";

export class CoreService {
  protected axios: AxiosInstance;

  constructor() {
    this.axios = ApiInterceptor.getInstance().axios;
  }
}
