import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  FormControl,
  Select,
  MenuItem,
  Card,
  Typography,
  FormHelperText,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import IndexHeader from "app/shared-components/IndexHeader";
import { useParams } from "react-router-dom";
import CustomButton from "app/shared-components/CustomColorButton";
import history from "@history";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PrintIcon from "@mui/icons-material/Print";
const defaultValues = {
  participantName: "",
  hostAgency: "",
  worksiteName: "",
  participantPosition: "",
  worksiteSupervisor: "",
  date: "",
  ssn: "",
  safetyIssue: "",
  healthConcern: "",
  siteClosure: "",
  other: "",
  otherReason: "",
  detailedExplanationForTransfer: "",
  youthName:""
};

const schema = yup.object().shape({});

export default function AdminTransferFormView() {
  const routeParams = useParams();
  const { id } = routeParams;

  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  const [values, setValue] = useState({
    employeeType: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue({
      ...values,
      [name]: value,
    });
  };
  const handleClick = () => {
    history.push("Transfer-form/add");
  };

  const myButtons = [
    <Button
      className="mx-8"
      variant="contained"
      disabled={false}
      startIcon={<CancelIcon />}
    >
      {"Cancel"}
    </Button>,
    <CustomButton
      StartIcon={<PrintIcon />}
      className="mx-8"
      disabled={false}
      onClick={handleClick}
      name="Print"
      color="primary"
      variant="contained"
    ></CustomButton>,
    <CustomButton
      StartIcon={<SaveIcon />}
      className="mx-8"
      disabled={false}
      onClick={handleClick}
      name="Download"
      color="primary"
      variant="contained"
    ></CustomButton>,
  ];

  return (
    <>
      <IndexHeader
        name={"Transfer Form"}
        update={id ? true : false}
        add={id ? false : true}
        previousPath={"admin-transfer-form/list"}
        buttons={myButtons}
      />

      <Card variant="outlined" className="m-24 mb-12">
        <div className="m-24 mt-8">
          <div className="flex grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-16 content-start">
          <Controller
              control={control}
              name="participantName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Participant Name"
                  placeholder="Participant Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.participantName}
                  helperText={errors?.participantName?.message}
                  value={"David Williams"}
                />
              )}
            />
            <Controller
              control={control}
              name="ssn"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="SSN (Last four digits) XXX-XX-0000"
                  placeholder="SSN (Last four digits)"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.ssn}
                  helperText={errors?.ssn?.message}
                  value={"XXX-XX-1258"}
                />
              )}
            />
            <Controller
              control={control}
              name="hostAgency"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Host Agency"
                  placeholder="Host Agency"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.hostAgency}
                  helperText={errors?.hostAgency?.message}
                  value={"Codice"}
                />
              )}
            />
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <DatePicker
                  className="mt-16 w-full"
                  label="Date"
                  slotProps={{ textField: { size: "small" } }}
                  value={Date.now()}
                />
              )}
            />
            <Controller
              control={control}
              name="worksiteName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Worksite Name"
                  placeholder="Worksite Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.worksiteName}
                  helperText={errors?.worksiteName?.message}
                  value={"Head Office"}
                />
              )}
            />
            <Controller
              control={control}
              name="worksiteSupervisor"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Worksite Supervisor"
                  placeholder="Worksite Supervisor"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.worksiteSupervisor}
                  helperText={errors?.worksiteSupervisor?.message}
                  value={"Thomas Brown"}
                />
              )}
            />

            <Controller
              control={control}
              name="participantPosition"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Participant's Position"
                  placeholder="Participant's Position"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.participantPosition}
                  helperText={errors?.participantPosition?.message}
                  required
                  value={"Accountant Assistance"}
                />
              )}
            />

            <Controller
              control={control}
              name="participantPosition"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  label="Status"
                  placeholder="Status"
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={!!errors.participantPosition}
                  helperText={errors?.participantPosition?.message}
                  required
                  value={"Approved"}
                />
              )}
            />
          </div>

          <Typography className="mt-16 text-2xl font-semibold tracking-tight leading-tight">
            Please check the box indicating the reason for the worksite
            transfer:
          </Typography>
          <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
            <Controller
              control={control}
              name="safetyIssue"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Safety issue (e.g. must have a police report, where applicable) "
                  labelPlacement="end"
                />
              )}
            />
          </div>
          <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
            <Controller
              control={control}
              name="healthConcern"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Health concern (Include a doctor's statement indicating the reason why tasks cannot be performed)"
                  labelPlacement="end"
                  checked
                />
              )}
            />
          </div>
          <div className="flex items-start gap-16 mt-16 ml-10 mr-10">
            <Controller
              control={control}
              name="siteClosure"
              render={({ field }) => (
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Site closure"
                  labelPlacement="end"
                />
              )}
            />
          </div>

          <Typography className="text-1xl tracking-tight mt-16 leading-tight">
            Please provide a detailed explanation supporting your transfer
            request. You may include attachments. Please note that failure to
            provide supporting documentation regarding your transfer request
            will result in an immediate rejection.
          </Typography>
          <div>
            <Controller
              control={control}
              name="detailedExplanationForTransfer"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-16 w-full"
                  variant="outlined"
                  fullWidth
                  multiline
                  size="small"
                  value={"Due to the health Concern David is requesting to transfer to a much closer location"}
                />
              )}
            />
          </div>
        </div>
      </Card>

    </>
  );
}
