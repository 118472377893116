import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Card, Button, Radio, RadioGroup, FormControlLabel, FormControl,TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useTranslation } from "react-i18next";

interface CalendarDateClickArg {
  date: Date;
}

function YouthTimeSheetTable() {
  const { t } = useTranslation("youthTimeSheetPage");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [invalue, setInValue] = useState<Dayjs | null>(dayjs(''));
  const [value, setValue] = useState<Dayjs | null>(dayjs(''));
  const [timeDifference, setTimeDifference] = useState<number | null>(null);

  const handleDateClick = (arg: CalendarDateClickArg) => {
    setSelectedDate(arg.date);
    setShowPopup(true);
  };



  const handleClosePopup = () => {
    setShowPopup(false);
  };


  const calculateTimeDifference = () => {
    if (value) {
      const inTime = dayjs(invalue);
      const outTime = dayjs(value);
      const diff = outTime.diff(inTime, 'hour', true);
      return diff.toFixed(2); // Returns the time difference in hours with two decimal places
    }
    return null;
  };

  return (
    <div className='w-4/5 m-24'>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        initialView='dayGridMonth'
        selectable={true}
        dateClick={handleDateClick}
        editable={true}
        selectMirror={true}
        dayMaxEvents={true}
        buttonText={{
            today: 'Today',
            month: 'Month',
            week: 'Week',
            day: 'Day'
          }}
      />

      {showPopup && (
        <Card variant='outlined' className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-opacity-100 z-50 p-6 rounded-md shadow-md'>
          <div className="flex flex-col">
          <h3>{t("SELECTED_DATE")}: {selectedDate?.toLocaleDateString()}</h3>
          <FormControl className='mt-6 mb-6'>
            <RadioGroup row aria-label='row-radio-buttons-group' name='row-radio-buttons-group'>
              <FormControlLabel value='workingday' control={<Radio />} label={t("WORKING_DAY")} />
              <FormControlLabel value='nonworkingday' control={<Radio />} label={t("NON_WORKING_DAY")} />
              <FormControlLabel value='holiday' control={<Radio />} label={t("HOLIDAY")} />
            </RadioGroup>
          </FormControl>
          <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker className='mr-4' label={t("IN_TIME")} value={invalue} onChange={(newValue)=>setInValue(newValue)}/>
            <TimePicker label={t("OUT_TIME")} value={value} onChange={(newValue) => setValue(newValue)} />
          </LocalizationProvider>
          </div>
          <p className='mt-6 mb-6'>{t("TIME_DIFFERENCE")}: {calculateTimeDifference()} hours</p>
          <TextField
          id="outlined-multiline-static"
          label={t("WORKDONE")}
          multiline
          rows={4}
          className='mt-6'
          />
          <Button variant='outlined' className='bg-blue rounded-4 mt-10 w-10' onClick={handleClosePopup}>
            {t("SUBMIT")}
          </Button>
          </div>
        </Card>
      )}
    </div>
  );
}

export default YouthTimeSheetTable;
