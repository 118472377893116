import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ArrowDownward";
import WorksiteTable from "./components/Worksite-Table";
import WorksiteSearchFilter from "./components/Worksite-Search-Filter";
import IndexHeader from "app/shared-components/PageHeader";
export default function WorksiteIndex() {
  return (
    <> 
      <IndexHeader
          name={"Worksite Index"}
          update={false}
          add={false}
          redirectingPath={"/worksite/add"}
        />
    <WorksiteSearchFilter />
    <WorksiteTable />

    </>
  );
}
