import React from "react";
import { Navigate } from "react-router-dom";
import JobApplicationIndexPage from "./jobAplicationsIndex/jobApplicationsPage";
import JobApplications from "./jobApplications";
import { UserRole } from "src/app/@types/enums/user.roles";

export const JobApplicationsRoutes = {
  settings: {
    layout: {},
  },
  auth: [UserRole.Host],
  routes: [
    {
      path: "job-applications",
      element: <JobApplications />,
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <JobApplicationIndexPage />,
        },
      ],
    },
  ],
};
