import axios from "axios";
import { authDataService } from "../data/auth.data.service";
import { CoreService } from "./core.service";
import { User } from "src/app/@types/interfaces/user.interface";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export default class AuthService extends CoreService {
  signIn = (
    email: string,
    password: string
  ): Promise<{ message: string; is2FAEnabled: boolean; tokenPayload: any }> => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/auth/login`, {
          email,
          password,
        })
        .then(async (response) => {
          const { data, success, message } = response.data;
          if (success) {
            if (data.authResponse.is2FAEnabled) {
              await authDataService.setInitialValues(data.authResponse.token);
            } else {
              await authDataService.setSession(data.authResponse.token);
            }
            const tokenPayload = await authDataService.decodeToken(
              data.authResponse.token
            );
            resolve({
              message,
              is2FAEnabled: data.authResponse.is2FAEnabled,
              tokenPayload,
            });
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  };

  logout = (id: string): Promise<null> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}/auth/logout/${id}`)
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(message);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  };

  generateOtp = (type: number): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      axios
        .post(`${BASE_URL}/auth/generateOTPCode`, {
          twoFAType: type,
          validationToken: await authDataService.getToken(),
        })
        .then(async (response) => {
          const { data, success, message } = response.data;
          if (success) {
            await authDataService.saveToken(data.validationToken);
            resolve(message);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  };

  verifyOtp = (
    otpCode: number
  ): Promise<{ message: string; tokenPayload: any }> => {
    return new Promise(async (resolve, reject) => {
      axios
        .post(`${BASE_URL}/auth/verifyOTPCode`, {
          otpCode: otpCode.toString(),
          validationToken: await authDataService.getToken(),
        })
        .then(async (response) => {
          const { data, success, message } = response.data;
          if (success) {
            await authDataService.clearToken();
            await authDataService.setSession(data.token);
            const tokenPayload = await authDataService.decodeToken(data.token);
            resolve({
              message,
              tokenPayload,
            });
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  };

  async signUp(user: User): Promise<any> {
    const baseOrigin = window.location.origin;
    const baseUrl = `${baseOrigin}/user`;
    return new Promise((resolve, reject) => {
      this.axios
        .post(`${process.env.REACT_APP_BASE_API_URL}/user`, {
          ...user,
        })
        .then(async (response) => {
          const { success, message, data } = response.data;
          if (success) {
            resolve(response.data);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }
}

export const authService = new AuthService();
