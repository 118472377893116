import React from "react";
import AdminTerminationFormAddPage from "./admin-form-termination-form-add/AdminTerminationFormAddPage";
import AdminTerminationFormIndexPage from "./admin-form-termination-index/AdminTerminatiomFormIndexPage";
import AdminTerminationForm from "./admin-termination-form";
import { Navigate } from "react-router-dom";
import { UserRole } from "src/app/@types/enums/user.roles";
export const AdminTerminationFormRoutes = {
  settings: {
    layout: {},
  },
  auth: null,
  routes: [
    {
      path: "admin-termination-form",
      element: <AdminTerminationForm />,
      auth: [UserRole.Admin],
      children: [
        {
          path: "",
          element: <Navigate to="list" />,
        },
        {
          path: "list",
          element: <AdminTerminationFormIndexPage />,
        },
        {
          path: "add",
          element: <AdminTerminationFormAddPage />,
        },
        {
          path: "update/:id",
          element: <AdminTerminationFormAddPage />,
        },
      ],
    },
  ],
};
