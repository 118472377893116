const locale = {
  TWO_STEP_VERIFICATION: "2-Step Verification",
  PLEASE_SELECT_AUTHENTICATION: "Please select your authentication method",
  EMAIL: "Email",
  GET_VERIFICATION_CODE: "Get verification code at ",
  SMS: "SMS",
  OR_TRY: "Or try",
  SIGN_IN: "Sign in",
  AGAIN: "Again!",

  //verification page
  TEXT_MESSAGE_SENT:
    "A text message with 4-digit verification code was sent to ",
  EMAIL_MESSAGE_SENT: "An Email with 4-digit verification code was sent to",
  ENTER_CODE: "Enter Code",
  NEXT: "Next",

  ENTER_CODE_LENGTH_ERROR: "Code length must be 6 digits.",
  CODE_REQUIRED_ERROR: "Verification Code is Required.",
};

export default locale;
