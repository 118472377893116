import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/Add";
import Video from "./compoent/video";
import Test from "./compoent/test";
import InnerFooter from "app/shared-components/InnerFooter";
import { useTranslation } from "react-i18next";
import { Card } from "@mui/material";

export default function YouthJobApplicationBankingPage() {
  const { t } = useTranslation("JobApplication");
  return (
    <>
      <Typography className="text-20 ml-24 mb-16 mt-8 font-semibold tracking-tight leading-tight">
        {t("INDEX_NAME_BANKING")}
      </Typography>
      <div className="m-24">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="mt-8 text-2xl sm:text-1xl font-bold tracking-tight leading-tight">
              {t("View_Video")}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Video />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <div className="mt-8 text-2xl sm:text-1xl font-bold tracking-tight leading-tight">
              {t("Test_Title")}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Test />
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
