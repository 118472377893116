import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React from "react";
import Box from "@mui/material/Box";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  FormGroup,
} from "@mui/material";

const defaultValues = { name: "", email: "", subject: "", message: "" };
const schema = yup.object().shape({
  name: yup.string().required("You must enter a name"),
  subject: yup.string().required("You must enter a subject"),
  message: yup.string().required("You must enter a message"),
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
});

function GenaralInfo() {
  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  if (_.isEmpty(form)) {
    return null;
  }

  return (
    <div className="flex flex-col p-10 sm:p-10 container">
      <div className="flex flex-col w-full max-w-6xl">
        <div className="sm:mt-5"></div>

        <Paper className="mt-5 sm:mt-5 p-24 pb-28 sm:p-5 sm:pb-10 rounded-2xl">
          <form className="px-0 sm:px-24">
            <div className="mb-24"></div>
            <div className="space-y-32">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Year</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Year"
                  >
                    <MenuItem value={10}>2021</MenuItem>
                    <MenuItem value={20}>2022</MenuItem>
                    <MenuItem value={30}>2023</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Typography className="mb-40" component="div">
                Please tell us a little more about your agency/organization.
                This information will help us to better serve you throughout our
                partnership.
              </Typography>
              <div>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      What other services are you interested?
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="What other services are you interested?"
                    >
                      <MenuItem value={10}>Financial Literacy</MenuItem>
                      <MenuItem value={20}>Wrap Around Services</MenuItem>
                      <MenuItem value={30}>
                        Year Round Youth Internship Services
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Do you plan to hire a MBSYEP participant after the program
                    ends?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="unsure"
                      control={<Radio />}
                      label="Unsure"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Would you like additional information and resources?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="unsure"
                      control={<Radio />}
                      label="Unsure"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <Typography className="mb-40" component="div">
                  Please check all that apply to your agency/organization:
                </Typography>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="There are youth currently enrolled in my year-round program who will apply to MBSYEP and need to be assigned to my worksite."
                      labelPlacement="end"
                      className="left-auto"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="There are youth who are required to attend my mandatory Summer Bridge Program as part of their requirement to attend my school in the fall."
                      labelPlacement="end"
                      className="left-auto"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="I run a special needs program/school and there are youth currently enrolled in my program who will apply to MBSYEP and need to be assigned to my work site."
                      labelPlacement="end"
                      className="left-auto"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="I run an Upward Bound Program and there are youth who have been or will be accepted in my program, will apply to MBSYEP, and need to be assigned to my worksite."
                      labelPlacement="end"
                      className="left-auto"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="I would like for DOES to assist me with identifying youth participants to be assigned to my work site."
                      labelPlacement="end"
                      className="left-auto"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="I am interested in participating in other hiring initiatives sponsored by DOES or its partner."
                      labelPlacement="end"
                      className="left-auto"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Youth from this site will participate in the Earn and LEarn Program."
                      labelPlacement="end"
                      className="left-auto"
                    />
                  </FormGroup>
                </FormControl>
              </div>

              <Controller
                name="message"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Any other suggestions"
                    className="mt-16 w-full"
                    margin="normal"
                    multiline
                    minRows={4}
                    variant="outlined"
                    error={!!errors.message}
                    helperText={errors?.message?.message}
                    required
                  />
                )}
              />

              <div>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Has your organization participated in MBSYEP
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label=" Has your organization participated in MBSYEP"
                    >
                      <MenuItem value={10}>Yes</MenuItem>
                      <MenuItem value={20}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    As an MBSYEP host site, Is your site ADA compliant?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </form>
          <div className="flex items-center justify-end mt-32">
            <Button
              className="mx-8"
              variant="contained"
              color="secondary"
              disabled = {false}
            >
              Save
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default GenaralInfo;
