import React from "react";
import YouthProgram from "./youth-programs";
import QuestionnairePage from "./questionnaire-form/QusetionnairePage";
import EnrollFormPage from "./enroll-form/EnrollFormPage";


export const YouthProgramRoutes = {
  settings: {
    layout: {},
  },
  auth: null,
  routes: [
    {
      path: "youth-program",
      element: <YouthProgram />,
      children: [
        {
          path: "questionnaire",
          element: <QuestionnairePage />,
        },
        {
          path: "enroll",
          element: <EnrollFormPage />,
        },
      ],
    },
  ],
};
