import * as React from "react";
import IndexHeader from "app/shared-components/PageHeader";
import { useState } from "react";
import JobFilter from "./Components/JobFilter";
import JobTable from "./Components/JobTable";

export default function JobIndex() {
  return (
    <>
      <IndexHeader
        name={"Job Index"}
        update={false}
        add={false}
        redirectingPath={"/job-add"}
      />
      <JobFilter />
      <JobTable />
    </>
  );
}
