import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React from "react";
import Box from "@mui/material/Box";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  FormGroup,
} from "@mui/material";

const defaultValues = { name: "", email: "", subject: "", message: "" };
const schema = yup.object().shape({
  name: yup.string().required("You must enter a name"),
  subject: yup.string().required("You must enter a subject"),
  message: yup.string().required("You must enter a message"),
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
});

function FinancialSupport() {
  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

  if (_.isEmpty(form)) {
    return null;
  }

  return (
    <div className="flex flex-col p-10 sm:p-10 container">
      <div className="flex flex-col w-full max-w-6xl">
        <div className="sm:mt-5"></div>

        <Paper className="mt-5 sm:mt-5 p-24 pb-28 sm:p-5 sm:pb-10 rounded-2xl">

          <form className="px-0 sm:px-24">
            <div className="mb-24"></div>
            <div className="space-y-32">
            <div>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="I am interested in supporting the MBSYEP financially by contributing to the wages of my youth employees."
                      labelPlacement="end"
                      className="left-auto"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="I am interested in supporting the MBSYEP financially by making a one-time donation."
                      labelPlacement="end"
                      className="left-auto"
                    />
                  </FormGroup>
                </FormControl>
              </div>
   
            </div>
          </form>
          <div className="flex items-center justify-end mt-32">
            <Button
              className="mx-8"
              variant="contained"
              color="secondary"
              disabled = {false}
            >
              Save
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default FinancialSupport;
