const locale = {
  PROGRAM: "Program",
  YEAR: "Year",
  PAY_PERIOD: "Pay Period",
  INCLUDE_NOT: "Include not submitted records of previous pay period",
  DATE: "Date",
  COMPANY_ID: "Company ID",
  YOUTH_ID: "Youth ID",
  HOURS: "Hours",
  SUBMIT: "Submit",
};

export default locale;
