import React, { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import IndexHeader from "app/shared-components/PageHeader";
import {
  Button,
  Divider,
  IconButton,
  Menu,
  TableFooter,
  TablePagination,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
} from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Link, NavLink } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import { withTranslation, WithTranslation } from "react-i18next";


interface MyComponentProps extends WithTranslation {
    
  }

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#202441",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

class AdminYouthList extends Component <MyComponentProps> {
    
  state = {
    page: 0,
    rowsPerPage: 10,
    actionMenu: null,
  };

  // Initialize state variables using the useState hook
  setPage = (page: React.SetStateAction<number>) => {
    this.setState({ page });
  };

  setRowsPerPage = (rowsPerPage: number) => {
    this.setState({ rowsPerPage });
  };

  actionMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ actionMenu: event.currentTarget });
  };

  actionMenuClose = () => {
    this.setState({ actionMenu: null });
  };

  dataSet: any[] = [
    {
      id: 1,
      firstName: "John",
      lastName: "Walker",
      dob: "5/6/1970",
      age: "53",
      phone: "09878654567",
      ssn: "553-8990-98",
      email:"abc@gmail.com",
    },
    {
        id: 2,
        firstName: "John",
        lastName: "Browns",
        dob: "5/6/1989",
        age: "34",
        phone: "91234567324",
        ssn: "553-8990-98",
        email:"abc@gmail.com",
      },
      {
        id: 3,
        firstName: "David",
        lastName: "Williams",
        dob: "5/6/1989",
        age: "34",
        phone: "91234567324",
        ssn: "553-8990-98",
        email:"xyz@gmail.com",
      },
      {
        id: 4,
        firstName: "Brock",
        lastName: "Lesner",
        dob: "5/6/1999",
        age: "24",
        phone: "91234567324",
        ssn: "553-8990-98",
        email:"abc@gmail.com",
      },
  ];

  // Avoid a layout jump when reaching the last page with empty rows.
  emptyRows: number =
    this.state.page > 0
      ? Math.max(
          0,
          (1 + this.state.page) * this.state.rowsPerPage - this.dataSet.length
        )
      : 0;

  handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    this.setRowsPerPage(parseInt(event.target.value, 10));
    this.setPage(0);
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="m-24">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t("ID")}</StyledTableCell>
                  <StyledTableCell>{t("FIRST_NAME")}</StyledTableCell>
                  <StyledTableCell>{t("LAST_NAME")}</StyledTableCell>
                  <StyledTableCell>{t("DOB")}</StyledTableCell>
                  <StyledTableCell>{t("AGE")}</StyledTableCell>
                  <StyledTableCell>{t("PHONE")}</StyledTableCell>
                  <StyledTableCell>{t("SSN")}</StyledTableCell>
                  <StyledTableCell>{t("EMAIL")}</StyledTableCell>
                  <StyledTableCell>{t("ACTION")}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.dataSet.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>{row.id}</StyledTableCell>
                    <StyledTableCell>{row.firstName}</StyledTableCell>
                    <StyledTableCell>{row.lastName}</StyledTableCell>
                    <StyledTableCell>{row.dob}</StyledTableCell>
                    <StyledTableCell>{row.age}</StyledTableCell>
                    <StyledTableCell>{row.phone}</StyledTableCell>
                    <StyledTableCell>{row.ssn}</StyledTableCell>
                    <StyledTableCell>{row.email}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        className="-m-6"
                      >
                      <Link to ="/" target="_blank">
                        <Button size="small"  style={{borderRadius:5}}>{t("VIEW")}</Button>
                      </Link>
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    colSpan={4}
                    count={this.dataSet.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  }
}
export default withTranslation("youthList")(AdminYouthList);

