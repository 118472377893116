import React, { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import IndexHeader from "app/shared-components/PageHeader";
import {
  Button,
  Divider,
  IconButton,
  Menu,
  TableFooter,
  TablePagination,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
} from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Link, NavLink } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#202441",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default class IncidentFormList extends Component {
  // Define state variables and their types
  state = {
    page: 0,
    rowsPerPage: 10,
    actionMenu: null,
  };

  // Initialize state variables using the useState hook
  setPage = (page: React.SetStateAction<number>) => {
    this.setState({ page });
  };

  setRowsPerPage = (rowsPerPage: number) => {
    this.setState({ rowsPerPage });
  };

  actionMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ actionMenu: event.currentTarget });
  };

  actionMenuClose = () => {
    this.setState({ actionMenu: null });
  };

  dataSet: any[] = [
    {
      id: 1,
      programName: "SYEP",
      year: "2023",
      receivedDate: "7/7/2023",
      worksite: "Head Office",
      dateOfIncident: "7/7/2023",
      supervisor: "James Smith",
      status: "Approved",
    },
    {
      id: 2,
      programName: "ISYP",
      year: "2023",
      receivedDate: "1/5/2023",
      worksite: "Head Office",
      dateOfIncident: "1/5/2023",
      supervisor: "Poll Wolker",
      status: "Submitted",
    },
    {
      id: 3,
      programName: "ISYP",
      year: "2022",
      receivedDate: "2/10/2022",
      worksite: "Head Office",
      dateOfIncident: "2/10/2022",
      supervisor: "Steve Jobs",
      status: "Draft",
    },
    {
      id: 4,
      programName: "ISYP",
      year: "2022",
      receivedDate: "2/12/2022",
      worksite: "Head Office",
      dateOfIncident: "2/12/2022",
      supervisor: "Andrew Simon",
      status: "Denied",
    },
    
  ];

  // Avoid a layout jump when reaching the last page with empty rows.
  emptyRows: number =
    this.state.page > 0
      ? Math.max(
          0,
          (1 + this.state.page) * this.state.rowsPerPage - this.dataSet.length
        )
      : 0;

  handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    this.setRowsPerPage(parseInt(event.target.value, 10));
    this.setPage(0);
  };

  render() {
    return (
      <>
        <div className="m-24">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Program</StyledTableCell>
                  <StyledTableCell>Year</StyledTableCell>
                  <StyledTableCell>Received Date</StyledTableCell>
                  <StyledTableCell>Worksite</StyledTableCell>
                  <StyledTableCell>Date of Incident</StyledTableCell>
                  <StyledTableCell>Supervisor</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.dataSet.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>{row.programName}</StyledTableCell>
                    <StyledTableCell>{row.year}</StyledTableCell>
                    <StyledTableCell>{row.receivedDate}</StyledTableCell>
                    <StyledTableCell>{row.worksite}</StyledTableCell>
                    <StyledTableCell>{row.dateOfIncident}</StyledTableCell>
                    <StyledTableCell>{row.supervisor}</StyledTableCell>
                    <StyledTableCell>{row.status}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        className="-m-8"
                        onClick={this.actionMenuClick}
                      >
                        <GridMoreVertIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    colSpan={4}
                    count={this.dataSet.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <Popover
          open={Boolean(this.state.actionMenu)}
          anchorEl={this.state.actionMenu}
          onClose={this.actionMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: "py-8",
          }}
        >
          <MenuItem component={Link} to="/incident-form/update/1" role="button">
            <ListItemIcon className="min-w-40">
              <Edit />
            </ListItemIcon>
            <ListItemText primary="Update" />
          </MenuItem>
          <MenuItem component={Link} to="/incident-form/list" role="button">
            <ListItemIcon className="min-w-40">
              <Delete />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        </Popover>
      </>
    );
  }
}
