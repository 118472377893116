import {
  PayRollSingleWorkerAdd,
  PaychexChecksResponse,
  PaychexUserResponse,
  WorkerAdd,
} from "src/app/@types/interfaces/paychex.interface";
import { CoreService } from "./core.service";
import { ResponseData } from "src/app/@types/interfaces/get.response";

export default class PaychexService extends CoreService {
  async createPaychexUser(workerData: WorkerAdd): Promise<string> {
    return new Promise((resolve, reject) => {
      this.axios
        .post(`${process.env.REACT_APP_BASE_API_URL}/paychex/add-worker`, {
          ...workerData,
        })
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(message);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }

  async submitUserPayroll(
    payrollData: PayRollSingleWorkerAdd
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.axios
        .post(`${process.env.REACT_APP_BASE_API_URL}/paychex/add-check`, {
          ...payrollData,
        })
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(message);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }

  async getPaychexUSerDataByUserId(
    userId?: string
  ): Promise<ResponseData<PaychexUserResponse>> {
    return new Promise((resolve, reject) => {
      this.axios
        .get(`${process.env.REACT_APP_BASE_API_URL}/paychex/${userId}`)
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(response.data);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }

  async getPaychexChecksDataByUserId(
    userId?: string
  ): Promise<ResponseData<PaychexChecksResponse[]>> {
    return new Promise((resolve, reject) => {
      this.axios
        .get(
          `${process.env.REACT_APP_BASE_API_URL}/paychex/get-checks/${userId}`
        )
        .then(async (response) => {
          const { success, message } = response.data;
          if (success) {
            resolve(response.data);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  }
}

export const paychexService = new PaychexService();
